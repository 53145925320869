/** @format */
import { useTranslation } from "react-i18next";
import React from "react";
function LatestCustomer({ toggles }) {
  const { t } = useTranslation();
  const names = [
    t("henry_rashford"),
    t("jack_chidwell"),
    t("marie_jones"),
    t("markus_rashford"),
    t("julia_bennet"),
  ];
  return (
    <div
      className={`card  fs-12 ${toggles ? "expandWidth" : "customCustomer"}`}
    >
      <div className="d-flex justify-content-between p-2">
        <b className="ps-3 pt-2 fs-5 mb-2 fw-bolder">{t("latest_customers")}</b>
        <i className="fa-solid fa-ellipsis me-2 mt-2 fs-4 text-secondary"></i>
      </div>
      <ol className="scroll">
        {names.map((name) => (
          <div
            className="border-bottom d-flex justify-content-between"
            key={name}
          >
            <li className="p-3 me-2 fw-bold">{name}</li>
            <i className="fa-solid fa-chevron-down mt-3 me-4 text-secondary dropDown"></i>
          </div>
        ))}
      </ol>
    </div>
  );
}

export default LatestCustomer;
