import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Popper,
  TextField,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import {
  allowLettersNumbersAndSpace,
  formatDateForInput,
  handleKeyDownNumbers,
} from "../../common/helpers";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import MapComponent from "../../MapFunction";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

function UpdateEvse({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [statusList, setStatusList] = React.useState([]);
  const [capabilitiesList, setCapabilitiesList] = React.useState([]);
  const [pRestrictionsList, setPRestrictionsList] = React.useState([]);
  const [statusMessage, setStatusMessage] = React.useState("");
  const location = useLocation();
  const [loader, setLoader] = React.useState(false);
  const [mapOpen, setMapOpen] = React.useState(false);
  const [statusObj, setStatusobj] = React.useState({
    period_begin: "",
    period_end: "",
    status: "",
  });
  const [evseObj, setEvseObj] = React.useState({
    physicalReference: "",
    floorLevel: "",
    status: "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
    capabilities: [],
    parkingRestrictions: [],
    statusSchedule: [],
    images: [],
    directions: [],
  });
  const [errors, setErrors] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);

  React.useEffect(() => {
    getEVSEStatus();
    getCapabilities();
    getPRestrictions();
    getEVSEById();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getEVSEStatus();
      getCapabilities();
      getPRestrictions();
      getEVSEById();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const navigate = useNavigate();

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const getEVSEById = async () => {
    let Url = `api/locations/getEvse/${location.state.uid}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      evseObj.evseId = res?.data.evseId;
      evseObj.capabilities = res?.data.capabilities;
      evseObj.physicalReference = res?.data.physicalReference;
      evseObj.floorLevel = res?.data.floorLevel;
      evseObj.status = res?.data.status;
      evseObj.coordinates = res?.data.coordinates;
      evseObj.parkingRestrictions = res?.data.parkingRestrictions;
      setStatusobj(res?.data?.statusSchedule[0]);
    }
  };

  const getPRestrictions = async () => {
    let Url = `api/locations/parkingRestrictions`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setPRestrictionsList(res?.data);
    }
  };
  const getEVSEStatus = async () => {
    let Url = `api/locations/getStatusDropdown`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setStatusList(res?.data);
    }
  };
  const getCapabilities = async () => {
    let Url = `api/locations/CapabilityDropdown`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setCapabilitiesList(res?.data);
    }
  };
  const longitudeRegex = /^-?[0-9]{1,3}\.[0-9]{5,7}$/;

  const validateForm = () => {
    const newErrors = {};
    if (!evseObj.evseId) newErrors.evseId = "EVSE ID is required";
    if (!evseObj.physicalReference)
      newErrors.physicalReference = "Physical Reference is required";
    if (!evseObj.floorLevel) newErrors.floorLevel = "Floor Level is required";
    if (!evseObj.status) newErrors.status = "Status is required";
    if (!evseObj.coordinates.latitude)
      newErrors.latitude = "Latitude is required";
    if (!evseObj.coordinates.longitude)
      newErrors.longitude = "Longitude is required";
    if (
      !Array.isArray(evseObj.capabilities) ||
      evseObj.capabilities.length === 0 ||
      evseObj.capabilities.some((f) => !f.trim())
    ) {
      newErrors.capabilities = "At least one Capability must be provided";
    }
    if (
      !Array.isArray(evseObj.parkingRestrictions) ||
      evseObj.parkingRestrictions.length === 0 ||
      evseObj.parkingRestrictions.some((f) => !f.trim())
    ) {
      newErrors.parkingRestrictions =
        "At least one Parking Restrictions must be provided";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleChange = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setEvseObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOpenmap = () => {
    setMapOpen((open) => !open);
  };
  const handleStatusObjChange = (name, value) => {
    setStatusobj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCoordinatesChange = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setEvseObj((prevState) => ({
      ...prevState,
      coordinates: {
        ...prevState.coordinates,
        [name]: value,
      },
    }));
    let valid = longitudeRegex.test(value);
    if (valid) {
      setErrors({ ...errors, [name]: "" });
    } else {
      setErrors({ ...errors, [name]: "Enter valid value eg: 12.32140" });
    }
  };
  const handleEvseObjChange = (name, newValue) => {
    setErrors({ ...errors, [name]: "" });
    setEvseObj((prevState) => ({
      ...prevState,
      [name]: Array.isArray(newValue) ? newValue : [], // Ensure `newValue` is an array
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const EVSEObj = evseObj;
      EVSEObj.statusSchedule = statusObj == null ? [] : [statusObj];
      let Url = `api/locations/updateEvse/${location.state.uid}`;
      let type = "PUT";
      let res = await handleApi_call(
        Url,
        headers,
        type,
        EVSEObj,
        setSessionExpire,
        setAlertMessage,
        handleAlertPopup,
        setLoader
      );
      if (res?.statusCode === 1000) {
        setStatusMessage("SUCCESS");
        setAlertMessage(res?.data);
        handleAlertPopup();
      } else {
        setStatusMessage("FAILURE");
        setAlertMessage(res?.data);
        handleAlertPopup();
      }
    }
  };
  return (
    <div className="mb-3">
      <div className="pagetitle">Update EVSE</div>
      <div
        sx={{ minWidth: 275 }}
        style={{
          borderRadius: "25px",
          marginTop: "14px",
          backgroundColor: "white",
        }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>EVSE ID *</b>
                </FormLabel>
                <TextField
                  disabled
                  className="w-100"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={evseObj?.evseId}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Physical Reference *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 100,
                  }}
                  onKeyDown={allowLettersNumbersAndSpace}
                  value={evseObj.physicalReference}
                  onChange={(e) =>
                    handleChange("physicalReference", e.target.value)
                  }
                  error={errors.physicalReference}
                  helperText={errors.physicalReference}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Floor Level *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 5,
                  }}
                  onKeyDown={handleKeyDownNumbers}
                  value={evseObj.floorLevel}
                  onChange={(e) => handleChange("floorLevel", e.target.value)}
                  error={errors.floorLevel}
                  helperText={errors.floorLevel}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>EVSE Status *</b>
                </FormLabel>
                <TextField
                  required
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={evseObj.status}
                  onChange={(e) => handleChange("status", e.target.value)}
                  error={errors.status}
                  helperText={errors.status}
                >
                  {statusList.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>

            <div className="col-4 position-relative">
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={capabilitiesList}
                disablePortal
                getOptionLabel={(option) => option}
                value={evseObj?.capabilities}
                // value={[]}
                onChange={(event, newValue) =>
                  handleEvseObjChange("capabilities", newValue)
                }
                renderInput={(params) => (
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Select Capabilities *</b>
                    </FormLabel>
                    <TextField
                      {...params}
                      placeholder="Capabilities"
                      error={Boolean(errors.capabilities)}
                      helperText={errors.capabilities}
                    />
                  </FormControl>
                )}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      width: "calc(100% - 16px)",
                      marginLeft: "6px",
                      marginTop: "6px",
                    }}
                    placement="bottom-start"
                  />
                )}
              />
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Schedule Status *</b>
                </FormLabel>
                <TextField
                  required
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={statusObj?.status}
                  onChange={(e) =>
                    handleStatusObjChange("status", e.target.value)
                  }
                >
                  {statusList.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>period Begin *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={
                    statusObj?.period_begin
                      ? formatDateForInput(statusObj.period_begin)
                      : ""
                  }
                  onChange={(e) => {
                    handleStatusObjChange("period_begin", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>period End *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={
                    statusObj?.period_end
                      ? formatDateForInput(statusObj.period_end)
                      : ""
                  }
                  onChange={(e) => {
                    handleStatusObjChange("period_end", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4 position-relative">
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={pRestrictionsList}
                disablePortal
                getOptionLabel={(option) => option}
                value={evseObj.parkingRestrictions}
                onChange={(event, newValue) =>
                  handleEvseObjChange("parkingRestrictions", newValue)
                }
                renderInput={(params) => (
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Parking Restrictions *</b>
                    </FormLabel>
                    <TextField
                      {...params}
                      placeholder="Parking Restrictions"
                      error={Boolean(errors.parkingRestrictions)}
                      helperText={errors.parkingRestrictions}
                    />
                  </FormControl>
                )}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      width: "calc(100% - 16px)",
                      marginLeft: "6px",
                      marginTop: "6px",
                    }}
                    placement="bottom-start"
                  />
                )}
              />
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Latitude *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={evseObj.coordinates.latitude}
                  onChange={(e) =>
                    handleCoordinatesChange("latitude", e.target.value)
                  }
                  error={errors.latitude}
                  helperText={errors.latitude}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Longitude *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={evseObj.coordinates.longitude}
                  onChange={(e) =>
                    handleCoordinatesChange("longitude", e.target.value)
                  }
                  error={errors.longitude}
                  helperText={errors.longitude}
                />
              </FormControl>
            </div>
            <div className="col-4 d-flex align-items-end">
              <button
                className=" d-flex align-items-center justify-content-center selectMap gap-2"
                onClick={handleOpenmap}
              >
                <img
                  src="map-icon-new.png"
                  alt="map-icon"
                  className="h-24-w-24"
                />
                <span>Select on Map</span>
              </button>
            </div>
          </div>
        </CardContent>
      </div>
      <div className="d-flex justify-content-end gap-3 mt-4">
        <Link to="/viewLocation">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          className="submit-btn"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
      <PopUp
        open={mapOpen}
        title={""}
        message={
          <MapComponent
            locationFields={evseObj.coordinates}
            setLocationFields={(updatedCoords) =>
              setEvseObj((prevState) => ({
                ...prevState,
                coordinates: updatedCoords,
              }))
            }
          />
        }
        btn1={true}
        btn1Color={"cancel-btn"}
        btn1Text={"Cancel"}
        handleClickBtn1={() => setMapOpen(false)}
        btn2={true}
        btn2Color={"submit-btn"}
        btn2Text={"Submit"}
        handleClickBtn2={() => setMapOpen(false)}
        popUpWidth={"mw-920"}
      />
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={
          statusMessage === "SUCCESS"
            ? () => navigate("/ViewLocation")
            : handleAlertPopup
        }
        popUpWidth={"width-100"}
      />
      {sessionExpire && <SessionExpire />}
      {loader && <FullLoader />}
    </div>
  );
}

export default UpdateEvse;
