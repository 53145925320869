import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import ChangeAvailability from "./ChangeAvailability";
import ChangeConfiguration from "./ChangeConfiguration";
import GetDiagnostics from "./GetDiagnostics";
import RemoteStartTransaction from "./RemoteStartTransaction";
import RemoteStopTransaction from "./RemoteStopTransaction";
import Reset from "./Reset";
import ClearCache from "./ClearCache";
import UnlockConnector from "./UnlockConnector";
import DataTransfer from "./DataTransfer";
import SendLocalList from "./SendLocalList";
import TriggerMessage from "./TriggerMessage";
import GetCompositeSchedule from "./GetCompositeSchedule";
import GetTransactionStatus201 from "../Operations201/GetTransactionStatus201";
import UnpublishFirmware201 from "../Operations201/UnpublishFirmware201";
import GetLog201 from "../Operations201/GetLog201";
import ClearChargingProfile from "./ClearChargingProfile";
import SetChargingProfile from "./SetChargingProfile";
import CancelReservation from "./CancelReservation";
import UpdateFirmware from "./UpdateFirmware";
import { basePathEV } from "../../common/config";
import PopUp from "../../components/PopUp";
import SessionExpire from "../../components/SessionExpire";
import FullLoader from "../../components/FullLoader";
import GetConfigurations from "./GetConfigurations";
import DeleteCertificate from "./DeleteCertificate";
import ExtendedTriggerMessage from "./ExtendedTriggerMessage";
import GetInstalledCertificateIds from "./GetInstalledCertificateIds";
import GetLog from "./GetLog";
import InstallCertificate from "./InstallCertificate";
import SignedUpdateFirmware from "./SignedUpdateFirmware";
import ReserveNow from "./ReserveNow";
import GetLocalListVersion from "./GetLocalListVersion";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
import ChangeAvailability20 from "../Operations201/ChangeAvailability20";
import RequestStartTransaction20 from "../Operations201/RequestStartTransaction20";
import RequestStopTransaction20 from "../Operations201/RequestStopTransaction20";
import UnlockConnector20 from "../Operations201/UnlockConnector20";
import TriggerMessage20 from "../Operations201/TriggerMessage20";
import CancelReservation20 from "../Operations201/CancelReservation20";
import ReserveNow20 from "../Operations201/ReserveNow20";
import UpdateFirmware20 from "../Operations201/UpdateFirmware20";

function Operations({ refresh, setRefresh }) {
  const [operations, setOperations] = React.useState([]);
  const [selectedOperation, setSelectedOperation] =
    React.useState("ChangeAvailability");
  const [csInventory, setCsInventory] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [selectSearchValue, setSelectSearchValue] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [entries, setEntries] = React.useState(10);
  const [selectedChargePoints, setSelectedChargePoints] = React.useState([]);
  const [chargePointError, setChargePointError] = React.useState("");
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [id, setId] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [protocol, setProtocol] = React.useState("1.6");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [alertPopUp, setAlertPopUp] = React.useState(false);
  const [protocolError, setProtocolError] = React.useState("");
  const radio = [
    "RemoteStartTransaction",
    "RemoteStopTransaction",
    "UnlockConnector",
    "SendLocalList",
    "SetChargingProfile",
    "ChangeConfiguration",
    "GetConfiguration",
  ];

  function handleAlertPopUp() {
    setAlertPopUp((pop) => !pop);
  }
  useEffect(() => {
    getOperations(protocol);
  }, [protocol]);
  useEffect(() => {
    GetChargeStations();
  }, [entries, page]);
  React.useEffect(() => {
    if (refresh) {
      getOperations();
      GetChargeStations();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  async function getOperations() {
    let Url = `csinfo/${protocol}/getOperations`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopUp,
      setLoader
    );
    if (res && res?.status) {
      setOperations(res?.data);
    }
  }

  const GetChargeStations = async () => {
    let Url = `csinfo/getChargingStaion`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopUp,
      setLoader
    );

    if (res && res?.status) {
      setCsInventory(res?.data);
    }
  };

  function handleSubmitOperation(error, payload) {
    if (selectedChargePoints.length === 0 || !protocol) {
      error = true;
    }
    if (error) {
      if (selectedChargePoints.length === 0) {
        setChargePointError("*Location is Mandatory");
      }
      if (!protocol) {
        setProtocolError("Please select Protocol");
      }
    } else {
      setLoader(true);
      let headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("Authorization"),
        "X-TenantID": localStorage.getItem("tenantId"),
      };

      fetch(
        `${basePathEV}api/cs/cmps-call/${localStorage.getItem("tenantId")}/${
          selectedChargePoints[0]
        }/${selectedOperation}?protocol=${protocol}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        }
      ).then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else if (resp.status === 200) {
          setStatusMessage("SUCCESS");
          setAlertMessage(
            selectedOperation + " Operation Initiated Successfully."
          );
          handleAlertPopUp();
        } else {
          setStatusMessage("FAILURE");
          setAlertMessage(selectedOperation + " Operation failed to Initiate.");
          handleAlertPopUp();
        }
      });
    }
  }

  function handleSelectedOperation(e) {
    if (
      (radio.includes(selectedOperation) && !radio.includes(e.target.value)) ||
      (!radio.includes(selectedOperation) && radio.includes(e.target.value))
    ) {
      setSelectedChargePoints([]);
      GetChargeStations();
    }
    setChargePointError("");
    setSelectedOperation(e.target.value);
  }

  const removeCommonElements = () => {
    const filteredArray = csInventory.filter(
      (item) => !selectedChargePoints.includes(item)
    );
    setCsInventory(filteredArray); // Update array1 by removing common elements
  };

  const handleSelectingStations = () => {
    if (!selectSearchValue) {
      setErrorMessage("Please select Locations");
      return;
    }
    setErrorMessage("");
    if (radio.includes(selectedOperation)) {
      setSelectedChargePoints([selectSearchValue]);
      setId(selectSearchValue);
      setSelectSearchValue("");
    } else {
      setSelectedChargePoints([...selectedChargePoints, selectSearchValue]);
      setSelectSearchValue("");
    }
  };

  React.useEffect(() => {
    if (!radio.includes(selectedOperation)) {
      removeCommonElements();
    }
  }, [selectedChargePoints]);

  function handleRemoveSelected(chargePointId) {
    setSelectedChargePoints((chargePoints) =>
      chargePoints.filter((chargePoint) => chargePoint !== chargePointId)
    );
  }

  const handleProtocolChange = (value) => {
    setProtocolError("");
    setProtocol(value);
    //getOperations(value);
  };
  const handleChangeSelect = (event, newValue) => {
    setChargePointError("");
    setSelectSearchValue(newValue); // Correctly set the selected value
  };

  return (
    <div style={{ width: "99%" }} className="mb-4">
      {loader && <FullLoader />}
      <Card sx={{ minWidth: 275 }} style={{ borderRadius: "25px" }}>
        <CardContent className="cardPadding">
          <div className="d-flex justify-content-start align-items-center ">
            <div className="d-flex gap-4">
              <div className="col-4 w-100">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Select Protocol *</b>
                  </FormLabel>
                  <TextField
                    required
                    select
                    size="small"
                    error={protocolError}
                    helperText={protocolError}
                    value={protocol}
                    onChange={(e) => handleProtocolChange(e.target.value)}
                  >
                    <MenuItem value={"1.6"}>OCPP16</MenuItem>
                    <MenuItem value={"2.0.1"}>OCPP201</MenuItem>
                  </TextField>
                </FormControl>
              </div>
              <div className="col-4 w-100">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Select Operations *</b>
                  </FormLabel>
                  <TextField
                    required
                    select
                    size="small"
                    value={selectedOperation}
                    onChange={handleSelectedOperation}
                  >
                    {operations?.map((operation) => (
                      <MenuItem value={operation} key={operation}>
                        {operation}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", backgroundColor: "white" }}
        className="mt-5"
      >
        <CardContent className="cardPadding ">
          <div className=" d-flex gap-2" style={{ height: "10.5rem" }}>
            <div className="border br-10 col-5 pe-3">
              <p className="mt-3 ms-3 fs-17" style={{ fontWeight: 600 }}>
                Search to select Locations
              </p>
              <div
                className="d-flex mt-2 h-auto ps-2 align-items-center justify-content-start"
                style={{ maxHeight: "9.5rem" }}
              >
                <div className="px-2 position-relative">
                  <Autocomplete
                    disablePortal
                    options={csInventory}
                    getOptionLabel={(option) => option}
                    sx={{ width: 300 }}
                    size="small"
                    value={selectSearchValue}
                    onChange={handleChangeSelect}
                    renderInput={(params) => (
                      <TextField {...params} label="Location" />
                    )}
                  />
                </div>

                <Button
                  style={{ backgroundColor: "#2DCC70" }}
                  variant="contained"
                  onClick={handleSelectingStations}
                >
                  Add
                </Button>
              </div>
              {errorMessage && (
                <p
                  style={{ color: "red", marginLeft: "25px" }}
                  className="mt-2"
                >
                  {errorMessage}
                </p>
              )}
            </div>
            <div className="border br-10 col-7 h-100 position-relative">
              <p className="p-2 mt-2 mb-3 fs-17" style={{ fontWeight: 600 }}>
                Selected Locations
              </p>
              <div className="overflow-auto" style={{ maxHeight: "12.5rem" }}>
                <div>
                  {selectedChargePoints.map((chargePoint) => (
                    <span
                      className="border p-1 ps-2 m-1 me-2 rounded-pill d-block"
                      style={{ float: "left" }}
                    >
                      {chargePoint}
                      <i
                        className="fa-solid fa-xmark ps-1"
                        onClick={() => handleRemoveSelected(chargePoint)}
                      ></i>
                    </span>
                  ))}
                </div>
              </div>
              {radio.includes(selectedOperation) ? (
                <span className="operations-Note-bottom p-2 mt-2 mb-2">
                  <b>Note :</b>
                  The {selectedOperation} allows only one Location to be
                  selected at a time.
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          {chargePointError && (
            <div className="fs-14 ps-1 fw-bold text-danger">
              {chargePointError}
            </div>
          )}
        </CardContent>
      </div>
      {protocol === "1.6" && (
        <div className="row row-gap-3 mt-3" style={{ width: "102%" }}>
          {selectedOperation === "ChangeAvailability" && (
            <ChangeAvailability handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "ChangeConfiguration" && (
            <ChangeConfiguration
              handleSubmitOperation={handleSubmitOperation}
              chargePointId={id}
            />
          )}
          {selectedOperation === "ClearCache" && (
            <ClearCache handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "GetDiagnostics" && (
            <GetDiagnostics handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "RemoteStartTransaction" && (
            <RemoteStartTransaction
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "RemoteStopTransaction" && (
            <RemoteStopTransaction
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "Reset" && (
            <Reset handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "UnlockConnector" && (
            <UnlockConnector handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "DataTransfer" && (
            <DataTransfer handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "SendLocalList" && (
            <SendLocalList handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "TriggerMessage" && (
            <TriggerMessage handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "GetCompositeSchedule" && (
            <GetCompositeSchedule
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "ClearChargingProfile" && (
            <ClearChargingProfile
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "SetChargingProfile" && (
            <SetChargingProfile
              handleSubmitOperation={handleSubmitOperation}
              setLoader={setLoader}
            />
          )}
          {selectedOperation === "UpdateFirmware" && (
            <UpdateFirmware handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "CancelReservation" && (
            <CancelReservation handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "GetConfiguration" && (
            <GetConfigurations
              handleSubmitOperation={handleSubmitOperation}
              selectedChargePoints={selectedChargePoints[0]}
              setLoader={setLoader}
              setSessionExpire={setSessionExpire}
            />
          )}
          {selectedOperation === "DeleteCertificate" && (
            <DeleteCertificate handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "ExtendedTriggerMessage" && (
            <ExtendedTriggerMessage
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "GetInstalledCertificateIds" && (
            <GetInstalledCertificateIds
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "GetLog" && (
            <GetLog handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "InstallCertificate" && (
            <InstallCertificate handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "SignedUpdateFirmware" && (
            <SignedUpdateFirmware
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "ReserveNow" && (
            <ReserveNow handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "GetLocalListVersion" && (
            <GetLocalListVersion
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
        </div>
      )}
      {protocol === "2.0.1" && (
        <div className="row row-gap-3 mt-3" style={{ width: "102%" }}>
          {selectedOperation === "GetTransactionStatus" && (
            <GetTransactionStatus201
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "UpdateFirmware" && (
            <UpdateFirmware20 handleSubmitOperation={handleSubmitOperation} />
          )}
          {/* {selectedOperation === "SendLocalList" && (
            <SendLocalList handleSubmitOperation={handleSubmitOperation} />
          )} */}
          {selectedOperation === "TriggerMessage" && (
            <TriggerMessage20 handleSubmitOperation={handleSubmitOperation} />
          )}
          {/* {selectedOperation === "ClearChargingProfile" && (
            <ClearChargingProfile
              handleSubmitOperation={handleSubmitOperation}
            />
          )} */}
          {/* {selectedOperation === "SetChargingProfile" && (
            <SetChargingProfile
              handleSubmitOperation={handleSubmitOperation}
              setLoader={setLoader}
            />
          )} */}
          {/* {selectedOperation === "InstallCertificate" && (
            <InstallCertificate handleSubmitOperation={handleSubmitOperation} />
          )} */}
          {/* {selectedOperation === "GetInstalledCertificateIds" && (
            <GetInstalledCertificateIds
              handleSubmitOperation={handleSubmitOperation}
            />
          )} */}
          {/* {selectedOperation === "DeleteCertificate" && (
            <DeleteCertificate handleSubmitOperation={handleSubmitOperation} />
          )} */}
          {selectedOperation === "ReserveNow" && (
            <ReserveNow20 handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "UnlockConnector" && (
            <UnlockConnector20 handleSubmitOperation={handleSubmitOperation} />
          )}
          {selectedOperation === "CancelReservation" && (
            <CancelReservation20
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "RequestStartTransaction" && (
            <RequestStartTransaction20
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "RequestStopTransaction" && (
            <RequestStopTransaction20
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "ChangeAvailability" && (
            <ChangeAvailability20
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "UnpublishFirmware" && (
            <UnpublishFirmware201
              handleSubmitOperation={handleSubmitOperation}
            />
          )}
          {selectedOperation === "GetLog" && (
            <GetLog201 handleSubmitOperation={handleSubmitOperation} />
          )}
        </div>
      )}
      <PopUp
        open={alertPopUp}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertPopUp}
        popUpWidth={"width-100"}
      />
      {sessionExpire && <SessionExpire />}
    </div>
  );
}

export default Operations;
