import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";

function SignedUpdateFirmware({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    requestId: "",
    retries: "",
    retryInterval: "",
    firmware: {
      location: "",
      retrieveDateTime: null,
      installDateTime: null,
      signingCertificate: "",
      signature: "",
    },
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    requestId: "",
    signingCertificate: "",
    signature: "",
    location: "",
    retrieveDateTime: "",
  });
  function handleOnChange(key, value) {
    if (key === "requestId" || key === "retries" || key === "retryInterval") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
        setInputFields({ ...inputFields, [key]: parseInt(value) });
      }
    } else {
      setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
      setInputFields({ ...inputFields, [key]: value });
    }
  }
  function handleSubmit() {
    let error = false;
    if (
      !inputFields.requestId ||
      !inputFields.firmware.location ||
      !inputFields.firmware.signingCertificate ||
      !inputFields.firmware.signature ||
      !inputFields.firmware.retrieveDateTime
    ) {
      error = true;
    }
    if (error) {
      if (!inputFields.requestId) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          requestId: "Request Id is Mandatory",
        }));
      }
      if (!inputFields.firmware.location) {
        console.log("Location");
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          location: "Location is Mandatory",
        }));
      }
      if (!inputFields.firmware.retrieveDateTime) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          retrieveDateTime: "Retrieve Date Time is Mandatory",
        }));
      }
      if (!inputFields.firmware.signature) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          signature: "Signature is Mandatory",
        }));
      }
      if (!inputFields.firmware.signingCertificate) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          signingCertificate: "Signing Certificate is Mandatory",
        }));
      }
    }
    let payLoad = {
      requestId: inputFields.requestId,
      firmware: {
        location: inputFields?.firmware?.location,
        retrieveDateTime: inputFields?.firmware?.retrieveDateTime,
        signingCertificate: inputFields?.firmware?.signingCertificate,
        signature: inputFields?.firmware?.signature,
      },
    };
    if (inputFields?.firmware?.installDateTime) {
      payLoad.firmware.installDateTime = inputFields?.firmware?.installDateTime;
    }
    if (inputFields?.retries) {
      payLoad.retries = inputFields?.retries;
    }
    if (inputFields?.retryInterval) {
      payLoad.retryInterval = inputFields?.retryInterval;
    }
    handleSubmitOperation(error, payLoad);
  }
  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Request Id *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.requestId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.requestId}
                  size="small"
                  helperText={inputFieldsValidation.requestId}
                  onChange={(e) => {
                    handleOnChange("requestId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Retries </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.retries}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("retries", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Retry Interval </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.retryInterval}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("retryInterval", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Location *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.location}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.location}
                  size="small"
                  helperText={inputFieldsValidation.location}
                  onChange={(e) => {
                    const { value } = e.target;

                    // Update inputFields
                    setInputFields((prevState) => ({
                      ...prevState,
                      firmware: {
                        ...prevState.firmware,
                        location: value, // Update the 'signature' field
                      },
                    }));

                    // Update inputFieldsValidation (assuming 'key' is defined)
                    setInputFieldsValidation((prevValidation) => ({
                      ...prevValidation,
                      location: "", // Clear the validation message for the 'key'
                    }));
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Signing Certificate *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.signingCertificate}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.signingCertificate}
                  size="small"
                  helperText={inputFieldsValidation.signingCertificate}
                  onChange={(e) => {
                    const { value } = e.target;

                    // Update inputFields
                    setInputFields((prevState) => ({
                      ...prevState,
                      firmware: {
                        ...prevState.firmware,
                        signingCertificate: value, // Update the 'signature' field
                      },
                    }));

                    // Update inputFieldsValidation (assuming 'key' is defined)
                    setInputFieldsValidation((prevValidation) => ({
                      ...prevValidation,
                      signingCertificate: "", // Clear the validation message for the 'key'
                    }));
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Signature *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.signature}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.signature}
                  size="small"
                  helperText={inputFieldsValidation.signature}
                  onChange={(e) => {
                    const { value } = e.target;

                    // Update inputFields
                    setInputFields((prevState) => ({
                      ...prevState,
                      firmware: {
                        ...prevState.firmware,
                        signature: value, // Update the 'signature' field
                      },
                    }));

                    // Update inputFieldsValidation (assuming 'key' is defined)
                    setInputFieldsValidation((prevValidation) => ({
                      ...prevValidation,
                      signature: "", // Clear the validation message for the 'key'
                    }));
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Retrieve Date/Time *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={inputFields?.retrieveDateTime}
                  onChange={(e) => {
                    const { value } = e.target;

                    // Update inputFields
                    setInputFields((prevState) => ({
                      ...prevState,
                      firmware: {
                        ...prevState.firmware,
                        retrieveDateTime: value, // Update the 'signature' field
                      },
                    }));

                    // Update inputFieldsValidation (assuming 'key' is defined)
                    setInputFieldsValidation((prevValidation) => ({
                      ...prevValidation,
                      retrieveDateTime: "", // Clear the validation message for the 'key'
                    }));
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {inputFieldsValidation.retrieveDateTime}
                </p>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Install Date/Time *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={inputFields?.installDateTime}
                  onChange={(e) => {
                    const { value } = e.target;

                    // Update inputFields
                    setInputFields((prevState) => ({
                      ...prevState,
                      firmware: {
                        ...prevState.firmware,
                        installDateTime: value, // Update the 'signature' field
                      },
                    }));
                  }}
                />
                {/* <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {inputFieldsValidation.retrieveDateTime}
                </p> */}
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default SignedUpdateFirmware;
