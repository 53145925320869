import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";

export default function RelatedLocationPop({
  rHoursObj,
  handleRHChange,
  rHErrors,
}) {
  console.log(rHoursObj);
  return (
    <div className="py-3">
      <Box>
        <div className="d-flex flex-wrap gap-4 w-auto">
          <div className="w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Week Day *</b>
              </FormLabel>
              <TextField
                select
                required
                className=" me-3 error"
                variant="outlined"
                size="small"
                value={rHoursObj.weekday}
                onChange={(e) => {
                  handleRHChange("weekday", e.target.value);
                }}
                error={rHErrors.weekday}
                helperText={rHErrors.weekday}
              >
                <MenuItem value="1">SUNDAY</MenuItem>
                <MenuItem value="2">MONDAY</MenuItem>
                <MenuItem value="3">TUESDAY</MenuItem>
                <MenuItem value="4">WEDNESDAY</MenuItem>
                <MenuItem value="5">THURSDAY</MenuItem>
                <MenuItem value="6">FRIDAY</MenuItem>
                <MenuItem value="7">SATURDAY</MenuItem>
              </TextField>
            </FormControl>
          </div>
          <div className="w-45"></div>
          {/* 
          <div className="w-45">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Period Begin"
                className="w-100 me-3 error"
                value={
                  rHoursObj.periodBegin
                    ? dayjs(rHoursObj.periodBegin, "HH:mm")
                    : null
                }
                onChange={(time) => {
                  handleRHChange("periodBegin", time);
                }}
                slots={{
                  popper: (props) => (
                    <div className="position-relative">
                      <Popper
                        {...props}
                        style={{ zIndex: 1000 }}
                        disablePortal
                        className="position-absolute"
                      />
                    </div>
                  ),
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    required: "true",
                    error: rHErrors.periodBegin,
                    helperText: rHErrors.periodBegin,
                  },
                }}
              />
            </LocalizationProvider>
          </div> */}
          <div className="w-45">
            <FormControl className="simpleInput">
              <div className="my-1 fs-15 fw-600">
                <span>period Begin *</span>
              </div>
              <input
                type="time"
                className="px-3 fs-17"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  border: "1px solid gainsboro",
                }}
                value={rHoursObj.periodBegin}
                onChange={(e) => {
                  handleRHChange("periodBegin", e.target.value);
                }}
              />
              <p
                class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                id=":r1:-helper-text"
              >
                {rHErrors.periodBegin}
              </p>
            </FormControl>
          </div>
          <div className="w-45">
            <FormControl className="simpleInput">
              <div className="my-1 fs-15 fw-600">
                <span>period End *</span>
              </div>
              <input
                type="time"
                className="px-3 fs-17"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  border: "1px solid gainsboro",
                }}
                value={rHoursObj.periodEnd}
                onChange={(e) => {
                  handleRHChange("periodEnd", e.target.value);
                }}
              />
              <p
                class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                id=":r1:-helper-text"
              >
                {rHErrors.periodEnd}
              </p>
            </FormControl>
          </div>
          {/* <div className="w-45">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Period End "
                className="w-100 me-3 error"
                value={
                  rHoursObj.periodEnd
                    ? dayjs(rHoursObj.periodEnd, "HH:mm")
                    : null
                }
                onChange={(time) => {
                  handleRHChange("periodEnd", time);
                }}
                slots={{
                  popper: (props) => (
                    <div className="position-relative">
                      <Popper
                        {...props}
                        style={{ zIndex: 1000 }}
                        disablePortal
                        className="position-absolute"
                      />
                    </div>
                  ),
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    required: "true",
                    error: rHErrors.periodEnd,
                    helperText: rHErrors.periodEnd,
                  },
                }}
              />
            </LocalizationProvider>
          </div> */}
        </div>
      </Box>
    </div>
  );
}
