import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import * as React from "react";
import ChangeAvailability from "../Operations/ChangeAvailability";
import ChangeConfiguration from "../Operations/ChangeConfiguration";
import ClearCache from "../Operations/ClearCache";
import GetDiagnostics from "../Operations/GetDiagnostics";
import RemoteStartTransaction from "../Operations/RemoteStartTransaction";
import RemoteStopTransaction from "../Operations/RemoteStopTransaction";
import Reset from "../Operations/Reset";
import UnlockConnector from "../Operations/UnlockConnector";
import DataTransfer from "../Operations/DataTransfer";
import SendLocalList from "../Operations/SendLocalList";
import TriggerMessage from "../Operations/TriggerMessage";
import GetCompositeSchedule from "../Operations/GetCompositeSchedule";
import ClearChargingProfile from "../Operations/ClearChargingProfile";
import SetChargingProfile from "../Operations/SetChargingProfile";
import UpdateFirmware from "../Operations/UpdateFirmware";
import { useEffect, useState } from "react";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import FullLoader from "../../components/FullLoader";
import CancelReservation from "../Operations/CancelReservation";
import GetConfigurations from "../Operations/GetConfigurations";
import DeleteCertificate from "../Operations/DeleteCertificate";
import ExtendedTriggerMessage from "../Operations/ExtendedTriggerMessage";
import GetInstalledCertificateIds from "../Operations/GetInstalledCertificateIds";
import GetLog from "../Operations/GetLog";
import InstallCertificate from "../Operations/InstallCertificate";
import SignedUpdateFirmware from "../Operations/SignedUpdateFirmware";
import ReserveNow from "../Operations/ReserveNow";
import GetLocalListVersion from "../Operations/GetLocalListVersion";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

function ChargingStationsOperation({ locationName }) {
  const [operations, setOperations] = React.useState([]);
  const [protocol, setProtocol] = React.useState("1.6");
  const [sessionExpire, setSessionExpire] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [alertPopUp, setAlertPopUp] = React.useState(false);
  const [protocolError, setProtocolError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [selectedOperation, setSelectedOperation] =
    React.useState("ChangeAvailability");
  useEffect(() => {
    getOperations();
  }, []);

  async function getOperations() {
    let Url = `csinfo/${protocol}/getOperations`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    if (res && res?.status) {
      setOperations(res?.data);
    }
  }
  function handleSelectOperaion(e) {
    setSelectedOperation(e.target.value);
  }
  function handleAlertPopUp() {
    setAlertPopUp((pop) => !pop);
  }
  const handleProtocolChange = (value) => {
    setProtocolError("");
    setProtocol(value);
  };
  function handleSubmitOperation(error, payload) {
    if (!error) {
      setLoader(true);
      let headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("Authorization"),
        "X-TenantID": localStorage.getItem("tenantId"),
      };

      fetch(
        `${basePathone}api/cs/cmps-call/${localStorage.getItem(
          "tenantId"
        )}/${locationName}/${selectedOperation}?protocol=${protocol}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        }
      ).then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else if (resp.status === 200) {
          setStatusMessage("SUCCESS");
          setAlertMessage(selectedOperation + " Initiated Successfully.");
          handleAlertPopUp();
        } else {
          setStatusMessage("FAILURE");
          setAlertMessage(selectedOperation + " Initiated Successfully.");
          handleAlertPopUp();
        }
      });
    }
  }
  return (
    <div>
      {sessionExpire && <SessionExpire />}
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="row mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Protocol *</b>
                </FormLabel>
                <TextField
                  required
                  select
                  size="small"
                  className="w-100"
                  error={protocolError}
                  helperText={protocolError}
                  value={protocol}
                  onChange={(e) => handleProtocolChange(e.target.value)}
                >
                  <MenuItem value={"1.6"}>OCPP16</MenuItem>
                  <MenuItem value={"2.0.1"}>OCPP201</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Charging Station *</b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  value={locationName}
                  disabled
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Operation *</b>
                </FormLabel>
                <TextField
                  select
                  size={"small"}
                  className="w-100"
                  value={selectedOperation}
                  onChange={handleSelectOperaion}
                >
                  {operations.map((operation) => (
                    <MenuItem
                      style={{
                        fontFamily: " Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: "400",
                        fontSize: "13px",
                      }}
                      value={operation}
                      key={operation}
                    >
                      {operation}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      {selectedOperation === "ChangeAvailability" && (
        <ChangeAvailability handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "ChangeConfiguration" && (
        <ChangeConfiguration
          handleSubmitOperation={handleSubmitOperation}
          chargePointId={locationName}
        />
      )}
      {selectedOperation === "ClearCache" && (
        <ClearCache handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "GetDiagnostics" && (
        <GetDiagnostics handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "RemoteStartTransaction" && (
        <RemoteStartTransaction handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "RemoteStopTransaction" && (
        <RemoteStopTransaction handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "Reset" && (
        <Reset handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "UnlockConnector" && (
        <UnlockConnector handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "DataTransfer" && (
        <DataTransfer handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "SendLocalList" && (
        <SendLocalList handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "TriggerMessage" && (
        <TriggerMessage handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "GetCompositeSchedule" && (
        <GetCompositeSchedule handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "ClearChargingProfile" && (
        <ClearChargingProfile handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "SetChargingProfile" && (
        <SetChargingProfile handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "UpdateFirmware" && (
        <UpdateFirmware handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "CancelReservation" && (
        <CancelReservation handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "GetConfiguration" && (
        <GetConfigurations
          handleSubmitOperation={handleSubmitOperation}
          selectedChargePoints={locationName}
          setSessionExpire={setSessionExpire}
          setLoader={setLoader}
        />
      )}
      {selectedOperation === "DeleteCertificate" && (
        <DeleteCertificate handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "ExtendedTriggerMessage" && (
        <ExtendedTriggerMessage handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "GetInstalledCertificateIds" && (
        <GetInstalledCertificateIds
          handleSubmitOperation={handleSubmitOperation}
        />
      )}
      {selectedOperation === "GetLog" && (
        <GetLog handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "InstallCertificate" && (
        <InstallCertificate handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "SignedUpdateFirmware" && (
        <SignedUpdateFirmware handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "ReserveNow" && (
        <ReserveNow handleSubmitOperation={handleSubmitOperation} />
      )}
      {selectedOperation === "GetLocalListVersion" && (
        <GetLocalListVersion handleSubmitOperation={handleSubmitOperation} />
      )}
      <PopUp
        open={alertPopUp}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertPopUp}
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}

export default ChargingStationsOperation;
