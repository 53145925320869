import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

function SideBar({ open, handleOpenClick, menuItems, getSidebarItems }) {
  const [open1, setOpen1] = useState("");
  const [activeParent, setActiveParent] = useState(
    localStorage.getItem("activeParent") || "Locations"
  );
  const location = useLocation();

  React.useEffect(() => {
    getSidebarItems();
    localStorage.setItem("activeParent", activeParent);
  }, [activeParent]);

  function handleExpandClick(title) {
    if (title === open1) {
      setOpen1("");
    } else setOpen1(title);
  }

  return (
    <div
      className="d-flex flex-column horizontal_scroll"
      style={{ height: "calc(100vh - 32px)" }}
    >
      <div
        className="pt-3 pb-3 d-flex align-items-center border-bottom position-relative"
        style={{
          flex: "0 10 auto",
        }}
      >
        {open && (
          <img
            src="charznow.png"
            alt="logo"
            className="logo_styles"
          />
        )}
        {!open && (
          <img
            src="icon.png"
            alt="logo"
            style={{
              width: "35px",
              height: "30px",
              marginLeft: "15px",
            }}
          />
        )}
        <i
          className="fa-solid fa-chevron-left p-1 border openIcon position-absolute pointerCursor "
          style={{ right: "0px", color: "white" }}
          onClick={handleOpenClick}
        ></i>
      </div>
      <div
        style={{
          flex: "1 1 auto",
          overflowY: "auto",
        }}
      >
        <List>
          {menuItems.map((item) =>
            (item.parent.isActive && item.subItems.length === 0) ||
            (item.parent.isActive &&
              item.subItems.length > 0 &&
              item.subItems.some((obj) => {
                return obj.isActive === 1;
              })) ? (
              <React.Fragment key={item.parent.id}>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "active text-decoration-none"
                      : "text-decoration-none"
                  }
                  to={item.parent.link}
                >
                  <ListItemButton
                    onClick={() => {
                      handleExpandClick(item.parent.title);
                      setActiveParent(item.parent.title);
                    }}
                    sx={{
                      backgroundColor:
                        activeParent === item.parent.title
                          ? "#2DCC70"
                          : "white",
                      "&:hover": {
                        backgroundColor:
                          activeParent === item.parent.title
                            ? "#2DCC70"
                            : "f5f5f5",
                      },
                    }}
                  >
                    <ListItemIcon
                      className={`resolution_40 ${
                        !open && "d-flex flex-column align-items-center"
                      }`}
                      sx={{
                        color:
                          activeParent === item.parent.title
                            ? "white"
                            : "black",
                      }}
                    >
                      {item?.parent?.title === "Dashboard" ? (
                        <img
                          src="./sidemenu/Dashboard.png"
                          alt="logo"
                          style={{
                            transform: "scale(0.3)",
                            filter:
                              activeParent === item.parent.title ||
                              location.pathname === item.parent.link ||
                              item.subItems.some((subItem) =>
                                location.pathname.includes(subItem.link)
                              )
                                ? "brightness(0) invert(1)"
                                : "brightness(0) invert(0)",
                            marginTop: "-6px",
                            position: "relative",
                            top: "2px"
                          }}
                        />
                      ) : item?.parent?.title === "Locations" ? (
                        <img
                          src="./sidemenu/ChargingStations.png"
                          alt="logo"
                          style={{
                            transform: "scale(0.3)",
                            filter:
                              activeParent === item.parent.title ||
                              location.pathname === item.parent.link ||
                              item.subItems.some((subItem) =>
                                location.pathname.includes(subItem.link)
                              )
                                ? "brightness(0) invert(1)"
                                : "brightness(0) invert(0)",
                            marginTop: "-6px",
                            position: "relative",
                            top: "2px"
                          }}
                        />
                      ) : item?.parent?.title === "Account" ? (
                        <img
                          src="./sidemenu/Account.png"
                          alt="logo"
                          style={{
                            transform: "scale(0.3)",
                            filter:
                              activeParent === item.parent.title ||
                              location.pathname === item.parent.link ||
                              item.subItems.some((subItem) =>
                                location.pathname.includes(subItem.link)
                              )
                                ? "brightness(0) invert(1)"
                                : "brightness(0) invert(0)",
                            marginTop: "-6px",
                            position: "relative",
                            top: "2px"
                          }}
                        />
                      ) : item?.parent?.title === "Maintenance" ? (
                        <img
                          src="./sidemenu/Maintenance.png"
                          alt="logo"
                          style={{
                            transform: "scale(0.3)",
                            filter:
                              activeParent === item.parent.title ||
                              location.pathname === item.parent.link ||
                              item.subItems.some((subItem) =>
                                location.pathname.includes(subItem.link)
                              )
                                ? "brightness(0) invert(1)"
                                : "brightness(0) invert(0)",
                            marginTop: "-6px",
                            position: "relative",
                            top: "2px"
                          }}
                        />
                      ) : item?.parent?.title === "Settings" ? (
                        <img
                          src="./sidemenu/Settings.png"
                          alt="logo"
                          style={{
                            transform: "scale(0.3)",
                            filter:
                              activeParent === item.parent.title ||
                              location.pathname === item.parent.link ||
                              item.subItems.some((subItem) =>
                                location.pathname.includes(subItem.link)
                              )
                                ? "brightness(0) invert(1)"
                                : "brightness(0) invert(0)",
                            marginTop: "-6px",
                            position: "relative",
                            top: "2px"
                          }}
                        />
                      ) : item?.parent?.title === "System" ? (
                        <img
                          src="./sidemenu/System.png"
                          alt="logo"
                          style={{
                            transform: "scale(0.3)",
                            filter:
                              activeParent === item.parent.title ||
                              location.pathname === item.parent.link ||
                              item.subItems.some((subItem) =>
                                location.pathname.includes(subItem.link)
                              )
                                ? "brightness(0) invert(1)"
                                : "brightness(0) invert(0)",
                            marginTop: "-6px",
                            position: "relative",
                            top: "2px"
                          }}
                        />
                      ) : item?.parent?.title === "Power Grid" ? (
                        <img
                          src="./sidemenu/Grid.png"
                          alt="logo"
                          style={{
                            transform: "scale(0.3)",
                            filter:
                              activeParent === item.parent.title ||
                              location.pathname === item.parent.link ||
                              item.subItems.some((subItem) =>
                                location.pathname.includes(subItem.link)
                              )
                                ? "brightness(0) invert(1)"
                                : "brightness(0) invert(0)",
                            marginTop: "-6px",
                            position: "relative",
                            top: "2px"
                          }}
                        />
                      ) : item?.parent?.title === "Service Provider" ? (
                        <img
                          src="./sidemenu/ServiceProvider.png"
                          alt="logo"
                          style={{
                            transform: "scale(0.3)",
                            filter:
                              activeParent === item.parent.title ||
                              location.pathname === item.parent.link ||
                              item.subItems.some((subItem) =>
                                location.pathname.includes(subItem.link)
                              )
                                ? "brightness(0) invert(1)"
                                : "brightness(0) invert(0)",
                            marginTop: "-6px",
                            position: "relative",
                            top: "2px"
                          }}
                        />
                      ) : null}
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        primary={item.parent.title}
                        sx={{
                          color:
                            activeParent === item.parent.title
                              ? "white"
                              : "black",
                        }}
                      />
                    )}
                    {item.subItems.length > 0 &&
                      open &&
                      (open1 === item.parent.title ? (
                        <i
                          className="fa-solid fa-chevron-up"
                          style={{
                            color:
                              activeParent === item.parent.title
                                ? "white"
                                : "black",
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-chevron-down"
                          style={{
                            color:
                              activeParent === item.parent.title
                                ? "white"
                                : "black",
                          }}
                        ></i>
                      ))}
                  </ListItemButton>
                </NavLink>
                {item.subItems.length > 0 && open && (
                  <Collapse
                    in={open1 === item.parent.title}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem) =>
                        subItem.isActive ? (
                          <NavLink
                            key={subItem.id}
                            to={subItem.link}
                            className="text-decoration-none"
                          >
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText
                                sx={{ textAlign: "left", marginLeft: "2rem" }}
                                primary={subItem.title}
                                className="black-color"
                              />
                            </ListItemButton>
                          </NavLink>
                        ) : null
                      )}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ) : null
          )}
        </List>
      </div>
    </div>
  );
}

export default SideBar;
