import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";

function RemoteStopTransaction({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({ transactionId: "" });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    transactionId: "",
  });
  function handleOnChange(key, value) {
    if (
      (value.charCodeAt(value.length - 1) >= 48 &&
        value.charCodeAt(value.length - 1) <= 57) ||
      value === ""
    ) {
      setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
      setInputFields({ ...inputFields, [key]: value });
    }
  }
  function handleSubmit() {
    let error = false;
    if (inputFields.transactionId.length === 0) {
      error = true;
    }
    if (error) {
      if (inputFields.transactionId.length === 0) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          transactionId: "Transaction Id is Mandatory",
        }));
      }
    }
    let payLoad = {
      transactionId: parseInt(inputFields.transactionId),
    };
    handleSubmitOperation(error, payLoad);
  }
  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Active Transaction Id *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.transactionId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.transactionId}
                  size="small"
                  helperText={inputFieldsValidation.transactionId}
                  onChange={(e) => {
                    handleOnChange("transactionId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default RemoteStopTransaction;
