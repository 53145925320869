import * as React from "react";
import * as material from "@mui/material";
import * as icons from "@mui/icons-material";
import moment from "moment";
import ColumnsBox from "../../components/ColumnsBox";
import TableHeadRow from "../../components/TableHeadRow";
import { resize, searchOperations } from "../../common/helpers";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import { alpha } from "@mui/material/styles";
import { Link } from "react-router-dom";
import PopUp from "../../components/PopUp";
const headCells = [
  {
    id: "idTag",
    numeric: false,
    disablePadding: true,
    label: "ID Tag",
    mandatory: true,
    filterOption: true,
    sort: true,
    minWidth: 150,
  },
  {
    id: "expiryDate",
    numeric: false,
    disablePadding: true,
    label: "Expiry Date",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 200,
  },
  {
    id: "parentIdTag",
    numeric: false,
    disablePadding: true,
    label: "Parent Id Tag",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "idTokenType",
    numeric: false,
    disablePadding: true,
    label: "Token Type",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "Update",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 150,
  },
];
function AuthorizationTag({setPageHeading}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("idTag");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [noData,setNoData]=React.useState("");
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [option, setOption] = React.useState("");
  const [searchInputs, setSearchInputs] = React.useState("");
  const [authorizationTagList, setAuthorizationTagList] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  React.useEffect(() => {
    resize();
    setPageHeading("Tags")
  }, []);
  React.useEffect(() => {
    getAuthorizationTag();
  }, [page, rowsPerPage, count, searchInputs, order, orderBy]);

  const getAuthorizationTag = () => {
    const headers = {
      "content-type": "application/json",
      // Authorization: localStorage.getItem("Authorization"),
      // "X-TenantID": localStorage.getItem("tenantId"),
    };
    const searchParams = [
      {
        key: "idTag",
        value: searchInputs,
        operation: searchOperations.CONTAINS,
      },
    ];
    const payload = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    fetch(basePathone + "api/idtags/search", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
          setAuthorizationTagList(res.data ?? []);
          setCount(res.count);
          setNoData(res?.count===0?"No Data Available":"");
      });
  };
  const deleteAuthorizationTag = () => {
    let headers = {
      "content-type": "application/json",
      // Authorization: localStorage.getItem("Authorization"),
      // "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(basePathone + "api/idtags/delete/multiple", {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(selected),
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setSelected([]);
          setAlertMessage(res.message);
          handleAlertPopup();
        }
      });
  };
  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }
  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  function handleSearchInputs(e) {
    setSearchInputs(e.target.value);
  }
  function handleAlertOk() {
    getAuthorizationTag();
    handleAlertPopup();
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = authorizationTagList.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    // setuser(newSelected[0]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <material.Box sx={{ width: "100%" }}>
      <material.Paper sx={{ width: "100%", mb: 2 }}>
        {selected.length === 0 ? (
          <material.Box
            sx={{ width: "100%", p: "5px" }}
            className="d-flex align-items-end justify-content-between"
          >
            <div
              style={{ width: "65%" }}
              className="d-flex flex-wrap align-items-center"
            >
              <material.FormControl
                sx={{ m: 1, width: "25ch" }}
                variant="filled"
                className=""
              >
                <material.FilledInput
                  id="filled-adornment-weight"
                  size="small"
                  sx={{ height: "40px", pb: "5px", width: "250px" }}
                  value={searchInputs}
                  onChange={handleSearchInputs}
                  placeholder="Search By ID Tag"
                  endAdornment={
                    <material.InputAdornment position="end" sx={{ mt: "15px" }}>
                      <icons.Search />
                    </material.InputAdornment>
                  }
                  aria-describedby="filled-weight-helper-text"
                  inputProps={{
                    "aria-label": "search",
                  }}
                />
              </material.FormControl>
            </div>
            <material.Box className="d-flex gap-1">
              <Link to={"/addAuthorizationTag"}>
                <material.Button>
                  <icons.Add />
                  {"add"}
                </material.Button>
              </Link>
              <div>
                <material.Button onClick={() => handleOptionClick("column")}>
                  <icons.ViewWeek />
                  <span className="ms-1">COLUMN</span>
                </material.Button>
                {option === "column" && (
                  <material.ClickAwayListener
                    onClickAway={() => handleOptionClick("")}
                  >
                    <div className="columnBox">
                      <ColumnsBox
                        headCells={headCells}
                        handelChange={handelChange}
                        disableColumns={disableColumns}
                      />
                    </div>
                  </material.ClickAwayListener>
                )}
              </div>
              <material.Button>
                <icons.Download />
                <span className="ms-1">EXPORT</span>
              </material.Button>
            </material.Box>
          </material.Box>
        ) : (
          <material.Toolbar
            className="border-top"
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {selected.length > 0 && (
              <i
                className="fa-solid fa-xmark me-3"
                style={{ cursor: "pointer" }}
                onClick={handleSelectAllClick}
              ></i>
            )}
            {selected.length > 0 ? (
              <material.Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </material.Typography>
            ) : (
              <material.Typography
                sx={{ flex: "1 1 100%", textAlign: "center" }}
                variant="h6"
                id="tableTitle"
                component="div"
              />
            )}

            {selected.length > 0 && (
              <material.IconButton
                style={{ color: "red" }}
                className="d-flex gap-2 rounded"
                onClick={deleteAuthorizationTag}
              >
                <i
                  className="fa-solid fa-trash"
                  style={{ fontSize: "16px" }}
                ></i>
                <span className="fs-6">{"Delete"}</span>
              </material.IconButton>
            )}
          </material.Toolbar>
        )}
        <material.TableContainer>
          <material.Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              numSelected={selected.length}
              rowCount={authorizationTagList.length}
              disableColumns={disableColumns}
              onSelectAllClick={handleSelectAllClick}
            />
            <material.TableBody>
              {authorizationTagList?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <material.TableRow tabIndex={-1} key={row.id}>
                    <material.TableCell padding="checkbox">
                      <material.Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </material.TableCell>
                    {!disableColumns.includes("idTag") && (
                      <material.TableCell
                        style={{ width: "30px" }}
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.idTag}
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("expiryDate") && (
                      <material.TableCell style={{ width: "60px" }}>
                        {row.expiryDate && (
                          <div className="dateTime">
                            <div>
                              <icons.CalendarToday className="calDate" />
                              {moment(row.expiryDate).format("DD-MM-YYYY ")}
                            </div>
                            <div>
                              <icons.AccessTime className="calTime" />
                              {moment(row.expiryDate).format("HH:mm A")}
                            </div>
                          </div>
                        )}
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("parentIdTag") && (
                      <material.TableCell>{row.parentIdTag}</material.TableCell>
                    )}
                    {!disableColumns.includes("idTokenType") && (
                      <material.TableCell>{row.idTokenType}</material.TableCell>
                    )}
                    <material.TableCell>
                      <Link to={"/editAuthorizationTag"} state={row.id}>
                        <material.IconButton>
                          <icons.Edit color="primary" />
                        </material.IconButton>
                      </Link>
                    </material.TableCell>
                  </material.TableRow>
                );
              })}
              {authorizationTagList.length === 0 && (
                <material.TableRow style={{ height: 40 }}>
                  <material.TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </material.TableCell>
                </material.TableRow>
              )}
            </material.TableBody>
          </material.Table>
        </material.TableContainer>
        <div className="position-relative">
          {authorizationTagList.length > 0 && (
            <material.TablePagination
              rowsPerPageOptions={[10,15,20]}
              disablePortal
              id="TablePagination"
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </material.Paper>
      {sessionExpire && <SessionExpire />}
      <PopUp
        open={alertPopup}
        title={"Alert"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"bg-primary"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
    </material.Box>
  );
}

export default AuthorizationTag;
