/** @format */

import React, { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";

import { basePath } from "../../common/config";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PopUp from "../../components/PopUp";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import AddIcon from "@mui/icons-material/Add";
import AddMenuForm from "./AddMenuForm";
import { CustomTreeItem } from "./CustomTreeItem";
import SessionExpire from "../../components/SessionExpire";
import Loader from "../../components/Loader";
import FullLoader from "../../components/FullLoader";

function MenuItem({ refresh, setRefresh, ...props }) {
  // const { menuItems } = props;
  const [updateIsActive, setUpdateIsActive] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [status, setStatus] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [openAddMenuPopup, setOpenAddMenuPopup] = useState(false);
  const [parents, setParents] = useState([]);
  const [viewMenuItemPopup, setViewMenuItemPopup] = useState(false);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [addmenuForm, setAddMenuForm] = useState({
    title: "",
    link: "",
    parentId: "",
  });
  const [addmenuFormError, setAddMenuFormError] = useState({
    title: "",
    link: "",
    parentId: "",
  });
  const [loader, setLoader] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [localMenuItems, setLocalMenuItems] = useState(props.menuItems);
  const [updateMenuItem, setUpdateMenuItem] = useState({});

  const [alertMessage, setAlertMessage] = useState("");
  let tempItems = props?.menuItems;
  let draggedObj = {};
  function handleAddSubItem(parentId) {
    setLocalMenuItems((menuItems) =>
      localMenuItems.map((item) =>
        item.parent.id === parentId ? { ...item, isOpen: !item.isOpen } : item
      )
    );
  }
  React.useEffect(() => {
    getParents();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getParents();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  function updateMenu(updatePayload) {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(basePath + "api/menuItems", {
      method: "PUT",
      body: JSON.stringify(updatePayload),
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res && res?.status) {
          setStatus(true);
          setAlertMessage(res.message);
          handleAlertPopup();
          setUpdateIsActive([]);
        } else {
          setStatus(false);
          setAlertMessage(res.message);
          handleAlertPopup();
        }
      });
  }

  const getParents = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(basePath + "api/getparents", {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res && res?.status) {
          if (res.status) {
            setParents(res.data);
          }
        }
      });
  };
  const deleteMenuItem = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(basePath + "api/menuItems/delete/" + deleteId, {
      method: "DELETE",
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res && res?.status) {
          setStatus(true);
          setAlertMessage(res.message);
          handleAlertPopup();
        } else {
          setStatus(false);
          setAlertMessage(res.message);
          handleAlertPopup();
        }
        props?.getSidebarItems();
      });
  };
  function handleDeleteMenuItem() {
    deleteMenuItem();
    setDeletePopup((pop) => !pop);
  }
  function handleUpdatePopup() {
    setUpdatePopup((pop) => !pop);
    setAddMenuFormError({ title: "", link: "", parentId: "" });
  }
  function handleUpdatePopupButton() {
    let error = false;
    if (
      updateMenuItem.title.length === 0 ||
      updateMenuItem.link.length === 0 ||
      !updateMenuItem.link.startsWith("/") ||
      updateMenuItem.parentId.length === 0
    ) {
      error = true;
    }
    if (error) {
      if (updateMenuItem.title.length === 0) {
        setAddMenuFormError((error) => ({
          ...error,
          title: "Title is Mandatory",
        }));
      }
      if (updateMenuItem.link.length === 0) {
        setAddMenuFormError((error) => ({
          ...error,
          link: "Link is Mandatory",
        }));
      }
      if (updateMenuItem.parentId.length === 0) {
        setAddMenuFormError((error) => ({
          ...error,
          parentId: "Category is Mandatory",
        }));
      }
      if (
        !updateMenuItem.link.startsWith("/") &&
        updateMenuItem.link.length > 0
      ) {
        setAddMenuFormError((error) => ({
          ...error,
          link: "Link must Start with /",
        }));
      }
    } else {
      updateMenu([updateMenuItem]);
      handleUpdatePopup();
    }
  }
  function handleEditClick(menuItem) {
    setUpdateMenuItem(menuItem);
    handleUpdatePopup();
  }
  function handleAddMenuForm() {
    setOpenAddMenuPopup((pop) => !pop);
    setAddMenuForm({ title: "", link: "", parentId: "" });
    setAddMenuFormError({ title: "", link: "", parentId: "" });
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleAlertOk() {
    props?.getSidebarItems();
    setAlertPopup(false);
  }
  function handleAddMenuItems() {
    let error = false;
    if (
      addmenuForm.title.length === 0 ||
      addmenuForm.link.length === 0 ||
      !addmenuForm.link.startsWith("/") ||
      addmenuForm.parentId.length === 0
    ) {
      error = true;
    }
    if (error) {
      if (addmenuForm.title.length === 0) {
        setAddMenuFormError((error) => ({
          ...error,
          title: "Title is Mandatory",
        }));
      }
      if (addmenuForm.link.length === 0) {
        setAddMenuFormError((error) => ({
          ...error,
          link: "Link is Mandatory",
        }));
      }
      if (addmenuForm.parentId.length === 0) {
        setAddMenuFormError((error) => ({
          ...error,
          parentId: "Category is Mandatory",
        }));
      }
      if (!addmenuForm.link.startsWith("/") && addmenuForm.link.length > 0) {
        console.log("hello");
        setAddMenuFormError((error) => ({
          ...error,
          link: "Link must Start with /",
        }));
      }
    } else {
      addMenuItem();
      handleAddMenuForm();
    }
  }
  function addMenuItem() {
    setLoader(true);
    let payload = {
      ...addmenuForm,
      tenantId: localStorage.getItem("tenantId"),
      isActive: 1,
      roleId: localStorage.getItem("roleId"),
      category: props?.category,
    };
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(basePath + "api/menuItems", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res && res?.status) {
          setStatus(true);
          setAlertMessage(res.message);
          handleAlertPopup();
        } else {
          setStatus(false);
          setAlertMessage(res.message);
          handleAlertPopup();
        }
      });
  }
  function handleSubItemCheck(updatedValue) {
    const updatedMenuItems = localMenuItems.map((item) =>
      item.subItems.length > 0
        ? {
            ...item,
            subItems: item.subItems.map((subItem) =>
              subItem.id === updatedValue.item.id
                ? { ...subItem, isActive: updatedValue.isActive }
                : subItem
            ),
          }
        : item
    );

    setLocalMenuItems(updatedMenuItems);
    props?.setMenuItems(updatedMenuItems);
    handleUpdateActive({
      id: updatedValue.item.id,
      isActive: updatedValue.isActive,
    });

    if (updatedValue.isActive && !updatedValue.menuItem.parent.isActive) {
      handleParentCheck({
        menuItem: updatedValue.menuItem,
        isActive: true,
      });
    }
  }
  function handleParentCheck(updatedValue) {
    const updatedMenuItems = localMenuItems.map((item) =>
      item.parent.id === updatedValue.menuItem.parent.id
        ? {
            ...item,
            parent: { ...item.parent, isActive: updatedValue.isActive },
            subItems: item.subItems.map((subItem) => ({
              ...subItem,
              isActive: updatedValue.isActive,
            })),
          }
        : item
    );

    setLocalMenuItems(updatedMenuItems);
    props?.setMenuItems(updatedMenuItems);
    handleUpdateActive({
      id: updatedValue.menuItem.parent.id,
      isActive: updatedValue.isActive,
    });
  }
  function handleUpdateActive(updatedValue) {
    const id = updatedValue.id.toString();

    if (updateIsActive.some((item) => item.id === id)) {
      setUpdateIsActive((menuItems) =>
        menuItems.filter((item) => item.id !== id)
      );
    } else {
      setUpdateIsActive((updateMenuItems) => [
        ...updateMenuItems,
        updatedValue,
      ]);
    }
  }
  function handleDeletePopup() {
    setDeletePopup((pop) => !pop);
  }
  function handleDelete(id) {
    setDeleteId(id);
    handleDeletePopup();
  }
  function handleAddForm(key, value) {
    setAddMenuFormError({ ...addmenuFormError, [key]: "" });
    setAddMenuForm({ ...addmenuForm, [key]: value });
  }
  function handleUpdateForm(key, value) {
    setAddMenuFormError({ ...addmenuFormError, [key]: "" });
    setUpdateMenuItem({ ...updateMenuItem, [key]: value });
  }
  function onDragOver(e, id, color) {
    e.preventDefault();
    if (e.target.id === String(id)) {
      const element = document.getElementById(e.target.id);
      element.classList.add(color);
    }
  }
  function onDragLeave(e, id, color) {
    if (e.target.id === String(id)) {
      const element = document.getElementById(e.target.id);
      element.classList.remove(color);
    }
  }
  const handleToggleSubItems = (index) => {
    const updatedMenuItems = [...localMenuItems];
    updatedMenuItems[index].isOpen = !updatedMenuItems[index].isOpen;
    setLocalMenuItems(updatedMenuItems);
  };

  function onDrop(id, e, elementId, color, menuorder) {
    if (id !== draggedObj.id) {
      draggedObj = { ...draggedObj, parentId: id, menuOrder: menuorder };
      updateMenu([draggedObj]);
      if (elementId) {
        onDragLeave(e, elementId, color);
      }
    }
  }
  function onDragStart(draggedObject) {
    console.log(draggedObject);
    draggedObj = draggedObject;
  }
  return (
    <div>
      <Box>
        {loader && <FullLoader />}
        <div className="row row-gap-4 mt-3 mb-2">
          {localMenuItems.map((item, itemIndex) => (
            <div key={item.parent.id} className="col-4">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <span
                  className={item.subItems.length === 0 ? "no-subitems" : ""}
                >
                  {/* Show AddIcon only if subItems are greater than 0 */}
                  {item.subItems.length > 0 && (
                    <IconButton onClick={() => handleToggleSubItems(itemIndex)}>
                      {item.isOpen ? (
                        <RemoveIcon color="primary" fontSize="small" />
                      ) : (
                        <AddIcon color="primary" fontSize="small" />
                      )}
                    </IconButton>
                  )}
                  {item.parent.title}
                </span>
                <div className="d-flex align-items-center">
                  <Checkbox
                    checked={item.parent.isActive}
                    onChange={() =>
                      handleParentCheck({
                        menuItem: item,
                        isActive: Number(!item.parent.isActive),
                      })
                    }
                  />
                  <IconButton onClick={() => handleEditClick(item.parent)}>
                    <EditIcon color="primary" fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(item.parent.id)}>
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </div>
              </div>
              {item.isOpen && (
                <div>
                  {item.subItems.map((subItem, index) => (
                    <div
                      key={subItem.id}
                      className="d-flex justify-content-between align-items-center mb-2"
                    >
                      <span style={{ marginLeft: "40px" }}>
                        {subItem.title}
                      </span>
                      <div className="d-flex align-items-center">
                        <Checkbox
                          size="small"
                          checked={subItem.isActive}
                          onChange={() =>
                            handleSubItemCheck({
                              item: subItem,
                              isActive: Number(!subItem.isActive),
                              menuItem: item,
                            })
                          }
                        />
                        <IconButton onClick={() => handleEditClick(subItem)}>
                          <EditIcon color="primary" fontSize="small" />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(subItem.id)}>
                          <DeleteIcon color="error" fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </Box>

      <div className="d-flex justify-content-end mt-4">
        <Button
          className="cancel-btn"
          variant="contained"
          style={{ marginRight: "15px" }}
          onClick={() => updateMenu(updateIsActive)}
        >
          Update
        </Button>
        <Button
          variant="contained"
          className="submit-btn"
          onClick={handleAddMenuForm}
        >
          Add
        </Button>
      </div>
      <PopUp
        title={"Confirm"}
        message={"Do you want to Delete this Menu Item"}
        btn1={true}
        btn1Text={"Delete"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleDeleteMenuItem}
        btn2={true}
        btn2Color={"cancel-btn"}
        btn2Text={"Cancel"}
        handleClickBtn2={handleDeletePopup}
        open={deletePopup}
        popUpWidth={"width-100"}
      />
      <PopUp
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        open={alertPopup}
        popUpWidth={"width-100"}
      />
      <PopUp
        title={"Add Menu Item"}
        open={openAddMenuPopup}
        message={
          <AddMenuForm
            parents={parents}
            addmenuForm={addmenuForm}
            addmenuFormError={addmenuFormError}
            handleAddForm={handleAddForm}
          />
        }
        btn1={true}
        btn1Text={"Cancel"}
        btn1Color={"cancel-btn"}
        btn2={true}
        handleClickBtn1={handleAddMenuForm}
        btn2Text={"Submit"}
        btn2Color={"submit-btn"}
        popUpWidth={"addMenu"}
        handleClickBtn2={handleAddMenuItems}
      />

      <PopUp
        title={"Edit Menu Item"}
        open={updatePopup}
        message={
          <AddMenuForm
            parents={parents}
            addmenuForm={updateMenuItem}
            addmenuFormError={addmenuFormError}
            handleAddForm={handleUpdateForm}
          />
        }
        btn2={true}
        btn2Text={"Cancel"}
        btn2Color={"cancel-btn"}
        handleClickBtn2={handleUpdatePopup}
        btn1={true}
        btn1Text={"Update"}
        btn1Color={"submit-btn"}
        popUpWidth={"addMenu"}
        handleClickBtn1={handleUpdatePopupButton}
      />
      {sessionExpire && <SessionExpire />}
    </div>
  );
}

export default MenuItem;
