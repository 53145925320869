/** @format */

import { createTheme } from "@mui/material";
import { brown } from "@mui/material/colors";

//
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// the date format for the below function is "2025-12-31 23:59:59"
export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

//the date formate for the below function is "2024-11-14T14:39"
export const formatDateForInput = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
// First and last name validation
export const validateName = (name) => {
  const nameValid = /^[a-zA-Z]+$/;
  return nameValid.test(name);
};
/*brown color Theme for input Fields */
export const inputColorTheme = createTheme({ palette: { primary: brown } });
// Email validation function
export function handleEmail(value) {
  const emailRegex =
    /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;
  if (emailRegex.test(value)) {
    return false;
  } else {
    return true;
  }
}
// password Validation
export const validatePassword = (password) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,16}$/;
  return passwordRegex.test(password);
};

export const resize = () => {
  // setTimeout(() => {
  //   var headerheight = document.querySelector("#header").offsetHeight;
  //   var footerheight = document.querySelector("footer").offsetHeight;
  //   var contentheight = window.innerHeight - (footerheight + headerheight);
  //   var sideMenuheight = window.innerHeight - (footerheight + 67);
  //   document.querySelector("main").style.maxHeight = `${contentheight}px`;
  //   document.querySelector(
  //     ".side_menu_container"
  //   ).style.height = `${sideMenuheight}px`;
  // }, 3000);
};
export const handleVersion = (value) => {
  const VersionRegex = /^[0-9]+\.[0-9]+\.[0-9]+$/;
  if (VersionRegex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const searchOperations = {
  EQUALITY: "EQUALITY",
  NEGATION: "NEGATION",
  GREATER_THAN: "GREATER_THAN",
  LESS_THAN: "LESS_THAN",
  LIKE: "LIKE",
  STARTS_WITH: "STARTS_WITH",
  ENDS_WITH: "ENDS_WITH",
  CONTAINS: "CONTAINS",
};

export const allowLetters = (e) => {
  if (/[^A-Za-z\s]/.test(e.key)) {
    e.preventDefault();
  }
};
export const allowLettersNumbersAndSpace = (e) => {
  if (/[^A-Za-z0-9\s]/.test(e.key)) {
    e.preventDefault();
  }
};

export const allowLettersNum = (e) => {
  // pattern: "^[A-Z]{2}-[A-Za-z0-9]{3}-[A-Za-z0-9]{3,}-[A-Za-z0-9_-]{1,30}$",
  if (
    !/[A-Za-z0-9-]/.test(e.key) &&
    !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)
  ) {
    e.preventDefault();
  }
};

export const handleKeyDown = (event) => {
  const allowedCharsRegex = /^[A-Za-z0-9]*$/;
  const key = event.key;
  const input = event.target;

  // Allow navigation keys and basic control keys (e.g., Backspace, Tab)
  if (["Backspace", "ArrowLeft", "ArrowRight", "Tab"].includes(key)) {
    return;
  }

  // Check for allowed characters
  if (!allowedCharsRegex.test(key)) {
    event.preventDefault();
    return;
  }

  // Check if the input value starts with an alphabet
  const currentValue = input.value;
  const newValue =
    currentValue.slice(0, input.selectionStart) +
    key +
    currentValue.slice(input.selectionStart);

  // Allow the input if the value is valid (starts with an alphabet)
  if (newValue.length > 0 && !/^[A-Za-z]/.test(newValue)) {
    event.preventDefault();
  }
};

// allows only alphabets and underscore
export const handleKeyDownAlphaUnderScore = (event) => {
  const allowedCharsRegex = /^[A-Za-z_]$/; // Allows alphabets and underscore
  const key = event.key;
  const input = event.target;

  // Allow navigation keys and basic control keys (e.g., Backspace, Tab)
  if (["Backspace", "ArrowLeft", "ArrowRight", "Tab"].includes(key)) {
    return;
  }

  // Check if the key is an allowed character
  if (!allowedCharsRegex.test(key)) {
    event.preventDefault();
    return;
  }

  // Check if the input value starts with an alphabet
  const currentValue = input.value;
  const newValue =
    currentValue.slice(0, input.selectionStart) +
    key +
    currentValue.slice(input.selectionStart);

  // Allow the input if the value is valid (starts with an alphabet)
  if (newValue.length > 0 && !/^[A-Za-z]/.test(newValue)) {
    event.preventDefault();
  }
};
// allows only alphabets and underscore and numeric
export const handleKeyDownAlphaUnderScoreandNumeric = (event) => {
  const allowedCharsRegex = /^[A-Za-z0-9_]$/; // Allows alphabets and underscore
  const key = event.key;
  const input = event.target;

  // Allow navigation keys and basic control keys (e.g., Backspace, Tab)
  if (["Backspace", "ArrowLeft", "ArrowRight", "Tab"].includes(key)) {
    return;
  }

  // Check if the key is an allowed character
  if (!allowedCharsRegex.test(key)) {
    event.preventDefault();
    return;
  }

  // Check if the input value starts with an alphabet
  const currentValue = input.value;
  const newValue =
    currentValue.slice(0, input.selectionStart) +
    key +
    currentValue.slice(input.selectionStart);

  // Allow the input if the value is valid (starts with an alphabet)
  if (newValue.length > 0 && !/^[A-Za-z]/.test(newValue)) {
    event.preventDefault();
  }
};

//name validations
export const handleKeyDownName = (e) => {
  const key = e.key;
  // Allow keys: alphabetic characters, backspace, delete, arrow keys, tab, etc.
  const allowedKeys = /^[A-Za-z\s]$/;

  // If the key pressed is not an alphabetic character and not a control key
  if (
    !allowedKeys.test(key) &&
    !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)
  ) {
    e.preventDefault(); // Prevent the default action (inserting the character)
  }
};
//name validations
export const handleKeyDownNumbers = (e) => {
  const key = e.key;
  // Allow keys: alphabetic characters, backspace, delete, arrow keys, tab, etc.
  const allowedKeys = /^[0-9]$/;

  // If the key pressed is not an alphabetic character and not a control key
  if (
    !allowedKeys.test(key) &&
    !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)
  ) {
    e.preventDefault(); // Prevent the default action (inserting the character)
  }
};
export const handlePercentageKeyDown = (e) => {
  const {
    key,
    target: { value, selectionStart, selectionEnd },
  } = e;
  const allowedKeys = [
    "Backspace",
    "Tab",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    "Escape",
    "Enter",
  ];
  if (allowedKeys.includes(key)) return;
  if (value === "" && key === ".") {
    e.preventDefault();
    return;
  }
  if (key === "." && value.includes(".")) {
    e.preventDefault();
    return;
  }
  const isDigit = /^\d$/.test(key);
  if (!isDigit && key !== ".") {
    e.preventDefault();
    return;
  }
  const newValue =
    value.slice(0, selectionStart) + key + value.slice(selectionEnd);
  const isValidPercentage = (val) => {
    const parts = val.split(".");
    if (parts.length > 2) return false;
    if (parts[0].length > 3) return false;
    const wholeNumber = parseInt(parts[0]);
    if (wholeNumber > 100) return false;
    if (wholeNumber === 100 && parts.length > 1) return false;
    if (parts.length === 2 && parts[1].length > 2) return false;
    return true;
  };

  if (!isValidPercentage(newValue)) {
    e.preventDefault();
  }
};
export const handleCurrencyKeydown = (e) => {
  const {
    key,
    target: { value },
  } = e;

  const allowedKeys = [
    "Backspace",
    "Tab",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    "Escape",
    "Enter",
  ];
  if (allowedKeys.includes(key)) return;
  if (value === "" && key === ".") {
    e.preventDefault();
  }
  if (key === "." && value.includes(".")) {
    e.preventDefault();
  }
  const isDigit = /^\d$/.test(key);
  if (!isDigit && key !== ".") {
    e.preventDefault();
  }
};
export const formatToTimestamp = (isoString) => {
  if (!isoString) return ""; // Handle empty values

  const date = new Date(isoString);
  if (isNaN(date.getTime())) return ""; // Handle invalid dates

  const pad = (number, length = 2) => number.toString().padStart(length, "0");

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = pad(date.getMilliseconds(), 3);

  // Combine the parts into the desired format: yyyy-mm-dd hh:mm:ss.fff
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};
