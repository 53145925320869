import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../../components/SessionExpire";
import { Link, useNavigate } from "react-router-dom";
import PopUp from "../../../components/PopUp";
import { allowLetters } from "../../../common/helpers";
import moment from "moment";
import FullLoader from "../../../components/FullLoader";
import { handleApi_call } from "../../../common/CommonAPI";
import { headers } from "../../../common/Headers";

export default function AddChargerProfile({
  addform,
  setAddForm,
  setRowIndex,
  setRow,
  chargingSchedulelist,
  setChargingScheduleList,
}) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [deleteCharging, setDeleteCharging] = React.useState("");
  const [status, setStatus] = React.useState("");

  const [errors, setErrors] = React.useState({
    chargingProfileName: "",
    stackLevel: "",
    chargingProfilePurpose: "",
    chargingProfileKind: "",
    recurrencyKind: "",
    validFrom: "",
    validTo: "",
  });
  const handleCancelPage = () => {
    setAddForm({});
    navigate("/chargerProfiles");
  };
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const navigate = useNavigate();
  function handleClosePopup() {
    navigate("/chargerProfiles");
  }
  const handleDeletePopup = () => {
    setDeletePopup((pop) => !pop);
  };
  const handleProfileErrors = () => {
    let error = {};
    if (!addform.chargingProfileName)
      error.chargingProfileName = "Charging Profile Name is required";
    if (!addform.stackLevel) error.stackLevel = "Stack Level is required";
    if (!addform.chargingProfilePurpose)
      error.chargingProfilePurpose = "Charging Profile Purpose is required";
    if (!addform.chargingProfileKind)
      error.chargingProfileKind = "Charging Profile Kind is required";
    if (!addform.recurrencyKind)
      error.recurrencyKind = "Recurrency Kind is required";
    if (!addform.validFrom) error.validFrom = "Valid From is required";
    if (!addform.validTo) error.validTo = "Valid To is required";

    setErrors(error);
    return Object.keys(error).length === 0; //return true/false
  };
  function handleSubmit() {
    if (handleProfileErrors()) {
      const formData = addform;
      formData.chargingSchedule = chargingSchedulelist;
      addChargeProfile();
    }
  }
  const handleAddSchedule = () => {
    if (handleProfileErrors()) {
      // setChargingProfile(addform);
      navigate("/AddchargingSchedule");
    }
  };

  const addChargeProfile = async () => {
    let Url = "chargingProfile/create";
    let type = "POST";
    let body = addform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      setChargingScheduleList([]);
      setAlertMessage(res.message);
      handleAlertPopup();
      setAddForm({});
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };
  const deleteSchedule = (list) => {
    setDeleteCharging(list);
    handleDeletePopup();
  };
  const deleteChargingSchedule = () => {
    setChargingScheduleList((prevItems) =>
      prevItems.filter((item) => item !== deleteCharging)
    );
    handleDeletePopup();
  };

  const handleRowEdit = (list, index) => {
    setRow(list);
    setRowIndex(index);
    if (list) {
      navigate("/updatechargingSchedule");
    }
  };
  function handleAddForm(key, value) {
    setErrors({ ...errors, [key]: "" });
    setAddForm({
      ...addform,
      [key]: value,
    });
  }

  return (
    <div>
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
      <div className=" mx-0 p-0 pagetitle">Add Charging Profile</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <Box className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Charging Profile Name *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  name="chargingProfileName"
                  value={addform.chargingProfileName}
                  inputProps={{
                    minLength: 3,
                    maxLength: 30,
                  }}
                  onKeyDown={allowLetters}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[0-9]/g, "");
                    handleAddForm("chargingProfileName", value);
                  }}
                  error={errors.chargingProfileName}
                  helperText={errors.chargingProfileName}
                  size="small"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Stack Level *</b>
                </FormLabel>
                <TextField
                  type="number"
                  required
                  className="w-100"
                  name="stackLevel"
                  value={addform.stackLevel}
                  inputProps={{
                    min: 1,
                    max: 1000,
                    step: 1,
                  }}
                  onChange={(e) => {
                    handleAddForm("stackLevel", e.target.value);
                  }}
                  error={errors.stackLevel}
                  helperText={errors.stackLevel}
                  size="small"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Charging Profile Purpose *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100 error"
                  error={errors.chargingProfilePurpose}
                  value={addform.chargingProfilePurpose}
                  required
                  size="small"
                  helperText={errors.chargingProfilePurpose}
                  onChange={(e) => {
                    handleAddForm("chargingProfilePurpose", e.target.value);
                  }}
                >
                  <MenuItem value="ChargePointMaxProfile ">
                    ChargePointMaxProfile
                  </MenuItem>
                  <MenuItem value="TxDefaultProfile">TxDefaultProfile</MenuItem>
                  <MenuItem value="TxProfile">TxProfile</MenuItem>
                  <MenuItem value="ChargingStationExternalConstraints">
                    ChargingStationExternalConstraints
                  </MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Charging Profile Kind *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100 error"
                  error={errors.chargingProfileKind}
                  value={addform.chargingProfileKind}
                  required
                  size="small"
                  helperText={errors.chargingProfileKind}
                  onChange={(e) => {
                    handleAddForm("chargingProfileKind", e.target.value);
                  }}
                >
                  <MenuItem value="Relative">Relative</MenuItem>
                  <MenuItem value="Recurring">Recurring</MenuItem>
                  <MenuItem value="Absolute">Absolute</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Recurrency Kind *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100 error"
                  error={errors.recurrencyKind}
                  value={addform.recurrencyKind}
                  required
                  size="small"
                  helperText={errors.recurrencyKind}
                  onChange={(e) => {
                    handleAddForm("recurrencyKind", e.target.value);
                  }}
                >
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className=" fs-15 fw-600">
                  <span>Value From *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={addform.validFrom}
                  onChange={(e) => {
                    handleAddForm("validFrom", e.target.value);
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors.validFrom}
                </p>
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <div className=" fs-15 fw-600">
                  <span>Valid To *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={addform.validTo}
                  onChange={(e) => {
                    handleAddForm("validTo", e.target.value);
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors.validTo}
                </p>
              </FormControl>
            </div>
          </Box>
        </CardContent>
      </Card>

      {/* // charging schedule table start code */}
      <div className="d-flex justify-content-between mt-4 p-0 ">
        <div className="ms-2 p-1 pagetitle">
          <span>Charging Schedule Configuration</span>
        </div>
        <Button
          variant="contained"
          className="submit-btn"
          onClick={handleAddSchedule}
        >
          Add
        </Button>
      </div>
      <div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <table class="table table-box-shadow mt-3">
              <thead>
                <tr class="table-primary">
                  <th scope="col">Schedule Name</th>
                  <th scope="col">Charging Duration</th>
                  <th scope="col">Start Schedule</th>
                  <th scope="col">Charging Rate Unit</th>
                  <th scope="col">Min Charging Rate</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              {chargingSchedulelist?.length > 0 ? (
                <tbody>
                  {chargingSchedulelist?.map((list, index) => (
                    <tr key={index}>
                      <td>{list?.chargingScheduleName}</td>
                      <td>{list?.duration}</td>
                      <td>
                        {moment(list?.startSchedule).format(
                          "DD-MMM-YYYY hh:mm A"
                        )}
                      </td>
                      <td>{list?.chargingRateUnit}</td>
                      <td>{list?.minChargingRate}</td>
                      <td>
                        <span onClick={() => handleRowEdit(list, index)}>
                          <i className="fa-solid fa-pencil action-icons"></i>
                        </span>

                        <span
                          onClick={() => deleteSchedule(list)}
                          style={{ color: "red" }}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colspan="6">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </CardContent>
        </Card>
      </div>
      <div className="d-flex aligm-items-center justify-content-end gap-4 my-5 ">
        <Button
          variant="contained"
          onClick={handleSubmit}
          className="submit-btn"
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleCancelPage}
          className="cancel-btn"
        >
          <Link to="/chargerProfiles" className="cancle-button">
            Cancel
          </Link>
        </Button>
      </div>

      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={status === true ? handleClosePopup : handleAlertPopup}
        popUpWidth={"width-100"}
      />

      <PopUp
        open={deletePopup}
        title={"Confirm"}
        message={"Do you want to delete this ChargingSchedule"}
        btn1={true}
        btn1Text={"Cancel"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleDeletePopup}
        btn2={true}
        btn2Text={"Delete"}
        btn2Color={"bg-danger"}
        handleClickBtn2={deleteChargingSchedule}
        handleClosePopUp={handleDeletePopup}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
