import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import React from "react";

export default function AddPopUp({ chargingPeriod, handleAddForm2, errors2 }) {
  return (
    <div className="py-3">
      <Box>
        <div className="row row-gap-3 mt-3">
          <div className="col-2 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Start Period (ms) *</b>
              </FormLabel>
              <TextField
                error={errors2.startPeriod}
                className=" me-3 error"
                required
                variant="outlined"
                value={chargingPeriod?.startPeriod}
                size="small"
                helperText={errors2.startPeriod}
                onChange={(e) => {
                  handleAddForm2("startPeriod", e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="col-2 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Charging Limit *</b>
              </FormLabel>
              <TextField
                error={errors2.chargingLimit}
                className=" me-3 error no-arrows"
                required
                type="number"
                step="0.1"
                variant="outlined"
                value={chargingPeriod?.chargingLimit}
                size="small"
                helperText={errors2.chargingLimit}
                onChange={(e) => {
                  handleAddForm2("chargingLimit", e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="col-2 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Number Of Phases *</b>
              </FormLabel>
              <TextField
                error={errors2.numberPhases}
                className=" me-3 error"
                required
                variant="outlined"
                value={chargingPeriod?.numberPhases}
                size="small"
                helperText={errors2.numberPhases}
                onChange={(e) => {
                  handleAddForm2("numberPhases", e.target.value);
                }}
              />
            </FormControl>
          </div>
        </div>
      </Box>
    </div>
  );
}
