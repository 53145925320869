import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Popper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

function GetConfigurations({
  handleSubmitOperation,
  selectedChargePoints,
  setSessionExpire,
  setLoader,
}) {
  const [keys, setKeys] = React.useState([]);
  const [inputFields, setInputFields] = useState({ key: [] });
  React.useEffect(() => {
    getConfigurationsKeys();
  }, [selectedChargePoints]);
  const getConfigurationsKeys = async () => {
    let Url = `csconfiguration/configurationKeys/${selectedChargePoints}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      "",
      () => {},
      setLoader
    );

    if (res && res?.status) {
      setKeys(res?.data);
    }
  };
  // function handleOnChange(key, value) {
  //   setInputFields({ ...inputFields, [key]: value });
  // }
  const handleOnChange = (name, newValue) => {
    setInputFields((prevState) => ({
      ...prevState,
      [name]: Array.isArray(newValue) ? newValue : [], // Ensure `newValue` is an array
    }));
  };
  function handleSubmit() {
    let error = false;
    let payLoad = {};
    if (inputFields.key) {
      payLoad.key = inputFields.key;
    }
    handleSubmitOperation(error, payLoad);
  }
  return (
    <>
      <div
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", backgroundColor: "white" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className=" position-relative">
                  <Autocomplete
                    multiple
                    id="size-small-outlined-multi"
                    size="small"
                    options={keys}
                    disablePortal
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) =>
                      handleOnChange("key", newValue)
                    }
                    renderInput={(params) => (
                      <FormControl className="simpleInput">
                        <FormLabel>
                          <b>Configuration Key </b>
                        </FormLabel>
                        <TextField {...params} placeholder="Select keys" />
                      </FormControl>
                    )}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        style={{
                          width: "calc(100% - 16px)",
                          marginLeft: "6px",
                          marginTop: "6px",
                        }}
                        placement="bottom-start"
                      />
                    )}
                  />
                </div>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </div>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default GetConfigurations;
