import {
    Box,
    Checkbox,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import moment from "moment";
import * as material from "@mui/material";
import React, { useEffect } from "react";
import { searchOperations } from "../../common/helpers";
import ColumnsBox from "../../components/ColumnsBox";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import { Link } from "react-router-dom";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import CustomTablePagination from "../../components/CustomTablePagination";
import FilterTable from "../../components/FilterTable";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

function Cdrs({ refresh, setRefresh }) {
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("id");
    const [page, setPage] = React.useState(0);
    const [filterColumns, setFilterColumns] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchInputs, setSearchInputs] = React.useState({
        startDate: "",
        endDate: "",
    });
    const [filterState, setFilterState] = React.useState([
        { key: "startDate", value: "" },
        { key: "endDate", value: "" },
    ]);
    const [count, setCount] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [noData, setNoData] = React.useState("");
    const [disableColumns, setDisableColumns] = React.useState([
        "currency",
        "totalTime",
        "invoiceReferenceId"
    ]);
    const [option, setOption] = React.useState("");
    const [loader, setLoader] = React.useState([]);
    const [chargingSessionList, setChargingSessionList] = React.useState([]);
    const [sessionExpire, setSessionExpire] = React.useState(false);
    // function formatChargingDuration(duration) {
    //     if (!duration || typeof duration !== "string") {
    //         return "00:00:00:00";
    //     }
    //     const parts = duration.split(":");
    //     if (parts.length !== 4) {
    //         return "00:00:00:00";
    //     }
    //     const [days, hours, minutes, seconds] = parts.map((num) =>
    //         String(Number(num)).padStart(2, "0")
    //     );
    //     return `${days}:${hours}:${minutes}:${seconds}`;
    // }

    const headCells = [
        {
            id: "sessionId",
            numeric: false,
            disablePadding: true,
            label: "Session ID",
            mandatory: true,
            filterOption: true,
            sort: false,
            minWidth: 100,
        },
        {
            id: "chargingStationId",
            numeric: false,
            disablePadding: true,
            label: "Location",
            mandatory: true,
            filterOption: false,
            sort: false,
            minWidth: 140,
        },
        {
            id: "evseId",
            numeric: false,
            disablePadding: true,
            label: "EVSE ID",
            mandatory: true,
            filterOption: false,
            sort: false,
            minWidth: 100,
        },
        {
            id: "connectorId",
            numeric: false,
            disablePadding: true,
            label: "Connector ID",
            mandatory: true,
            filterOption: false,
            sort: false,
            minWidth: 120,
        },

        {
            id: "type",
            numeric: false,
            disablePadding: true,
            label: "Tag Type",
            mandatory: false,
            filterOption: false,
            sort: false,
            minWidth: 100,
        },

        {
            id: "startDate",
            numeric: false,
            disablePadding: true,
            label: "Start Date",
            mandatory: false,
            filterOption: true,
            sort: false,
            searchType: "date",
            minWidth: 150,
        },
        {
            id: "endDate",
            numeric: false,
            disablePadding: true,
            label: "Stop Date",
            mandatory: false,
            filterOption: true,
            sort: false,
            searchType: "date",
            minWidth: 150,
        },
        {
            id: "currency",
            numeric: false,
            disablePadding: true,
            label: "Currency",
            mandatory: true,
            filterOption: false,
            sort: false,
            minWidth: 100,
        },
        {
            id: "totalEnergy",
            numeric: false,
            disablePadding: true,
            label: "Total Energy",
            mandatory: false,
            filterOption: false,
            sort: false,
            minWidth: 100,
        },
        {
            id: "totalCost",
            numeric: false,
            disablePadding: true,
            label: "Total Cost",
            mandatory: false,
            filterOption: false,
            sort: false,
            minWidth: 100,
        },
        {
            id: "invoiceReferenceId",
            numeric: false,
            disablePadding: true,
            label: "Invoice ID",
            mandatory: false,
            filterOption: false,
            sort: false,
            minWidth: 100,
        },
        {
            id: "credit",
            numeric: false,
            disablePadding: true,
            label: "Credit",
            mandatory: false,
            filterOption: false,
            sort: false,
            minWidth: 100,
        },
        {
            id: "totalTime",
            numeric: false,
            disablePadding: true,
            mandatory: false,
            filterOption: false,
            sort: false,
            minWidth: 100,
            label: "Total Time",
        }
    ];
    // const buildSearchParams = () => {
    //     const params = Object.keys(searchInputs)
    //         .filter((key) => searchInputs[key])
    //         .map((key) => {
    //             if (key === "chargingPointId") {
    //                 return {
    //                     key: key,
    //                     value: searchInputs[key],
    //                     operation: searchOperations.LIKE,
    //                 };
    //             } else if (key === "fromDate") {
    //                 return {
    //                     key: "startTime",
    //                     value: searchInputs.fromDate,
    //                     operation: searchOperations.GREATER_THAN,
    //                 };
    //             } else if (key === "toDate") {
    //                 return {
    //                     key: "startTime",
    //                     value: searchInputs.toDate,
    //                     operation: searchOperations.LESS_THAN,
    //                 };
    //             } else {
    //                 return {
    //                     key: key,
    //                     value: searchInputs[key],
    //                     operation: searchOperations.CONTAINS,
    //                 };
    //             }
    //         });
    //     return params;
    // };

    const getCdrs = async () => {
        // const searchParams = buildSearchParams();
        // const requestBody = {
        //     params: searchParams,
        //     pageNo: page + 1,
        //     size: rowsPerPage,
        //     sortBy: orderBy,
        //     sortDir: order,
        // };
        let Url = `cpo/2.2/cdrs?offset=${page}&limit=${rowsPerPage}&date_from=${searchInputs.startDate}&date_to=${searchInputs.endDate}`;
        let type = "GET";
        let res = await handleApi_call(
            Url,
            headers,
            type,
            {},
            setSessionExpire,
            () => { },
            () => { },
            setLoader
        );
        setChargingSessionList(res?.data ?? []);
        setCount(res?.count);
        setNoData(res?.count === 0 ? "No Data Available" : "");
    };
    function handleFilterColumns(id) {
        if (id === "removeAll") {
            setFilterColumns([]);
            setSearchInputs({
                startDate: "",
                endDate: "",
            });
        } else setFilterColumns([...filterColumns, id]);
        setOption("");
    }
    const updateFilter = (key, newValue) => {
        setFilterState((prevState) =>
            prevState.map((filter) =>
                filter.key === key ? { ...filter, value: newValue } : filter
            )
        );
    };
    const syncFilterStateToSearchInputs = () => {
        const newSearchInputs = filterState.reduce((acc, filter) => {
            acc[filter.key] = filter.value;
            return acc;
        }, {});

        setSearchInputs(newSearchInputs);
    };
    // Function to clear a specific input value by key
    const clearInputValue = (key) => {
        setSearchInputs((prevInputs) => ({
            ...prevInputs,
            [key]: "", // Set the specified key's value to an empty string
        }));
    };
    // Function to clear a specific filter value
    const clearFilterValue = (key) => {
        setFilterState((prevFilters) =>
            prevFilters.map((filter) =>
                filter.key === key ? { ...filter, value: "" } : filter
            )
        );
    };
    const resetStates = () => {
        setSearchInputs({
            startDate: "",
            endDate: "",
        });
        setFilterState([
            { key: "startDate", value: "" },
            { key: "endDate", value: "" },
        ]);
    };
    useEffect(() => {
        getCdrs();
    }, [page, rowsPerPage, searchInputs]);

    function handleOptionClick(value) {
        if (option === "") setOption(value);
        else setOption("");
    }

    function handelChange(id) {
        if (disableColumns.includes(id)) {
            setDisableColumns((columns) => columns.filter((c) => id !== c));
        } else setDisableColumns([...disableColumns, id]);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    React.useEffect(() => {
        if (refresh) {
            getCdrs();
            setRefresh(false); // Reset refresh state
        }
    }, [refresh]);

    return (
        <Box sx={{ width: "100%" }}>
            {loader && <Loader />}
            <div className="d-flex align-items-center justify-content-between">
                <div className="pagetitle">CDRs</div>
                <div className="Export-Button bgcolor-blue">
                    <i className="fa-solid fa-arrow-up-from-bracket" />
                    Export
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between cardFilter">
                <div>
                    <DisplayFilterInputs
                        searchInputs={searchInputs}
                        clearFilterValue={clearFilterValue}
                        clearInputValue={clearInputValue}
                        resetStates={resetStates}
                    />
                </div>
                <div>
                    <material.Box className="d-flex align-items-center gap-3">
                        <div>
                            <div
                                className="filter-filter bgcolor-blue"
                                onClick={() => handleOptionClick("filter")}
                            >
                                <i class="fa-solid fa-sliders"></i>
                                <span className="ms-1">Filter</span>
                            </div>
                            {option === "filter" && (
                                <material.ClickAwayListener
                                    onClickAway={() => handleOptionClick("")}
                                >
                                    <div className="filterBox">
                                        <FilterTable
                                            headCells={headCells}
                                            handleFilterColumns={handleFilterColumns}
                                            filterColumns={filterColumns}
                                            handleOptionClick={handleOptionClick}
                                            updateFilter={updateFilter}
                                            filterState={filterState}
                                            syncFilterStateToSearchInputs={
                                                syncFilterStateToSearchInputs
                                            }
                                        />
                                    </div>
                                </material.ClickAwayListener>
                            )}
                        </div>
                        <div className="position-relative">
                            <div
                                className="filter-filter bgcolor-blue"
                                onClick={() => handleOptionClick("column")}
                            >
                                <i
                                    class="fa-solid fa-bars"
                                    style={{
                                        transform: "rotate(-90deg)",
                                    }}
                                ></i>
                                <span className="ms-1">Column</span>
                            </div>
                            {option === "column" && (
                                <material.ClickAwayListener
                                    onClickAway={() => handleOptionClick("")}
                                >
                                    <div className="columnBox">
                                        <ColumnsBox
                                            headCells={headCells}
                                            handelChange={handelChange}
                                            disableColumns={disableColumns}
                                        />
                                    </div>
                                </material.ClickAwayListener>
                            )}
                        </div>
                        {/* <div
                className="deletediv bg-red "
              >
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div> */}
                    </material.Box>
                </div>
            </div>
            <Paper sx={{ width: "100%", borderRadius: "14px" }}>
                <TableContainer
                    className="table_body_height"
                    style={{
                        borderRadius: "14px",
                    }}
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        className="overflow-auto"
                    >
                        <TableHeadRow
                            headCells={headCells}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={chargingSessionList.length}
                            disableColumns={disableColumns}
                            disableCheckBox={true}
                        />
                        <TableBody>
                            {chargingSessionList?.map((row, index) => {
                                // const isItemSelected = isSelected(row.id);
                                // const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={false}
                                                disabled={true}
                                            // onClick={(event) => handleClick(event, row.id)}
                                            // inputProps={{
                                            //   "aria-labelledby": labelId,
                                            // }}
                                            />
                                        </TableCell>
                                        {!disableColumns.includes("sessionId") && (
                                            <TableCell component="th" scope="row" padding="none">
                                                {/* <Link
                                                to={"/activeCharging"}
                                                state={row}
                                                className="linkUnderLineHover"
                                                > */}
                                                {row.sessionId}
                                                {/* </Link> */}
                                            </TableCell>
                                        )}

                                        {!disableColumns.includes("chargingStationId") && (
                                            <TableCell>{row.cdrLocation.name}</TableCell>
                                        )}
                                        {!disableColumns.includes("evseId") && (
                                            <TableCell>{row.cdrLocation.evseId}</TableCell>
                                        )}
                                        {!disableColumns.includes("connectorId") && (
                                            <TableCell>{row.cdrLocation.connectorId}</TableCell>
                                        )}
                                        {!disableColumns.includes("type") && (
                                            <TableCell>{row.cdrToken.type}</TableCell>
                                        )}
                                        {!disableColumns.includes("startDate") && (
                                            <TableCell>
                                                {row.startDate &&
                                                    moment(row.startDate).format("DD MMM YYYY | hh:mm A")}
                                            </TableCell>
                                        )}
                                        {!disableColumns.includes("endDate") && (
                                            <TableCell>
                                                {row.endDate &&
                                                    moment(row.endDate).format("DD MMM YYYY | hh:mm A")}
                                            </TableCell>
                                        )}
                                        {!disableColumns.includes("currency") && (
                                            <TableCell>
                                                {row.currency}
                                            </TableCell>
                                        )}
                                        {!disableColumns.includes("totalEnergy") && (
                                            <TableCell>
                                                {row.totalEnergy}
                                            </TableCell>
                                        )}
                                        {!disableColumns.includes("totalCost") && (
                                            <TableCell>{row.totalCost?.inclVat}</TableCell>
                                        )}

                                        {!disableColumns.includes("invoiceReferenceId") && (
                                            <TableCell>{row.invoiceReferenceId}</TableCell>
                                        )}
                                        {!disableColumns.includes("credit") && (
                                            <TableCell>{row.credit}</TableCell>
                                        )}
                                        {!disableColumns.includes("totalTime") && (
                                            <TableCell>
                                                {row.totalTime}
                                            </TableCell>
                                        )}
                                        {/* {!disableColumns.includes("costsPerKwh") && (
                                            <TableCell>
                                                {row?.chargingSessionsBilling?.costsPerKwh}
                                            </TableCell>
                                        )}

                                        {!disableColumns.includes("status") && (
                                            <TableCell>{row.chargingState}</TableCell>
                                        )} */}
                                    </TableRow>
                                );
                            })}
                            {chargingSessionList.length === 0 && (
                                <TableRow style={{ height: 40 }}>
                                    <TableCell colSpan={8} className="saas-nodata-row">
                                        {noData}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="position-relative">
                    {chargingSessionList.length > 0 && (
                        <CustomTablePagination
                            rowsPerPageOptions={[10, 15, 20]}
                            count={count}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </div>
            </Paper>

            {sessionExpire && <SessionExpire />}
        </Box>
    );
}

export default Cdrs;
