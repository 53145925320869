import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";

function CredentialViewform() {
  const navigate = useNavigate();
  const location = useLocation();
  const [credentialsObject, setCredentialsObject] = React.useState({});

  React.useEffect(() => {
    setCredentialsObject(location.state);
  }, [location.state]);

  return (
    <div>
      <div className="w-100 mb-4 mx-0 p-0 d-flex align-items-center justify-content-between">
        <div className="fs-19 saas-boldHeading">Credentials</div>
        <div>
          <Button
            className="submit-btn"
            variant="contained"
            size="small"
            onClick={() => navigate("/credentials")}
          >
            <ArrowBackIosIcon fontSize="10px" />
            Back
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="p-4 border br-10 cardBackground h-100">
            <div className="w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Token
              </span>
              <span>:</span>
              <span>{credentialsObject?.token}</span>
            </div>
            <div className="w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Url
              </span>
              <span>:</span>
              <span>{credentialsObject?.url}</span>
            </div>
            <div className="w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                RoleName
              </span>
              <span>:</span>
              <span>{credentialsObject?.roles?.[0]?.role}</span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="p-4 border br-10 cardBackground h-100">
            <div className="w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                BusinessName
              </span>
              <span>:</span>
              <span>
                {credentialsObject?.roles?.[0]?.businessDetails?.businessName}
              </span>
            </div>
            <div className="w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                BusinessWebsite
              </span>
              <span>:</span>
              <span>
                {credentialsObject?.roles?.[0]?.businessDetails?.website}
              </span>
            </div>
            <div className="w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Logo URL
              </span>
              <span>:</span>
              <span>
                {credentialsObject?.roles?.[0]?.businessDetails?.logo?.url}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CredentialViewform;
