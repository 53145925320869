import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import * as material from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import CustomTablePagination from "../../../components/CustomTablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FilterTable from "../../../components/FilterTable";
import ColumnsBox from "../../../components/ColumnsBox";
import TableHeadRow from "../../../components/TableHeadRow";
import SessionExpire from "../../../components/SessionExpire";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { searchOperations } from "../../../common/helpers";
import PopUp from "../../../components/PopUp";
import { basePathone } from "../../../common/config";
import DisplayFilterInputs from "../../../components/DisplayFilterInputs";
import Loader from "../../../components/Loader";
import { handleApi_call } from "../../../common/CommonAPI";
import { headers } from "../../../common/Headers";
export default function ChargingProfiles({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [searchInputs, setSearchInputs] = React.useState({
    chargingProfileName: "",

    chargingProfilePurpose: "",
    chargingProfileKind: "",
    recurrencyKind: "",
    validFrom: "",
    validTo: "",
  });

  const [option, setOption] = React.useState("");
  const navigate = useNavigate();
  const [count, setCount] = React.useState(0);
  const [chargingProfileData, setChargingProfileData] = React.useState([]);
  const [noData, setNoData] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const headCells = [
    {
      id: "chargingProfileName",
      numeric: false,
      disablePadding: true,
      label: "Name",
      mandatory: true,
      filterOption: true,
      searchType: "text",
      sort: true,
      minWidth: 100,
    },

    {
      id: "chargingProfilePurpose",
      numeric: false,
      disablePadding: false,
      label: "Purpose",
      mandatory: false,
      searchType: "dropdown",
      filterOption: true,
      sort: true,
      dropdownOptions: [
        {
          value: "ChargingStationMaxProfile",
          label: "ChargingStationMaxProfile",
        },
        { value: "TxDefaultProfile", label: "TxDefaultProfile" },
        { value: "TxProfile", label: "TxProfile" },
        {
          value: "ChargingStationExternalConstraints",
          label: "ChargingStationExternalConstraints",
        },
      ],
    },
    {
      id: "chargingProfileKind",
      numeric: false,
      disablePadding: false,
      label: "Kind",
      mandatory: false,
      filterOption: true,
      searchType: "dropdown",
      sort: true,
      dropdownOptions: [
        { value: "Relative", label: "Relative" },
        { value: "Recurring", label: "Recurring" },
        { value: "Absolute", label: "Absolute" },
      ],
      minWidth: 100,
    },
    {
      id: "recurrencyKind",
      numeric: false,
      disablePadding: false,
      label: "Recurrency Kind",
      mandatory: false,
      filterOption: true,
      searchType: "dropdown",
      sort: true,
      dropdownOptions: [
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
      ],
      minWidth: 140,
    },
    {
      id: "validFrom",
      numeric: false,
      disablePadding: false,
      label: "Valid From",
      mandatory: true,
      filterOption: true,
      searchType: "date",
      sort: true,
      minWidth: 180,
    },
    {
      id: "validTo",
      numeric: false,
      disablePadding: false,
      label: "Valid To",
      mandatory: true,
      searchType: "date",
      filterOption: true,
      sort: true,
      minWidth: 180,
    },

    {
      id: "chargingScheduleName",
      numeric: false,
      disablePadding: false,
      label: "Schedule Name",
      mandatory: true,
      filterOption: false,
      minWidth: 130,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Update",
      mandatory: true,
      filterOption: false,
      sort: false,
    },
  ];
  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        chargingProfileName: "",
        chargingProfilePurpose: "",
        chargingProfileKind: "",
        recurrencyKind: "",
        chargingSchedule: "",
        validFrom: "",
        validTo: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }
  const [filterState, setFilterState] = React.useState([
    { key: "chargingProfileName", value: "" },
    { key: "chargingProfilePurpose", value: "" },
    { key: "chargingProfileKind", value: "" },
    { key: "recurrencyKind", value: "" },
    { key: "validFrom", value: "" },
    { key: "validTo", value: "" },
    { key: "chargingScheduleName", value: "" },
  ]);

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  const handleSchedulePage = (profileid, SchId) => {
    localStorage.setItem("ProfileId", profileid);
    navigate("/viewchargingschedule", { state: SchId });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleProfileId = (id) => {
    localStorage.setItem("ProfileId", id);
    navigate("/editChargeProfile");
  };
  const handleProfileIdForViewPage = (id) => {
    localStorage.setItem("ProfileId", id);
    navigate("/viewchargingprofiles");
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = chargingProfileData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "type") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.EQUALITY,
          };
        } else if (key === "validFrom") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.GREATER_THAN,
          };
        } else if (key === "validTo") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.LESS_THAN,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });

    return params;
  };
  const getChargingProfiles = async () => {
    const searchParams = buildSearchParams();
    const body = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    let Url = "chargingProfile/search";
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    setChargingProfileData(res?.data ?? []);
    setCount(res?.count);
    setNoData(res?.count === 0 ? "No Data Available" : "");
  };
  const deleteProfile = async () => {
    let Url = "chargingProfile/ids";
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      selected,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status === true) {
      setStatus(true);
      setSelected([]);
      setAlertMessage(res?.message);
      handleAlertPopup();
      getChargingProfiles();
    }
    if (res?.status === false) {
      setStatus(false);
      setAlertMessage(res?.message);
      handleAlertPopup();
    }
  };
  React.useEffect(() => {
    if (refresh) {
      getChargingProfiles();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getChargingProfiles();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);

  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      chargingProfileName: "",
      chargingProfilePurpose: "",
      chargingProfileKind: "",
      recurrencyKind: "",
      validFrom: "",
      validTo: "",
      chargingScheduleName: "",
    });
    setFilterState([
      { key: "chargingProfileName", value: "" },
      { key: "chargingProfilePurpose", value: "" },
      { key: "chargingProfileKind", value: "" },
      { key: "recurrencyKind", value: "" },
      { key: "validFrom", value: "" },
      { key: "validTo", value: "" },
      { key: "chargingScheduleName", value: "" },
    ]);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {loader && <Loader />}
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Charging Profiles</div>
        <div className="d-flex align-items-center gap-3 ">
          <div className="Export-Button bgcolor-blue">
            <Link
              to="/addChargerProfile"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <i class="fa-solid fa-plus" />
              ADD
            </Link>
          </div>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red " onClick={deleteProfile}>
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={chargingProfileData.length}
              disableColumns={disableColumns}
            />
            <TableBody>
              {chargingProfileData.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {!disableColumns.includes("chargingProfileName") && (
                      <TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <span
                          className="txt-blue cursor-Pointer"
                          onClick={() => handleProfileIdForViewPage(row.id)}
                        >
                          {row.chargingProfileName}
                        </span>
                      </TableCell>
                    )}

                    {!disableColumns.includes("chargingProfilePurpose") && (
                      <TableCell>{row.chargingProfilePurpose}</TableCell>
                    )}
                    {!disableColumns.includes("chargingProfileKind") && (
                      <TableCell>{row.chargingProfileKind}</TableCell>
                    )}
                    {!disableColumns.includes("recurrencyKind") && (
                      <TableCell>{row.recurrencyKind}</TableCell>
                    )}
                    {!disableColumns.includes("validFrom") && (
                      <TableCell>
                        {row.validFrom &&
                          moment(row.validFrom).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}
                    <TableCell>
                      {row.validTo &&
                        moment(row.validTo).format("DD MMM YYYY | hh:mm A")}
                    </TableCell>
                    {!disableColumns.includes("chargingScheduleName") && (
                      <TableCell>
                        {row?.chargingSchedules?.map((list, index) => (
                          <span key={index}>
                            {/* <Link to={"/viewchargingschedule"} state={list.id}> */}
                            <span
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={() =>
                                handleSchedulePage(row.id, list.id)
                              }
                            >
                              {list.chargingScheduleName}
                            </span>
                            {/* </Link> */}
                            {index < row?.chargingSchedules.length - 1 && ", "}
                          </span>
                        ))}
                      </TableCell>
                    )}
                    {!disableColumns.includes("action") && (
                      <TableCell className="m-0 " align="center">
                        {/* <Link to={"/editChargeProfile"} state={row}> */}
                        <i
                          className="fa-solid fa-pencil action-icons"
                          onClick={() => handleProfileId(row.id)}
                          style={{ color: "#2DCC70" }}
                        ></i>
                        {/* </Link> */}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {chargingProfileData.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {chargingProfileData.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertPopup}
        popUpWidth={"width-100"}
      />

      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
