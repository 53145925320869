import * as React from "react";

import {
  TextField,
  FormControl,
  FormLabel,
  Card,
  CardContent,
} from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import { Link, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { allowLettersNum } from "../../common/helpers";
import { basePathone } from "../../common/config";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function AddCredentials() {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const navigate = useNavigate();
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [statusFalsePop, setStatusFalsePop] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [addform, setAddForm] = React.useState({
    token: "",
    url: "",
  });
  const [addformErrors, setAddFormErrors] = React.useState({
    token: "",
    url: "",
  });
  const [loader, setLoader] = React.useState(false);
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))" + // domain name or IP address
      "(\\:[0-9]{1,5})?(\\/.*)?$",
    "i"
  );
  function handleClosePop() {
    navigate("/credentials");
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  const handleStatusFalse = () => {
    setStatusFalsePop((pop) => !pop);
  };
  function handleAddForm(key, value) {
    setAddFormErrors({ ...addformErrors, [key]: "" });
    setAddForm({ ...addform, [key]: value });
    if (key === "url") {
      let valid = urlPattern.test(value);
      if (valid) {
        setAddFormErrors({ ...addformErrors, [key]: "" });
      } else {
        setAddFormErrors({ ...addformErrors, [key]: "Enter Valid Url" });
      }
    }
  }
  function handleAddCredentials() {
    let error = false;
    if (!addform.token || !addform.url) {
      error = true;
    }
    if (error) {
      if (!addform.token) {
        setAddFormErrors((errors) => ({
          ...errors,
          token: "Token is Mandatory",
        }));
      }
      if (!addform.url) {
        setAddFormErrors((errors) => ({
          ...errors,
          url: "Url is Mandatory",
        }));
      }
    }
    if (!error && addformErrors.url.length === 0) {
      addCredentials();
    }
  }
  const addCredentials = async () => {
    let Url = `cpo/${2.1}/credentials`;
    let type = "POST";
    let body = addform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusMessage === "SUCCESS") {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleStatusFalse(); // Show error popup
    }
  };

  return (
    <div>
      {sessionExpire && <SessionExpire />}
      <div className="pagetitle">Add Credentials</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            {/* <div className="row mt-4 column-gap-5 row-gap-4 ms-3"> */}
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Token *</b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  placeholder="Token"
                  name="token"
                  variant="outlined"
                  required
                  value={addform.token}
                  inputProps={{
                    minLength: 1,
                    maxLength: 30,
                    onKeyDown: allowLettersNum,
                  }}
                  error={addformErrors.token}
                  helperText={addformErrors.token}
                  onChange={(e) => {
                    handleAddForm("token", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Url *</b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  placeholder="Url"
                  variant="outlined"
                  required
                  value={addform.url}
                  error={addformErrors.url}
                  helperText={addformErrors.url}
                  onChange={(e) => {
                    handleAddForm("url", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            {/* </div> */}
          </div>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-end gap-3 my-4">
        <Link to="/credentials" className="cancle-button">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          color="primary"
          variant="contained"
          className="submit-btn"
          onClick={handleAddCredentials}
        >
          Add
        </Button>
      </div>
      {loader && <FullLoader />}
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClosePop}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={statusFalsePop}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={handleStatusFalse}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
