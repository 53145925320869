import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";

function GetCompositeSchedule({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    connectorId: "",
    duration: "",
    chargingRateUnit: "",
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    connectorId: "",
    duration: "",
  });
  function handleOnChange(key, value) {
    if (key === "connectorId" || key === "duration") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
        setInputFields({ ...inputFields, [key]: parseInt(value) });
      }
    } else {
      setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
      setInputFields({ ...inputFields, [key]: value });
    }
  }
  function handleSubmit() {
    let error = false;
    if (
      inputFields.connectorId.length === 0 ||
      inputFields.duration.length === 0
    ) {
      error = true;
    }
    if (error) {
      if (inputFields.connectorId.length === 0) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          connectorId: "Connector Id is Mandatory",
        }));
      }
      if (inputFields.duration.length === 0) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          duration: "Duration is Mandatory",
        }));
      }
    }
    let payLoad = {
      connectorId: inputFields.connectorId,
      duration: inputFields.duration,
    };
    if (inputFields.chargingRateUnit) {
      payLoad.chargingRateUnit = inputFields.chargingRateUnit;
    }
    handleSubmitOperation(error, payLoad);
  }
  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Id (Integer) *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.connectorId}
                  helperText={inputFieldsValidation.connectorId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.connectorId}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("connectorId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Duration (In seconds) *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="outlined"
                  error={inputFieldsValidation.duration}
                  helperText={inputFieldsValidation.duration}
                  value={inputFields.duration}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("duration", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Charging Rate Unit </b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  variant="outlined"
                  value={inputFields.chargingRateUnit}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("chargingRateUnit", e.target.value);
                  }}
                >
                  <MenuItem value="W">W</MenuItem>
                  <MenuItem value="A">A</MenuItem>
                </TextField>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default GetCompositeSchedule;
