/** @format */
import { useTranslation } from "react-i18next";
import React from "react";
function PopularProduct({ toggles }) {
  const { t } = useTranslation();
  const elements = [
    { element: t("elements"), value: 124436 },
    { element: t("creative_eBook"), value: 124510 },
    { element: t("website_mockup"), value: 144750 },
  ];
  return (
    <div className={`card fs-12 ${toggles ? "expandWidth" : "customProducts"}`}>
      <div className="d-flex justify-content-between p-2">
        <b className="ps-3 pt-2 fs-5 mb-2 fw-bolder">{t("popular_products")}</b>
        <i className="fa-solid fa-ellipsis me-2 mt-2 fs-4 text-secondary"></i>
      </div>
      <span className="ps-3">{t("product_name")}</span>
      <ul className="list-group border-0 scroll ms-4">
        {elements.map((element) => (
          <li className="list-group-item border-0" key={element.element}>
            <b>{element.element}</b>
            <span className="d-block">#{element.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PopularProduct;
