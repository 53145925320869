import moment from "moment";

function DisplayFilterInputs({
  searchInputs,
  clearFilterValue,
  clearInputValue,
  resetStates,
}) {
  const handleClear = (key) => {
    clearFilterValue(key);
    clearInputValue(key);
  };
  // const displayValue = (value) => {
  //   // if (value === true) return "Active";
  //   // if (value === false) return "Deactive";
  //   // return value;
  //   if (typeof value === "boolean") {
  //     return value ? "Active" : "Deactive";
  //   }

  //   // Check if value is a valid date
  //   const date = moment(value);
  //   if (date.isValid()) {
  //     return date.format("DD MMM YYYY HH:mm");
  //   }

  //   return value; // Fallback for other types
  // };
  const displayValue = (value) => {
    if (typeof value === "boolean") {
      return value ? "Active" : "Deactive";
    }

    // Check if the value is a number or a string that can be a number
    if (
      typeof value === "number" ||
      (typeof value === "string" && !isNaN(value))
    ) {
      return value; // Return the number or string representation of a number directly
    }

    // // Check if value is a valid date
    // const date = moment(value);
    // if (date.isValid()) {
    //   return date.format("DD MMM YYYY HH:mm");
    // }
    // Check if the value is a specific date format
    if (
      typeof value === "string" &&
      /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}$/.test(value)
    ) {
      const date = moment(value);
      if (date.isValid()) {
        return date.format("DD MMM YYYY HH:mm");
      }
    }

    // Fallback for other types (text, etc.)
    return value;
  };

  return (
    <div>
      {Object.entries(searchInputs).map(
        ([key, value]) =>
          value && ( // Check if the value is present
            <span key={key} className="mx-2 filter-inputs">
              {displayValue(value)}
              <span
                className="mx-2 cursor-Pointer"
                onClick={() => handleClear(key)}
              >
                <i class="fa-solid fa-xmark text-danger" />
              </span>
            </span>
          )
      )}

      {/* Check if any value in searchInputs is present to show "Clear all" */}
      {Object.values(searchInputs).some((value) => value) && (
        <b
          style={{
            color: "red",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={resetStates}
        >
          Clear all
        </b>
      )}
    </div>
  );
}

export default DisplayFilterInputs;
