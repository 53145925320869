import { basePathone } from "./config";

export const handleApi_call = async (
  Url,
  headers,
  type,
  payLoad,
  setSessionExpire,
  setAlertMessage,
  handleAlertPopup,
  setLoader
) => {
  setLoader(true); // Start loading indicator

  try {
    const options = {
      method: type,
      headers: headers,
    };

    // Only include the body if the method is not GET
    if (type !== "GET") {
      options.body = JSON.stringify(payLoad);
    }

    const response = await fetch(basePathone + Url, options);
    // Handle different response statuses
    if (response.status === 401) {
      const data = handleUpdateJwtToken(
        Url,
        headers,
        type,
        payLoad,
        setSessionExpire,
        setAlertMessage,
        handleAlertPopup,
        setLoader
      );
      return data;
    } else if (response.status === 200) {
      const data = await response.json();
      return data; // Return the data from the API
    } else if (response.status === 400) {
      setAlertMessage("Bad Request. Please check your input.");
      handleAlertPopup();
    } else if (response.status === 403) {
      // setAlertMessage("Access Denied. You do not have permission.");
      // handleAlertPopup();
      const data = await response.json();
      return data; // Return the data from the API
    } else if (response.status === 500) {
      console.log("500 error");
      setAlertMessage("Internal Server Error. Please try again later.");
      handleAlertPopup();
    } else if (response.status === 502) {
      setAlertMessage("Bad Gateway. Please try again later.");
      handleAlertPopup();
    } else {
      setAlertMessage("Unexpected error occurred.");
      handleAlertPopup();
    }
  } catch (error) {
    console.error("API call failed:", error);
    setAlertMessage("Network error occurred. Please try again.");
    handleAlertPopup();
  } finally {
    setLoader(false); // Stop loading indicator after response is handled
  }
};
const handleUpdateJwtToken = async (
  Url,
  headers,
  type,
  payLoad,
  setSessionExpire,
  setAlertMessage,
  handleAlertPopup,
  setLoader
) => {
  setLoader(true);

  try {
    const resp = await fetch(basePathone + "auth/renew-jwt", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(localStorage.getItem("Refresh_Token")),
    });

    if (resp.status === 401) {
      setSessionExpire(true);
      // setAlertMessage("Session expired. Please log in again.");
      // handleAlertPopup();
      setLoader(false);
      return null; // return null in case of session expiration
    }

    const res = await resp.json();
    setLoader(false);

    if (res?.status) {
      localStorage.setItem("Authorization", res?.data?.accessToken);
      localStorage.setItem("Refresh_Token", res?.data?.refreshToken);
      headers.Authorization = res?.data.accessToken;

      // Recalling the original API call with the updated token
      const data = await handleApi_call(
        Url,
        headers,
        type,
        payLoad,
        setSessionExpire,
        setAlertMessage,
        handleAlertPopup,
        setLoader
      );

      return data;
    } else {
      setAlertMessage(res.message);
      handleAlertPopup();
      return null;
    }
  } catch (error) {
    console.error("JWT token renewal failed:", error);
    setAlertMessage("Failed to renew JWT token.");
    handleAlertPopup();
    setLoader(false);
    return null;
  }
};
