import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import moment from "moment";
import * as material from "@mui/material";
import React, { useEffect } from "react";
import ColumnsBox from "../../components/ColumnsBox";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import CustomTablePagination from "../../components/CustomTablePagination";
import FilterTable from "../../components/FilterTable";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

function Sessions({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchInputs, setSearchInputs] = React.useState({
    startDateTime: "",
    endDateTime: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "startDateTime", value: "" },
    { key: "endDateTime", value: "" },
  ]);
  const [count, setCount] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [noData, setNoData] = React.useState("");
  const [disableColumns, setDisableColumns] = React.useState([
    "currency",
    "kwh",
    "totalCost",
    "lastUpdated",
    "endDateTime",
  ]);
  const [option, setOption] = React.useState("");
  const [loader, setLoader] = React.useState([]);
  const [chargingSessionList, setChargingSessionList] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);

  const headCells = [
    {
      id: "locationId",
      numeric: false,
      disablePadding: true,
      label: "Location Id",
      mandatory: true,
      filterOption: true,
      sort: false,
      minWidth: 100,
    },
    {
      id: "evseUid",
      numeric: false,
      disablePadding: true,
      label: "EVSE Id",
      mandatory: true,
      filterOption: false,
      sort: false,
      minWidth: 80,
    },
    {
      id: "connectorId",
      numeric: false,
      disablePadding: true,
      label: "Connector Id",
      mandatory: true,
      filterOption: false,
      sort: false,
      minWidth: 80,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Token Type",
      mandatory: true,
      filterOption: false,
      sort: false,
      minWidth: 120,
    },

    {
      id: "startDateTime",
      numeric: false,
      disablePadding: true,
      label: "Start Date",
      mandatory: false,
      filterOption: true,
      sort: false,
      searchType: "date",
      minWidth: 150,
    },
    {
      id: "endDateTime",
      numeric: false,
      disablePadding: true,
      label: "Stop Date",
      mandatory: false,
      filterOption: true,
      sort: false,
      searchType: "date",
      minWidth: 150,
    },
    {
      id: "currency",
      numeric: false,
      disablePadding: true,
      label: "Currency",
      mandatory: true,
      filterOption: false,
      sort: false,
      minWidth: 100,
    },
    {
      id: "kwh",
      numeric: false,
      disablePadding: true,
      label: "Kwh",
      mandatory: false,
      filterOption: false,
      sort: false,
      minWidth: 100,
    },
    {
      id: "totalCost",
      numeric: false,
      disablePadding: true,
      label: "Total Cost",
      mandatory: false,
      filterOption: false,
      sort: false,
      minWidth: 100,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
      mandatory: false,
      filterOption: false,
      sort: false,
      minWidth: 150,
    },
    {
      id: "lastUpdated",
      numeric: false,
      disablePadding: true,
      mandatory: false,
      filterOption: false,
      sort: false,
      minWidth: 150,
      label: "Last Updated",
    },
  ];
  const getCdrs = async () => {
    let Url = `cpo/2.2/sessions?limit=${rowsPerPage}&offset=${page}&date_from=${searchInputs.startDateTime}&date_to=${searchInputs.endDateTime}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    setChargingSessionList(res?.data ?? []);
    setCount(res?.count);
    setNoData(res?.count === 0 ? "No Data Available" : "");
  };
  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        startDateTime: "",
        endDateTime: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      startDateTime: "",
      endDateTime: "",
    });
    setFilterState([
      { key: "startDateTime", value: "" },
      { key: "endDateTime", value: "" },
    ]);
  };
  useEffect(() => {
    getCdrs();
  }, [page, rowsPerPage, searchInputs]);

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  React.useEffect(() => {
    if (refresh) {
      getCdrs();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  return (
    <Box sx={{ width: "100%" }}>
      {loader && <Loader />}
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Sessions</div>
        <div className="Export-Button bgcolor-blue">
          <i className="fa-solid fa-arrow-up-from-bracket" />
          Export
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {/* <div
                className="deletediv bg-red "
              >
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div> */}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={chargingSessionList.length}
              disableColumns={disableColumns}
              disableCheckBox={true}
            />
            <TableBody>
              {chargingSessionList?.map((row, index) => {
                // const isItemSelected = isSelected(row.id);
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                        // onClick={(event) => handleClick(event, row.id)}
                        // inputProps={{
                        //   "aria-labelledby": labelId,
                        // }}
                      />
                    </TableCell>
                    {!disableColumns.includes("locationId") && (
                      <TableCell component="th" scope="row" padding="none">
                        {row.locationId}
                      </TableCell>
                    )}

                    {!disableColumns.includes("evseUid") && (
                      <TableCell>{row.evseUid}</TableCell>
                    )}
                    {!disableColumns.includes("connectorId") && (
                      <TableCell>{row.connectorId}</TableCell>
                    )}
                    {!disableColumns.includes("type") && (
                      <TableCell>{row.cdrToken.type}</TableCell>
                    )}
                    {!disableColumns.includes("startDateTime") && (
                      <TableCell>
                        {row?.startDateTime &&
                          moment(row?.startDateTime).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("endDateTime") && (
                      <TableCell>
                        {row?.endDateTime &&
                          moment(row?.endDateTime).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("kwh") && (
                      <TableCell>{row?.kwh}</TableCell>
                    )}

                    {!disableColumns.includes("currency") && (
                      <TableCell>{row?.currency}</TableCell>
                    )}

                    {!disableColumns.includes("totalCost") && (
                      <TableCell>
                        {row.totalCost?.exclVat}
                        {" (excl)"} <br />
                        {row.totalCost?.inclVat}
                        {" (incl)"}
                      </TableCell>
                    )}
                    {!disableColumns.includes("lastUpdated") && (
                      <TableCell>{row?.lastUpdated}</TableCell>
                    )}

                    {!disableColumns.includes("status") && (
                      <TableCell>{row?.status}</TableCell>
                    )}
                  </TableRow>
                );
              })}
              {chargingSessionList.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {chargingSessionList.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>

      {sessionExpire && <SessionExpire />}
    </Box>
  );
}

export default Sessions;
