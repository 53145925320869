import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";

function ClearChargingProfile({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    id: "",
    connectorId: "",
    chargingProfilePurpose: "",
    stackLevel: "",
  });
  function handleOnChange(key, value) {
    if (key === "connectorId" || key === "stackLevel") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFields({ ...inputFields, [key]: parseInt(value) });
      }
    } else {
      setInputFields({ ...inputFields, [key]: value });
    }
  }
  function handleSubmit() {
    let error = false;
    let payload = {};

    if (inputFields.id) {
      payload.id = parseInt(inputFields.id);
    }
    if (inputFields.connectorId) {
      payload.connectorId = parseInt(inputFields.connectorId);
    }
    if (inputFields.chargingProfilePurpose) {
      payload.chargingProfilePurpose = inputFields.chargingProfilePurpose;
    }
    if (inputFields.stackLevel) {
      payload.stackLevel = parseInt(inputFields.stackLevel);
    }

    handleSubmitOperation(error, payload);
  }
  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Profile Id </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="outlined"
                  value={inputFields.id}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("id", e.target.value);
                  }}
                ></TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Id (Integer) *</b>
                </FormLabel>
                <TextField
                  variant="outlined"
                  className="w-100"
                  value={inputFields.connectorId}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("connectorId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Charging Profile Purpose </b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  variant="outlined"
                  value={inputFields.chargingProfilePurpose}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("chargingProfilePurpose", e.target.value);
                  }}
                >
                  <MenuItem value="ChargePointMaxProfile">
                    Charge Point Max Profile
                  </MenuItem>
                  <MenuItem value="TxDefaultProfile">
                    TxDefault Profile
                  </MenuItem>
                  <MenuItem value="TxProfile">TxProfile</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Stack Level (Integer) </b>
                </FormLabel>
                <TextField
                  variant="outlined"
                  className="w-100"
                  value={inputFields.stackLevel}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("stackLevel", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          className="submit-btn"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default ClearChargingProfile;
