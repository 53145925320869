/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import { FormControl, FormLabel, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { handleKeyDownAlphaUnderScore } from "../../common/helpers";
export default function RoleAddForm({
  addform,
  handleAddForm,
  tenants,
  addformErrors,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Box>
        <div className="d-flex flex-column  align-items-center gap-4 w-auto mt-3">
          <FormControl className="simpleInput">
            <FormLabel>
              <b>Role Name *</b>
            </FormLabel>
            <TextField
              placeholder="Role Name"
              error={addformErrors.roleName}
              className=" me-3 w-100 error"
              required
              inputProps={{
                maxLength: 25,
              }}
              onKeyDown={handleKeyDownAlphaUnderScore}
              variant="outlined"
              value={addform?.roleName}
              size="small"
              helperText={addformErrors.roleName}
              onChange={(e) => {
                handleAddForm("roleName", e.target.value);
              }}
            />
          </FormControl>
          <FormControl className="simpleInput">
            <FormLabel>
              <b>Tenant Name *</b>
            </FormLabel>
            <TextField
              select
              placeholder="Tenant Name"
              className=" me-3 w-100 error"
              error={addformErrors.tenantId}
              value={addform?.tenantId}
              required
              size="small"
              helperText={addformErrors.tenantId}
              onChange={(e) => {
                handleAddForm("tenantId", e.target.value);
              }}
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                  {tenant.tenantName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl className="simpleInput">
            <FormLabel>
              <b>Role Description</b>
            </FormLabel>
            <TextField
              placeholder="Role Description"
              className="me-3 w-100 error"
              error={addformErrors.description}
              required
              inputProps={{
                maxLength: 100,
              }}
              variant="outlined"
              value={addform?.description}
              size="small"
              helperText={addformErrors.description}
              onChange={(e) => {
                handleAddForm("description", e.target.value);
              }}
            />
          </FormControl>
          {/* <TextField
              id="outlined-basic"
              label="Role"
              variant="outlined"
            /> */}
        </div>
      </Box>
    </div>
  );
}
