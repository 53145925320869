import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";

function TriggerMessage20({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    requestedMessage: "",
    evse: { connectorId: "", id: "" },
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    id: "",
    requestedMessage: "",
  });
  function handleOnChange(key, value) {
    if (key === "id" || key === "connectorId") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
        setInputFields((prevState) => ({
          ...prevState,
          evse: {
            ...prevState.evse,
            [key]: value,
          },
        }));
      }
    } else {
      setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
      setInputFields({ ...inputFields, [key]: value });
    }
  }
  function handleSubmit() {
    let error = false;
    if (!inputFields.evse.id || !inputFields.requestedMessage) {
      error = true;
    }
    if (error) {
      if (!inputFields.evse.id) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          id: " Id is Mandatory",
        }));
      }
      if (!inputFields.requestedMessage) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          requestedMessage: "Please Select the MessageType",
        }));
      }
    }
    let payLoad = {
      requestedMessage: inputFields.requestedMessage,
      evse: {
        id: parseInt(inputFields.evse.id),
      },
    };
    if (inputFields.evse.connectorId) {
      payLoad.evse.connectorId = parseInt(inputFields.evse.connectorId);
    }
    handleSubmitOperation(error, payLoad);
  }

  return (
    <div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b> Id (Integer) *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.id}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.evse.id}
                  size="small"
                  helperText={inputFieldsValidation.id}
                  onChange={(e) => {
                    handleOnChange("id", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Id (Integer) </b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.connectorId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.connectorId}
                  size="small"
                  helperText={inputFieldsValidation.connectorId}
                  onChange={(e) => {
                    handleOnChange("connectorId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Requestes Message Type *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  error={inputFieldsValidation.requestedMessage}
                  required
                  variant="outlined"
                  value={inputFields.requestedMessage}
                  size="small"
                  helperText={inputFieldsValidation.requestedMessage}
                  onChange={(e) => {
                    handleOnChange("requestedMessage", e.target.value);
                  }}
                >
                  <MenuItem value="BootNotification">BootNotification</MenuItem>
                  <MenuItem value="LogStatusNotification">
                    Log Status Notification
                  </MenuItem>
                  <MenuItem value="FirmwareStatusNotification">
                    Firmware Status Notification
                  </MenuItem>
                  <MenuItem value="Heartbeat">HeartBeat</MenuItem>
                  <MenuItem value="MeterValues">Meter Values</MenuItem>
                  <MenuItem value="StatusNotification">
                    Status Notification
                  </MenuItem>
                  <MenuItem value="SignChargingStationCertificate">
                    SignCharging Station Certificate
                  </MenuItem>
                  <MenuItem value="SignV2GCertificate">
                    Sign V2G Certificate
                  </MenuItem>
                  <MenuItem value="TransactionEvent">TransactionEvent</MenuItem>
                  <MenuItem value="SignCombinedCertificate">
                    Sign Combined Certificate
                  </MenuItem>
                  <MenuItem value="PublishFirmwareStatusNotification">
                    Publish Firmware Status Notification
                  </MenuItem>
                </TextField>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className="submit-btn"
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default TriggerMessage20;
