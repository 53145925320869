import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { useTranslation } from "react-i18next";
import { basePath } from "../../common/config";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
export default function EditUser({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [roles, setRoles] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [updateRow, setUpdateRow] = React.useState({});
  const [statusFalsePop, setStatusFalsepop] = React.useState(false);
  const [updateRowErrors, setUpdateRowErrors] = React.useState({
    firstName: "",
    lastName: "",
    mobile: "",
  });
  const location = useLocation();
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleStatusFalsePop() {
    setStatusFalsepop((pop) => !pop);
  }

  const navigate = useNavigate();
  function handleCloseUpdatePopup() {
    navigate("/userManagement");
  }

  function handleUpdateForm(key, value) {
    if (key === "mobile") {
      if (!isNaN(value)) {
        setUpdateRowErrors({ ...updateRowErrors, [key]: "" });
        setUpdateRow({
          ...updateRow,
          [key]: value,
        });
      }
    } else {
      setUpdateRowErrors({ ...updateRowErrors, [key]: "" });
      setUpdateRow({ ...updateRow, [key]: value });
    }
  }
  function handleUpdate() {
    let error = false;
    if (
      updateRow.firstName.length === 0 ||
      updateRow.lastName.length === 0 ||
      updateRow.mobile.length === 0 ||
      updateRow.mobile.length !== 10
    ) {
      error = true;
    }
    if (error) {
      if (updateRow.firstName.length === 0) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          firstName: t("name_validation"),
        }));
      }
      if (updateRow.lastName.length === 0) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          lastName: t("name_validation"),
        }));
      }
      if (updateRow.mobile.length === 0) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          mobile: t("number_required"),
        }));
      }
      if (updateRow.mobile.length !== 10 && updateRow.mobile.length > 0) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          mobile: t("phonenumber_validation"),
        }));
      }
    } else {
      updateUser();
    }
  }
  const getRoles = async () => {
    const Url = "roles/getAllRoles";
    const type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res && res?.status) {
      setRoles(res.data);
    }
  };

  const updateUser = async () => {
    let Url = "user/" + updateRow.userId;
    let type = "PATCH";
    let body = updateRow;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleStatusFalsePop();
    }
  };

  React.useEffect(() => {
    getRoles();
    setUpdateRow(location.state);
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getRoles();
      setUpdateRow(location.state);
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <div>
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
      <div className="pagetitle">Update User</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>First Name *</b>
                </FormLabel>
                <TextField
                  error={updateRowErrors.firstName}
                  className="w-100"
                  required
                  inputProps={{
                    minLength: 5,
                    maxLength: 25,
                  }}
                  variant="outlined"
                  value={updateRow?.firstName}
                  size="small"
                  helperText={updateRowErrors.firstName}
                  onChange={(e) => {
                    handleUpdateForm("firstName", e.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Last Name *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  error={updateRowErrors.lastName}
                  required
                  variant="outlined"
                  value={updateRow?.lastName}
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 25,
                  }}
                  helperText={updateRowErrors.lastName}
                  onChange={(e) => {
                    handleUpdateForm("lastName", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Email *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  type="email"
                  variant="outlined"
                  value={updateRow?.email}
                  size="small"
                  inputProps={{
                    maxLength: 30,
                  }}
                  disabled
                  onChange={(e) => {
                    handleUpdateForm("email", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Role Name *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  value={location.state?.roleId}
                  required
                  size="small"
                  disabled
                  onChange={(e) => {
                    handleUpdateForm("roleId", e.target.value);
                  }}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.roleId} value={role.roleId}>
                      {role.roleName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            {/* <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Password *</b>
                </FormLabel>
                <TextField
                  type="password"
                  className="w-100"
                  placeholder="Password"
                  error={updateRowErrors.password}
                  required
                  variant="outlined"
                  value={updateRow?.password}
                  size="small"
                  inputProps={{
                    minLength: 8,
                    maxLength: 20,
                  }}
                  helperText={updateRowErrors.password}
                  onChange={(e) => {
                    handleAddForm("password", e.target.value);
                  }}
                />
              </FormControl>
            </div> */}
            {/* <div className="text-danger fw-bold mt-4">{t(passwordError)}</div> */}
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Mobile *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  error={updateRowErrors.mobile}
                  variant="outlined"
                  value={updateRow?.mobile}
                  size="small"
                  inputProps={{
                    maxLength: 10,
                  }}
                  helperText={updateRowErrors.mobile}
                  onChange={(e) => {
                    handleUpdateForm("mobile", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Tenant Name *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  disabled
                  variant="outlined"
                  value={updateRow?.tenantId}
                  size="small"
                  onChange={(e) => {
                    handleUpdateForm("tenantId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-end gap-3 my-4">
        <Button
          size="small"
          color="error"
          variant="contained"
          className="cancel-btn"
        >
          <Link to="/userManagement" className="cancle-button">
            Cancel
          </Link>
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleUpdate}
          className="submit-btn"
        >
          Update
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        handleClosePopUp={handleCloseUpdatePopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleCloseUpdatePopup}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={statusFalsePop}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleStatusFalsePop}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
