/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "./Card";
import Graph from "./Graph";
import LatestCustomer from "./LatestCustomer";
import PopularProduct from "./PopularProduct";
import ProductEarnings from "./ProductEarnings";
import ProductList from "./ProductList";
import { resize } from "../../common/helpers";

function Dashboard({ toggles }) {
  const { t } = useTranslation();

  React.useEffect(() => {
    resize();
  });
  return (
    <div className="d-flex flex-column flex-sm-row gap-4">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column flex-sm-row gap-4">
          <Card
            value={4107220}
            name={t("visitors")}
            percentage={16.24}
            toggles={toggles}
          />
          <Card
            value={312560}
            name={t("followers")}
            percentage={-10.78}
            toggles={toggles}
          />
          <Card
            value={39680}
            name={t("sales")}
            percentage={20.9}
            toggles={toggles}
          />
        </div>
        <Graph toggles={toggles} />
        <ProductList toggles={toggles} />
      </div>
      <div className="d-flex flex-column gap-3">
        <LatestCustomer toggles={toggles} />
        <ProductEarnings toggles={toggles} />
        <PopularProduct toggles={toggles} />
      </div>
    </div>
  );
}

export default Dashboard;
