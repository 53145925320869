import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";

function GetLog201({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    logType: "",
    requestId: "",
    retries: "",
    retryInterval: "",
    log: "",
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    logType: "",
    requestId: "",
    log: "",
  });
  function handleOnChange(key, value) {
    setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
    setInputFields({ ...inputFields, [key]: value });
  }

  function handleSubmit() {
    let error = false;
    if (
      inputFields.logType.length === 0 ||
      inputFields.requestId.length === 0 ||
      inputFields.log.length === 0
    ) {
      error = true;
    }
    if (error) {
      if (inputFields.logType.length === 0) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          logType: "Please Select the Log Type",
        }));
      }
      if (inputFields.requestId.length === 0) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          requestId: "Request Id is Mandatory",
        }));
      }
      if (inputFields.log.length === 0) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          log: " Log Parameter is required",
        }));
      }
    }
    handleSubmitOperation(error, inputFields);
  }

  return (
    <div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row gap-2 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Log Type *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  error={inputFieldsValidation.logType}
                  required
                  variant="outlined"
                  value={inputFields.logType}
                  size="small"
                  helperText={inputFieldsValidation.logType}
                  onChange={(e) => {
                    handleOnChange("logType", e.target.value);
                  }}
                >
                  <MenuItem value="DiagnosticsLog">DiagnosticsLog</MenuItem>
                  <MenuItem value="SecurityLog">SecurityLog</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Request Id (Integer) *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.requestId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.requestId}
                  size="small"
                  helperText={inputFieldsValidation.requestId}
                  onChange={(e) => {
                    handleOnChange("requestId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Retries (Integer)</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.requestId}
                  className="w-100"
                  variant="outlined"
                  value={inputFields.requestId}
                  size="small"
                  helperText={inputFieldsValidation.requestId}
                  onChange={(e) => {
                    handleOnChange("requestId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Retry Interval (Integer) *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.retryInterval}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.retryInterval}
                  size="small"
                  helperText={inputFieldsValidation.retryInterval}
                  onChange={(e) => {
                    handleOnChange("retryInterval", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>LogParameter *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.log}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.log}
                  size="small"
                  helperText={inputFieldsValidation.log}
                  onChange={(e) => {
                    handleOnChange("log", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className="submit-btn"
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default GetLog201;
