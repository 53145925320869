/** @format */
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import TableHeadRow from "../../components/TableHeadRow";
import { basePathone } from "../../common/config";
import CustomTablePagination from "../../components/CustomTablePagination";
import * as material from "@mui/material";
import React from "react";
import SessionExpire from "../../components/SessionExpire";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import Loader from "../../components/Loader";
import PopUp from "../../components/PopUp";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function PingPongLogs({ refresh, setRefresh }) {
  const [orderBy, setOrderBy] = React.useState("id");
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [option, setOption] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [noData, setNoData] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [CPLogs, setCpLogs] = React.useState([]);
  const [searchInputs, setSearchInputs] = React.useState({
    messageChargingStationId: "",
    messageMessageId: "",
    messageAction: "",
    responseChargingStationId: "",
    messageCreatedAt: "",
    responseUpdatedAt: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "messageChargingStationId", value: "" },
    { key: "messageMessageId", value: "" },
    { key: "messageAction", value: "" },
    { key: "responseChargingStationId", value: "" },
    { key: "messageCreatedAt", value: "" },
    { key: "responseUpdatedAt", value: "" },
  ]);
  const headCells = [
    {
      id: "messageMessageId",
      numeric: false,
      disablePadding: false,
      label: "Message ID",
      mandatory: true,
      filterOption: true,
      searchType: "text",
      sort: false,
      minWidth: 120,
    },
    {
      id: "messageChargingStationId",
      numeric: false,
      disablePadding: false,
      label: "Location",
      mandatory: false,
      filterOption: true,
      searchType: "text",
      sort: false,
      minWidth: 150,
    },
    {
      id: "messageAction",
      numeric: false,
      disablePadding: false,
      label: "Action",
      mandatory: true,
      filterOption: true,
      searchType: "text",
      sort: false,
      minWidth: 130,
    },

    // {
    //   id: "messagePayload",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Request",
    //   mandatory: false,
    //   filterOption: false,
    //   sort: false,
    //   minWidth: 150,
    // },
    // {
    //   id: "responseResponse",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Response",
    //   mandatory: false,
    //   filterOption: false,
    //   sort: false,
    // },
    {
      id: "messageCreatedAt",
      numeric: false,
      disablePadding: false,
      label: "Start Date",
      mandatory: true,
      filterOption: true,
      searchType: "date",
      sort: false,
      minWidth: 180,
    },
    {
      id: "responseUpdatedAt",
      numeric: false,
      disablePadding: false,
      label: "End Date",
      mandatory: true,
      filterOption: true,
      sort: false,
      searchType: "date",
      minWidth: 180,
    },
    {
      id: "body",
      numeric: false,
      disablePadding: false,
      label: "View",
      mandatory: false,
      filterOption: false,
      sort: false,
      searchType: "date",
      minWidth: 65,
    },
  ];

  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  const handleClick1 = (request, response) => {
    const message = (
      <div>
        <p>
          <strong>Request:</strong>
        </p>
        <p>{request}</p>
        <p>
          <strong>Response:</strong>
        </p>
        <p>{response}</p>
      </div>
    );
    setPopupMessage(message);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      messageChargingStationId: "",
      messageMessageId: "",
      messageAction: "",
      responseChargingStationId: "",
      messageCreatedAt: "",
      responseUpdatedAt: "",
    });
    setFilterState([
      { key: "messageMessageId", value: "" },
      { key: "messageChargingStationId", value: "" },
      { key: "messageAction", value: "" },
      { key: "responseChargingStationId", value: "" },
      { key: "messageCreatedAt", value: "" },
      { key: "responseUpdatedAt", value: "" },
    ]);
  };
  const handleOptionClick = (value) => {
    setOption(option === value ? "" : value);
  };

  const handleColumnChange = (id) => {
    setDisableColumns(
      disableColumns.includes(id)
        ? disableColumns.filter((c) => c !== id)
        : [...disableColumns, id]
    );
  };

  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        messageMessageId: "",
        messageChargingStationId: "",
        messageAction: "",
        responseChargingStationId: "",
        messageCreatedAt: "",
        responseUpdatedAt: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCpLogs = async () => {
    const Url = `api/charging-station-messages/view-data?messageChargingStationId=${searchInputs.messageChargingStationId}&messageMessageId=${searchInputs.messageMessageId}&messageAction=${searchInputs.messageAction}&responseChargingStationId=${searchInputs.responseChargingStationId}&startDate=${searchInputs.messageCreatedAt}&endDate=${searchInputs.responseUpdatedAt}&page=${page}&size=${rowsPerPage}`;
    const type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    setCpLogs(res?.content ?? []);
    setCount(res?.totalElements);
    setNoData(res?.totalElements === 0 ? "No Data Available" : "");
  };
  React.useEffect(() => {
    if (refresh) {
      getCpLogs();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getCpLogs();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);
  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Station Logs</div>
        <div className="d-flex align-items-center gap-3 ">
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handleColumnChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {/* {selected.length > 0 && (
              <div className="deletediv bg-red ">
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )} */}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer className="table_body_height">
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            //className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={CPLogs.length}
              disableColumns={disableColumns}
              disableCheckBox={true}
            />
            <TableBody>
              {CPLogs.map((row, index) => {
                //  const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow tabIndex={-1} key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    {!disableColumns.includes("messageMessageId") && (
                      <TableCell>{row.messageMessageId}</TableCell>
                    )}
                    {!disableColumns.includes("messageChargingStationId") && (
                      <TableCell>{row.messageChargingStationId}</TableCell>
                    )}

                    {!disableColumns.includes("messageAction") && (
                      <TableCell>{row.messageAction}</TableCell>
                    )}

                    {!disableColumns.includes("messageCreatedAt") && (
                      <TableCell>
                        {row.messageCreatedAt &&
                          moment(row.messageCreatedAt).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("responseUpdatedAt") && (
                      <TableCell>
                        {row.responseUpdatedAt &&
                          moment(row.responseUpdatedAt).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("body") && (
                      <TableCell>
                        <i className="fa-solid fa-eye" style={{ color: "#2DCC70" }} onClick={() =>
                            handleClick1(
                              row.messagePayload,
                              row.responseResponse
                            )
                          }></i>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {CPLogs.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {CPLogs.length > 0 && (
          <CustomTablePagination
            rowsPerPageOptions={[10, 15, 20]}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <PopUp
          open={open}
          title={"View Body"}
          message={popupMessage}
          btn1={true}
          btn1Text={"Ok"}
          btn1Color={"submit-btn"}
          handleClickBtn1={handleClose}
          popUpWidth={"width-100"}
        />
      </Paper>

      {/* {SessionExpire && <SessionExpire />} */}
      {loader && <Loader />}
    </Box>
  );
}
