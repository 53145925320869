import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import SessionExpire from "../../components/SessionExpire";
import { basePathone } from "../../common/config";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
import { formatDate } from "../../common/helpers";

export default function AddTariffs({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState();
  const [statusFalsePop, setStatusFalsePop] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [tarrifTypeDrop, setTarrifTypeDrop] = React.useState([]);
  const [currencyDrop, setCurrencyDrop] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const navigate = useNavigate();
  const [minPriceObj, setMinPriceObj] = React.useState({
    exclVat: "",
    inclVat: "",
  });
  const [maxPriceObj, setMaxPriceObj] = React.useState({
    exclVat: "",
    inclVat: "",
  });
  const [energyMixObj, setEnergyMixObj] = React.useState({
    supplier_name: "",
    energy_product_name: "",
    green_energy: false,
  });

  const [errors, setErrors] = React.useState({});
  const [formData, setFormData] = React.useState({
    currency: "",
    type: "",
    tariff_alt_url: "",
    reservation: "",
    minPrice: {},
    maxPrice: {},
    startDateTime: "",
    endDateTime: "",
    energy_mix: {},
  });
  React.useEffect(() => {
    getTarrifType();
    getCurrencyList();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getTarrifType();
      getCurrencyList();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  // All Drop down Apis

  const getCurrencyList = async () => {
    let Url = `cpo/${2.1}/tariffs/countryAndCurrencies`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setCurrencyDrop(res?.data);
    }
  };
  const getTarrifType = async () => {
    let Url = `cpo/${2.1}/tariffs/getTarrifType`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setTarrifTypeDrop(res?.data);
    }
  };
  //
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const handleStatusFalse = () => {
    setStatusFalsePop((pop) => !pop);
  };
  function handleChange(key, value) {
    setErrors({ ...errors, [key]: "" });
    setFormData({
      ...formData,
      [key]: value,
    });
    if (key === "tariff_alt_url") {
      let valid = urlPattern.test(value);
      if (valid) {
        setErrors({ ...errors, [key]: "" });
      } else {
        setErrors({ ...errors, [key]: "Enter valid url" });
      }
    }
  }
  function HandleMinChange(key, value) {
    if (key === "exclVat") {
      setErrors({ ...errors, MinexclVat: "" });
    } else {
      setErrors({ ...errors, MininclVat: "" });
    }
    setMinPriceObj({
      ...minPriceObj,
      [key]: value,
    });
  }
  function HandleMaxChange(key, value) {
    if (key === "exclVat") {
      setErrors({ ...errors, MaxexclVat: "" });
    } else {
      setErrors({ ...errors, MaxinclVat: "" });
    }
    setMaxPriceObj({
      ...maxPriceObj,
      [key]: value,
    });
  }
  function handleEnergyMinChange(key, value) {
    setErrors({ ...errors, [key]: "" });
    setEnergyMixObj({
      ...energyMixObj,
      [key]: value,
    });
  }

  const urlPattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol, must be http:// or https://
      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))" + // domain name or IP address
      "(\\:[0-9]{1,5})?(\\/.*)?$", // optional port and path
    "i"
  );
  const handleValidations = () => {
    let errors = {};
    if (!energyMixObj.energy_product_name)
      errors.energy_product_name = "Product Name is required";
    if (!energyMixObj.supplier_name)
      errors.supplier_name = "Supplier Name is required";
    if (!formData.currency) errors.currency = "Please select currency type";
    if (!formData.type) errors.type = "Please select type";
    if (!formData.endDateTime) errors.endDateTime = "End Date is required";
    if (!formData.startDateTime)
      errors.startDateTime = "Start Date is required";
    if (!formData.tariff_alt_url)
      errors.tariff_alt_url = "tariff Alt Url is required";
    if (!minPriceObj.exclVat) errors.MinexclVat = "ExclVat is required";
    if (!minPriceObj.inclVat) errors.MininclVat = "inclVat is required";
    if (!maxPriceObj.exclVat) errors.MaxexclVat = "ExclVat is required";
    if (!maxPriceObj.inclVat) errors.MaxinclVat = "inclVat is required";
    //
    if (formData.tariff_alt_url) {
      let valid = urlPattern.test(formData.tariff_alt_url);
      if (!valid) {
        errors.tariff_alt_url = "Enter valid url";
      }
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // return true or false
  };
  const handleAddSubmit = async () => {
    if (handleValidations()) {
      let form = formData;
      form.minPrice = minPriceObj;
      form.maxPrice = maxPriceObj;
      form.energy_mix = energyMixObj;
      let Url = `cpo/${2.1}/tariffs/addtariff`;
      let type = "POST";
      let body = form;
      let res = await handleApi_call(
        Url,
        headers,
        type,
        body,
        setSessionExpire,
        setAlertMessage,
        handleAlertPopup,
        setLoader
      );
      if (res?.statusMessage === "SUCCESS") {
        setStatusMessage("SUCCESS");
        setAlertMessage(res?.data);
        handleAlertPopup();
      } else {
        setStatusMessage("FAILURE");
        setAlertMessage(res?.data);
        handleAlertPopup(); // Show error popup
      }
    }
  };

  return (
    <div className="row row-gap-3 mt-3 mb-5">
      <div className="pagetitle">Add Tariff</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Product Name *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Product Name"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={energyMixObj.energy_product_name}
                  inputProps={{
                    minLength: 3,
                    maxLength: 30,
                    onKeyDown: (e) => {
                      if (/[^A-Za-z\s]/.test(e.key)) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[0-9]/g, "");
                    handleEnergyMinChange(
                      "energy_product_name",
                      e.target.value
                    );
                  }}
                  error={errors.energy_product_name}
                  helperText={errors.energy_product_name}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Supplier Name *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Supplier Name"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={energyMixObj.supplier_name}
                  inputProps={{
                    minLength: 3,
                    maxLength: 30,
                    onKeyDown: (e) => {
                      if (/[^A-Za-z\s]/.test(e.key)) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[0-9]/g, "");
                    handleEnergyMinChange("supplier_name", e.target.value);
                  }}
                  error={errors.supplier_name}
                  helperText={errors.supplier_name}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Type *</b>
                </FormLabel>
                <TextField
                  select
                  placeholder="Type"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={formData.type}
                  error={errors.type}
                  helperText={errors.type}
                  onChange={(e) => handleChange("type", e.target.value)}
                >
                  {tarrifTypeDrop.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Price (exclVat) *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Max Price (exclVat)"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={maxPriceObj.exclVat}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    HandleMaxChange("exclVat", e.target.value);
                  }}
                  error={errors.MaxexclVat}
                  helperText={errors.MaxexclVat}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Price (inclVat) *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Max Price (inclVat)"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={maxPriceObj.inclVat}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    HandleMaxChange("inclVat", e.target.value);
                  }}
                  error={errors.MaxinclVat}
                  helperText={errors.MaxinclVat}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Green Energy *</b>
                </FormLabel>
                <Switch
                  checked={energyMixObj.green_energy}
                  onChange={(e) =>
                    handleEnergyMinChange("green_energy", e.target.checked)
                  }
                  size="small"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Min Price (exclVat) *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Min Price (exclVat)"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={minPriceObj.exclVat}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    HandleMinChange("exclVat", e.target.value);
                  }}
                  error={errors.MinexclVat}
                  helperText={errors.MinexclVat}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Min Price (inclVat) *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Min Price (inclVat)"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={minPriceObj.inclVat}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");

                    HandleMinChange("inclVat", value);
                  }}
                  error={errors.MininclVat}
                  helperText={errors.MininclVat}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Currency *</b>
                </FormLabel>
                <TextField
                  select
                  placeholder="Currency"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={formData.currency}
                  onChange={(e) => handleChange("currency", e.target.value)}
                  error={errors.currency}
                  helperText={errors.currency}
                >
                  {currencyDrop.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Tarrif Alt Url *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Tarrif Alt Url"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={formData.tariff_alt_url}
                  onChange={(e) =>
                    handleChange("tariff_alt_url", e.target.value)
                  }
                  error={errors.tariff_alt_url}
                  helperText={errors.tariff_alt_url}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Start Date Time *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={formData?.startDateTime}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value); // Convert to Date object
                    handleChange("startDateTime", formatDate(dateValue)); // Pass formatted date
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors.startDateTime}
                </p>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>End Date Time *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={formData?.endDateTime}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value); // Convert to Date object
                    handleChange("endDateTime", formatDate(dateValue)); // Pass formatted date
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors.endDateTime}
                </p>
              </FormControl>
            </div>
            {/* <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Start Date Time *</b>
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    ampm
                    AdapterMoment
                    viewRenderers={{
                      hours: null,
                      minutes: null,
                      seconds: null,
                    }}
                    placeholder="Start Date Time"
                    inputFormat="DD/MM/YYYY HH:mm"
                    value={
                      formData.startDateTime ? moment(formData.startDateTime) : null
                    }
                    className="w-100"
                    slots={{
                      popper: (props) => (
                        <div className="position-relative">
                          <Popper
                            {...props}
                            style={{ zIndex: 1000 }}
                            disablePortal
                            className="position-absolute"
                          />
                        </div>
                      ),
                    }}
                    sx={{ width: "96%" }}
                    slotProps={{
                      textField: {
                        size: "small",
                        required: "true",
                        error: errors.startDateTime,
                        helperText: errors.startDateTime,
                      },
                    }}
                    shouldDisableDate={shouldDisableStartDate}
                    onChange={(value) => {
                      handleChange("startDateTime", value);
                      if (
                        formData.endDateTime &&
                        moment(value).isSameOrAfter(moment(formData.endDateTime))
                      ) {
                        handleChange("endDateTime", null);
                      }
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div> */}
            {/* <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>End Date Time *</b>
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    ampm
                    AdapterMoment
                    viewRenderers={{
                      hours: null,
                      minutes: null,
                      seconds: null,
                    }}
                    placeholder="End Date Time"
                    inputFormat="DD/MM/YYYY HH:mm"
                    value={formData.endDateTime ? moment(formData.endDateTime) : null}
                    minDateTime={
                      formData.startDateTime
                        ? moment(formData.startDateTime).add(1, "minute")
                        : null
                    }
                    className="w-100"
                    slots={{
                      popper: (props) => (
                        <div className="position-relative">
                          <Popper
                            {...props}
                            style={{ zIndex: 1000 }}
                            disablePortal
                            className="position-absolute"
                          />
                        </div>
                      ),
                    }}
                    sx={{ width: "96%" }}
                    slotProps={{
                      textField: {
                        size: "small",
                        required: "true",
                        error: errors.endDateTime,
                        helperText: errors.endDateTime,
                      },
                    }}
                    shouldDisableDate={shouldDisableEndDate}
                    onChange={(value) => {
                      handleChange("endDateTime", value);
                      if (
                        formData.startDateTime &&
                        moment(value).isSameOrBefore(moment(formData.startDateTime))
                      ) {
                        handleChange("startDateTime", null);
                      }
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div> */}
          </div>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-end gap-3 mt-4">
        <Link to="/tariffs">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleAddSubmit}
          className="submit-btn"
        >
          Submit
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={
          statusMessage === "SUCCESS"
            ? () => navigate("/tariffs")
            : handleAlertPopup
        }
        popUpWidth={"width-100"}
      />
      {/* <PopUp
        open={statusFalsePop}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={handleStatusFalse}
        popUpWidth={"width-100"}
      /> */}
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}
