import { Button, Card, CardContent } from "@mui/material";
import { useLocation } from "react-router-dom";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import SessionExpire from "../../../components/SessionExpire";
import FullLoader from "../../../components/FullLoader";
import { handleApi_call } from "../../../common/CommonAPI";
import { headers } from "../../../common/Headers";

function ViewchargingSchedules({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [scheduleobj, setscheduleobj] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    getschedules();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getschedules();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const getschedules = async () => {
    let Url = `chargingSchedules/${location.state}`;
    let type = "GET";
    let body = {};
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    if (res && res?.status) {
      setscheduleobj(res?.data);
    }
  };
  return (
    <div>
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
      <div className=" mx-0 mb-3 p-0 pagetitle">View Charging Schedule</div>
      {/* <div className="w-100 mb-4 mx-0 p-0 d-flex align-items-center justify-content-end"></div> */}
      <div className="row mb-3">
        <div className="col-12 ">
          <div className="py-4 px-5 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Charging Schedule Name
              </span>
              <span>:</span>
              <span>{scheduleobj.chargingScheduleName} </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Start Schedule
              </span>

              <span>:</span>
              <span>
                <span>
                  {scheduleobj.startSchedule
                    ? moment(scheduleobj.startSchedule).format("DD-MMM-YYYY")
                    : null}
                </span>
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Charging Duration
              </span>
              <span>:</span>
              <span>{scheduleobj.duration}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Charging Rate Unit
              </span>
              <span>:</span>
              <span>{scheduleobj?.chargingRateUnit}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Min Charging Rate
              </span>
              <span>:</span>
              <span>{scheduleobj?.minChargingRate}</span>
            </div>
          </div>
        </div>
        <div className="ms-2 mt-3 p-1 pagetitle">
          <span>Charging Schedule Periods</span>
        </div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <div>
              <table className="table table-box-shadow mt-3">
                <thead>
                  <tr className="table-primary">
                    <th scope="col">Start Period</th>
                    <th scope="col">Charging Limit</th>
                    <th scope="col">Number Phases</th>
                  </tr>
                </thead>

                <tbody>
                  {scheduleobj?.chargingSchedulePeriods?.map((list, index) => (
                    <tr key={index}>
                      <td>{list.startPeriod}</td>
                      <td>{list.chargingLimit}</td>
                      <td>{list.numberPhases}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
        <div
          className="d-flex aligm-items-center justify-content-end gap-4 my-5 "
          style={{ marginRight: "13px" }}
        >
          <Link to="/editChargingSchedule" state={scheduleobj.id}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="submit-btn"
            >
              Edit
            </Button>
          </Link>

          <Link to="/chargerProfiles">
            <Button
              variant="contained"
              size="small"
              color="error"
              className="cancel-btn"
            >
              Cancel
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ViewchargingSchedules;
