/** @format */
import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import CustomTablePagination from "../../components/CustomTablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import * as material from "@mui/material";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import { basePathone } from "../../common/config";
import moment from "moment";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import { searchOperations } from "../../common/helpers";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function FotaAlerts({ refresh, setRefresh }) {
  const [orderBy, setOrderBy] = React.useState("completedAt");
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = React.useState(false);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [option, setOption] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [noData, setNoData] = React.useState("");
  const [logs, setLogs] = React.useState([]);
  const [searchInputs, setSearchInputs] = React.useState({
    chargingStationId: "",
    firmwareVersion: "",
    location: "",
    retries: "",
    retryInterval: "",
    status: "",
    createdAt: "",
    fromDate: "",
    toDate: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "chargingStationId", value: "" },
    { key: "firmwareVersion", value: "" },
    { key: "location", value: "" },
    { key: "retries", value: "" },
    { key: "retryInterval", value: "" },
    { key: "status", value: "" },
    { key: "fromDate", value: "" },
    { key: "toDate", value: "" },
  ]);

  const headCells = [
    {
      id: "chargingStationId",
      numeric: false,
      disablePadding: false,
      label: "Location",
      mandatory: true,
      searchType: "text",
      filterOption: true,
      sort: true,
    },

    {
      id: "firmwareVersion",
      numeric: false,
      disablePadding: false,
      label: "FW Version",
      searchType: "text",
      mandatory: true,
      filterOption: true,
      sort: true,
    },

    {
      id: "retries",
      numeric: false,
      disablePadding: false,
      label: "Retries",
      mandatory: false,
      searchType: "text",
      filterOption: true,
      sort: true,
    },
    {
      id: "retryInterval",
      numeric: false,
      disablePadding: false,
      label: "Retry Interval",
      mandatory: false,
      searchType: "text",
      filterOption: true,
      sort: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created Date",
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "completedAt",
      numeric: false,
      disablePadding: false,
      label: "Updated Date",
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      mandatory: true,
      searchType: "active",
      filterOption: true,
      sort: true,
    },
    {
      id: "fromDate",
      numeric: false,
      disablePadding: false,
      label: "From Date",
      filterOption: true,
      onlySearch: true,
      searchType: "date",
    },
    {
      id: "toDate",
      numeric: false,
      disablePadding: false,
      label: "To Date",
      filterOption: true,
      onlySearch: true,
      searchType: "date",
    },
  ];

  const handleOptionClick = (value) => {
    setOption(option === value ? "" : value);
  };

  const handleColumnChange = (id) => {
    setDisableColumns(
      disableColumns.includes(id)
        ? disableColumns.filter((c) => c !== id)
        : [...disableColumns, id]
    );
  };

  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        chargingStationId: "",
        firmwareVersion: "",
        location: "",
        retries: "",
        retryInterval: "",
        status: "",
        fromDate: "",
        toDate: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "retries" || key === "retryInterval") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.EQUALITY,
          };
        } else if (key === "fromDate") {
          return {
            key: "completedAt",
            value: searchInputs[key],
            operation: searchOperations.GREATER_THAN,
          };
        } else if (key === "toDate") {
          return {
            key: "completedAt",
            value: searchInputs[key],
            operation: searchOperations.LESS_THAN,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });

    return params;
  };

  const getFirmwareLogs = async () => {
    const searchParams = buildSearchParams();
    const body = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    const Url = `api/firmwareUpdate/search`;
    const type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    setLogs(res?.data ?? []);
    setCount(res?.count);
    setNoData(res?.count === 0 ? "No Data Available" : "");
  };

  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      chargingStationId: "",
      firmwareVersion: "",
      location: "",
      retries: "",
      retryInterval: "",
      status: "",
      createdAt: "",
      fromDate: "",
      toDate: "",
    });
    setFilterState([
      { key: "chargingStationId", value: "" },
      { key: "firmwareVersion", value: "" },
      { key: "location", value: "" },
      { key: "retries", value: "" },
      { key: "retryInterval", value: "" },
      { key: "status", value: "" },
      { key: "fromDate", value: "" },
      { key: "toDate", value: "" },
    ]);
  };
  React.useEffect(() => {
    if (refresh) {
      getFirmwareLogs();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getFirmwareLogs();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);

  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">FOTA Alerts</div>
        <div className="d-flex align-items-center gap-3 ">
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handleColumnChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {/* {selected.length > 0 && (
              <div className="deletediv bg-red ">
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )} */}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer className="table_body_height">
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <TableHeadRow
              headCells={headCells}
              order={order}
              // deleteCheckBox={false}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={logs.length}
              disableColumns={disableColumns}
              disableCheckBox={true}
            />
            <TableBody>
              {logs.map((row, index) => {
                //  const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow tabIndex={-1} key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    {!disableColumns.includes("chargingStationId") && (
                      <TableCell>{row.chargingStationId}</TableCell>
                    )}
                    {!disableColumns.includes("firmwareVersion") && (
                      <TableCell>{row.firmwareVersion}</TableCell>
                    )}
                    {!disableColumns.includes("retries") && (
                      <TableCell>{row.retries}</TableCell>
                    )}
                    {!disableColumns.includes("retryInterval") && (
                      <TableCell>{row.retryInterval}</TableCell>
                    )}

                    {!disableColumns.includes("createdAt") && (
                      <TableCell>
                        {row.createdAt &&
                          moment(row.createdAt).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}
                    {!disableColumns.includes("completedAt") && (
                      <TableCell>
                        {row.completedAt &&
                          moment(row.completedAt).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("status") && (
                      <TableCell>{row.status}</TableCell>
                    )}
                  </TableRow>
                );
              })}
              {logs.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {logs.length > 0 && (
          <CustomTablePagination
            rowsPerPageOptions={[10, 15, 20]}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
