import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import "moment/locale/en-gb";
import { basePathone } from "../../common/config";
import { formatDateForInput } from "../../common/helpers";
import FullLoader from "../../components/FullLoader";

export default function EditInventoryForm({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [showMasterPassword, setShowMasterPassword] = React.useState(false);
  const [showOcppPassword, setShowOcppPassword] = React.useState(false);
  const [updateRow, setUpdateRow] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [updateRowErrors, setupdateRowErrors] = React.useState({
    modelName: "",
    manufacturingDate: "",
    partNumber: "",
    mesSerialNumber: "",
    firmwareVersion: "",
    ocppPassword: "",
    vendorCode: "",
    accessCode: "",
    chargePointName: "",
    masterPassword: "",
  });
  const [alertPopup, setAlertPopup] = React.useState(false);
  const location = useLocation();
  const [loader, setLoader] = React.useState(false);
  const navigate = useNavigate();
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  React.useEffect(() => {
    setUpdateRow(location.state);
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setUpdateRow(location.state);
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const updateInventory = () => {
    setLoader(true);
    let headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}api/csinventory/update/` + updateRow.id, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(updateRow),
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setStatus(true);
          setAlertMessage(res?.message);
          handleAlertPopup();
        } else {
          setStatus(false);
          setAlertMessage(res?.message);
          handleAlertPopup();
        }
      });
  };
  function handleAlertPopupClose() {
    navigate("/Stations");
  }
  function toggleMasterPasswordVisibility() {
    setShowMasterPassword((password) => !password);
  }
  function toggleOcppPasswordVisibility() {
    setShowOcppPassword((password) => !password);
  }

  function handleUpdateForm(key, value) {
    setupdateRowErrors({ ...updateRowErrors, [key]: "" });
    setUpdateRow({ ...updateRow, [key]: value });
  }
  function handleEditInventory() {
    let error = false;
    if (
      !updateRow.partNumber ||
      !updateRow.firmwareVersion ||
      !updateRow.vendorCode ||
      !updateRow.accessCode ||
      !updateRow.masterPassword
    ) {
      error = true;
    }
    if (error) {
      if (!updateRow.modelName) {
        setupdateRowErrors((errors) => ({
          ...errors,
          modelName: "Model name is Mandatory",
        }));
      }
      if (!updateRow.partNumber) {
        setupdateRowErrors((errors) => ({
          ...errors,
          partNumber: "part Number is Mandatory",
        }));
      }
      if (!updateRow.firmwareVersion) {
        setupdateRowErrors((errors) => ({
          ...errors,
          firmwareVersion: "fw version is Mandatory",
        }));
      }
      if (!updateRow.ocppPassword) {
        setupdateRowErrors((errors) => ({
          ...errors,
          ocppPassword: "ocpp password name is Mandatory",
        }));
      }
      if (!updateRow.vendorCode) {
        setupdateRowErrors((errors) => ({
          ...errors,
          vendorCode: "Vendor code is Mandatory",
        }));
      }
      if (!updateRow.accessCode) {
        setupdateRowErrors((errors) => ({
          ...errors,
          accessCode: "Access code is Mandatory",
        }));
      }
      if (!updateRow.masterPassword) {
        setupdateRowErrors((errors) => ({
          ...errors,
          masterPassword: "Master Password code is Mandatory",
        }));
      }
    }
    if (!error) {
      updateInventory();
    }
  }

  return (
    <div>
      {sessionExpire && <SessionExpire />}
      {loader && <FullLoader />}
      <Box>
        <div className="pagetitle">Update Station</div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <div className="row mt-3 row-gap-3">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Charge Point Name *</b>
                  </FormLabel>
                  <TextField
                    label="Charge Point Name"
                    disabled
                    className="w-100"
                    variant="outlined"
                    value={updateRow?.evsn}
                    size="small"
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Model *</b>
                  </FormLabel>
                  <TextField
                    error={updateRowErrors.modelName}
                    className="w-100"
                    variant="outlined"
                    value={updateRow?.modelName}
                    size="small"
                    helperText={updateRowErrors.modelName}
                    onChange={(e) => {
                      handleUpdateForm("modelName", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <div className="my-1 fs-15 fw-600">
                    <span>Manufacturing Date *</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="px-3 fs-17"
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      border: "1px solid gainsboro",
                    }}
                    value={
                      updateRow.manufacturingDate
                        ? formatDateForInput(updateRow.manufacturingDate)
                        : ""
                    }
                    onChange={(e) => {
                      handleUpdateForm("manufacturingDate", e.target.value);
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Part No. *</b>
                  </FormLabel>
                  <TextField
                    error={updateRowErrors.partNumber}
                    className="w-100"
                    variant="outlined"
                    value={updateRow?.partNumber}
                    size="small"
                    helperText={updateRowErrors.partNumber}
                    onChange={(e) => {
                      handleUpdateForm("partNumber", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>MES Serial No *</b>
                  </FormLabel>
                  <TextField
                    disabled
                    className="w-100"
                    variant="outlined"
                    value={updateRow?.mesSerialNumber}
                    size="small"
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>FW Version *</b>
                  </FormLabel>
                  <TextField
                    error={updateRowErrors.firmwareVersion}
                    className="w-100"
                    variant="outlined"
                    value={updateRow?.firmwareVersion}
                    size="small"
                    helperText={updateRowErrors.firmwareVersion}
                    onChange={(e) => {
                      handleUpdateForm("firmwareVersion", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>OCPP Password *</b>
                  </FormLabel>
                  <TextField
                    error={updateRowErrors.ocppPassword}
                    type={showOcppPassword ? "text" : "password"}
                    className="w-100"
                    variant="outlined"
                    value={updateRow?.ocppPassword}
                    size="small"
                    helperText={updateRowErrors.ocppPassword}
                    onChange={(e) => {
                      handleUpdateForm("ocppPassword", e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleOcppPasswordVisibility}
                            edge="end"
                          >
                            {showOcppPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Vendor Code *</b>
                  </FormLabel>
                  <TextField
                    error={updateRowErrors.vendorCode}
                    className="w-100"
                    variant="outlined"
                    value={updateRow?.vendorCode}
                    size="small"
                    helperText={updateRowErrors.vendorCode}
                    onChange={(e) => {
                      handleUpdateForm("vendorCode", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Access Code *</b>
                  </FormLabel>
                  <TextField
                    error={updateRowErrors.accessCode}
                    className="w-100"
                    variant="outlined"
                    value={updateRow?.accessCode}
                    size="small"
                    helperText={updateRowErrors.accessCode}
                    onChange={(e) => {
                      handleUpdateForm("accessCode", e.target.value);
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Master Password *</b>
                  </FormLabel>
                  <TextField
                    type={showMasterPassword ? "text" : "password"}
                    error={updateRowErrors.masterPassword}
                    className="w-100"
                    variant="outlined"
                    value={updateRow?.masterPassword}
                    size="small"
                    helperText={updateRowErrors.masterPassword}
                    onChange={(e) => {
                      handleUpdateForm("masterPassword", e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleMasterPasswordVisibility}
                            edge="end"
                          >
                            {showMasterPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </CardContent>
        </Card>
        <div className="d-flex aligm-items-center justify-content-end gap-4 mt-5">
          <Button variant="contained" color="error" className="cancel-btn">
            <Link to="/Stations" className="cancle-button">
              Cancel
            </Link>
          </Button>
          <Button
            variant="contained"
            onClick={handleEditInventory}
            className="submit-btn"
          >
            Update
          </Button>
        </div>
      </Box>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"bg-primary"}
        handleClickBtn1={handleAlertPopupClose}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
