import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";

function ExtendedTriggerMessage({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    connectorId: "",
    requestedMessage: "",
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    requestedMessage: "",
  });
  function handleOnChange(key, value) {
    if (key === "connectorId") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
        setInputFields({ ...inputFields, [key]: parseInt(value) });
      }
    } else {
      setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
      setInputFields({ ...inputFields, [key]: value });
    }
  }
  function handleSubmit() {
    let error = false;
    if (!inputFields.requestedMessage) {
      error = true;
    }
    if (error) {
      if (!inputFields.requestedMessage) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          requestedMessage: "Requested Message is Mandatory",
        }));
      }
    }
    let payload = {
      requestedMessage: inputFields.requestedMessage,
    };
    if (inputFields.connectorId) {
      payload.connectorId = parseInt(inputFields.connectorId);
    }
    handleSubmitOperation(error, payload);
  }
  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Requested Message Type *</b>
                </FormLabel>
                <TextField
                  select
                  error={inputFieldsValidation.requestedMessage}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.requestedMessage}
                  size="small"
                  helperText={inputFieldsValidation.requestedMessage}
                  onChange={(e) => {
                    handleOnChange("requestedMessage", e.target.value);
                  }}
                >
                  <MenuItem value="BootNotification">
                    Boot Notification
                  </MenuItem>
                  <MenuItem value="LogStatusNotification">
                    Log Status Notification
                  </MenuItem>
                  <MenuItem value="FirmwareStatusNotification">
                    Firmware Status Notification
                  </MenuItem>
                  <MenuItem value="Heartbeat">Heartbeat</MenuItem>
                  <MenuItem value="MeterValues ">Meter Values </MenuItem>
                  <MenuItem value="SignChargePointCertificate">
                    Sign ChargePoint Certificate
                  </MenuItem>
                  <MenuItem value="StatusNotification ">
                    Status Notification
                  </MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Id </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.connectorId}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("connectorId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default ExtendedTriggerMessage;
