/** @format */

import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

function TableHeadRow({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  disableColumns,
  headCells,
  deleteCheckBox = true,
  disableCheckBox = false,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {deleteCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox
              style={{ fontWeight: "bold" }}
              disabled={disableCheckBox}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headCells.map(
          (headCell) =>
            !disableColumns.includes(headCell.id) &&
            !headCell.onlySearch && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  // color: "#fff",
                  fontWeight: "bold",
                  minWidth: headCell.minWidth || "auto", // Use minWidth if defined
                  width: headCell.width || "auto", // Custom width property if available
                }}
              >
                {headCell.sort ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    style={{ minWidth: headCell.minWidth }}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

export default TableHeadRow;
