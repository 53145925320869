import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import React from "react";
import {
  allowLettersNumbersAndSpace,
  handleKeyDown,
} from "../../common/helpers";
import MapComponent from "../../MapFunction";

export default function RelatedLocationPop({
  rlocationObj,
  rLErrors,
  handleRlChange,
  setRLocationObj,
}) {
  return (
    <div className="py-3">
      <Box>
        <div className="d-flex flex-wrap gap-4 w-auto">
          <div className="w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Name Text *</b>
              </FormLabel>
              <TextField
                required
                className=" me-3 error"
                variant="outlined"
                size="small"
                inputProps={{
                  minLength: 5,
                  maxLength: 100,
                }}
                onKeyDown={allowLettersNumbersAndSpace}
                value={rlocationObj.name.text}
                onChange={(e) => handleRlChange("text", e.target.value)}
                error={rLErrors.text}
                helperText={rLErrors.text}
              />
            </FormControl>
          </div>
          <div className="w-45 me-3 error"></div>
          <div className="w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Latitude *</b>
              </FormLabel>
              <TextField
                required
                className=" me-3 error"
                variant="outlined"
                size="small"
                type="number"
                value={rlocationObj.latitude}
                onChange={(e) => handleRlChange("latitude", e.target.value)}
                error={rLErrors.latitude}
                helperText={rLErrors.latitude}
              />
            </FormControl>
          </div>
          <div className="w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Longitude *</b>
              </FormLabel>
              <TextField
                required
                className=" me-3 error"
                variant="outlined"
                size="small"
                type="number"
                value={rlocationObj.longitude}
                onChange={(e) => handleRlChange("longitude", e.target.value)}
                error={rLErrors.longitude}
                helperText={rLErrors.longitude}
              />
            </FormControl>
          </div>
          <MapComponent
            locationFields={rlocationObj}
            setLocationFields={setRLocationObj}
          />
        </div>
      </Box>
    </div>
  );
}
