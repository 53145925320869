/** @format */

import * as React from "react";
import * as material from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Link } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { Switch } from "@mui/material";
import { Edit } from "@mui/icons-material";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import { basePath } from "../../common/config";
import CustomTablePagination from "../../components/CustomTablePagination";
import PopUp from "../../components/PopUp";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import { useTranslation } from "react-i18next";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function UserTable({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [activeDeactivePopUp, setActivateDeactivePopUp] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState();
  const [loader, setLoader] = React.useState([]);
  const [isActive, setIsActive] = React.useState(1);
  const [searchInputs, setSearchInputs] = React.useState({
    mobile: "",
    email: "",
    role: "",
    firstName: "",
    lastName: "",
  });
  const [option, setOption] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [count, setCount] = React.useState(0);
  // const [roles, setRoles] = React.useState([]);
  const [noData, setNoData] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  const headCells = [
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
      mandatory: true,
      filterOption: true,
      searchType: "text",
      sort: true,
    },
    {
      id: "role",
      numeric: false,
      disablePadding: false,
      label: "Role Name",
      mandatory: false,
      filterOption: false,
      sort: true,
    },
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: "First Name",
      searchType: "text",
      mandatory: true,
      filterOption: true,
      sort: true,
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      searchType: "text",
      mandatory: false,
      filterOption: true,
      sort: true,
    },
    {
      id: "mobile",
      numeric: false,
      disablePadding: false,
      label: "Phone",
      mandatory: false,
      filterOption: true,
      searchType: "text",
      sort: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      mandatory: true,
      filterOption: false,
      sort: false,
    },
    {
      id: "update",
      numeric: false,
      disablePadding: false,
      label: "Update",
      mandatory: true,
      filterOption: false,
      sort: false,
    },
  ];

  function handleActiveDeactivePopup(userId, isActive) {
    setIsActive(isActive);
    setSelectedRow(userId);
    setActivateDeactivePopUp((pop) => !pop);
  }
  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        email: "",
        firstName: "",
        lastName: "",
        mobile: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }

  function handleAlertOk() {
    getUsers();
    handleAlertPopup();
  }

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n.userId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1; //.....

  // Avoid a layout jump when reaching the last page with empty rows.

  const getUsers = async () => {
    const Url = `user/allUsers?page=${
      page + 1
    }&size=${rowsPerPage}&searchFirstName=${
      searchInputs.firstName
    }&searchLastName=${searchInputs.lastName}&searchEmail=${
      searchInputs.email
    }&searchMobile=${searchInputs.mobile}&searchRoleId=${
      searchInputs.role
    }&sortBy=${orderBy}&sortDir=${order}`;
    const type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.data) {
      setUsers(res?.data?.response ?? []);
      setCount(res?.data?.count);
      setNoData(res?.data?.count === 0 ? "No Data Available" : "");
    }
  };

  // const getRoles = async () => {
  //   const Url = "roles/getAllRoles";
  //   const type = "GET";
  //   let res = await handleApi_call(
  //     Url,
  //     headers,
  //     type,
  //     {},
  //     setSessionExpire,
  //     setAlertMessage,
  //     handleAlertPopup,
  //     setLoader
  //   );

  //   if (res && res?.status) {
  //     setRoles(res.data);
  //   }
  // };

  const deleteuser = async () => {
    let Url = "user/delete";
    let type = "DELETE";
    let body = selected;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.status) {
      setStatus(true);
      setSelected([]);
      setAlertMessage(res?.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res?.message);
      handleAlertPopup();
    }
  };

  const activateDeactivateAccount = async () => {
    let Url = `user/active/${selectedRow}?isActive=${isActive === 1 ? 0 : 1}`;
    let type = "PUT";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.status) {
      setStatus(true);
      handleAlertPopup();
      setAlertMessage(res.message);
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
    setActivateDeactivePopUp(false);
  };

  React.useEffect(() => {
    getUsers();
    //getRoles();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);
  React.useEffect(() => {
    if (refresh) {
      getUsers();
      //getRoles();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const [filterState, setFilterState] = React.useState([
    { key: "mobile", value: "" },
    { key: "email", value: "" },
    { key: "firstName", value: "" },
    { key: "lastName", value: "" },
    { key: "role", value: "" },
  ]);
  const resetStates = () => {
    setSearchInputs({
      lastName: "",
      email: "",
      firstName: "",
      mobile: "",
      role: "",
    });
    setFilterState([
      { key: "mobile", value: "" },
      { key: "email", value: "" },
      { key: "firstName", value: "" },
      { key: "lastName", value: "" },
      { key: "role", value: "" },
    ]);
  };

  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }
  return (
    <Box sx={{ width: "100%" }}>
      {loader && <Loader />}
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Users</div>
        <div className="d-flex align-items-center gap-3 ">
          <Link to="/addUser">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i className="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                      filterState={filterState} // Pass filter state to FilterTable
                      setFilterState={setFilterState} // Pass the state setter
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  className="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange} // Implement your column change logic
                      disableColumns={[]} // Define your disabled columns if necessary
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red " onClick={deleteuser}>
                <i className="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
              disableColumns={disableColumns}
            />
            <TableBody>
              {users.map((row, index) => {
                const isItemSelected = isSelected(row.userId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.userId}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.userId)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {!disableColumns.includes("email") && (
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.email}
                      </TableCell>
                    )}
                    {!disableColumns.includes("role") && (
                      <TableCell>{row.roleName}</TableCell>
                    )}
                    {!disableColumns.includes("firstName") && (
                      <TableCell>{row.firstName}</TableCell>
                    )}
                    {!disableColumns.includes("lastName") && (
                      <TableCell>{row.lastName}</TableCell>
                    )}
                    {!disableColumns.includes("mobile") && (
                      <TableCell>{row.mobile}</TableCell>
                    )}
                    {!disableColumns.includes("status") && (
                      <TableCell>
                        <Switch
                          checked={row.active}
                          onClick={() =>
                            handleActiveDeactivePopup(row.userId, row.active)
                          }
                          size="small"
                        />
                      </TableCell>
                    )}
                    {!disableColumns.includes("update") && (
                      <TableCell>
                        <Link to="/editUser" state={row}>
                          <i
                            className="fa-solid fa-pencil action-icons"
                            style={{ color: "#2DCC70" }}
                          ></i>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {users.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {users.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      <PopUp
        open={activeDeactivePopUp}
        handleClosePopUp={handleActiveDeactivePopup}
        message={t("activation_account", {
          action: t(isActive ? "deactivate" : "activate"),
        })}
        btn1={true}
        btn2={true}
        btn1Color={"bg-secondary"}
        btn2Color={"submit-btn"}
        btn1Text={t("no")}
        btn2Text={t("yes")}
        handleClickBtn1={handleActiveDeactivePopup}
        title={t("confirm")}
        handleClickBtn2={activateDeactivateAccount}
        popUpWidth="width-100"
      />
      <PopUp
        open={alertPopup}
        handleClosePopUp={handleAlertPopup}
        title={t(status === true ? "SUCCESS" : "FAILURE")}
        message={alertMessage}
        btn1={true}
        btn1Text={t("ok")}
        btn1Color={"submit-btn"}
        handleClickBtn1={status === true ? handleAlertOk : handleAlertPopup}
        popUpWidth={"width-100"}
      />
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
