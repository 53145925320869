/** @format */
import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import PopUp from "../../components/PopUp";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import CustomTablePagination from "../../components/CustomTablePagination";
import * as material from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import ColumnsBox from "../../components/ColumnsBox";
import { basePathone } from "../../common/config";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import { useTranslation } from "react-i18next";
import { searchOperations } from "../../common/helpers";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import FilterTable from "../../components/FilterTable";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function UnknownChargePoint({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = React.useState();
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);

  const [searchInputs, setSearchInputs] = React.useState({
    chargingStationId: "",
    ipAddress: "",
    no_of_attempts: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "chargingStationId", value: "" },
    { key: "ipAddress", value: "" },
    { key: "no_of_attempts", value: "" },
  ]);
  const [noData, setNoData] = React.useState("");
  const [option, setOption] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const headCells = [
    {
      id: "chargingStationId",
      numeric: false,
      disablePadding: false,
      label: "Location",
      mandatory: true,
      searchType: "text",
      filterOption: true,
      sort: true,
      minWidth: 140,
    },

    {
      id: "ipAddress",
      numeric: false,
      disablePadding: false,
      label: t("IP Address"),
      mandatory: true,
      filterOption: true,
      searchType: "text",
      sort: true,
    },
    {
      id: "no_of_attempts",
      numeric: false,
      disablePadding: false,
      label: t("No. of Attempts"),
      mandatory: false,
      filterOption: false,
      searchType: "text",
      // sort: true,
      // minWidth: 80,
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: t("Created Date"),
      mandatory: false,
      filterOption: false,
      sort: true,
      minWidth: 180,
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: t("Updated Date"),
      mandatory: false,
      filterOption: false,
      sort: true,
      minWidth: 180,
    },
  ];
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      chargingStationId: "",

      ipAddress: "",
      no_of_attempts: "",
    });
    setFilterState([
      { key: "chargingStationId", value: "" },
      { key: "ipAddress", value: "" },
      { key: "no_of_attempts", value: "" },
    ]);
  };
  const handleOptionClick = (value) => {
    setOption(option === value ? "" : value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleColumnChange = (id) => {
    setDisableColumns(
      disableColumns.includes(id)
        ? disableColumns.filter((c) => c !== id)
        : [...disableColumns, id]
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "chargingStationId") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });

    return params;
  };

  const getChargePointsData = async () => {
    const searchParams = buildSearchParams();
    const requestBody = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: "id.chargingStationId",
      sortDir: order,
    };
    const Url = `unknown/search`;
    const type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      requestBody,
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    if (res && res?.status) {
      setData(res?.data);
      setCount(res?.count);
      setNoData(res?.count === 0 ? "No data Available" : "");
    }
  };
  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        chargingStationId: "",

        ipAddress: "",
        no_of_attempts: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };
  React.useEffect(() => {
    getChargePointsData();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);
  React.useEffect(() => {
    if (refresh) {
      getChargePointsData();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Anonymous Stations</div>
        <div className="d-flex align-items-center gap-3 ">
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handleColumnChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer className="table_body_height">
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <TableHeadRow
              headCells={headCells}
              order={order}
              // deleteCheckBox={false}
              disableCheckBox={true}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              disableColumns={disableColumns}
            />
            <TableBody>
              {data.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow tabIndex={-1} key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    {!disableColumns.includes("chargeStationId") && (
                      <TableCell>{row.chargingStationId}</TableCell>
                    )}

                    {!disableColumns.includes("ipAddress") && (
                      <TableCell>{row.ipAddress}</TableCell>
                    )}
                    {!disableColumns.includes("no_of_attempts") && (
                      <TableCell>{row.noOfAttempts}</TableCell>
                    )}

                    {!disableColumns.includes("created_at") && (
                      <TableCell>
                        {row.createdAt &&
                          moment(row.createdAt).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}
                    {!disableColumns.includes("updatedAt") && (
                      <TableCell>
                        {row.updatedAt &&
                          moment(row.updatedAt).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {data.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data.length > 0 && (
          <CustomTablePagination
            rowsPerPageOptions={[10, 15, 20]}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>

      <PopUp
        open={open}
        title={t("view_changedData")}
        message={message}
        btn1={true}
        btn1Text={t("ok")}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClose}
        popUpWidth={"width-100"}
      />
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
