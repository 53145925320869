import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PopUp from "../../components/PopUp";
import { Link, useNavigate } from "react-router-dom";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import FullLoader from "../../components/FullLoader";

export default function BulkImport({ refresh, setRefresh }) {
  const [tenantList, setTenantList] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  let blobUrl;
  const [errors, setErrors] = React.useState({});
  const [tenantId, setTenantId] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [downloadPopUp, setDownloadPopUp] = React.useState(false);
  const handleTenantId = (event) => {
    setErrors({ ...errors, tenantId: "" });
    setTenantId(event.target.value);
  };
  const validateForm = () => {
    const newErrors = {};
    if (!fileName) newErrors.fileName = "Please Upload file";
    if (!tenantId) newErrors.tenantId = "TenantId is required";
    return newErrors;
  };
  function handleUploadFile(event) {
    event.preventDefault();
    let error2 = false;
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      error2 = true;
    }
    if (!error2) {
      uploadfiles();
    }
  }

  function handleAlertPopUp() {
    setAlertPopup((pop) => !pop);
  }
  const navigate = useNavigate();
  function handleClosePopup() {
    navigate("/Stations");
  }

  function handleDownloadPopup() {
    setDownloadPopUp((pop) => !pop);
  }

  const getTemplate = async () => {
    setLoader(true);
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    const res = await fetch(`${basePathone}api/csinventory/download/template`, {
      method: "GET",
      headers: headers,
    });
    if (res.ok) {
      setLoader(false);
      const blob = await res.blob();
      blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Charge-Point-Onboard-Template.xlsx";
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    }
  };
  const downloadFile = async () => {
    setLoader(true);
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    const res = await fetch(`${basePathone}api/csinventory/download`, {
      method: "GET",
      headers: headers,
    });
    if (res.ok) {
      setLoader(false);
      const blob = await res.blob();
      blobUrl = window.URL.createObjectURL(blob);
      console.log(blobUrl);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "inventorydownload.xlsx";
      a.click();
      window.URL.revokeObjectURL(blobUrl);
      handleClosePopup();
    }
  };

  const uploadfiles = () => {
    setLoader(true);
    let headers = {
      // "content-type": "multipart/form-data ",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    const formData = new FormData();
    formData.append("file", file);

    fetch(`${basePathone}api/csinventory/bulkupload?tenantId=${tenantId}`, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status === "SUCCESS") {
          handleDownloadPopup();
        } else {
          setAlertMessage(
            "Upload failed: The file contains invalid data formats, missing values, or incorrect structure. Please review and correct the file"
          );
          handleAlertPopUp();
        }
      });
  };
  const getTenantId = () => {
    setLoader(true);
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(`${basePathone}tenants/list`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setTenantList(res?.data);
        }
      });
  };
  React.useEffect(() => {
    getTenantId();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getTenantId();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  const handleFileChange1 = (event) => {
    setErrors({ ...errors, fileName: "" });
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };
  return (
    <div>
      <div className="pagetitle">Bulk Import </div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="col-12 mt-3 ">
            <span
              className="px-4 py-2 bg-blue text-light cursor-Pointer   me-3"
              onClick={getTemplate}
            >
              <i class="fa-solid fa-download"></i>
            </span>
            <span className="tc-blue">Download Template</span>
          </div>

          <div className=" row mt-5">
            <div className="col-3">
              <FormControl className="simpleInput">
                <FormLabel style={{ marginBottom: "3px" }}>
                  <b>FileName *</b>
                </FormLabel>
                <Box
                  noValidate
                  required
                  autoComplete="off"
                  component="form"
                  className={`${
                    errors.fileName ? "file-border-error" : "file-border"
                  }`}
                  style={{
                    display: "flex",
                    gap: 5,
                    padding: "2px",
                    alignItems: "center",
                    height: "40px",
                  }}
                >
                  <Button
                    component="label"
                    variant="contained"
                    style={{
                      width: "130px",
                      textTransform: "none",
                      fontSize: "14px",
                      lineHeight: "18px",
                      opacity: "0.9",
                      padding: "9px",
                      color: "white",
                      height: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Choose file
                    <CloudUploadIcon />
                    <input type="file" hidden onChange={handleFileChange1} />
                  </Button>
                  {fileName ? (
                    <Typography
                      className="file-text "
                      style={{ lineHeight: "16px" }}
                    >
                      {fileName}
                    </Typography>
                  ) : (
                    "No Choosen file"
                  )}
                </Box>
              </FormControl>
              <FormHelperText
                error
                style={{ fontWeight: "700", fontSize: "13px" }}
              >
                {file ? null : errors.fileName}
              </FormHelperText>
            </div>
            <div className="col-3">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Organization *</b>
                </FormLabel>
                <TextField
                  size="small"
                  select
                  required
                  value={tenantId}
                  error={errors.tenantId}
                  name="tenantId"
                  variant="outlined"
                  onChange={handleTenantId}
                >
                  {tenantList?.map((list, index) => (
                    <MenuItem key={index} value={list.tenantId}>
                      {list.name}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText error>{errors.tenantId}</FormHelperText>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="me-5 d-flex justify-content-end gap-4 my-3">
        <Button variant="contained" color="error" className="cancel-btn">
          <Link to="/Stations" className="cancle-button">
            Cancel
          </Link>
        </Button>
        <Button
          variant="contained"
          onClick={handleUploadFile}
          className="submit-btn"
        >
          Upload
        </Button>
      </div>
      <PopUp
        open={downloadPopUp}
        title={"Confirm"}
        message={
          "File uploaded Successfully, Do you want to download this file"
        }
        btn1={true}
        btn1Text={"Cancel"}
        btn2Color={"bg-primary"}
        handleClickBtn1={handleClosePopup}
        btn2={true}
        btn2Text={"Download"}
        btn1Color={"bg-danger"}
        handleClickBtn2={downloadFile}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={alertPopup}
        title={"Alert"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"bg-primary"}
        handleClickBtn1={handleAlertPopUp}
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}
