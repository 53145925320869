/** @format */

import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { basePath } from "../../../common/config";
import { Collapse } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({
  open,
  setOpen,
  setPage,
  getSidebarItems,
  menuItems,
}) {
  const theme = useTheme();
  const [open1, setOpen1] = useState("");
  const handleDrawerClose = () => {
    setOpen(false);
  };
  function handleExpandClick(title) {
    if (title === open1) {
      setOpen1("");
    } else setOpen1(title);
  }
  React.useEffect(() => {
    getSidebarItems();
  }, []);

  return (
    <Drawer
      variant="permanent"
      open={open}
      className={`open-mobile-response ${
        open ? "sidebar-response-open" : "sidebar-response-close"
      }`}
    >
      <DrawerHeader sx={{ minHeight: "50px !important" }}>
        <div>
          <img
            src={localStorage.getItem("tenantLogopath")}
            alt="logo123"
            width={155}
          />
        </div>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List className="side_menu_container">
        {menuItems.map((item) =>
          (item.parent.isActive && item.subItems.length === 0) ||
          (item.parent.isActive &&
            item.subItems.length > 0 &&
            item.subItems.some((obj) => {
              return obj.isActive === 1;
            })) ? (
            <React.Fragment key={item.parent.id}>
              <Link
                className="text-decoration-none"
                to={item.parent.link}
                // onClick={() => setPage(item.parent.title)}
              >
                <ListItemButton
                  onClick={
                    item.subItems.length > 0
                      ? () => handleExpandClick(item.parent.title)
                      : undefined
                  }
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.parent.title} />
                  {item.subItems.length > 0 &&
                    (open1 === item.parent.title ? (
                      <Remove style={{ color: "#5d5e54", fontSize: "15px" }} />
                    ) : (
                      <Add style={{ color: "#5d5e54", fontSize: "15px" }} />
                    ))}
                </ListItemButton>
              </Link>
              {item.subItems.length > 0 && (
                <Collapse
                  in={open1 === item.parent.title}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) =>
                      subItem.isActive ? (
                        <Link
                          key={subItem.id}
                          to={subItem.link}
                          className="text-decoration-none"
                          // onClick={() => setPage(subItem.title)}
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText
                              sx={{ textAlign: "left", marginLeft: "2rem" }}
                              primary={subItem.title}
                            />
                          </ListItemButton>
                        </Link>
                      ) : null
                    )}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ) : null
        )}
      </List>
    </Drawer>
  );
}
