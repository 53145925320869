/** @format */

import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Graph({ toggles }) {
  const { t } = useTranslation();

  const data = {
    labels: [
      t("jan"),
      t("feb"),
      t("mar"),
      t("apr"),
      t("may"),
      t("jun"),
      t("jul"),
      t("aug"),
      t("sep"),
      t("oct"),
      t("nov"),
      t("dec"),
    ],
    datasets: [
      {
        label: t("expenses"),
        data: [30, 25, 14, 16, 35, 12, 15, 30, 34, 33, 29, 22],
        backgroundColor: "rgb(30,180,246)",
        borderColor: "rgba(0,0,0,0)",
        borderWidth: 5,
      },
      {
        label: t("payments"),
        data: [30, 25, 36, 14, 35, 23, 26, 30, 24, 30, 19, 12],
        backgroundColor: "rgb(235,89,217)",
        borderWidth: 5,
        borderColor: "rgba(0,0,0,0)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: t("graph_analysis"),
        align: "start",
        color: "black",
        font: { size: 14 },
      },
      legend: {
        position: "top",
        reverse: true,
        labels: {
          boxWidth: 15,
          useBorderRadius: true,
          borderRadius: 4,
        },
      },
    },
    datasets: {
      bar: {
        barThickness: 15,
        borderRadius: {
          topLeft: 10,
          topRight: 10,
          bottomLeft: 10,
          bottomRight: 10,
        },
        borderSkipped: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: { display: true, offset: false, lineWidth: 3 },
        border: { display: false },
        stacked: true,
      },
      y: {
        ticks: {
          callback: (value) => `${value}k`,
        },
        beginAtZero: true,
        grid: { display: false },
        border: { display: false },
        stacked: true,
      },
    },
  };

  return (
    <div className={`card customGraph p-3 ${toggles && "fullGraph"}`}>
      <Bar data={data} options={options} width="840px" height="240px" />
    </div>
  );
}

export default Graph;
