import React from "react";
import PopUp from "./PopUp";

export default function SessionExpire() {
  function handleAlertOk() {
    localStorage.clear();
    window.location.replace("/");
  }

  return (
    <div>
      <PopUp
        open={true}
        title={"Session expired"}
        message={"Session expired, please login again"}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
