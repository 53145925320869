import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import TableHeadRow from "../../components/TableHeadRow";
import PopUp from "../../components/PopUp";
import moment from "moment";
import React from "react";
import * as material from "@mui/material";
import ViewInventoryForm from "./ViewInventoryForm";
import { searchOperations } from "../../common/helpers";
import { Link } from "react-router-dom";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import CustomTablePagination from "../../components/CustomTablePagination";
import { Edit } from "@mui/icons-material";
import Loader from "../../components/Loader";
const headCells = [
  {
    id: "evsn",
    numeric: false,
    disablePadding: true,
    label: "Charging Station",
    mandatory: false,
    filterOption: true,
    sort: true,
    searchType: "text",
    minWidth: 150,
  },
  {
    id: "modelName",
    numeric: false,
    disablePadding: true,
    label: "Charger Model",
    mandatory: false,
    filterOption: true,
    sort: true,
    searchType: "text",
    minWidth: 140,
  },
  {
    id: "mesSerialNumber",
    numeric: false,
    disablePadding: true,
    label: "Mes Serial No",
    mandatory: true,
    filterOption: true,
    sort: true,
    searchType: "text",
    minWidth: 150,
  },
  {
    id: "materialNo",
    numeric: false,
    disablePadding: true,
    label: "Material No",
    mandatory: false,
    filterOption: true,
    sort: true,
    searchType: "text",
    minWidth: 100,
  },
  {
    id: "partNumber",
    numeric: false,
    disablePadding: true,
    label: "Part No",
    mandatory: false,
    filterOption: false,
    sort: true,
    searchType: "text",
    minWidth: 150,
  },

  {
    id: "firmwareVersion",
    numeric: false,
    disablePadding: true,
    label: "FW Version",
    mandatory: true,
    filterOption: false,
    sort: true,
    searchType: "text",
    minWidth: 100,
  },
  {
    id: "vendorCode",
    numeric: false,
    disablePadding: true,
    label: "Vendor Code",
    mandatory: false,
    filterOption: false,
    sort: true,
    searchType: "text",
    minWidth: 180,
  },
  {
    id: "accessCode",
    numeric: false,
    disablePadding: true,
    label: "Access Code",
    mandatory: false,
    filterOption: false,
    sort: true,
    searchType: "text",
    minWidth: 130,
  },
  {
    id: "inTimeout",
    numeric: false,
    disablePadding: true,
    label: "In TimeOut",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "manufacturingDate",
    numeric: false,
    disablePadding: true,
    label: "MF Date",
    mandatory: false,
    filterOption: false,
    sort: true,
    searchType: "date",
    minWidth: 180,
  },

  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created Date",
    mandatory: false,
    filterOption: false,
    sort: true,
    searchType: "date",
    minWidth: 180,
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Modified Date",
    mandatory: false,
    filterOption: false,
    sort: true,
    searchType: "date",
    minWidth: 180,
  },
  {
    id: "update",
    numeric: false,
    disablePadding: false,
    label: "Update",
    mandatory: false,
    filterOption: false,
    sort: false,
  },
];
function Inventory({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [searchInputs, setSearchInputs] = React.useState({
    evsn: "",
    modelName: "",
    mesSerialNumber: "",
    materialNo: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "evsn", value: "" },
    { key: "modelName", value: "" },
    { key: "mesSerialNumber", value: "" },
    { key: "materialNo", value: "" },
  ]);
  const [count, setCount] = React.useState(0);
  const [noData, setNoData] = React.useState("");
  const [status, setsStatus] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [disableColumns, setDisableColumns] = React.useState([
    "partNumber",
    "updatedAt",
    "materialNo",
    "createdAt",
    "inTimeout",
    "accessCode",
    "vendorCode",
    "manufacturingDate",
  ]);
  const [option, setOption] = React.useState("");
  const [csInventory, setCsInventory] = React.useState([]);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [viewPopup, setViewPopup] = React.useState(false);
  const [viewRow, setViewRow] = React.useState({});

  React.useEffect(() => {
    getInventories();
  }, [page, rowsPerPage, order, orderBy, searchInputs]);
  React.useEffect(() => {
    if (refresh) {
      getInventories();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "chargingStationId") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.LIKE,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });
    return params;
  };
  const getInventories = () => {
    setLoader(true);
    const searchParams = buildSearchParams();
    const requestBody = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(`${basePathone}api/csinventory/search`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestBody),
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        setCsInventory(res?.data);
        setCount(res?.count);
        setNoData(res?.count === 0 ? "No data Available" : "");
      });
  };

  const deleteInventories = () => {
    setLoader(true);
    let headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}api/csinventory/deleteIds`, {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(selected),
    })
      .then((resp) => {
        setLoader(false);
        return resp.json();
      })
      .then((res) => {
        if (res?.status) {
          setsStatus(true);
          setAlertMessage(res?.message);
          setSelected([]);
          handleAlertPopup();
        } else {
          setsStatus(false);
          setAlertMessage(res?.message);
          handleAlertPopup();
        }
      });
  };

  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        evsn: "",
        modelName: "",
        mesSerialNumber: "",
        materialNo: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      evsn: "",
      modelName: "",
      mesSerialNumber: "",
      materialNo: "",
    });
    setFilterState([
      { key: "evsn", value: "" },
      { key: "modelName", value: "" },
      { key: "mesSerialNumber", value: "" },
      { key: "materialNo", value: "" },
    ]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = csInventory.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  function handleViewPopup() {
    setViewPopup((pop) => !pop);
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleAlertOk() {
    getInventories();
    handleAlertPopup();
  }
  function handleViewClick(row) {
    setViewRow(row);
    handleViewPopup();
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Stations</div>
        <div className="d-flex align-items-center gap-3 ">
          <Link to="/bulkImport">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-arrow-down" />
              Import
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red " onClick={deleteInventories}>
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={csInventory.length}
              disableColumns={disableColumns}
            />
            <TableBody>
              {csInventory.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // hover
                    // role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {!disableColumns.includes("evsn") && (
                      <TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Link
                          to={"/viewstations"}
                          className="linkUnderLineHover"
                          state={row}
                        >
                          {row.evsn}
                        </Link>
                      </TableCell>
                    )}
                    {!disableColumns.includes("modelName") && (
                      <TableCell>{row.modelName}</TableCell>
                    )}
                    {!disableColumns.includes("mesSerialNumber") && (
                      <TableCell>{row.mesSerialNumber}</TableCell>
                    )}
                    {!disableColumns.includes("materialNo") && (
                      <TableCell>{row.materialNo}</TableCell>
                    )}
                    {!disableColumns.includes("partNumber") && (
                      <TableCell>{row?.partNumber}</TableCell>
                    )}
                    {!disableColumns.includes("firmwareVersion") && (
                      <TableCell>{row.firmwareVersion}</TableCell>
                    )}
                    {!disableColumns.includes("vendorCode") && (
                      <TableCell>{row.vendorCode}</TableCell>
                    )}
                    {!disableColumns.includes("accessCode") && (
                      <TableCell>{row.accessCode}</TableCell>
                    )}
                    {!disableColumns.includes("inTimeout") && (
                      <TableCell>
                        <Switch
                          checked={row.inTimeout === true}
                          disabled
                          size="small"
                        />
                      </TableCell>
                    )}
                    {!disableColumns.includes("manufacturingDate") && (
                      <TableCell>
                        {row.manufacturingDate &&
                          moment(row.manufacturingDate).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("createdAt") && (
                      <TableCell>
                        {row.createdAt &&
                          moment(row.createdAt).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}
                    {!disableColumns.includes("updatedAt") && (
                      <TableCell>
                        {row.updatedAt &&
                          moment(row.updatedAt).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}
                    {/* {!disableColumns.includes("view") && (
                      <TableCell>
                        <IconButton onClick={() => handleViewClick(row)}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    )} */}
                    {!disableColumns.includes("update") && (
                      <TableCell>
                        <Link to={"/editInventory"} state={row}>
                          <IconButton>
                            <Edit color="primary" />
                          </IconButton>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {csInventory.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {csInventory.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      <PopUp
        open={viewPopup}
        title={<b className="fs-19">Inventory</b>}
        message={<ViewInventoryForm viewRow={viewRow} />}
        popUpWidth={"width-100"}
        btn1={true}
        btn1Color={"bg-primary"}
        btn1Text={"Close"}
        handleClickBtn1={handleViewPopup}
      />
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"bg-primary"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}

export default Inventory;
