import * as React from "react";
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { allowLettersNum } from "../../common/helpers";
import { basePathone } from "../../common/config";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function EditCredentials({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const location = useLocation();
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [statusFalsePop, setStatusFalsePop] = React.useState(false);
  const [editform, setEditForm] = React.useState({
    token: "",
    url: "",
  });
  const [editformErrors, setEditFormErrors] = React.useState({
    token: "",
    url: "",
  });
  const [loader, setLoader] = React.useState(false);
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))" + // domain name or IP address
      "(\\:[0-9]{1,5})?(\\/.*)?$",
    "i"
  );
  const navigate = useNavigate();
  function handleClosePopup() {
    navigate("/credentials");
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleeditform(key, value) {
    setEditFormErrors({ ...editformErrors, [key]: "" });
    setEditForm({ ...editform, [key]: value });
    if (key === "url") {
      let valid = urlPattern.test(value);
      if (valid) {
        setEditFormErrors({ ...editformErrors, [key]: "" });
      } else {
        setEditFormErrors({ ...editformErrors, [key]: "Enter Valid Url" });
      }
    }
  }
  const handleStatusFalse = () => {
    setStatusFalsePop((pop) => !pop);
  };
  function handleEditCredentials() {
    let error = false;
    if (!editform.token || !editform.url) {
      error = true;
    }
    if (error) {
      if (!editform.token) {
        setEditFormErrors((errors) => ({
          ...errors,
          token: "Token is Mandatory",
        }));
      }
      if (!editform.url) {
        setEditFormErrors((errors) => ({
          ...errors,
          url: "Url is Mandatory",
        }));
      }
    }
    if (!error && editformErrors.url.length === 0) {
      updateCredentials();
    }
  }
  const updateCredentials = async () => {
    let Url = `cpo/${2.1}/credentials/${editform.id}`;
    let type = "PUT";
    let body = editform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusMessage === "SUCCESS") {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleStatusFalse(); // Show error popup
    }
  };

  React.useEffect(() => {
    setEditForm(location.state);
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setEditForm(location.state);
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <div>
      {sessionExpire && <SessionExpire />}
      {/* <div className="row mt-4 column-gap-5 row-gap-4 ms-3"> */}
      <div className="pagetitle">Update Credentials</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Token *</b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  placeholder="Token"
                  vehicleName="token"
                  variant="outlined"
                  required
                  value={editform.token}
                  inputProps={{
                    minLength: 1,
                    maxLength: 30,
                    onKeyDown: allowLettersNum,
                  }}
                  error={editformErrors.token}
                  helperText={editformErrors.token}
                  onChange={(e) => {
                    handleeditform("token", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Url *</b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  placeholder="Url"
                  variant="outlined"
                  required
                  value={editform.url}
                  error={editformErrors.url}
                  helperText={editformErrors.url}
                  onChange={(e) => {
                    handleeditform("url", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      {/* </div> */}
      <div className="d-flex aligm-items-center justify-content-end gap-4 my-5 me-5 ">
        <Link to="/credentials" className="cancle-button">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          color="primary"
          variant="contained"
          className="submit-btn"
          onClick={handleEditCredentials}
        >
          Update
        </Button>
      </div>
      {loader && <FullLoader />}
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClosePopup}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={statusFalsePop}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={handleStatusFalse}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
