/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { handleVersion } from "../../common/helpers";
import { basePathone } from "../../common/config";
import FullLoader from "../../components/FullLoader";

export default function EditFirmware({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [modelList, setModelList] = React.useState([]);
  const [prerequisiteList, setPrerequisiteList] = React.useState([]);
  const location = useLocation();
  const { received } = location.state;
  const [updateform, setUpdateForm] = React.useState({
    firmwareVersion: "",
    chargingStationModel: "",
    prerequisite: "",
    fileName: "",
    releaseNote: "",
    file: null,
    releaseNoteFile: null,
  });
  const [errors, setErrors] = React.useState({
    firmwareVersion: "",
    chargingStationModel: "",
    prerequisite: "",
    fileName: "",
    releaseNote: "",
  });
  const handleClose = () => {
    setUpdateForm({
      firmwareVersion: "",
      chargingStationModel: "",
      prerequisite: "",
      file: null,
      releaseNoteFile: null,
      fileName: "",
      releaseNote: "",
    });
    setErrors({
      firmwareVersion: "",
      chargingStationModel: "",
      prerequisite: "",
      fileName: "",
      releaseNote: "",
    });
  };
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const navigate = useNavigate();
  function handleClosePopup() {
    handleClose();
    navigate("/firmware");
  }
  function handleUpdateForm(key, value) {
    if (key === "firmwareVersion") {
      let valid = handleVersion(value);
      if (valid) {
        setErrors((errors) => ({
          ...errors,
          firmwareVersion: "Enter Valid firmwareVersion",
        }));
        setUpdateForm({
          ...updateform,
          [key]: value,
        });
      } else {
        setErrors({ ...errors, firmwareVersion: "" });
        setUpdateForm({
          ...updateform,
          [key]: value,
        });
      }
    } else {
      setErrors({ ...errors, [key]: "" });
      setUpdateForm({
        ...updateform,
        [key]: value,
      });
    }
  }
  function handleUpdateFirmware() {
    let error = false;
    if (
      !updateform.firmwareVersion ||
      !updateform.chargingStationModel ||
      !updateform.prerequisite ||
      !updateform.fileName ||
      !updateform.releaseNote ||
      updateform.firmwareVersion < updateform.prerequisite ||
      handleVersion(updateform.firmwareVersion)
    ) {
      error = true;
    }
    if (error) {
      if (!updateform.firmwareVersion) {
        setErrors((errors) => ({
          ...errors,
          firmwareVersion: "Version is Mandatory",
        }));
      }
      if (!updateform.chargingStationModel) {
        setErrors((errors) => ({
          ...errors,
          chargingStationModel: "Model is Mandatory",
        }));
      }
      if (!updateform.prerequisite) {
        setErrors((errors) => ({
          ...errors,
          prerequisite: "Prerequisite is Mandatory",
        }));
      }
      if (updateform.firmwareVersion < updateform.prerequisite) {
        setErrors((errors) => ({
          ...errors,
          prerequisite: "Prerequisite should be less than Version",
        }));
      }
      if (!updateform.fileName) {
        setErrors((errors) => ({
          ...errors,
          fileName: "fileName is Mandatory",
        }));
      }
      if (!updateform.releaseNote) {
        setErrors((errors) => ({
          ...errors,
          releaseNote: "ReleaseNotes is Mandatory",
        }));
      }
      if (
        updateform.firmwareVersion &&
        handleVersion(updateform.firmwareVersion)
      ) {
        let valid = handleVersion(updateform.firmwareVersion);
        if (valid) {
          setErrors((errors) => ({
            ...errors,
            firmwareVersion: "Enter valid version",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            firmwareVersion: "",
          }));
        }
      }
    } else {
      updateFirmware();
    }
  }
  React.useEffect(() => {
    setUpdateForm(received);
    getModels();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setUpdateForm(received);
      getModels();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const updateFirmware = async () => {
    setLoader(true);
    let headers = {
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    const formData = new FormData();
    formData.append("file", updateform.file);
    formData.append("releaseNoteFile", updateform.releaseNoteFile);

    await fetch(
      `${basePathone}api/firmware/updateUpload/${updateform.id}?firmwareVersion=${updateform.firmwareVersion}&chargingStationModel=${updateform.chargingStationModel}&prerequisite=${updateform.prerequisite}`,
      {
        method: "PUT",
        headers: headers,
        body: formData,
      }
    )
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setStatus(true);
          setAlertMessage(res?.message);
          handleAlertPopup();
        } else {
          setStatus(false);
          setAlertMessage(res?.message);
          handleAlertPopup();
        }
      });
  };
  const getModels = () => {
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}api/firmware/getAllModels`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setModelList(res?.data);
        }
      });
  };
  React.useEffect(() => {
    if (updateform.chargingStationModel) {
      getPrerequisites();
    }
  }, [updateform.chargingStationModel]);
  const getPrerequisites = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(
      `${basePathone}api/firmware/getPrerequisites/${updateform.chargingStationModel}`,
      {
        method: "GET",
        headers: headers,
      }
    )
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setPrerequisiteList(res?.data);
        }
      });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setUpdateForm((prevState) => ({
      ...prevState,
      releaseNoteFile: selectedFile,
      releaseNote: selectedFile ? selectedFile.name : "",
    }));
  };
  const handleFileChange1 = (event) => {
    const selectedFile = event.target.files[0];
    setUpdateForm((prevState) => ({
      ...prevState,
      file: selectedFile,
      fileName: selectedFile ? selectedFile.name : "",
    }));
  };

  return (
    <div className="mb-3">
      {sessionExpire && <SessionExpire />}
      {loader && <FullLoader />}
      <div className="pagetitle">Update Firmware</div>
      <Box>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <div className="row row-gap-3 mt-3">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Model *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    select
                    required
                    value={updateform.chargingStationModel}
                    error={errors.chargingStationModel}
                    name="chargingStationModel"
                    variant="outlined"
                    onChange={(e) => {
                      handleUpdateForm("chargingStationModel", e.target.value);
                    }}
                  >
                    {modelList.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormHelperText error>
                    {errors.chargingStationModel}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Version *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    name="firmwareVersion"
                    variant="outlined"
                    required
                    value={updateform.firmwareVersion}
                    error={errors.firmwareVersion}
                    helperText={errors.firmwareVersion}
                    onChange={(e) => {
                      handleUpdateForm("firmwareVersion", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Prerequisite *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    select
                    required
                    value={updateform.prerequisite}
                    error={errors.prerequisite}
                    name="prerequisite"
                    onChange={(e) => {
                      handleUpdateForm("prerequisite", e.target.value);
                    }}
                  >
                    {prerequisiteList.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormHelperText error>
                    <b className="fs-12">{errors.prerequisite}</b>
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>FileName *</b>
                  </FormLabel>
                  <Box
                    noValidate
                    required
                    autoComplete="off"
                    component="form"
                    className={`${
                      errors.fileName ? "file-border-error" : "file-border"
                    }`}
                    style={{
                      display: "flex",
                      gap: 5,
                      padding: "2px",
                      alignItems: "center",
                      height: "38px",
                    }}
                  >
                    <Button
                      component="label"
                      variant="contained"
                      style={{
                        width: "150px",
                        textTransform: "none",
                        fontSize: "13px",
                        lineHeight: "13px",
                        opacity: "0.9",
                        padding: "10px",
                        height: "30px",
                        color: "white",
                      }}
                    >
                      Choose Firmware file
                      <CloudUploadIcon />
                      <input type="file" hidden onChange={handleFileChange1} />
                    </Button>
                    {updateform.fileName ? (
                      <Typography
                        className="file-text"
                        style={{ padding: "10px" }}
                      >
                        {updateform.fileName}
                      </Typography>
                    ) : (
                      "No Choosen file"
                    )}
                  </Box>
                  <FormHelperText error>{errors.firmwareName}</FormHelperText>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Release Notes *</b>
                  </FormLabel>
                  <Box
                    noValidate
                    required
                    autoComplete="off"
                    className={`${
                      errors.releaseNote ? "file-border-error" : "file-border"
                    }`}
                    component="form"
                    style={{
                      display: "flex",
                      gap: 5,
                      padding: "2px",
                      alignItems: "center",
                      height: "38px",
                    }}
                  >
                    <Button
                      component="label"
                      variant="contained"
                      style={{
                        width: "150px",
                        height: "30px",
                        textTransform: "none",
                        fontSize: "13px",
                        lineHeight: "13px",
                        opacity: "0.9",
                        color: "white",
                        padding: "13px",
                      }}
                    >
                      Choose Release Notes
                      <CloudUploadIcon />
                      <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                    {updateform.releaseNote ? (
                      <Typography
                        className="file-text"
                        style={{ padding: "10px" }}
                      >
                        {updateform.releaseNote}
                      </Typography>
                    ) : (
                      "No Choosen file"
                    )}
                  </Box>
                  <FormHelperText error>{errors.fileName}</FormHelperText>
                </FormControl>
              </div>
            </div>
          </CardContent>
        </Card>
        <div className="d-flex aligm-items-center justify-content-end gap-4 mt-5 ">
          <Button
            variant="contained"
            onClick={handleUpdateFirmware}
            className="submit-btn"
          >
            Update
          </Button>
          <Button variant="contained" color="error" className="cancel-btn">
            <Link to="/firmware" className="cancle-button">
              Cancel
            </Link>
          </Button>
        </div>
      </Box>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClosePopup}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
