import * as material from "@mui/material";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import CustomTablePagination from "../../components/CustomTablePagination";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import FilterTable from "../../components/FilterTable";
import TableHeadRow from "../../components/TableHeadRow";
import ColumnsBox from "../../components/ColumnsBox";
import React from "react";
import { Link } from "react-router-dom";
import SessionExpire from "../../components/SessionExpire";
import Loader from "../../components/Loader";
import { Edit } from "@mui/icons-material";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
const headCells = [
  {
    id: "userId",
    numeric: false,
    disablePadding: true,
    label: "UID",
    mandatory: false,
    // filterOption: true,
    sort: true,
    minWidth: 150,
  },
  {
    id: "idTag",
    numeric: false,
    disablePadding: true,
    label: "Token",
    searchType: "text",
    mandatory: false,
    filterOption: true,
    sort: true,
    minWidth: 150,
  },

  {
    id: "idTokenType",
    numeric: false,
    disablePadding: true,
    label: "Token Type",
    mandatory: false,
    // filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "groupId",
    numeric: false,
    disablePadding: true,
    label: "Group ID",
    mandatory: false,
    // filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "defaultProfileType",
    numeric: false,
    disablePadding: true,
    label: "Default Profile Type",
    mandatory: false,
    // filterOption: false,
    sort: true,
    minWidth: 160,
  },
  {
    id: "wlType",
    numeric: false,
    disablePadding: true,
    label: "WhiteList",
    mandatory: true,
    // filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "issuer",
    numeric: false,
    disablePadding: true,
    label: "Issuer Name",
    mandatory: false,
    searchType: "text",
    filterOption: true,
    sort: true,
    minWidth: 150,
  },
  {
    id: "supplierName",
    numeric: false,
    disablePadding: true,
    label: "Supplier Name",
    searchType: "text",
    mandatory: false,
    filterOption: true,
    sort: true,
    minWidth: 180,
  },
  {
    id: "valid",
    numeric: false,
    disablePadding: true,
    label: "Valid",
    mandatory: false,
    // filterOption: false,
    sort: true,
    minWidth: 100,
  },

  {
    id: "last_updated",
    numeric: false,
    disablePadding: true,
    label: "Last Updated",
    // searchType: "date",
    mandatory: false,
    // searchType: "date",
    // filterOption: true,
    sort: true,
    minWidth: 180,
  },
  {
    id: "fromDate",
    numeric: false,
    disablePadding: false,
    label: "From Date",
    filterOption: true,
    onlySearch: true,
    searchType: "date",
  },
  {
    id: "toDate",
    numeric: false,
    disablePadding: false,
    label: "To Date",
    filterOption: true,
    onlySearch: true,
    searchType: "date",
  },
  {
    id: "update",
    numeric: false,
    disablePadding: false,
    label: "Update",
    mandatory: true,
    minWidth: 100,
  },
];

function Token({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("last_updated");
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [noData, setNoData] = React.useState("");
  const [searchInputs, setSearchInputs] = React.useState({
    supplierName: "",
    issuer: "",
    idTag: "",
    fromDate: "",
    toDate: "",
  });

  const [count, setCount] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [disableColumns, setDisableColumns] = React.useState([
    // "defaultProfileType",
    "last_updated",
    "userId",
    "defaultProfileType",
    "wlType",

    // "supplierName",
    // "issuer",
    //"groupId",
  ]);
  const [option, setOption] = React.useState("");
  const [tokenDetails, settokenDetails] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);

  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const [loader, setLoader] = React.useState(false);
  const [filterState, setFilterState] = React.useState([
    { key: "supplierName", value: "" },
    { key: "issuer", value: "" },
    { key: "idTag", value: "" },
    { key: "fromDate", value: "" },
    { key: "toDate", value: "" },
  ]);
  const resetStates = () => {
    // Reset search inputs for user fields
    setSearchInputs({
      supplierName: "",
      issuer: "",
      idTag: "",
      fromDate: "", // Reset date fields
      toDate: "",
    });

    // Reset filter state for user fields
    setFilterState([
      { key: "supplierName", value: "" },
      { key: "issuer", value: "" },
      { key: "idTag", value: "" },
      { key: "fromDate", value: "" },
      { key: "toDate", value: "" },
    ]);
  };
  const getTokens = async () => {
    const Url = `cpo/${2.1}/tokens/UL/party123?pageNo=${
      page + 1
    }&pageSize=${rowsPerPage}&sortBy=${orderBy}&sortDir=${order}&date_from=${
      searchInputs.fromDate
    }&date_to=${searchInputs.toDate}&issuer=${
      searchInputs.issuer
    }&supplierName=${searchInputs.supplierName}`;
    const type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    if (res && res?.statusCode === 1000) {
      settokenDetails(res?.data);
      setCount(res?.count);
      setNoData(res?.count === 0 ? "No Data Available" : "");
    }
  };
  React.useEffect(() => {
    if (refresh) {
      getTokens();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getTokens();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);

  function handleOptionClick(value) {
    setOption(option === value ? "" : value);
  }
  const handelChange = (id) => {
    setDisableColumns(
      disableColumns.includes(id)
        ? disableColumns.filter((c) => c !== id)
        : [...disableColumns, id]
    );
  };
  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        issuer: "",
        idTag: "",
        supplierName: "",
        fromDate: "", // Reset date fields
        toDate: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Tokens</div>
        <div className="d-flex align-items-center gap-3 ">
          <Link to="/addtoken">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              ADD
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red ">
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              // deleteCheckBox={false}
              onRequestSort={handleRequestSort}
              rowCount={tokenDetails.length}
              disableCheckBox={true}
              disableColumns={disableColumns}
            />
            <TableBody>
              {tokenDetails.map((row, index) => {
                return (
                  <TableRow key={row.uid}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    {!disableColumns.includes("userId") && (
                      <TableCell
                        // component="th"
                        scope="row"
                        padding="none"
                      >
                        {row.userId}
                      </TableCell>
                    )}
                    {!disableColumns.includes("idTag") && (
                      <TableCell>{row.idTag}</TableCell>
                    )}

                    {!disableColumns.includes("type") && (
                      <TableCell>{row.idTokenType}</TableCell>
                    )}
                    {!disableColumns.includes("groupId") && (
                      <TableCell>{row.groupId}</TableCell>
                    )}
                    {!disableColumns.includes("defaultProfileType") && (
                      <TableCell>{row.defaultProfileType}</TableCell>
                    )}
                    {!disableColumns.includes("wlType") && (
                      <TableCell>{row.wlType}</TableCell>
                    )}
                    {!disableColumns.includes("issuer") && (
                      <TableCell>{row.issuer}</TableCell>
                    )}
                    {!disableColumns.includes("supplierName") && (
                      <TableCell>{row.energyContract?.supplierName}</TableCell>
                    )}
                    {!disableColumns.includes("valid") && (
                      <TableCell>
                        {row.valid ? (
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            style={{ color: "green" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{ color: "red" }}
                          />
                        )}
                      </TableCell>
                    )}

                    {!disableColumns.includes("last_updated") && (
                      <TableCell>
                        {row.last_updated &&
                          moment(row.last_updated).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}

                    {!disableColumns.includes("update") && (
                      <TableCell>
                        <Link to={"/edittoken"} state={row}>
                          <i
                            className="fa-solid fa-pencil action-icons"
                            style={{ color: "#2DCC70" }}
                          ></i>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {tokenDetails.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={12} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {tokenDetails.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
export default Token;
