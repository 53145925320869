import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Popper,
  TextField,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import { handleKeyDownNumbers } from "../../common/helpers";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

function AddEvse({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [connectorTypeList, setConnectorTypeList] = React.useState([]);
  const [powerTypeList, setPowerTypeList] = React.useState([]);
  const [connectorFormateList, setConnectorFormatList] = React.useState([]);
  const [tariffIdsList, setTariffIdsList] = React.useState([]);
  const [statusMessage, setStatusMessage] = React.useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [connectorObj, setConnectorObj] = React.useState({
    connectorId: "",
    standard: "",
    format: "",
    powerType: "",
    maxVoltage: "",
    maxAmperage: "",
    maxElectricPower: "",
    tariffIds: [],
    terms_and_conditions: "",
  });
  const [errors, setErrors] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const EvseID = localStorage.getItem("EVSEID");
  React.useEffect(() => {
    getConnectorFormat();
    getConnectorType();
    getPowerType();
    getTariffIds();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getConnectorFormat();
      getConnectorType();
      getPowerType();
      getTariffIds();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  const getPowerType = async () => {
    let Url = `api/locations/getPowerTypeDropdown`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setPowerTypeList(res?.data);
    }
  };
  const getTariffIds = async () => {
    let Url = `api/locations/getTariffDisplayText`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setTariffIdsList(res?.data);
    }
  };
  const getConnectorType = async () => {
    let Url = `api/locations/getConnectorTypeDropdown`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setConnectorTypeList(res?.data);
    }
  };
  const getConnectorFormat = async () => {
    let Url = `api/locations/getConnectorFormatDropdown`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setConnectorFormatList(res?.data);
    }
  };
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol, must be http:// or https://
      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))" + // domain name or IP address
      "(\\:[0-9]{1,5})?(\\/.*)?$", // optional port and path
    "i"
  );
  const validateForm = () => {
    const newErrors = {};

    // Check for required fields
    if (!connectorObj.connectorId)
      newErrors.connectorId = "Connector ID is required";
    if (!connectorObj.standard)
      newErrors.standard = "Connector Type is required";
    if (!connectorObj.format) newErrors.format = "Format is required";
    if (!connectorObj.powerType) newErrors.powerType = "Power Type is required";
    if (!connectorObj.maxVoltage)
      newErrors.maxVoltage = "Max Voltage is required";
    if (!connectorObj.maxAmperage)
      newErrors.maxAmperage = "Max Amperage is required";
    if (!connectorObj.maxElectricPower)
      newErrors.maxElectricPower = "Max Electric Power is required";
    if (!connectorObj.terms_and_conditions) {
      newErrors.terms_and_conditions = "Terms and Conditions URL is required";
    } else {
      const valid = urlPattern.test(connectorObj.terms_and_conditions);
      if (!valid) {
        newErrors.terms_and_conditions = "Enter a valid URL";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChangeConObj = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setConnectorObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleConObjChange = (name, newValue) => {
    setErrors({ ...errors, [name]: "" });
    setConnectorObj((prevState) => ({
      ...prevState,
      [name]: Array.isArray(newValue) ? newValue : [], // Ensure `newValue` is an array
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let Url = `api/locations/createConnector/${EvseID}`;
      let type = "POST";
      let res = await handleApi_call(
        Url,
        headers,
        type,
        connectorObj,
        setSessionExpire,
        setAlertMessage,
        handleAlertPopup,
        setLoader
      );
      if (res?.statusCode === 1000) {
        setStatusMessage("SUCCESS");
        setAlertMessage(res?.data);
        handleAlertPopup();
      } else {
        setStatusMessage("FAILURE");
        setAlertMessage(res?.data);
        handleAlertPopup();
      }
    }
  };
  return (
    <div>
      <div className="pagetitle">Add Connector</div>
      <div
        sx={{ minWidth: 275 }}
        style={{
          borderRadius: "25px",
          marginTop: "14px",
          backgroundColor: "white",
        }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector ID *</b>
                </FormLabel>
                <TextField
                  required
                  onKeyDown={(e) => handleKeyDownNumbers(e)}
                  type="number"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={connectorObj.connectorId}
                  onChange={(e) =>
                    handleChangeConObj("connectorId", e.target.value)
                  }
                  error={errors.connectorId}
                  helperText={errors.connectorId}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Type *</b>
                </FormLabel>
                <TextField
                  required
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={connectorObj.standard}
                  onChange={(e) =>
                    handleChangeConObj("standard", e.target.value)
                  }
                  error={errors.standard}
                  helperText={errors.standard}
                >
                  {connectorTypeList.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Power Type *</b>
                </FormLabel>
                <TextField
                  required
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={connectorObj.powerType}
                  onChange={(e) =>
                    handleChangeConObj("powerType", e.target.value)
                  }
                  error={errors.powerType}
                  helperText={errors.powerType}
                >
                  {powerTypeList.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Format *</b>
                </FormLabel>
                <TextField
                  required
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={connectorObj.format}
                  onChange={(e) => handleChangeConObj("format", e.target.value)}
                  error={errors.format}
                  helperText={errors.format}
                >
                  {connectorFormateList.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Voltage *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={connectorObj.maxVoltage}
                  onChange={(e) =>
                    handleChangeConObj("maxVoltage", e.target.value)
                  }
                  error={errors.maxVoltage}
                  helperText={errors.maxVoltage}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Amperage *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={connectorObj.maxAmperage}
                  onChange={(e) =>
                    handleChangeConObj("maxAmperage", e.target.value)
                  }
                  error={errors.maxAmperage}
                  helperText={errors.maxAmperage}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Electric Power *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  // type="number"
                  value={connectorObj.maxElectricPower}
                  onChange={(e) =>
                    handleChangeConObj("maxElectricPower", e.target.value)
                  }
                  error={errors.maxElectricPower}
                  helperText={errors.maxElectricPower}
                />
              </FormControl>
            </div>
            <div className="col-4 position-relative">
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={tariffIdsList}
                disablePortal
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  // Map the selected values to an array of tariffIds
                  const selectedTariffIds = newValue.map(
                    (option) => option.tariffId
                  );
                  handleConObjChange("tariffIds", selectedTariffIds);
                }}
                renderInput={(params) => (
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Select tariffIds *</b>
                    </FormLabel>
                    <TextField
                      {...params}
                      placeholder="tariffIds"
                      error={Boolean(errors.tariffIds)}
                      helperText={errors.tariffIds}
                    />
                  </FormControl>
                )}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      width: "calc(100% - 16px)",
                      marginLeft: "6px",
                      marginTop: "6px",
                    }}
                    placement="bottom-start"
                  />
                )}
              />
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Terms & Conditions Url *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={connectorObj.terms_and_conditions}
                  onChange={(e) =>
                    handleChangeConObj("terms_and_conditions", e.target.value)
                  }
                  error={errors.terms_and_conditions}
                  helperText={errors.terms_and_conditions}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </div>
      <div className="d-flex justify-content-end gap-3 mt-4">
        <Link to="/viewEvse">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          className="submit-btn"
          size="small"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={
          statusMessage === "SUCCESS"
            ? () => navigate("/viewEvse")
            : handleAlertPopup
        }
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}

export default AddEvse;
