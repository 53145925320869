import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { useRef } from "react";
import * as material from "@mui/material";
import TableHeadRow from "../../components/TableHeadRow";
import { searchOperations } from "../../common/helpers";
import CustomTablePagination from "../../components/CustomTablePagination";
import SessionExpire from "../../components/SessionExpire";
import moment from "moment";
import FilterTable from "../../components/FilterTable";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";

import FullLoader from "../../components/FullLoader";
import { useLocation } from "react-router-dom";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function SessionEvents({
  chargingSessionId,
  refresh,
  setRefresh,
}) {
  const [orderBy, setOrderBy] = React.useState("eventType");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [noData, setNoData] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const location = useLocation();
  const [searchInputs, setSearchInputs] = React.useState({
    eventType: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "eventType", value: "" },
  ]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [option, setOption] = React.useState("");
  const [sessionEventsData, setSessionEventsData] = React.useState([]);
  const [isVisible, setIsVisible] = React.useState(false);
  const detailsRef = useRef(null);
  const headCells = [
    {
      id: "chargingSessionId",
      numeric: false,
      disablePadding: true,
      label: "Event ID",
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "eventType",
      numeric: false,
      disablePadding: false,
      label: "Event Type ",
      mandatory: true,
      filterOption: true,
      searchType: "text",
      sort: true,
    },
    {
      id: "triggerReason",
      numeric: false,
      disablePadding: false,
      label: "Trigger Reason",
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "chargingPriority",
      numeric: false,
      disablePadding: false,
      label: "Charging Priority",
      mandatory: false,
      filterOption: false,
      sort: true,
    },
    {
      id: "cableMaxCurrent",
      numeric: false,
      disablePadding: false,
      label: "Cable Max Current",
      mandatory: false,
      filterOption: false,
      sort: true,
    },
    {
      id: "eventTimestamp",
      numeric: false,
      disablePadding: false,
      label: "Time Stamp",
      mandatory: false,
      filterOption: false,
      sort: true,
    },
  ];
  // Function to toggle visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  React.useEffect(() => {
    if (refresh) {
      getSessionEvents();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOptionClick = (value) => {
    setOption(option === value ? "" : value);
  };
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      eventType: "",
    });
    setFilterState([{ key: "eventType", value: "" }]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "chargingSessionId") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.EQUALITY,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });
    if (location.state) {
      params.push({
        key: "chargingSessionId",
        value: location.state.id,

        operation: searchOperations.EQUALITY,
      });
    }

    return params;
  };
  const getSessionEvents = async () => {
    setLoader(true);
    const searchParams = buildSearchParams();
    const requestBody = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    searchParams.push({
      key: "id",
      value: chargingSessionId,
      operation: "EQUALITY",
    });
    let Url = "events/search";
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      requestBody,
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );

    setSessionEventsData(res?.data);
    setCount(res?.count);
    setNoData(res?.count === 0 ? "No Data Available" : "");
  };
  React.useEffect(() => {
    getSessionEvents();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);

  return (
    <div>
      <div
        className="view my-3 "
        onClick={toggleVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>Session Events</span>
        <span className={`icon ${isVisible ? "minus" : "plus"}`}>
          {isVisible ? "-" : "+"}
        </span>
      </div>

      {/* <div ref={detailsRef} className="session-details" > */}
      {isVisible && (
        <div className="row border br-10 mx-0 h-auto">
          <Box sx={{ width: "100%", position: "relative" }}>
            <div className="d-flex align-items-center justify-content-between cardFilter">
              <div>
                <DisplayFilterInputs
                  searchInputs={searchInputs}
                  clearFilterValue={clearFilterValue}
                  clearInputValue={clearInputValue}
                  resetStates={resetStates}
                />
              </div>
              <div>
                <material.Box className="d-flex align-items-center gap-3">
                  <div>
                    <div
                      className="filter-filter bgcolor-blue"
                      onClick={() => handleOptionClick("filter")}
                      style={{ position: "relative", zIndex: 10 }}
                    >
                      <i class="fa-solid fa-sliders"></i>
                      <span className="ms-1">Filter</span>
                    </div>
                    {option === "filter" && (
                      <material.ClickAwayListener
                        onClickAway={() => handleOptionClick("")}
                      >
                        <div className="filterBox">
                          <FilterTable
                            headCells={headCells}
                            filterColumns={filterColumns}
                            handleOptionClick={handleOptionClick}
                            updateFilter={updateFilter}
                            filterState={filterState}
                            syncFilterStateToSearchInputs={
                              syncFilterStateToSearchInputs
                            }
                          />
                        </div>
                      </material.ClickAwayListener>
                    )}
                  </div>
                </material.Box>
              </div>
            </div>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"small"}
                >
                  <TableHeadRow
                    headCells={headCells}
                    order={order}
                    disableCheckBox={true}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={sessionEventsData.length}
                    disableColumns={[]}
                  />
                  <TableBody>
                    {sessionEventsData.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow tabIndex={-1} key={row.id}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={false} disabled={true} />
                          </TableCell>
                          <TableCell>{row.chargingSessionId}</TableCell>
                          <TableCell>{row.eventType}</TableCell>
                          <TableCell>{row.triggerReason}</TableCell>
                          <TableCell>{row.chargingPriority}</TableCell>
                          <TableCell>{row.cableMaxCurrent}</TableCell>
                          <TableCell>
                            {moment(row.eventTimestamp).format(
                              "DD MMM YYYY | hh:mm A"
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {sessionEventsData.length === 0 && (
                      <TableRow style={{ height: 40 }}>
                        <TableCell colSpan={8} className="saas-nodata-row">
                          {noData}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {sessionEventsData.length > 0 && (
                <CustomTablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </Box>
        </div>
      )}

      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}
