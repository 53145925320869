/** @format */

import React from "react";
import { useTranslation } from "react-i18next";

function ProductList({ toggles }) {
  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat({
    style: "currency",
    currency: "USD",
  });

  const productLists = [
    {
      name: t("elements_and_widgets"),
      views: 3890,
      convertion: 16.24,
      total: 4995,
      rate: 0.59,
    },
    {
      name: t("designer_assets"),
      views: 4785,
      convertion: 19.33,
      total: 5995,
      rate: 0.79,
    },
    {
      name: t("designer_components"),
      views: 9712,
      convertion: -2.07,
      total: 1899,
      rate: 0.01,
    },
    {
      name: t("cards_and_elements"),
      views: 3993,
      convertion: 64.24,
      total: 2905,
      rate: 1.79,
    },
    {
      name: t("dashboard_design"),
      views: 1972,
      convertion: -23.1,
      total: 9995,
      rate: 0.72,
    },
    {
      name: t("learning_desing"),
      views: 3449,
      convertion: 0.0,
      total: 4995,
      rate: 0.59,
    },
  ];

  return (
    <div className={`card customList ps-3 fs-12 ${toggles && "fullGraph"}`}>
      <b className=" pt-2 fs-5 mb-2 fw-bolder">{t("product_list")}</b>
      <div className="scroll">
        <table className={`ms-4 tableWidth ${toggles && "fullTable"}`}>
          <thead className="position mt-0">
            <tr className="greyColor">
              <th>{t("product_name")}</th>
              <th>{t("views")}</th>
              <th>{t("conversion")}</th>
              <th>{t("total")}</th>
              <th>{t("rate")}</th>
            </tr>
          </thead>
          <tbody className="scroll  h-auto">
            {productLists.map((product) => (
              <tr key={product.name} className="border-bottom">
                <td className={`fw-bold p-3 ${toggles && "fs-6"}`}>
                  {product.name}
                </td>
                <td>
                  <span className="border rounded-3 p-2 ps-3 pe-3 greyColor">
                    {product.views}
                  </span>
                </td>
                <td className="fw-bold ">
                  {product.convertion === 0 ? (
                    <>
                      <span className="circle greyColor bg-zero fw-bold">
                        &rarr;
                      </span>
                      <span className="greyColor ms-2">
                        {product.convertion}
                      </span>
                    </>
                  ) : (
                    <div
                      className={`${
                        product.convertion > 0 ? "text-success" : "text-danger"
                      }`}
                    >
                      <span
                        className={`circle ${
                          product.convertion > 0
                            ? "bg-green correct"
                            : "bg-red wrong"
                        }`}
                      >
                        &uarr;
                      </span>
                      <span className="ms-2">
                        {product.convertion > 0
                          ? "+" + product.convertion
                          : product.convertion}
                      </span>
                    </div>
                  )}
                </td>
                <td className="fw-bold">{formatter.format(product.total)}</td>
                <td className="fw-bold">{product.rate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProductList;
