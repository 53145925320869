/** @format */

import * as React from "react";
import { Box, FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
export default function AddForm({ addform, handleAddForm, addformErrors }) {
  const { t } = useTranslation();
  return (
    <div className="mt-1">
      <Box height={300} width={800} marginLeft={2} marginTop={1}>
        <Grid container spacing={3}>
          <Grid item>
            <FormControl className="simpleInput">
              <FormLabel>
                <b>{t("template_name")} *</b>
              </FormLabel>
              <TextField
                placeholder={t("template_name")}
                error={addformErrors.templateName}
                required
                variant="outlined"
                value={addform?.templateName}
                size="small"
                helperText={addformErrors.templateName}
                inputProps={{
                  minLength: 3,
                  maxLength: 50,
                  onKeyDown: (e) => {
                    if (/[^A-Za-z]/.test(e.key)) {
                      e.preventDefault();
                    }
                  },
                }}
                onChange={(e) => {
                  const value = e.target.value.replace(/[0-9]/g, "");
                  handleAddForm("templateName", e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className="simpleInput">
              <FormLabel>
                <b>{t("subject")}*</b>
              </FormLabel>
              <TextField
                placeholder={t("subject")}
                error={addformErrors.subject}
                required
                variant="outlined"
                value={addform?.subject}
                size="small"
                helperText={addformErrors.subject}
                inputProps={{
                  minLength: 3,
                  maxLength: 100,
                }}
                onChange={(e) => {
                  handleAddForm("subject", e.target.value);
                }}
                multiline
                rows={addform.subject.length > 70 ? 2 : 1}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className="simpleInput">
              <FormLabel>
                <b>{t("body")}*</b>
              </FormLabel>
              <TextField
                placeholder={t("body")}
                error={addformErrors.body}
                required
                variant="outlined"
                value={addform?.body}
                size="small"
                helperText={addformErrors.body}
                inputProps={{
                  minLength: 3,
                }}
                onChange={(e) => {
                  handleAddForm("body", e.target.value);
                }}
                multiline
                rows={3}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
