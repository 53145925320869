/** @format */

import { Box, MenuItem, TextField } from "@mui/material";

function AddMenuForm({
  parents,
  addmenuForm,
  addmenuFormError,
  handleAddForm,
}) {
  return (
    <div>
      <Box>
        <div className="d-flex flex-column gap-4 w-100 mt-3">
          <TextField
            label="Menu Title"
            error={addmenuFormError.title}
            // className=" me-3 error"
            required
            variant="outlined"
            value={addmenuForm?.title}
            size="small"
            helperText={addmenuFormError.title}
            onChange={(e) => {
              handleAddForm("title", e.target.value);
            }}
          />
          <TextField
            label="Menu Link"
            // className="w-45 me-3 error"
            error={addmenuFormError.link}
            required
            variant="outlined"
            value={addmenuForm?.link}
            size="small"
            helperText={addmenuFormError.link}
            onChange={(e) => {
              handleAddForm("link", e.target.value);
            }}
          />
          <TextField
            select
            required
            label="Select Category"
            // className="w-45 me-3 error"
            error={addmenuFormError.parentId}
            value={addmenuForm.parentId}
            size="small"
            helperText={addmenuFormError.parentId}
            onChange={(e) => {
              handleAddForm("parentId", e.target.value);
            }}
          >
            {parents.map((parent) => (
              <MenuItem key={parent.id} value={parent.id}>
                {parent.title}
              </MenuItem>
            ))}
            <MenuItem value={0}>None</MenuItem>
          </TextField>
        </div>
      </Box>
    </div>
  );
}

export default AddMenuForm;
