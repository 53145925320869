/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import SessionExpire from "../../components/SessionExpire";
import { basePath } from "../../common/config";
import * as helpers from "../../common/helpers";
import { resize } from "../../common/helpers";
import PopUp from "../../components/PopUp";
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  handleKeyDownAlphaUnderScoreandNumeric,
  handleKeyDownName,
} from "../../common/helpers";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function TanentAddForm({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [dbtypestate, setdbtypestate] = React.useState("separate-instance");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [addressDB, setaddressDB] = React.useState([]);
  const [nameschema, setnameschema] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [statusFalsePop, setStatusFalsepop] = React.useState(false);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [addform, setAddForm] = React.useState({
    schemaName: "",
    driverClassName: "com.mysql.cj.jdbc.Driver",
    dbAddress: "",
    port: "",
    dbName: "Test",
    userName: "",
    password: "",
    tenantName: "",
    tenantId: "",
    dbType: "separate-instance",
    email: "",
    address: "",
  });
  const [errors, setErrors] = React.useState({
    schemaName: "",
    dbAddress: "",
    port: "",
    userName: "",
    password: "",
    tenantName: "",
    tenantId: "",
    dbType: "",
    email: "",
    address: "",
  });

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleStatusFalsePop() {
    setStatusFalsepop((pop) => !pop);
  }
  const navigate = useNavigate();
  function handelAlertOk() {
    handleAlertPopup();
    navigate("/tenantManagement");
  }
  function handleAddForm(key, value) {
    if (key === "phone") {
      if (!isNaN(value)) {
        setErrors({ ...errors, [key]: "" });
        setAddForm({
          ...addform,
          [key]: value,
        });
      }
    } else {
      setErrors({ ...errors, [key]: "" });
      if (key === "dbType") {
        setAddForm({ ...addform, [key]: value, dbAddress: "" });
      } else {
        setAddForm({
          ...addform,
          [key]: value,
        });
      }
      if (key === "email") {
        const valid = helpers.handleEmail(value);
        if (valid) {
          setErrors({
            ...errors,
            [key]: "Enter valid email",
          });
        } else {
          setErrors({ ...errors, [key]: "" });
        }
      }
    }
  }
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;

  const handleErrors = () => {
    let error = false;
    if (
      !addform.dbAddress ||
      !addform.port ||
      !addform.userName ||
      !addform.password ||
      !addform.tenantName ||
      !addform.tenantId ||
      !addform.dbType ||
      !addform.email ||
      !addform.address ||
      helpers.handleEmail(addform.email)
    ) {
      error = true;
    }

    if (error) {
      if (!addform.schemaName) {
        setErrors((errors) => ({
          ...errors,
          schemaName: "Schema Name is Mandatory",
        }));
      }
      if (!addform.dbAddress) {
        setErrors((errors) => ({
          ...errors,
          dbAddress: "DB Address is Mandatory"
        }));
      }
      if (addform.dbAddress) {
        let valid = ipRegex.test(addform.dbAddress);
        if (valid) {
          setErrors((errors) => ({
            ...errors,
            dbAddress: ""
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            dbAddress: "Enter valid DB Address eg:1.2.3.5"
          }));
        }
      }

      if (!addform.port) {
        setErrors((errors) => ({ ...errors, port: "Port is Mandatory" }));
      }
      if (!addform.password) {
        setErrors((errors) => ({
          ...errors,
          password: "Password is mandatory",
        }));
      }
      if (!addform.tenantName) {
        setErrors((errors) => ({
          ...errors,
          tenantName: "Tenant is Mandatory",
        }));
      }
      if (!addform.tenantId) {
        setErrors((errors) => ({
          ...errors,
          tenantId: "Tenant is Mandatory",
        }));
      }
      if (!addform.dbType) {
        setErrors((errors) => ({
          ...errors,
          dbType: "DB Type is Mandatory",
        }));
      }
      if (!addform.userName) {
        setErrors((errors) => ({
          ...errors,
          userName: "User Name is Mandatory",
        }));
      }

      if (!addform.email) {
        setErrors((errors) => ({
          ...errors,
          email: "Email is Mandatory",
        }));
      }
      if (addform.email) {
        let valid = helpers.handleEmail(addform.email);
        if (!valid) {
          setErrors((errors) => ({
            ...errors,
            email: "",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            email: "Enter valid email",
          }));
        }
      }
      if (!addform.address) {
        setErrors((errors) => ({
          ...errors,
          address: "Address is Mandatory",
        }));
      }
      if (
        !helpers.validatePassword(addform.password) &&
        addform.password.length > 0
      ) {
        setErrors((errors) => ({
          ...errors,
          password: "Password must be 8-20 characters long and include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9), and 1 special character (!@#$%^&*()+)",
        }));
      }
    } else {
      addTenant();
    }
  };
  const addTenant = async () => {
    let Url = "api/v1.0/tenants/onboard/tenant";
    let type = "POST";
    let body = addform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res.status) {
      setAlertMessage(res?.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res?.message);
      handleStatusFalsePop();
    }
  };

  const getDbAddress = async () => {
    let Url = `api/v1.0/tenants/database/address/list`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setaddressDB(res.data);
    }
  };

  const getSchemaname = async (value) => {
    let Url = `api/v1.0/tenants/db/${value}/availableSchemas`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.status) {
      setnameschema(res.data);
    }
  };
  const handleDbaddresSet = (e) => {
    handleAddForm("dbAddress", e.target.value);
    // onSchemasget();
  };

  const handleDbTypeSet = (e) => {
    handleAddForm("dbType", e.target.value);
    setdbtypestate(e.target.value);
    // handleClearAddForm();
  };

  React.useEffect(() => {
    getDbAddress();
    getSchemaname();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getDbAddress();
      getSchemaname();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <div>
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
      <div className="pagetitle">Add Tenant</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent>
          <FormControl className="gap-2">
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              error={errors.dbType}
              helperText={errors.dbType}
            >
              {"Select Database Type :"}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={addform?.dbType} // Set default value here
              onChange={handleDbTypeSet}
            >
              <FormControlLabel
                value="separate-instance"
                control={<Radio />}
                label={"Separate-Instance"}
              />
              <FormControlLabel
                value="separate-schema"
                control={<Radio />}
                label={"Separate-Schema"}
              />
              <FormControlLabel
                value="sharded-db"
                control={<Radio />}
                label={"Sharded-DB"}
              />
            </RadioGroup>
          </FormControl>
          {/* {dbtypestate === "separate-schema" && (
          <>
            <FormControl size="small">
              <InputLabel id="db-address-label">{t("DB Address")}</InputLabel>
              <Select
                required
                value={addform?.dbAddress}
                error={errors.dbAddress}
                helperText={errors.dbAddress}
                labelId="db-address-label"
                onChange={handleDbaddresSet}
              >
                {addressDB.map((list, index) => (
                  <MenuItem key={index} value={list}>
                    {list}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="schema-name-label">{t("Schema Name")}</InputLabel>
              <Select
                required
                value={addform?.schemaName}
                error={errors.schemaName}
                helperText={errors.schemaName}
                labelId="schema-name-label"
                onChange={(e) => {
                  handleAddForm("schemaName", e.target.value);
                }}
                disabled={addform.dbAddress !== "" ? false : true}
              >
                {nameschema.map((list, index) => (
                  <MenuItem key={index} value={list}>
                    {list}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )} */}

          <Box className="row row-gap-4 mt-3">
            {dbtypestate === "separate-instance" && (
              <>
                <div className="col-4">
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Adddress Name *</b>
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      className="w-100"
                      placeholder="Address Name"
                      error={errors.dbAddress}
                      helperText={errors.dbAddress}
                      value={addform?.dbAddress}
                      size="small"
                      required
                      onChange={(e) => {
                        handleAddForm("dbAddress", e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="col-4">
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Schema Name *</b>
                    </FormLabel>
                    <TextField
                      className="w-100"
                      placeholder="Schema Name"
                      variant="outlined"
                      required
                      disabled={addform.dbAddress !== "" ? false : true}
                      value={addform?.schemaName}
                      error={errors.schemaName}
                      helperText={errors.schemaName}
                      inputProps={{
                        maxLength: 30,
                      }}
                      onKeyDown={handleKeyDownAlphaUnderScoreandNumeric}
                      size="small"
                      onChange={(e) => {
                        handleAddForm("schemaName", e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              </>
            )}
            {dbtypestate === "separate-schema" && (
              <>
                <div className="col-4">
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Address Name *</b>
                    </FormLabel>
                    <TextField
                      select
                      required
                      value={addform?.dbAddress}
                      className="w-100"
                      placeholder="DB Address"
                      size="small"
                      error={errors.dbAddress}
                      helperText={errors.dbAddress}
                      onChange={handleDbaddresSet}
                    >
                      {addressDB.map((list, index) => (
                        <MenuItem key={index} value={list}>
                          {list}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </div>
                <div className="col-4">
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Schema Name *</b>
                    </FormLabel>
                    <TextField
                      className="w-100"
                      variant="outlined"
                      required
                      placeholder="Schema Name"
                      value={addform?.schemaName}
                      error={errors.schemaName}
                      helperText={errors.schemaName}
                      size="small"
                      inputProps={{
                        maxLength: 30,
                      }}
                      onKeyDown={handleKeyDownAlphaUnderScoreandNumeric}
                      disabled={addform.dbAddress !== "" ? false : true}
                      onChange={(e) => {
                        handleAddForm("schemaName", e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              </>
            )}
            {dbtypestate === "sharded-db" && (
              <>
                <div className="col-4">
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Address Name *</b>
                    </FormLabel>
                    <TextField
                      select
                      required
                      value={addform?.dbAddress}
                      className="w-100"
                      placeholder="Address Name"
                      size="small"
                      error={errors.dbAddress}
                      helperText={errors.dbAddress}
                      onChange={handleDbaddresSet}
                    >
                      {addressDB.map((list, index) => (
                        <MenuItem key={index} value={list}>
                          {list}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </div>
                {nameschema.length === 0 ? (
                  <div className="col-4">
                    <FormControl className="simpleInput">
                      <FormLabel>
                        <b>Schema Name *</b>
                      </FormLabel>
                      <TextField
                        className="w-100"
                        placeholder="Schema Name"
                        variant="outlined"
                        required
                        inputProps={{
                          maxLength: 30,
                        }}
                        onKeyDown={handleKeyDownAlphaUnderScoreandNumeric}
                        disabled={addform.dbAddress !== "" ? false : true}
                        value={addform?.schemaName}
                        error={errors.schemaName}
                        helperText={errors.schemaName}
                        size="small"
                        onChange={(e) => {
                          handleAddForm("schemaName", e.target.value);
                        }}
                      />
                    </FormControl>
                  </div>
                ) : (
                  <div className="col-4">
                    <FormControl className="simpleInput">
                      <FormLabel>
                        <b>Schema Name *</b>
                      </FormLabel>
                      <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">
                         "Schema Name"
                        </InputLabel>
                        <Select
                          value={addform?.schemaName}
                          error={errors.schemaName}
                          helperText={errors.schemaName}
                          className="w-100"
                          placeholder="Schema Name"
                          required
                          disabled={addform.dbAddress !== "" ? false : true}
                          onChange={(e) => {
                            handleAddForm("schemaName", e.target.value);
                          }}
                        >
                          {nameschema.map((list, index) => (
                            <MenuItem key={index} value={list}>
                              {list}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormControl>
                  </div>
                )}
              </>
            )}
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Port *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  placeholder="Port"
                  variant="outlined"
                  required
                  inputProps={{
                    maxLength: 4,
                  }}
                  onKeyDown={helpers.handleKeyDownNumbers}
                  value={addform?.port}
                  error={errors.port}
                  helperText={errors.port}
                  size="small"
                  onChange={(e) => {
                    handleAddForm("port", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>User Name *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  placeholder="User Name"
                  variant="outlined"
                  required
                  onKeyDown={handleKeyDownName}
                  value={addform?.userName}
                  error={errors.userName}
                  helperText={errors.userName}
                  size="small"
                  inputProps={{
                    maxLength: 25,
                  }}
                  onChange={(e) => {
                    handleAddForm("userName", e.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Tenant Name *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  placeholder="Tenant Name"
                  variant="outlined"
                  inputProps={{
                    maxLength: 25,
                  }}
                  value={addform?.tenantName}
                  error={errors.tenantName}
                  onKeyDown={handleKeyDownName}
                  helperText={errors.tenantName}
                  size="small"
                  required
                  onChange={(e) => {
                    handleAddForm("tenantName", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Tenant ID *</b>
                </FormLabel>
                <TextField
                  variant="outlined"
                  className="w-100"
                  placeholder="Tenant ID"
                  inputProps={{
                    maxLength: 8,
                  }}
                  value={addform?.tenantId}
                  error={errors.tenantId}
                  helperText={errors.tenantId}
                  size="small"
                  required
                  onChange={(e) => {
                    handleAddForm("tenantId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Address *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="outlined"
                  placeholder="Address"
                  required
                  inputProps={{
                    maxLength: 100,
                  }}
                  value={addform?.address}
                  error={errors.address}
                  helperText={errors.address}
                  size="small"
                  onChange={(e) => {
                    handleAddForm("address", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Email *</b>
                </FormLabel>
                <TextField
                  variant="outlined"
                  placeholder="Email"
                  className="w-100"
                  required
                  value={addform?.email}
                  error={errors.email}
                  helperText={errors.email}
                  size="small"
                  onChange={(e) => {
                    handleAddForm("email", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Password *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  placeholder="Password"
                  type="password"
                  inputProps={{
                    maxLength: 20,
                  }}
                  variant="outlined"
                  value={addform?.password}
                  error={errors.password}
                  helperText={errors.password}
                  size="small"
                  required
                  onChange={(e) => {
                    handleAddForm("password", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </Box>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-end gap-3 my-4">
        <Link to="/tenantManagement" className="cancle-button">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          color="primary"
          variant="contained"
          className="submit-btn"
          onClick={handleErrors}
        >
          Add
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        handleClosePopUp={handleAlertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handelAlertOk}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={statusFalsePop}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleStatusFalsePop}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
