import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Popper,
  Switch,
  TextField,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import {
  allowLetters,
  formatDateForInput,
  handleKeyDown,
} from "../../common/helpers";
import SessionExpire from "../../components/SessionExpire";
import { basePathone } from "../../common/config";
import PopUp from "../../components/PopUp";
import RelatedLocationPop from "./RelatedLocationsPop";
import RegularHoursPop from "./RegularHoursPop";
import EnergySourcesPopUp from "./EnergySourcesPopUp";
import EnvironImpactPopUp from "./EnvironImpactPopUp";
import DirectionsPOP from "./DirectionsPOP";
import MapComponent from "../../MapFunction";
import FullLoader from "../../components/FullLoader";
import { headers } from "../../common/Headers";
import { handleApi_call } from "../../common/CommonAPI";

function LocationUpdate({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [timeZoneList, setTimeZoneList] = React.useState([]);
  const [parkingTypeList, setParkingTypeLIst] = React.useState([]);
  const [facilitiesList, setFacilitiesList] = React.useState([]);
  const [environImpactList, setEnvironImpactList] = React.useState([]);
  const [environImpactObj, setEnvironImpactObj] = React.useState({
    source: "",
    amount: "",
  });
  const [EiErrors, setEiErrors] = React.useState({});
  const [energySourceDrop, setEnergySourceDrop] = React.useState([]);
  const [categoryDrop, setCategoryDrop] = React.useState([]);
  const [eIEditPopUp, setEIEditPopUp] = React.useState(false);
  const [rLocationPopUp, setRLocationPopUp] = React.useState(false);
  const [rLocationEditPopUp, setRLocationEditPopUp] = React.useState(false);
  const [relatedLocationsList, setRelatedLocationsList] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const [rLErrors, setRLErrors] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [rHoursPopUp, setRHoursPopUp] = React.useState(false);
  const [rHoursEditPopUp, setRHoursEditPopUp] = React.useState(false);
  const [rHoursList, setRHoursList] = React.useState([]);
  const [energySourceList, setEnergySourceList] = React.useState([]);
  const [energySourceObj, setEnergySourceObj] = React.useState({
    energSource: "",
    percentage: "",
  });
  const [EsErrors, setEsErrors] = React.useState({});
  const [energySourceAddPop, setEnergySourceAddPop] = React.useState(false);
  const [environmentImpactPop, setEnvironmentImpactPop] = React.useState(false);
  const [rHoursObj, setRHoursObj] = React.useState({
    weekday: "",
    periodBegin: "",
    periodEnd: "",
  });
  const [directionsObj, setDirectionsObj] = React.useState({
    language: "En",
    text: "",
  });
  const [directionsList, setDirectionsList] = React.useState([]);
  const [inPageAlertPopup, setInPageAlertPopup] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(false);
  const [mapOpen, setMapOpen] = React.useState(false);
  const [rlocationObj, setRLocationObj] = React.useState({
    longitude: "",
    latitude: "",
    name: {
      language: "EN",
      text: "",
    },
  });
  const [rHErrors, setRHErrors] = React.useState({});
  const [energyMixObj, setEnergyMixObj] = React.useState({
    supplier_name: "",
    energy_product_name: "",
    is_green_energy: "",
  });
  const [eSEditPopUp, setESEditPopUp] = React.useState(false);
  const [openingTimesObj, setOpeningTimesObj] = React.useState({
    twentyfourseven: "",
    exceptionalOpenings: {
      periodBegin: "",
      periodEnd: "",
    },
    exceptionalClosings: {
      periodBegin: "",
      periodEnd: "",
    },
  });
  const [locationFields, setLocationFields] = React.useState({
    name: "",
    address: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    publish: false,
    charging_when_closed: false,
    time_zone: "",
    parkingType: "",
    energy_mix: "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
    facilities: [],
    opening_times: {},
  });
  React.useEffect(() => {
    getTimezones();
    getparkingType();
    getFacilities();
    getLocationById();
    getRelatedLocationByLocId();
    getDirectionsByLocId();
    getRHByOTIdAndLocId();
    getESByMixIdAndLocId();
    getEIByMixIdAndLocId();
    getCategory();
    getEnergySources();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getTimezones();
      getparkingType();
      getFacilities();
      getLocationById();
      getRelatedLocationByLocId();
      getDirectionsByLocId();
      getRHByOTIdAndLocId();
      getESByMixIdAndLocId();
      getEIByMixIdAndLocId();
      getCategory();
      getEnergySources();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  //get  Location by LocId
  const getLocationById = async () => {
    let Url = `api/locations/${location.state?.id}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      locationFields.name = res.data?.name;
      locationFields.city = res.data?.city;
      locationFields.state = res.data?.state;
      locationFields.address = res.data?.address;
      locationFields.postalCode = res.data?.postalCode;
      locationFields.country = res.data?.country;
      locationFields.publish = res.data?.publish;
      locationFields.charging_when_closed = res.data?.charging_when_closed;
      locationFields.coordinates.latitude = res.data?.coordinates?.latitude;
      locationFields.coordinates.longitude = res.data?.coordinates?.longitude;
      locationFields.parkingType = res.data?.parkingType;
      locationFields.time_zone = res.data?.time_zone;
      locationFields.facilities = res.data?.facilities;
      locationFields.opening_times = res.data?.opening_times;
      setEnergyMixObj(
        res.data.energy_mix === null ? energyMixObj : res.data.energy_mix
      );
      setOpeningTimesObj(res.data?.opening_times);
      openingTimesObj.exceptionalClosings =
        res.data?.opening_times?.exceptionalClosings;
      openingTimesObj.exceptionalOpenings =
        res.data?.opening_times?.exceptionalOpenings;
      console.log(res.data?.opening_times);
    }
  };
  // Related Locations All
  const [rocId, setRocId] = React.useState();
  //get Releted Location by location Id
  const getRelatedLocationByLocId = async () => {
    let Url = `api/locations/getAllRelatedLocations/${location.state?.id}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      setRelatedLocationsList(res?.data);
    }
  };
  //Add Related Locations by Location Id
  const addRelatedLocationByLocId = async () => {
    let Url = `api/locations/addRelatedLocation/${location.state?.id}`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      rlocationObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Update Related Locations by LocationId and record Id
  const updateRelatedLocationByLocId = async () => {
    let Url = `api/locations/updateRelatedLocation/${location.state?.id}`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      rlocationObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Delete RelatedLocations by LocationId and recordId
  const deleteRelatedLocationByLocId = async (id) => {
    let Url = `api/locations/deleteRelatedLocation/${location.state?.id}/${id}`;
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //
  //Directions All Apis
  //get Directions by location Id
  const getDirectionsByLocId = async () => {
    let Url = `api/locations/getAllDirections/${location.state?.id}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setDirectionsList(res?.data);
    }
  };
  //Add Related Locations by Location Id
  const addDirectionsByLocId = async () => {
    let Url = `api/locations/addDirection/${location.state?.id}`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      directionsObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Update Related Locations by LocationId and record Id
  const updateDirectionsByLocId = async () => {
    let Url = `api/locations/updateDirection/${location.state?.id}`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      directionsObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Delete RelatedLocations by LocationId and recordId
  const deleteDirectionsByLocId = async (id) => {
    let Url = `api/locations/deleteDirection/${location.state?.id}/${id}`;
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };

  //Energy-Mix EnergySource All Apis
  //ES-EnergySources
  //Get Energy sources by energy-mix id and Location Id
  const getESByMixIdAndLocId = async () => {
    let Url = `api/locations/getEnergySource/${location.state?.id}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      setEnergySourceList(res?.data);
    }
  };
  //Add EnergySources By mix id And Location Id
  const addESByMixIdAndLocId = async () => {
    let Url = `api/locations/addEnergySource/${location.state?.id}`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      energySourceObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Update energy sources By rocId and enrgymixId and Loc Id
  const updateESByMixIdRocIdAndLocId = async () => {
    let Url = `api/locations/updateEnergySource/${location.state?.id}`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      energySourceObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Delete EnergySource By rocId and mixId and Loc
  const DeleteESByRocIdmixIdAndLocId = async (id) => {
    let Url = `api/locations/deleteEnergySource/${location.state?.id}/${id}`;
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //
  //EI-EnvironImpact Apis
  //   const [energyMixId, setEnergyMixId] = React.useState();
  //Get Energy sources by energy-mix id and Location Id
  const getEIByMixIdAndLocId = async () => {
    let Url = `api/locations/getAllEnvImpactList/${location.state?.id}/${location.state?.energy_mix?.id}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setEnvironImpactList(res?.data);
    }
  };
  //Add EnergySources By mix id And Location Id
  const addEIByMixIdAndLocId = async () => {
    let Url = `api/locations/addEnvImpact/${location.state?.id}/${location.state?.energy_mix?.id}`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      environImpactObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Update energy sources By rocId and enrgymixId and Loc Id
  const updateEIByMixIdRocIdAndLocId = async () => {
    let Url = `api/locations/updateEnvImpact/${location.state?.id}/${location.state?.energy_mix?.id}`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      environImpactObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Delete EnergySource By rocId and mixId and Loc
  const DeleteEIByRocIdmixIdAndLocId = async (id) => {
    let Url = `api/locations/deleteEnvImpact/${location.state?.id}/${location.state?.energy_mix?.id}/${id}`;
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      environImpactObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //
  // Regular Hours All apis
  //RH-Regular Hours
  //get Reguler Hours by openings times id and location Id
  const getRHByOTIdAndLocId = async () => {
    let Url = `api/locations/getAllRegularHours/${location.state?.id}/${location.state?.opening_times?.id}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setRHoursList(res?.data);
    }
  };
  //Add Reguler Hours by openings times id and  Location Id
  const addRHByOTIdAndLocId = async () => {
    let Url = `api/locations/addRegularHour/${location.state?.id}/${location.state?.opening_times?.id}`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      rHoursObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Update Reguler Hours by openings times id and  record Id % LocId
  const updateRHByLocId = async () => {
    let Url = `api/locations/updateRegularHours/${location.state?.id}/${location.state?.opening_times?.id}`;
    let type = "PUT";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      rHoursObj,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //Delete  Reguler Hours by openings times id and  record Id & LocId

  const deleteRHByLocId = async (id) => {
    let Url = `api/locations/deleteRegularHours/${location.state?.id}/${location.state?.opening_times?.id}/${id}`;
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleInPageAlertPopup();
    }
  };
  //
  // All drop Down Apis
  const getEnergySources = async () => {
    let Url = `cpo/${2.1}/tariffs/getEnergySource`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setEnergySourceDrop(res?.data);
    }
  };
  const getCategory = async () => {
    let Url = `cpo/${2.1}/tariffs/getEnvironmentalImpact`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setCategoryDrop(res?.data);
    }
  };
  const getFacilities = async () => {
    let Url = `api/locations/getFacilities`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setFacilitiesList(res?.data);
    }
  };
  const getparkingType = async () => {
    let Url = `api/locations/getParkingType`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setParkingTypeLIst(res?.data);
    }
  };
  const getTimezones = async () => {
    let Url = `api/locations/getTimeZone`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setTimeZoneList(res?.data);
    }
  };

  // All Error Validations
  function RLocationValiDationForm() {
    const errors = {};
    if (!rlocationObj.latitude) errors.latitude = "Latitude is required";
    if (!rlocationObj.longitude) errors.longitude = "Longitude is required";
    if (!longitudeRegex.test(rlocationObj.latitude))
      errors.latitude = "Enter valid latitude";
    if (!longitudeRegex.test(rlocationObj.longitude))
      errors.longitude = "Enter valid longitude";
    if (!rlocationObj.name.text) errors.text = "text is required";
    setRLErrors(errors);
    return Object.keys(errors).length === 0; // return true or false
  }

  function RHoursValiDationForm() {
    const errors = {};
    if (!rHoursObj.weekday) errors.weekday = "Please select Weekday ";
    if (!rHoursObj.periodBegin)
      errors.periodBegin = "Please select periodBegin ";
    if (!rHoursObj.periodEnd) errors.periodEnd = "Please select periodEnd ";
    setRHErrors(errors);
    return Object.keys(errors).length === 0; // return true or false
  }

  const handleEsSubmit = () => {
    const errors = {};
    if (!energySourceObj.energSource)
      errors.energSource = "please select Source";
    if (!energySourceObj.percentage)
      errors.percentage = " percentage is required";
    setEsErrors(errors);
    return Object.keys(errors).length === 0; // return true or false
  };

  const handleEIFormValidations = () => {
    const errors = {};
    if (!environImpactObj.source) errors.source = "please select Source";
    if (!environImpactObj.amount) errors.amount = " amount is required";
    setEiErrors(errors);
    return Object.keys(errors).length === 0; // return true or false
  };
  const minLength = 4;
  const maxLength = 10;
  const longitudeRegex = /^-?[0-9]{1,3}\.[0-9]{5,20}$/;
  const validateForm = () => {
    const newErrors = {};
    if (!locationFields.name) newErrors.name = "Name is required";
    if (!locationFields.address) newErrors.address = "Address is required";
    if (!locationFields.country) newErrors.country = "Country is required";
    if (!locationFields.state) newErrors.state = "State is required";
    if (!locationFields.city) newErrors.city = "City is required";
    if (!locationFields.postalCode)
      newErrors.postalCode = "Postal Code is required";
    if (!locationFields.time_zone)
      newErrors.time_zone = "Time Zone is required";
    if (!locationFields.parkingType)
      newErrors.parkingType = "Parking Type is required";
    if (!locationFields.coordinates.latitude)
      newErrors.latitude = "Latitude is required";
    if (!locationFields.coordinates.longitude)
      newErrors.longitude = "Longitude is required";
    if (!longitudeRegex.test(locationFields.coordinates.latitude))
      newErrors.latitude = "Enter valid latitude";
    if (!longitudeRegex.test(locationFields.coordinates.longitude))
      newErrors.longitude = "Enter valid longitude";
    //
    if (/^\d*$/.test(locationFields.postalCode) && locationFields.postalCode) {
      if (locationFields.postalCode.length > maxLength) {
        newErrors.postalCode = "please enter valid PostalCode";
      } else if (locationFields.postalCode.length < minLength) {
        newErrors.postalCode = "please enter valid PostalCode";
      }
    }
    //
    //energy mix Validations
    if (!energyMixObj?.supplier_name)
      newErrors.supplier_name = "Supplier Name is Required";
    if (!energyMixObj?.energy_product_name)
      newErrors.energy_product_name = "Product Name is required";

    // Validate facilities
    if (
      !Array.isArray(locationFields.facilities) ||
      locationFields.facilities.length === 0 ||
      locationFields.facilities.some((f) => !f.trim())
    ) {
      newErrors.facilities = "At least one facility must be provided";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const directionsValidations = () => {
    let errors = {};
    if (!directionsObj.text) errors.text = "text is required";
    setDirErrors(errors);
    return Object.keys(errors).length === 0; //true or false
  };

  //
  //all pop Ups functions
  //
  const handleOpenmap = () => {
    setMapOpen((open) => !open);
  };
  function handleInPageAlertPopup() {
    setInPageAlertPopup((pop) => !pop);
  }
  function callAllList() {
    setInPageAlertPopup((pop) => !pop);
    getDirectionsByLocId();
    getEIByMixIdAndLocId();
    getESByMixIdAndLocId();
    getRHByOTIdAndLocId();
    getRelatedLocationByLocId();
  }
  function handleRLPop() {
    setRLocationPopUp((pop) => !pop);
    setRLErrors({
      latitude: "",
      longitude: "",
      name: {
        text: "",
      },
    });
    setRLocationObj({
      latitude: "",
      longitude: "",
      name: {
        language: "EN",
        text: "",
      },
    });
  }

  function handleRHPop() {
    setRHErrors({
      weekday: "",
      periodBegin: "",
      periodEnd: "",
    });
    setRHoursObj({
      weekday: "",
      periodBegin: "",
      periodEnd: "",
    });
    setRHoursPopUp((pop) => !pop);
  }

  function handleESPop() {
    setEsErrors({
      energSource: "",
      percentage: "",
    });
    setEnergySourceObj({
      energSource: "",
      percentage: "",
    });
    setEnergySourceAddPop((pop) => !pop);
  }
  const [directionPop, setDirectionPop] = React.useState(false);
  const [directionEditPop, setDirectionEditPop] = React.useState(false);
  const [dirErrors, setDirErrors] = React.useState({
    text: "",
  });
  function handleDirectionPop() {
    setDirErrors({
      language: "",
      text: "",
    });
    setDirectionsObj({
      language: "En",
      text: "",
    });
    setDirectionPop((pop) => !pop);
  }
  function handleDirectionEditPop() {
    setDirectionEditPop((pop) => !pop);
    setDirErrors({
      language: "",
      text: "",
    });
  }
  function handleEIPop() {
    setEiErrors({
      source: "",
      amount: "",
    });
    setEnvironImpactObj({
      source: "",
      amount: "",
    });
    setEnvironmentImpactPop((pop) => !pop);
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  //
  function handleRLEditPop() {
    setRLocationEditPopUp((pop) => !pop);
    setRLErrors({
      latitude: "",
      longitude: "",
      name: {
        text: "",
      },
    });
  }
  function handleRHEditPop() {
    setRHoursEditPopUp((pop) => !pop);
    setRHErrors({
      weekday: "",
      periodBegin: "",
      periodEnd: "",
    });
  }
  function handleEsEditPop() {
    setESEditPopUp((pop) => !pop);
    setEsErrors({
      energSource: "",
      percentage: "",
    });
  }
  function handleEIEditPop() {
    setEIEditPopUp((pop) => !pop);
    setEiErrors({
      source: "",
      amount: "",
    });
  }
  //

  function handleRHEditingPopUp(list, id) {
    setRHoursObj(list);
    setRocId(id);
    handleRHEditPop();
  }

  function handleEsEditingPopUp(list) {
    setEnergySourceObj(list);
    setRocId(list.id);
    handleEsEditPop();
  }

  function handleEIEditingPopUp(list) {
    setEnvironImpactObj(list);
    setRocId(list.id);
    handleEIEditPop();
  }
  function handleRLEditingPopUp(list, id) {
    setRLocationObj(list);
    setRocId(id);
    handleRLEditPop();
  }
  function handleDirEditingPopUp(list, id) {
    setDirectionsObj(list);
    setRocId(id);
    handleDirectionEditPop();
  }

  //All calling Api functions
  const addDirections = (e) => {
    e.preventDefault();
    if (directionsValidations()) {
      addDirectionsByLocId();
      handleDirectionPop();
      setDirectionsObj({
        language: "En",
        text: "",
      });
    }
  };
  const editDirections = (e) => {
    e.preventDefault();
    if (directionsValidations()) {
      updateDirectionsByLocId();
      handleDirectionEditPop();
      setDirectionsObj({
        language: "En",
        text: "",
      });
    }
  };

  const handleRLSubmit = (e) => {
    e.preventDefault();
    if (RLocationValiDationForm()) {
      addRelatedLocationByLocId();
      handleRLPop();
      setRLocationObj({
        longitude: "",
        latitude: "",
        name: {
          language: "EN",
          text: "",
        },
      });
    }
  };
  const handleRLEditSubmit = (e) => {
    e.preventDefault();
    if (RLocationValiDationForm()) {
      updateRelatedLocationByLocId();
      handleRLEditPop();
      setRLocationObj({
        longitude: "",
        latitude: "",
        name: {
          language: "EN",
          text: "",
        },
      });
    }
  };
  const handleRHSubmit = (e) => {
    e.preventDefault();
    if (RHoursValiDationForm()) {
      addRHByOTIdAndLocId();
      handleRHPop();
      setRHoursObj({
        weekday: "",
        periodBegin: "",
        periodEnd: "",
      });
    }
  };
  const handleRHEditSubmit = (e) => {
    e.preventDefault();
    if (RHoursValiDationForm()) {
      updateRHByLocId();
      handleRHEditPop();
      setRHoursObj({
        weekday: "",
        periodBegin: "",
        periodEnd: "",
      });
    }
  };
  const ESvalidateForm = () => {
    if (handleEsSubmit()) {
      addESByMixIdAndLocId();
      handleESPop();
      setEnergySourceObj({
        energSource: "",
        percentage: "",
      });
    }
  };
  const ESEditvalidateForm = () => {
    if (handleEsSubmit()) {
      updateESByMixIdRocIdAndLocId();
      handleEsEditPop();
      setEnergySourceObj({
        energSource: "",
        percentage: "",
      });
    }
  };
  const EIvalidateForm = () => {
    if (handleEIFormValidations()) {
      addEIByMixIdAndLocId();
      handleEIPop();
      setEnvironImpactObj({
        source: "",
        amount: "",
      });
    }
  };
  const EIEditvalidateForm = () => {
    if (handleEIFormValidations()) {
      updateEIByMixIdRocIdAndLocId();
      handleEIEditPop();
      setEnvironImpactObj({
        source: "",
        amount: "",
      });
    }
  };

  //All Onchange functions
  //
  function handleDirectionschange(key, value) {
    setDirErrors({ ...dirErrors, [key]: "" });
    setDirectionsObj({ ...directionsObj, [key]: value });
  }

  function handleRlChange(key, value) {
    setRLErrors({ ...rLErrors, [key]: "" });
    if (key === "text") {
      setRLocationObj((prevState) => ({
        ...prevState,
        name: { ...prevState.name, text: value },
      }));
    } else {
      setRLocationObj({
        ...rlocationObj,
        [key]: value,
      });
      let valid = longitudeRegex.test(value);
      if (valid) {
        setRLErrors({ ...rLErrors, [key]: "" });
      } else {
        setRLErrors({ ...rLErrors, [key]: "Enter valid value ex:21.032145" });
      }
    }
  }

  function handleEnergyMinChange(key, value) {
    setErrors({ ...errors, [key]: "" });
    setEnergyMixObj({
      ...energyMixObj,
      [key]: value,
    });
  }
  function handleRHChange(key, value) {
    setRHErrors({ ...rHErrors, [key]: "" });
    if (key === "weekday") {
      setRHoursObj({
        ...rHoursObj,
        [key]: value,
      });
    } else {
      setRHoursObj((prev) => ({
        ...prev,
        [key]:
          typeof value === "object" && value.format
            ? value.format("HH:mm")
            : value, // Only format if it's a `dayjs` object
      }));
    }
  }
  function handleESchange(key, value) {
    setEsErrors({ ...EsErrors, [key]: "" });
    setEnergySourceObj({
      ...energySourceObj,
      [key]: value,
    });
  }
  function handleEIchange(key, value) {
    setEiErrors({ ...EiErrors, [key]: "" });
    setEnvironImpactObj({
      ...environImpactObj,
      [key]: value,
    });
  }

  const handleChange = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setLocationFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCoordinatesChange = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setLocationFields((prevState) => ({
      ...prevState,
      coordinates: {
        ...prevState.coordinates,
        [name]: value,
      },
    }));
    let valid = longitudeRegex.test(value);
    if (valid) {
      setErrors({ ...errors, [name]: "" });
    } else {
      if (name === "latitude")
        setErrors({ ...errors, [name]: "Enter valid latitude" });
      else setErrors({ ...errors, [name]: "Enter valid longitude" });
    }
  };
  const handleFacilitiesChange = (name, newValue) => {
    setErrors({ ...errors, [name]: "" });
    setLocationFields((prevState) => ({
      ...prevState,
      [name]: Array.isArray(newValue) ? newValue : [], // Ensure `newValue` is an array
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let locFields = locationFields;
      locFields.energy_mix = energyMixObj;
      locFields.opening_times = openingTimesObj;
      let Url = `api/locations/${location.state?.id}`;
      let type = "PUT";
      let res = await handleApi_call(
        Url,
        headers,
        type,
        locFields,
        setSessionExpire,
        setAlertMessage,
        handleAlertPopup,
        setLoader
      );
      if (res?.statusCode === 1000) {
        setStatusMessage("SUCCESS");
        setAlertMessage(res?.data);
        handleAlertPopup();
      } else {
        setStatusMessage("FAILURE");
        setAlertMessage(res?.data);
        handleAlertPopup();
      }
    }
  };
  React.useEffect(() => {
    if (
      locationFields.coordinates.latitude &&
      locationFields.coordinates.longitude
    ) {
      getDetailsByLatLongitude();
      getTimeZonesWithApi();
    }
  }, [locationFields.coordinates]);
  const getTimeZonesWithApi = () => {
    fetch(
      `https://api.wheretheiss.at/v1/coordinates/${locationFields.coordinates.latitude},${locationFields.coordinates.longitude}`
    )
      .then((response) => response.json())
      .then((res) => {
        // let locData = locationFields;
        // locData.time_zone = res?.timezone_id;
        // console.log(res?.timezone_id);
        // const zoneName = timeZoneList.filter(
        //   (list) => list.zoneId === res?.timezone_id
        // );
        // locData.time_zone = zoneName[0]?.zoneName;
        const zoneName = timeZoneList.filter(
          (list) => list.zoneId === res?.timezone_id
        );
        setLocationFields((prevData) => ({
          ...prevData,
          time_zone: zoneName[0]?.zoneName,
        }));
      });
  };
  const getDetailsByLatLongitude = () => {
    fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${locationFields.coordinates.latitude}&lon=${locationFields.coordinates.longitude}`
    )
      .then((response) => response.json())
      .then((res) => {
        // let locData = locationFields;
        // locData.address = res?.display_name;
        // locData.city = res?.address?.city ?? "";
        // locData.country = res?.address?.country;
        // locData.state = res?.address?.state;
        // locData.postalCode = res?.address?.postcode;
        setLocationFields((prevData) => ({
          ...prevData,
          address: res?.display_name,
          city: res?.address?.city ?? "",
          country: res?.address?.country,
          state: res?.address?.state,
          postalCode: res?.address?.postcode,
        }));
      });
  };
  return (
    <div className="mb-3">
      <div className="pagetitle">Update Location</div>
      <div
        sx={{ minWidth: 275 }}
        style={{
          borderRadius: "25px",
          marginTop: "14px",
          backgroundColor: "white",
        }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Location Name *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 30,
                  }}
                  onKeyDown={handleKeyDown}
                  value={locationFields?.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  error={errors.name}
                  helperText={errors.name}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>City *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 30,
                  }}
                  onKeyDown={handleKeyDown}
                  value={locationFields.city}
                  onChange={(e) => handleChange("city", e.target.value)}
                  error={errors.city}
                  helperText={errors.city}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>State *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 30,
                  }}
                  onKeyDown={handleKeyDown}
                  value={locationFields.state}
                  onChange={(e) => handleChange("state", e.target.value)}
                  error={errors.state}
                  helperText={errors.state}
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Address *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  name="address"
                  inputProps={{
                    minLength: 5,
                    maxLength: 100,
                  }}
                  value={locationFields.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  error={errors.address}
                  helperText={errors.address}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Postal Code *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                  name="postalCode"
                  value={locationFields.postalCode}
                  onChange={(e) => handleChange("postalCode", e.target.value)}
                  error={errors.postalCode}
                  helperText={errors.postalCode}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Country *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 30,
                  }}
                  onKeyDown={handleKeyDown}
                  value={locationFields.country}
                  onChange={(e) => handleChange("country", e.target.value)}
                  error={errors.country}
                  helperText={errors.country}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Parking Type *</b>
                </FormLabel>
                <TextField
                  required
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={locationFields.parkingType}
                  onChange={(e) => handleChange("parkingType", e.target.value)}
                  error={errors.parkingType}
                  helperText={errors.parkingType}
                >
                  {parkingTypeList.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Time Zone *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={locationFields.time_zone}
                  onChange={(e) => handleChange("time_zone", e.target.value)}
                  error={errors.time_zone}
                  helperText={errors.time_zone}
                ></TextField>
              </FormControl>
            </div>
            <div className="col-4 position-relative">
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={facilitiesList}
                disablePortal
                getOptionLabel={(option) => option}
                value={locationFields.facilities}
                onChange={(event, newValue) =>
                  handleFacilitiesChange("facilities", newValue)
                }
                renderInput={(params) => (
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Select Facilities *</b>
                    </FormLabel>
                    <TextField
                      {...params}
                      placeholder="Facilities"
                      error={Boolean(errors.facilities)}
                      helperText={errors.facilities}
                    />
                  </FormControl>
                )}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      width: "calc(100% - 16px)",
                      marginLeft: "6px",
                      marginTop: "6px",
                    }}
                    placement="bottom-start"
                  />
                )}
              />
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Latitude *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={locationFields?.coordinates?.latitude}
                  onChange={(e) =>
                    handleCoordinatesChange("latitude", e.target.value)
                  }
                  error={errors.latitude}
                  helperText={errors.latitude}
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Longitude *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={locationFields?.coordinates?.longitude}
                  onChange={(e) =>
                    handleCoordinatesChange("longitude", e.target.value)
                  }
                  error={errors.longitude}
                  helperText={errors.longitude}
                />
              </FormControl>
            </div>
            <div className="col-4 d-flex align-items-end">
              <button
                className=" d-flex align-items-center justify-content-center selectMap gap-2"
                onClick={handleOpenmap}
              >
                <img
                  src="map-icon-new.png"
                  alt="map-icon"
                  className="h-24-w-24"
                />
                <span>Select on Map</span>
              </button>
            </div>

            <div className="col-4">
              <FormLabel>
                <b>Publish :</b>
              </FormLabel>
              <Switch
                checked={locationFields.publish}
                onChange={(e) => handleChange("publish", e.target.checked)}
                size="small"
              />
            </div>

            <div className="col-4">
              <FormLabel>
                <b>Charging When Closed :</b>
              </FormLabel>

              <Switch
                checked={locationFields.charging_when_closed}
                onChange={(e) =>
                  handleChange("charging_when_closed", e.target.checked)
                }
                size="small"
              />
            </div>
          </div>
        </CardContent>
      </div>
      <div>
        <div>
          <div className="d-flex justify-content-between mt-3">
            <div className=" mx-0 p-0 pagetitle">Related Locations</div>

            <div
              className="Export-Button bgcolor-blue cursor-Pointer"
              onClick={handleRLPop}
            >
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </div>
          <Card
            sx={{ minWidth: 275 }}
            style={{ borderRadius: "25px", marginTop: "14px" }}
          >
            <CardContent className="cardPadding">
              <table class="table table-box-shadow mt-3">
                <thead>
                  <tr class="table-primary">
                    <th scope="col">Latitude</th>
                    <th scope="col">Longitude</th>
                    <th scope="col">Language</th>
                    <th scope="col">Text</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {relatedLocationsList?.length > 0 ? (
                  <tbody>
                    {relatedLocationsList?.map((list, index) => (
                      <tr key={index}>
                        <td>{list?.latitude}</td>
                        <td>{list?.longitude}</td>
                        <td>{list?.name?.language}</td>
                        <td>{list?.name?.text}</td>

                        <td>
                          <span
                            onClick={() => handleRLEditingPopUp(list, list.id)}
                          >
                            <i className="fa-solid fa-pencil action-icons"></i>
                          </span>

                          <span
                            onClick={() =>
                              deleteRelatedLocationByLocId(list.id)
                            }
                            style={{ color: "red" }}
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody className="text-center">
                    <tr>
                      <th colspan="5">No Data</th>
                    </tr>
                  </tbody>
                )}
              </table>
            </CardContent>
          </Card>
        </div>
        <div>
          <div className="d-flex justify-content-between mt-3">
            <div className=" mx-0 p-0 pagetitle">Directions</div>

            <div
              className="Export-Button bgcolor-blue cursor-Pointer"
              onClick={handleDirectionPop}
            >
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </div>
          <Card
            sx={{ minWidth: 275 }}
            style={{ borderRadius: "25px", marginTop: "14px" }}
          >
            <CardContent className="cardPadding">
              <table class="table table-box-shadow mt-3 ">
                <thead>
                  <tr class="table-primary">
                    <th scope="col">Language</th>
                    <th scope="col">Text</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {directionsList?.length > 0 ? (
                  <tbody>
                    {directionsList?.map((list, index) => (
                      <tr key={index}>
                        <td>{list?.language}</td>
                        <td>{list?.text}</td>
                        <td>
                          <span
                            onClick={() => handleDirEditingPopUp(list, list.id)}
                          >
                            <i className="fa-solid fa-pencil action-icons"></i>
                          </span>

                          <span
                            onClick={() => deleteDirectionsByLocId(list.id)}
                            style={{ color: "red" }}
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody className="text-center">
                    <tr>
                      <th colspan="5">No Data</th>
                    </tr>
                  </tbody>
                )}
              </table>
            </CardContent>
          </Card>
        </div>

        <div className="d-flex justify-content-start">
          <div className=" mt-3 p-0 pagetitle ">Opening Times</div>
        </div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <div className="col-4 fs-17 ">
              <b>Twenty Four Seven :</b>
              <Switch
                checked={openingTimesObj?.twentyfourseven}
                onChange={(e) =>
                  setOpeningTimesObj((obj) => ({
                    ...obj,
                    twentyfourseven: e.target.checked,
                  }))
                }
                size="small"
              />
            </div>

            <div>
              <div className="d-flex justify-content-between my-3">
                <div className=" mx-0 p-0 pagetitle ">Regular Hours</div>

                <div
                  className="Export-Button bgcolor-blue cursor-Pointer"
                  onClick={handleRHPop}
                >
                  <i class="fa-solid fa-plus" />
                  Add
                </div>
              </div>

              <table class="table table-box-shadow">
                <thead>
                  <tr class="table-primary">
                    <th scope="col">WeekDay</th>
                    <th scope="col">Period Begin</th>
                    <th scope="col">Period End</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {rHoursList?.length > 0 ? (
                  <tbody>
                    {rHoursList?.map((list, index) => (
                      <tr key={index}>
                        <td>{list?.weekday}</td>
                        <td>{list?.periodBegin}</td>
                        <td>{list?.periodEnd}</td>
                        <td>
                          <span
                            onClick={() => handleRHEditingPopUp(list, list.id)}
                          >
                            <i className="fa-solid fa-pencil action-icons"></i>
                          </span>

                          <span
                            onClick={() => deleteRHByLocId(list.id)}
                            style={{ color: "red" }}
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody className="text-center">
                    <tr>
                      <th colspan="5">No Data</th>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
            <div className="d-flex justify-content-start">
              <div className=" my-3 p-0 pagetitle ">Exceptional Openings</div>
            </div>
            <div className="row">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <div className="my-1 fs-15 fw-600">
                    <span>Period Begin *</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="px-3 fs-17"
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      border: "1px solid gainsboro",
                    }}
                    value={
                      openingTimesObj?.exceptionalOpenings?.periodBegin
                        ? formatDateForInput(
                            openingTimesObj?.exceptionalOpenings?.periodBegin
                          )
                        : ""
                    }
                    onChange={(e) => {
                      setOpeningTimesObj((obj) => ({
                        ...obj,
                        exceptionalOpenings: {
                          ...obj.exceptionalOpenings,
                          periodBegin: e.target.value,
                        },
                      }));
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <div className="my-1 fs-15 fw-600">
                    <span>Period End *</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="px-3 fs-17"
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      border: "1px solid gainsboro",
                    }}
                    value={
                      openingTimesObj?.exceptionalOpenings?.periodEnd
                        ? formatDateForInput(
                            openingTimesObj?.exceptionalOpenings?.periodEnd
                          )
                        : ""
                    }
                    onChange={(e) => {
                      setOpeningTimesObj((obj) => ({
                        ...obj,
                        exceptionalOpenings: {
                          ...obj.exceptionalOpenings,
                          periodEnd: e.target.value,
                        },
                      }));
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div className=" my-3 p-0 pagetitle ">Exceptional Closings</div>
            </div>
            <div className="row">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <div className="my-1 fs-15 fw-600">
                    <span>Period Begin *</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="px-3 fs-17"
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      border: "1px solid gainsboro",
                    }}
                    value={
                      openingTimesObj?.exceptionalClosings?.periodBegin
                        ? formatDateForInput(
                            openingTimesObj?.exceptionalClosings?.periodBegin
                          )
                        : ""
                    }
                    onChange={(e) => {
                      setOpeningTimesObj((obj) => ({
                        ...obj,
                        exceptionalClosings: {
                          ...obj.exceptionalClosings,
                          periodBegin: e.target.value,
                        },
                      }));
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <div className="my-1 fs-15 fw-600">
                    <span>Period End *</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="px-3 fs-17"
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      border: "1px solid gainsboro",
                    }}
                    value={
                      openingTimesObj?.exceptionalClosings?.periodEnd
                        ? formatDateForInput(
                            openingTimesObj?.exceptionalClosings?.periodEnd
                          )
                        : ""
                    }
                    onChange={(e) => {
                      setOpeningTimesObj((obj) => ({
                        ...obj,
                        exceptionalClosings: {
                          ...obj.exceptionalClosings,
                          periodEnd: e.target.value,
                        },
                      }));
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </CardContent>
        </Card>
        <div className="d-flex justify-content-start">
          <div className=" mt-3 pagetitle">Energy Mix Configurations</div>
        </div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <div className="row">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Product Name *</b>
                  </FormLabel>
                  <TextField
                    className="w-100"
                    variant="outlined"
                    size="small"
                    inputProps={{
                      minLength: 5,
                      maxLength: 25,
                    }}
                    onKeyDown={allowLetters}
                    value={energyMixObj?.energy_product_name}
                    onChange={(e) =>
                      handleEnergyMinChange(
                        "energy_product_name",
                        e.target.value
                      )
                    }
                    error={errors.energy_product_name}
                    helperText={errors.energy_product_name}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Supplier Name *</b>
                  </FormLabel>
                  <TextField
                    className="w-100"
                    variant="outlined"
                    size="small"
                    inputProps={{
                      minLength: 5,
                      maxLength: 25,
                    }}
                    onKeyDown={allowLetters}
                    value={energyMixObj?.supplier_name}
                    onChange={(e) =>
                      handleEnergyMinChange("supplier_name", e.target.value)
                    }
                    error={errors.supplier_name}
                    helperText={errors.supplier_name}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Green Energy :</b>

                    <Switch
                      checked={energyMixObj?.is_green_energy}
                      onChange={(e) =>
                        handleEnergyMinChange(
                          "is_green_energy",
                          e.target.checked
                        )
                      }
                      size="small"
                    />
                  </FormLabel>
                </FormControl>
              </div>
            </div>
          </CardContent>
        </Card>
        <div>
          <div className="d-flex justify-content-between mt-3">
            <div className="pagetitle">Energy Sources</div>

            <div
              className="Export-Button bgcolor-blue cursor-Pointer"
              onClick={handleESPop}
            >
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </div>
          <Card
            sx={{ minWidth: 275 }}
            style={{ borderRadius: "25px", marginTop: "14px" }}
          >
            <CardContent className="cardPadding">
              <table class="table table-box-shadow mt-4">
                <thead>
                  <tr class="table-primary">
                    <th scope="col">Source</th>
                    <th scope="col">Percentage</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {energySourceList?.length > 0 ? (
                  <tbody>
                    {energySourceList?.map((list, index) => (
                      <tr key={index}>
                        <td>{list?.energSource}</td>
                        <td>{list?.percentage}</td>
                        <td>
                          <span onClick={() => handleEsEditingPopUp(list)}>
                            <i className="fa-solid fa-pencil action-icons"></i>
                          </span>

                          <span
                            onClick={() =>
                              DeleteESByRocIdmixIdAndLocId(list.id)
                            }
                            style={{ color: "red" }}
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody className="text-center">
                    <tr>
                      <th colspan="5">No Data</th>
                    </tr>
                  </tbody>
                )}
              </table>
            </CardContent>
          </Card>
        </div>
        <div>
          <div className="d-flex justify-content-between mt-4">
            <div className=" mx-0 p-0 pagetitle ">Environment Impact</div>

            <div
              className="Export-Button bgcolor-blue cursor-Pointer"
              onClick={handleEIPop}
            >
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </div>
          <Card
            sx={{ minWidth: 275 }}
            style={{ borderRadius: "25px", marginTop: "14px", padding: "8px" }}
          >
            <CardContent className="cardPadding"></CardContent>
            <table class="table table-box-shadow mb-4">
              <thead>
                <tr class="table-primary">
                  <th scope="col">Category</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              {environImpactList?.length > 0 ? (
                <tbody>
                  {environImpactList?.map((list, index) => (
                    <tr key={index}>
                      <td>{list?.source}</td>
                      <td>{list?.amount}</td>
                      <td>
                        <span onClick={() => handleEIEditingPopUp(list)}>
                          <i className="fa-solid fa-pencil action-icons"></i>
                        </span>

                        <span
                          onClick={() => DeleteEIByRocIdmixIdAndLocId(list.id)}
                          style={{ color: "red" }}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colspan="5">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </Card>
        </div>
      </div>

      <div className="d-flex justify-content-end gap-3 mt-4">
        <Link to="/location">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          className="submit-btn"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={
          statusMessage === "SUCCESS"
            ? () => navigate("/location")
            : handleAlertPopup
        }
        popUpWidth={"width-100"}
      />
      <PopUp
        open={inPageAlertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={callAllList}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={rLocationPopUp}
        handleClosePopUp={handleRLPop}
        title={"Add Related Location"}
        message={
          <RelatedLocationPop
            rlocationObj={rlocationObj}
            handleRlChange={handleRlChange}
            rLErrors={rLErrors}
            setRLocationObj={setRLocationObj}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleRLPop}
        handleClickBtn2={handleRLSubmit}
        popUpWidth="width-100"
      />
      <PopUp
        open={rLocationEditPopUp}
        handleClosePopUp={handleRLEditPop}
        title={"Update Related Location"}
        message={
          <RelatedLocationPop
            rlocationObj={rlocationObj}
            handleRlChange={handleRlChange}
            rLErrors={rLErrors}
            setRLocationObj={setRLocationObj}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleRLEditPop}
        handleClickBtn2={handleRLEditSubmit}
        popUpWidth="width-100"
      />
      <PopUp
        open={rHoursPopUp}
        handleClosePopUp={handleRHPop}
        title={"Add Regular Hours"}
        message={
          <RegularHoursPop
            rHoursObj={rHoursObj}
            handleRHChange={handleRHChange}
            rHErrors={rHErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleRHPop}
        handleClickBtn2={handleRHSubmit}
        popUpWidth="popUpMaxWidth"
      />
      <PopUp
        open={mapOpen}
        title={""}
        message={
          <MapComponent
            locationFields={locationFields.coordinates}
            setLocationFields={(updatedCoords) =>
              setLocationFields((prevState) => ({
                ...prevState,
                coordinates: updatedCoords,
              }))
            }
          />
        }
        btn1={true}
        btn1Color={"cancel-btn"}
        btn1Text={"Cancel"}
        handleClickBtn1={() => setMapOpen(false)}
        btn2={true}
        btn2Color={"submit-btn"}
        btn2Text={"Submit"}
        handleClickBtn2={() => setMapOpen(false)}
        popUpWidth={"mw-920"}
      />
      <PopUp
        open={rHoursEditPopUp}
        handleClosePopUp={handleRHEditPop}
        title={"Update Regular Hours"}
        message={
          <RegularHoursPop
            rHoursObj={rHoursObj}
            handleRHChange={handleRHChange}
            rHErrors={rHErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleRHEditPop}
        handleClickBtn2={handleRHEditSubmit}
        popUpWidth="popUpMaxWidth"
      />
      <PopUp
        open={energySourceAddPop}
        handleClosePopUp={handleESPop}
        title={"Add Energy Source"}
        message={
          <EnergySourcesPopUp
            energySourceDrop={energySourceDrop}
            energySourceObj={energySourceObj}
            handleESchange={handleESchange}
            EsErrors={EsErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleESPop}
        handleClickBtn2={ESvalidateForm}
        popUpWidth="width-100"
      />
      <PopUp
        open={eSEditPopUp}
        handleClosePopUp={handleEsEditPop}
        title={"Update Energy Source"}
        message={
          <EnergySourcesPopUp
            energySourceDrop={energySourceDrop}
            energySourceObj={energySourceObj}
            handleESchange={handleESchange}
            EsErrors={EsErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleEsEditPop}
        handleClickBtn2={ESEditvalidateForm}
        popUpWidth="width-100"
      />
      <PopUp
        open={environmentImpactPop}
        handleClosePopUp={handleEIPop}
        title={"Add Environment Impact"}
        message={
          <EnvironImpactPopUp
            categoryDrop={categoryDrop}
            environImpactObj={environImpactObj}
            handleEIchange={handleEIchange}
            EiErrors={EiErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleEIPop}
        handleClickBtn2={EIvalidateForm}
        popUpWidth="width-100"
      />
      <PopUp
        open={eIEditPopUp}
        handleClosePopUp={handleEIEditPop}
        title={"Update Environment Impact"}
        message={
          <EnvironImpactPopUp
            categoryDrop={categoryDrop}
            environImpactObj={environImpactObj}
            handleEIchange={handleEIchange}
            EiErrors={EiErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleEIEditPop}
        handleClickBtn2={EIEditvalidateForm}
        popUpWidth="width-100"
      />
      <PopUp
        open={directionPop}
        handleClosePopUp={handleDirectionPop}
        title={"Add Direction"}
        message={
          <DirectionsPOP
            directionsObj={directionsObj}
            handleDirectionschange={handleDirectionschange}
            dirErrors={dirErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleDirectionPop}
        handleClickBtn2={addDirections}
        popUpWidth="width-100"
      />
      <PopUp
        open={directionEditPop}
        handleClosePopUp={handleDirectionEditPop}
        title={"Update Direction"}
        message={
          <DirectionsPOP
            directionsObj={directionsObj}
            handleDirectionschange={handleDirectionschange}
            dirErrors={dirErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleDirectionEditPop}
        handleClickBtn2={editDirections}
        popUpWidth="width-100"
      />
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}

export default LocationUpdate;
