import {
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { useNavigate } from "react-router-dom";

function HeaderDesign({ setRefresh }) {
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const navigate = useNavigate();
  const handleMenuClose = () => {
    setAnchorElProfile(null);
  };
  const Logout = () => {
    setAnchorElProfile(null);
    localStorage.clear();
    window.location.replace("/");
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  return (
    <div className="br-10 bg-white" style={{ width: "99%" }}>
      <div className="w-100 rounded p-2 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center" style={{ width: "30%" }}>
          <TextField
            type="text"
            placeholder="Search anything here"
            variant="outlined"
            size="small"
            className="rounded"
            style={{ backgroundColor: "#F4F4FF" }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            fullWidth={true}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <SearchIcon style={{ color: "black" }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="d-flex align-items-center gap-3 pe-2">
          <i className="fa-solid fa-rotate-right p-2 rounded-circle page-background-color fs-15 cursor-Pointer" onClick={() => setRefresh(true)}></i>
          <i className="fa-regular fa-bell p-2 rounded-circle page-background-color fs-15"></i>
          <i className="fa-solid fa-gear p-2 rounded-circle fs-15 pointerCursor" onClick={() => { navigate("/settings"); }}></i>
          <div onClick={handleProfileMenuOpen} className="pointerCursor">
            <i className="fa-solid fa-user p-2 rounded-circle fs-15"></i>
            <i className="fa-solid fa-chevron-down" style={{ fontSize: "xx-small" }}></i>
          </div>
          <Menu
            anchorEl={anchorElProfile}
            open={Boolean(anchorElProfile)}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <span className="pe-2">First Name : </span>
              <span title={localStorage.getItem("firstName")}>{localStorage.getItem("firstName")?.length <= 20 ? localStorage.getItem("firstName") : localStorage.getItem("firstName")?.substring(0, 20) + "..."}</span>
            </MenuItem>
            <MenuItem>
              <span className="pe-2">Last Name : </span>
              <span title={localStorage.getItem("lastName")}>{localStorage.getItem("lastName")?.length <= 20 ? localStorage.getItem("lastName") : localStorage.getItem("lastName")?.substring(0, 20) + "..."}</span>
            </MenuItem>
            <MenuItem>
              <span className="pe-2">Email : </span>
              <span title={localStorage.getItem("email")}>{localStorage.getItem("email")?.length <= 20 ? localStorage.getItem("email") : localStorage.getItem("email")?.substring(0, 20) + "..."}</span>
            </MenuItem>
            <MenuItem>
              <span className="pe-2">Role : </span>
              <span title={localStorage.getItem("roleName")}>{localStorage.getItem("roleName")?.length <= 20 ? localStorage.getItem("roleName") : localStorage.getItem("roleName")?.substring(0, 20) + "..."}</span>
            </MenuItem>
            <MenuItem onClick={Logout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default HeaderDesign;
