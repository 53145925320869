/** @format */

import { Card, CardContent, CardHeader } from "@mui/material";
import { Box, Tab } from "@mui/material";
import React from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuConfiguration from "./MenuConfiguration";
import Profile from "./Profile";
import { resize } from "../../common/helpers";
import FullLoader from "../../components/FullLoader";

function Settings({
  refresh,
  setRefresh,
  headerItems,
  setHeaderItems,
  menuItems,
  setMenuItems,
  footerItems,
  setFooterItems,
  getSidebarItems,
}) {
  const [value, setValue] = React.useState("1");
  //const [loader, setLoader] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    resize();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      //setLoader(true);
      getSidebarItems();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  return (
    <Card elevation={3} sx={{ width: "100%", borderRadius: 2 }}>
      <CardContent>
        {/* //{loader && <FullLoader />} */}
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="halfWidth"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#2DCC70",
                },
              }}
            >
              <Tab
                label="Menu Configurations"
                value="1"
                className="settings_Fonts_style"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#2DCC70",
                    color: "white",
                  },
                }}
              />
              <Tab
                label="Profile"
                value="2"
                className="settings_Fonts_style"
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#2DCC70",
                    color: "white",
                  },
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <MenuConfiguration
              refresh={refresh}
              setRefresh={setRefresh}
              headerItems={headerItems}
              setHeaderItems={setHeaderItems}
              menuItems={menuItems}
              setMenuItems={setMenuItems}
              footerItems={footerItems}
              setFooterItems={setFooterItems}
              getSidebarItems={getSidebarItems}
            />
          </TabPanel>
          <TabPanel value="2">
            <Profile />
          </TabPanel>
        </TabContext>
      </CardContent>
    </Card>
  );
}

export default Settings;
