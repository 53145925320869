import React, { useState } from "react";
import "./App.css";
import "./custom-styles.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MiniDrawer from "./views/Layout/theme1/SideHeadbar";
import NewHeadBar from "./views/Layout/theme1/NewHeadBar";
import Dashboard from "./views/Dashboard/Dashboard";
import TenantManagementTable from "./views/TenantManagement/TenantManagementTable";
import Settings from "./views/Settings/Settings";
import UserTable from "./views/UserManagement/Users";
import { basePath } from "./common/config";
import Roles from "./views/Role/Roles";
import Foot from "./views/Layout/theme1/Footer";
import AuditLogs from "./views/Logs/AuditLog";
import EventLogs from "./views/Logs/EventLogs";
import ErrorLogs from "./views/Logs/ErrorLogs";
import SessionExpire from "./components/SessionExpire";
import Email from "./views/EmailTemplates/Email";
import { useTranslation } from "react-i18next";
import Firmware from "./views/Firmware Management/Firmware";
import Inventory from "./views/Inventory/CSInventory";
import Vehicle from "./views/Vehicle/Vehicle";
import Operations from "./views/Operations/Operations";
import LoginForm from "./views/LoginModule/Login";
import ForgetPassword from "./views/LoginModule/ForgetPassword";
import ResetPassword from "./views/LoginModule/ResetPassword";
import AddFirmware from "./views/Firmware Management/AddFirmware";
import EditFirmware from "./views/Firmware Management/EditFirmware";
import AddVehicle from "./views/Vehicle/AddVehicle";
import EditVehicle from "./views/Vehicle/EditVehicle";
import EditInventoryForm from "./views/Inventory/EditInventoryForm";
import ChargingProfiles from "./views/ChargingStations/ChargingProfile/ChargingProfiles";
import AddChargerProfile from "./views/ChargingStations/ChargingProfile/AddChargerProfile";
import ChargerDiagnostics from "./views/ChargingStations/ChargerDiagnostics/ChargerDiagnostics";
import ChargingSessions from "./views/ChargingSessions/ChargingSessions";
import EditChargeProfile from "./views/ChargingStations/ChargingProfile/EditChargerProfile";
import ViewChargePoint from "./views/ChargingStations/ViewChargePoint";
import ChargingStations from "./views/ChargingStations/ChargingStations";
import ChargingSchedule from "./views/ChargingStations/ChargingSchedule/ChargingSchedule";
import AddChargerSchedule from "./views/ChargingStations/ChargingSchedule/AddChargingSchedule";
import UpdateChargerSchedule from "./views/ChargingStations/ChargingSchedule/UpdateChargingSchedule";
import EditChargingSchedule from "./views/ChargingStations/ChargingSchedule/EditChargingSchedule";
import MeterValues from "./views/ChargingSessions/MeterValues";
import HeartBeatLogs from "./views/Logs/HeartBeatLogs";
import UnknownChargePoint from "./views/ChargingStations/UnknownChargePoint";
import Reservations from "./Reservations/Reservations";
import ViewReservation from "./Reservations/ViewReservation";
import Payment from "./Payment";
import BulkImport from "./views/Inventory/BulkImport";
import PingPongLogs from "./views/Logs/PingPongLogs";
import CSLogs from "./views/Logs/CSLogs";
import CSCPLogs from "./views/Logs/CSCPLogs";
import FirmwareUpdates from "./views/Logs/FirmwareUpdates";
import ConnectorStatus from "./views/ChargingStations/ConnectorStatus/ConnectorStatus";
import AddReservation from "./Reservations/AddReservation";
import EditReservation from "./Reservations/EditReservation";
import AuthorizationTag from "./views/AuthorizationTag/AuthorizationTag";
import AddAuthorizationTag from "./views/AuthorizationTag/AddAuthorizationTag";
import EditAuthorizationTag from "./views/AuthorizationTag/EditAuthorizationTag";
import Location from "./views/Location/Location";
import LocationAdd from "./views/Location/LocationAdd";
import Versions from "./views/Version Maintenance/Versions";
import AddVersion from "./views/Version Maintenance/AddVersion";
import EditVersion from "./views/Version Maintenance/EditVersion";
import Versionendpoints from "./views/Version Maintenance/Versionendpoints";
import EndpointEditVersion from "./views/Version Maintenance/Versionendpointedit";
import AddEndpointversion from "./views/Version Maintenance/VersionAddendpoint";
import Credentials from "./views/CredentialsManagement/Credentials";
import Tariffs from "./views/Tariffs/Tariffs";
import AddCredentials from "./views/CredentialsManagement/AddCredentials";
import EditCredentials from "./views/CredentialsManagement/EditCredentials";
import CredentialViewform from "./views/CredentialsManagement/CredentialViewform";
import Token from "./views/TokenManagement/Token";
import Addtoken from "./views/TokenManagement/Addtoken";
import Edittoken from "./views/TokenManagement/Edittoken";
import TariffViewPage from "./views/Tariffs/TariffViewPage";
import ViewLocation from "./views/Location/ViewLocation";
import ViewEvse from "./views/Location/ViewEvse";
import AddEvse from "./views/Location/AddEvse";
import EditEvse from "./views/Location/EditEvse";
import AddConnector from "./views/Location/AddConnector";
import EditConnector from "./views/Location/EditConnector";
import MspPage from "./views/MSP/MspPage";
import RegisterMsp from "./views/MSP/RegisterMsp";
import EditMsp from "./views/MSP/EditMsp";
import EditLocation from "./views/Location/EditLocation";
import TariffsAdd from "./views/Tariffs/TariffsAdd";
import TariffEdit from "./views/Tariffs/TariffEdit";
import AddElement from "./views/Tariffs/AddElement";
import UpdateElement from "./views/Tariffs/UpdateTariffElement";
import ViewStations from "./views/Inventory/ViewStations";
import ViewchargingSchedules from "./views/ChargingStations/ChargingProfile/ViewChargingSchedules";
import ViewChargingProfiles from "./views/ChargingStations/ChargingProfile/ViewChargingProfiles";
import AddUser from "./views/UserManagement/AddUser";
import EditUser from "./views/UserManagement/EditUser";
import AddTenant from "./views/TenantManagement/AddTenant";
import EditTenant from "./views/TenantManagement/EditTenant";
import AddChargingScheduleWithApi from "./views/ChargingStations/ChargingSchedule/AddChargingScheduleWithApi";
import SideBar from "./views/Layout/theme3/SideBar";
import HeaderDesign from "./views/Layout/theme3/HeaderDesign";
import CpoPage from "./views/CPO/CpoPage";
import RegisterCpo from "./views/CPO/RegisterCpo";
import EditCpo from "./views/CPO/EditCpo";
import ChargingStationMeterValues from "./views/ChargingStations/ChargingStationMeterValues";
import { handleApi_call } from "./common/CommonAPI";
import FullLoader from "./components/FullLoader";
import Cdrs from "./views/ChargingSessions/Cdr";
import Sessions from "./views/ChargingSessions/sessions";
function App() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [page, setPage] = useState(t("Dashboard"));
  const [menuItems, setMenuItems] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [loader, setLoader] = React.useState(false);
  const [footerItems, setFooterItems] = useState([]);
  const [sessionExpire, setSessionExpire] = useState(false);
  const [chargingSchedulelist, setChargingScheduleList] = useState([]);
  const [row, setRow] = React.useState(null);
  const [rowIndex, setRowIndex] = React.useState(null);
  const [chargingProfile, setChargingProfile] = React.useState({});

  const getSidebarItems = async () => {
    let language = localStorage.getItem("language");
    if (!language || language === "null" || language === "0") {
      language = "1";
      localStorage.setItem("language", language);
    }
    let type = "GET";
    let Url = `api/getall?languageId=${language}`;
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    if (res?.status) {
      setMenuItems(res.data.sidenav);
      setHeaderItems(res.data.header);
      setFooterItems(res.data.footer);
    }
  };

  function handleOpenClick() {
    setOpen((open) => !open);
  }

  if (localStorage.getItem("Authorization"))
    return (
      <BrowserRouter basename="/">
        <div
          className="d-flex align-items-stretch gap-4 p-3 page-background-color"
          style={{ height: "100vh" }}
        >
          {/* {loader && <FullLoader />} */}
          {sessionExpire && <SessionExpire />}
          <div
            className="br-10"
            style={
              open
                ? { width: "16%", backgroundColor: "white" }
                : { width: "5%", backgroundColor: "white" }
            }
          >
            <div>
              <SideBar
                open={open}
                menuItems={menuItems}
                getSidebarItems={getSidebarItems}
                handleOpenClick={handleOpenClick}
              />
            </div>
          </div>
          <div
            className="br-10 d-flex flex-column"
            style={open ? { width: "84%" } : { width: "95%" }}
          >
            <HeaderDesign setRefresh={setRefresh} />
            <main
              id="main"
              className="mt-3 br-10 pe-3"
              style={{
                flex: "1 1 auto",
                overflowY: "auto",
                // overflowX: "auto",
              }}
            >
              <Routes>
                <Route path="/" element={<Navigate to="/location" />} />
                <Route
                  path="/dashboard"
                  element={<Dashboard toggles={!open} />}
                />
                <Route
                  path="/tenantManagement"
                  element={
                    <TenantManagementTable
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />

                <Route
                  path="/userManagement"
                  element={
                    <UserTable setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/changeLogs"
                  element={
                    <AuditLogs setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/eventLogs"
                  element={
                    <EventLogs setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/pingPongLogs"
                  element={<PingPongLogs setPageHeading={setPage} />}
                />
                <Route
                  path="/fotaAlerts"
                  element={
                    <FirmwareUpdates
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/chargingStationMessageLogs"
                  element={<CSLogs setPageHeading={setPage} />}
                />
                <Route
                  path="/session"
                  element={<Sessions setPageHeading={setPage} />}
                />
                <Route
                  path="/stationLogs"
                  element={
                    <CSCPLogs setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/errorLogs"
                  element={
                    <ErrorLogs setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/emailTemplates"
                  element={<Email setRefresh={setRefresh} refresh={refresh} />}
                />
                <Route
                  path="/roles"
                  element={<Roles setRefresh={setRefresh} refresh={refresh} />}
                />
                <Route
                  path="/payments"
                  element={<Payment setPageHeading={setPage} />}
                />
                <Route
                  path="/settings"
                  element={
                    <Settings
                      refresh={refresh}
                      setRefresh={setRefresh}
                      headerItems={headerItems}
                      setHeaderItems={setHeaderItems}
                      menuItems={menuItems}
                      setMenuItems={setMenuItems}
                      footerItems={footerItems}
                      setFooterItems={setFooterItems}
                      getSidebarItems={getSidebarItems}
                    />
                  }
                />
                <Route
                  path="/Stations"
                  element={
                    <Inventory setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/vehicle"
                  element={
                    <Vehicle setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/firmware"
                  element={
                    <Firmware setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/addFirmware"
                  element={
                    <AddFirmware setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editFirmware"
                  element={
                    <EditFirmware setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/operations"
                  element={
                    <Operations setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/chargeStations"
                  element={
                    <ChargingStations
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/addVehicle"
                  element={
                    <AddVehicle setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/cdrs"
                  element={<Cdrs setRefresh={setRefresh} refresh={refresh} />}
                />
                <Route
                  path="/editVehicle"
                  element={
                    <EditVehicle setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editInventory"
                  element={
                    <EditInventoryForm
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />

                <Route
                  path="/chargerProfiles"
                  element={
                    <ChargingProfiles
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/viewchargingschedule"
                  element={
                    <ViewchargingSchedules
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/viewchargingprofiles"
                  element={
                    <ViewChargingProfiles
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/addChargerProfile"
                  element={
                    <AddChargerProfile
                      addform={chargingProfile}
                      setAddForm={setChargingProfile}
                      setRowIndex={setRowIndex}
                      setRow={setRow}
                      setChargingScheduleList={setChargingScheduleList}
                      chargingSchedulelist={chargingSchedulelist}
                    />
                  }
                />
                <Route
                  path="/editChargeProfile"
                  element={
                    <EditChargeProfile
                      chargingSchedulelist={chargingSchedulelist}
                      setChargingScheduleList={setChargingScheduleList}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/chargerDiagnostics"
                  element={
                    <ChargerDiagnostics
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route path="/viewChargePoint" element={<ViewChargePoint />} />
                <Route
                  path="/chargingSchedule"
                  element={
                    <ChargingSchedule
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/AddchargingSchedule"
                  element={
                    <AddChargerSchedule
                      row={row}
                      chargingSchedulelist={chargingSchedulelist}
                      setChargingScheduleList={setChargingScheduleList}
                      setPageHeading={setPage}
                    />
                  }
                />
                <Route
                  path="/updatechargingSchedule"
                  element={
                    <UpdateChargerSchedule
                      rowIndex={rowIndex}
                      row={row}
                      setRow={setRow}
                      setRowIndex={setRowIndex}
                      chargingSchedulelist={chargingSchedulelist}
                      setChargingScheduleList={setChargingScheduleList}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/chargingScheduleAdd"
                  element={
                    <AddChargingScheduleWithApi
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />

                <Route
                  path="/editchargingSchedule"
                  element={
                    <EditChargingSchedule
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/chargingSessions"
                  element={
                    <ChargingSessions
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/activeCharging"
                  element={
                    <MeterValues setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/stationMeterValues"
                  element={
                    <ChargingStationMeterValues
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/heartBeatLogs"
                  element={<HeartBeatLogs setPageHeading={setPage} />}
                />
                <Route
                  path="/anonymousStations"
                  element={
                    <UnknownChargePoint
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/reservations"
                  element={
                    <Reservations setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route path="/viewReservation" element={<ViewReservation />} />
                <Route
                  path="/bulkImport"
                  element={
                    <BulkImport setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editReservation"
                  element={
                    <EditReservation
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/addReservation"
                  element={
                    <AddReservation setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/connectorStatus"
                  element={<ConnectorStatus setPageHeading={setPage} />}
                />
                <Route
                  path="/authorizationTag"
                  element={<AuthorizationTag setPageHeading={setPage} />}
                />
                <Route
                  path="/addAuthorizationTag"
                  element={<AddAuthorizationTag setPageHeading={setPage} />}
                />
                <Route
                  path="/editAuthorizationTag"
                  element={<EditAuthorizationTag setPageHeading={setPage} />}
                />
                <Route
                  path="/addTenant"
                  element={
                    <AddTenant setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editTenant"
                  element={
                    <EditTenant setRefresh={setRefresh} refresh={refresh} />
                  }
                />

                <Route
                  path="/addUser"
                  element={
                    <AddUser setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editUser"
                  element={
                    <EditUser setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/location"
                  element={
                    <Location setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/addLocation"
                  element={
                    <LocationAdd setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editLocation"
                  element={
                    <EditLocation setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/viewLocation"
                  element={
                    <ViewLocation setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/addEvse"
                  element={
                    <AddEvse setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editEvse"
                  element={
                    <EditEvse setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/addConnector"
                  element={
                    <AddConnector setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editConnector"
                  element={
                    <EditConnector setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/viewEvse"
                  element={
                    <ViewEvse setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/version"
                  element={
                    <Versions setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/addVersion"
                  element={
                    <AddVersion setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editVersion"
                  element={
                    <EditVersion setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/versionendpoint"
                  element={
                    <Versionendpoints
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/addversionendpoint"
                  element={
                    <AddEndpointversion
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/editversionendpoint"
                  element={
                    <EndpointEditVersion
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/addCredentials"
                  element={
                    <AddCredentials setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/credentials"
                  element={
                    <Credentials setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/tariffs"
                  element={
                    <Tariffs setRefresh={setRefresh} refresh={refresh} />
                  }
                />

                <Route
                  path="/tariffsAdd"
                  element={
                    <TariffsAdd setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/addElements"
                  element={
                    <AddElement setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/updateElements"
                  element={
                    <UpdateElement setRefresh={setRefresh} refresh={refresh} />
                  }
                />

                <Route
                  path="/TariffEdit"
                  element={
                    <TariffEdit setRefresh={setRefresh} refresh={refresh} />
                  }
                />

                <Route
                  path="/editCredentials"
                  element={
                    <EditCredentials
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/credentialViewform"
                  element={
                    <CredentialViewform
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  path="/token"
                  element={<Token setRefresh={setRefresh} refresh={refresh} />}
                />
                <Route
                  path="/addtoken"
                  element={
                    <Addtoken setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/edittoken"
                  element={
                    <Edittoken setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/viewTariff"
                  element={
                    <TariffViewPage setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/mspPage"
                  element={
                    <MspPage setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/registerMsp"
                  element={
                    <RegisterMsp setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editMsp"
                  element={
                    <EditMsp setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/viewstations"
                  element={
                    <ViewStations setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/cpoPage"
                  element={
                    <CpoPage setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/registerCpo"
                  element={
                    <RegisterCpo setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  path="/editCpo"
                  element={
                    <EditCpo setRefresh={setRefresh} refresh={refresh} />
                  }
                />
              </Routes>
            </main>
          </div>
        </div>
      </BrowserRouter>
    );
  else
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    );
}

export default App;
