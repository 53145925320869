import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
import SessionExpire from "../../components/SessionExpire";

function ChangeConfiguration({ handleSubmitOperation, chargePointId }) {
  const [sessionExpire, setSessionExpire] = useState(false);
  const [loader, setLoader] = useState(false);
  const [inputFields, setInputFields] = useState({
    key: "",
    value: "",
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    key: "",
    value: "",
  });
  // const [key, setConfigurationKey] = useState([]);
  // useEffect(() => {
  //   if (chargePointId) {
  //     getChargePoints();
  //   }
  // }, [chargePointId]);
  // const getChargePoints = async () => {
  //   let Url = `getChargingStations/?chargePointId_like=${chargePointId}`;
  //   let type = "GET";
  //   let res = await handleApi_call(
  //     Url,
  //     headers,
  //     type,
  //     {},
  //     setSessionExpire,
  //     () => {},
  //     () => {},
  //     setLoader
  //   );
  //   setConfigurationKey(res[0]?.configurationKeys);
  // };
  function handleOnChange(key, value) {
    setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
    setInputFields({ ...inputFields, [key]: value });
  }
  function handleSubmit() {
    let error = false;
    if (!inputFields.key || !inputFields.value) {
      error = true;
    }
    if (error) {
      if (!inputFields.key) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          key: "Key is Mandatory",
        }));
      }
      if (!inputFields.value) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          value: "Value is Mandatory",
        }));
      }
    }
    handleSubmitOperation(error, inputFields);
  }
  return (
    <>
      {sessionExpire && <SessionExpire />}
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Configuration Key *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.key}
                  required
                  className="w-100"
                  variant="outlined"
                  value={inputFields.key}
                  size="small"
                  helperText={inputFieldsValidation.key}
                  onChange={(e) => {
                    handleOnChange("key", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            {/* )} */}
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Value *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.value}
                  required
                  variant="outlined"
                  className="w-100"
                  value={inputFields.value}
                  size="small"
                  helperText={inputFieldsValidation.value}
                  onChange={(e) => {
                    handleOnChange("value", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          variant="contained"
          className="submit-btn"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default ChangeConfiguration;
