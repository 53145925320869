import * as React from "react";
import * as material from "@mui/material";
import * as icons from "@mui/icons-material";
import TableHeadRow from "../../components/TableHeadRow";
import ColumnsBox from "../../components/ColumnsBox";
import moment from "moment";
import PopUp from "../../components/PopUp";
import EditConfigurationKey from "./EditConfigurationKey";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
const headCells = [
    {
        id: "keyName",
        numeric: false,
        disablePadding: true,
        label: "Configuration Key",
        mandatory: true,
        filterOption: false,
        sort: true,
        minWidth: 130,
    },
    {
        id: "value",
        numeric: false,
        disablePadding: true,
        label: "Configuration Value",
        mandatory: true,
        filterOption: false,
        sort: true,
        minWidth: 130,
    },
    {
        id: "updatedDate",
        numeric: false,
        disablePadding: true,
        label: "Last Update",
        mandatory: false,
        filterOption: false,
        sort: true,
        minWidth: 130,
    },
    {
        id: "favourite",
        numeric: false,
        disablePadding: true,
        label: "Favourites",
        mandatory: true,
        filterOption: false,
        sort: true,
        minWidth: 130,
    },
    {
        id: "update",
        numeric: false,
        disablePadding: true,
        label: "Update",
        mandatory: true,
        filterOption: false,
        sort: true,
        minWidth: 130,
    }
]
function ConfigurationTable({ keys, updateFavourite, getConfigurationKeys }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [disableColumns, setDisableColumns] = React.useState([]);
    const [option, setOption] = React.useState("");
    const [keyFields, setKeyFields] = React.useState({});
    const [valueValidation, setValueValidation] = React.useState("");
    const [editPopup, setEditPopup] = React.useState(false);
    const [sessionExpire, setSessionExpire] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertPopup, setAlertPopup] = React.useState(false);

    const updateConfigurationKeys = () => {
        let headers = {
            "content-type": "application/json",
            // Authorization: localStorage.getItem("Authorization"),
            // "X-TenantID": localStorage.getItem("tenantId"),
        };
        fetch(basePathone + "cskey/" + keyFields.id, {
            method: "PUT",
            body: JSON.stringify(keyFields),
            headers: headers,
        })
            .then((resp) => {
                if (resp.status === 401) {
                    setSessionExpire(true);
                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                setAlertMessage(res.message);
                handleAlertPopup();
            });
    };

    function handleAlertPopup() {
        setAlertPopup(pop => !pop);
    }
    function handleAlertOk() {
        handleAlertPopup();
        getConfigurationKeys();
    }
    function handleEditPOpup() {
        setEditPopup(pop => !pop);
    }
    function handleEditClick(row) {
        setKeyFields(row)
        handleEditPOpup();
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    function handleOptionClick(value) {
        if (option === "") setOption(value);
        else setOption("");
    }
    function handelChange(id) {
        if (disableColumns.includes(id)) {
            setDisableColumns((columns) => columns.filter((c) => id !== c));
        } else setDisableColumns([...disableColumns, id]);
    }
    function handleFavourite(favourite, id) {
        favourite = favourite ? 0 : 1;
        updateFavourite(favourite, id)
    }
    function handleKeyFieldChange(key, value) {
        setValueValidation("")
        setKeyFields({ ...keyFields, [key]: value });
    }
    function handleEditCancelButton() {
        handleEditPOpup();
    }
    function handleEditSubmitButton() {
        if (!keyFields.value) {
            setValueValidation("Configuration Value is Mandatory")
        }
        else {
            updateConfigurationKeys();
            handleEditPOpup();
        }
    }
    return (
        <material.Box sx={{ width: "100%" }}>
            <material.Paper sx={{ width: "100%", mb: 2 }}>

                <material.Box
                    sx={{ width: "100%", p: "5px" }}
                    className="d-flex align-items-end justify-content-end"
                >
                    {/* <div
                        style={{ width: "65%" }}
                        className="d-flex flex-wrap align-items-center"
                    > */}
                    {/* <FormControl
            sx={{ m: 1, width: "25ch" }}
            variant="filled"
            className=""
          >
            <FilledInput
              id="filled-adornment-weight"
              size="small"
              sx={{ height: "40px", pb: "5px", width: "200px" }}
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end" sx={{ mt: "15px" }}>
                  <Search />
                </InputAdornment>
              }
              aria-describedby="filled-weight-helper-text"
              inputProps={{
                "aria-label": "search",
              }}
            />
          </FormControl> */}
                    {/* {filterColumns.map((column) => (
                            <FilterInputs
                                key={column.id}
                                name={column}
                                removeInput={removeInput}
                                value={searchInputs[column.id]}
                                handleSearchInputs={handleSearchInputs}
                            />
                        ))} */}
                    {/* </div> */}
                    <material.Box className="d-flex gap-1">
                        {/* <div>
                            <material.Button onClick={() => handleOptionClick("filter")}>
                                <icons.FilterList />
                                <span className="ms-1">{"add filter"}</span>
                            </material.Button>

                            {option === "filter" && (
                                <material.ClickAwayListener onClickAway={() => handleOptionClick("")}>
                                    <div className="filterBox">
                                        <FilterTable
                                            headCells={headCells}
                                            handleFilterColumns={handleFilterColumns}
                                            filterColumns={filterColumns}
                                        />
                                    </div>
                                </material.ClickAwayListener>
                            )}
                        </div> */}
                        <div>
                            <material.Button onClick={() => handleOptionClick("column")}>
                                <icons.ViewWeek />
                                <span className="ms-1">COLUMN</span>
                            </material.Button>
                            {option === "column" && (
                                <material.ClickAwayListener
                                    onClickAway={() => handleOptionClick("")}
                                >
                                    <div className="columnBox" style={{ right: "25px" }}>
                                        <ColumnsBox
                                            headCells={headCells}
                                            handelChange={handelChange}
                                            disableColumns={disableColumns}
                                        />
                                    </div>
                                </material.ClickAwayListener>
                            )}
                        </div>
                        {/* <material.Button>
                            <icons.Download />
                            <span className="ms-1">EXPORT</span>
                        </material.Button> */}
                    </material.Box>
                </material.Box>

                <material.TableContainer>
                    <material.Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"small"}
                        className="overflow-auto"
                    >
                        <TableHeadRow
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            // rowCount={chargingSessionList.length}
                            disableColumns={disableColumns}
                            deleteCheckBox={false}
                        />
                        <material.TableBody>
                            {keys?.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <material.TableRow
                                        tabIndex={-1}
                                        key={row.id}
                                    >
                                        {!disableColumns.includes("keyName") && (
                                            <material.TableCell
                                                // component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.keyName}
                                            </material.TableCell>
                                        )}
                                        {!disableColumns.includes("value") && (
                                            <material.TableCell>{row.value}</material.TableCell>
                                        )}
                                        {!disableColumns.includes("updatedDate") && (
                                            <material.TableCell>{moment(row.updatedDate).format("DD-MM-YYYY hh:mm A")}</material.TableCell>
                                        )}
                                        {!disableColumns.includes("favourite") && (
                                            <material.TableCell>
                                                <material.Switch
                                                    checked={row.favourite}
                                                    onClick={() =>
                                                        handleFavourite(row.favourite, row.id)
                                                    }
                                                    size="small"
                                                />
                                            </material.TableCell>
                                        )}
                                        {!disableColumns.includes("update") && (
                                            <material.TableCell>
                                                {!row.readonly && <material.IconButton onClick={() => handleEditClick(row)}>
                                                    <icons.Edit color="primary" />
                                                </material.IconButton>}
                                            </material.TableCell>
                                        )}
                                    </material.TableRow>
                                );
                            })}
                            {keys.length === 0 && (
                                <material.TableRow style={{ height: 40 }}>
                                    <material.TableCell colSpan={8} className="saas-nodata-row">
                                        No Data Available
                                    </material.TableCell>
                                </material.TableRow>
                            )}
                        </material.TableBody>
                    </material.Table>
                </material.TableContainer>
                {/* <div className="position-relative">
                    {chargingSessionList.length > 0 && (
                    <material.TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        disablePortal
                        id="TablePagination"
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    )}
                </div> */}
            </material.Paper>
            <PopUp
                open={editPopup}
                title={"Edit Configuration Key"}
                message={<EditConfigurationKey keyFields={keyFields} valueValidation={valueValidation} handleChange={handleKeyFieldChange} />}
                btn1={true}
                btn1Color={"bg-danger"}
                btn1Text={"Cancel"}
                handleClickBtn1={handleEditCancelButton}
                btn2={true}
                btn2Color={"bg-primary"}
                btn2Text={"Update"}
                handleClickBtn2={handleEditSubmitButton}
                popUpWidth={"width-100 popUpMaxWidth"}
            />
            <PopUp
                open={alertPopup}
                title={"Alert"}
                message={alertMessage}
                btn1={true}
                btn1Text={"Ok"}
                btn1Color={"bg-primary"}
                handleClickBtn1={handleAlertOk}
                popUpWidth={"width-100"}
            />
            {sessionExpire && <SessionExpire />}
        </material.Box >
    )
}

export default ConfigurationTable
