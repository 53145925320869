/** @format */
import CustomTablePagination from "../../components/CustomTablePagination";
import {
  Box,
  Paper,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableRow,
} from "@mui/material";
import * as material from "@mui/material";
import moment from "moment";
import TableHeadRow from "../../components/TableHeadRow";
import ColumnsBox from "../../components/ColumnsBox";
import React, { useEffect } from "react";
import PopUp from "../../components/PopUp";
import { Link } from "react-router-dom";
import SessionExpire from "../../components/SessionExpire";
import { resize, searchOperations } from "../../common/helpers";
import { basePathone } from "../../common/config";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import FilterTable from "../../components/FilterTable";
import Loader from "../../components/Loader";
import { Edit } from "@mui/icons-material";
import { headers } from "../../common/Headers";
import { handleApi_call } from "../../common/CommonAPI";

const headCells = [
  {
    id: "vehicleName",
    numeric: false,
    disablePadding: true,
    label: "Vehicle Name",
    mandatory: true,
    filterOption: true,
    sort: true,
    minWidth: 100,
    searchType: "text",
    status: "active",
    date: "date",
  },
  {
    id: "chargingStatus",
    numeric: false,
    disablePadding: true,
    label: "Charging Status",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "soc",
    numeric: false,
    disablePadding: true,
    label: "State Of Charge",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "oem",
    numeric: false,
    disablePadding: true,
    label: "Manufacturer",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "updatedDate",
    numeric: false,
    disablePadding: true,
    label: "Last Updated",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "update",
    numeric: false,
    disablePadding: false,
    label: "Update",
    mandatory: true,
    filterOption: false,
    sort: false,
  },
];

function Vehicle({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedDate");
  const [page, setPage] = React.useState(0);
  const [noData, setNoData] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loader, setLoader] = React.useState([]);
  const [searchInputs, setSearchInputs] = React.useState({
    vehicleName: "",
  });
  const [count, setCount] = React.useState(0);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [filterState, setFilterState] = React.useState([
    { key: "vehicleName", value: "" },
  ]);

  const [disableColumns, setDisableColumns] = React.useState([]);
  const [option, setOption] = React.useState("");
  const [vehicleDetails, setVehicleDetails] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "vehicleName") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.LIKE,
          };
        }
      });
    return params;
  };
  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        vehicleName: "",
        chargingStatus: "",
        soc: "",
        oem: "",
        updatedDate: "",
      });
    } else {
      setFilterColumns((prevColumns) => {
        if (!prevColumns.includes(id)) {
          return [...prevColumns, id];
        }
        return prevColumns; // Prevent adding duplicates
      });
    }
    setOption("");
  }

  const getVehicles = async () => {
    const searchParams = buildSearchParams();
    const Url = "vehicles/search";
    const type = "POST";
    const body = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };

    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.status) {
      setVehicleDetails(res?.data);
      setCount(res?.count);
      setNoData(res?.count === 0 ? "No Data Available" : "");
    }
  };

  const deleteVehicle = async () => {
    let Url = "vehicles/ids";
    let type = "DELETE";
    let body = selected;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      setSelected([]);
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res?.data);
      handleAlertPopup();
    }
  };
  React.useEffect(() => {
    getVehicles();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);

  React.useEffect(() => {
    if (refresh) {
      getVehicles();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  const handleSearchInput = (key, value) => {
    setPage(0);
    setSearchInputs({ ...searchInputs, [key]: value });
  };

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = vehicleDetails.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  function handleAlertOk() {
    //getVehicles();
    handleAlertPopup();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      vehicleName: "",
    });
    setFilterState([{ key: "vehicleName", value: "" }]);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {loader && <Loader />}
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Vehicles</div>
        <div className="d-flex align-items-center gap-3 ">
          <Link to="/addVehicle">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red " onClick={deleteVehicle}>
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={vehicleDetails.length}
              disableColumns={disableColumns}
            />
            <TableBody>
              {vehicleDetails.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // hover
                    // role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {!disableColumns.includes("vehicleName") && (
                      <TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.vehicleName}
                      </TableCell>
                    )}
                    {!disableColumns.includes("chargingStatus") && (
                      <TableCell>{row.chargingStatus}</TableCell>
                    )}
                    {!disableColumns.includes("soc") && (
                      <TableCell>{row.soc}</TableCell>
                    )}
                    {!disableColumns.includes("oem") && (
                      <TableCell>{row.oem}</TableCell>
                    )}
                    {!disableColumns.includes("updatedDate") && (
                      <TableCell>
                        {row.updatedDate &&
                          moment(row.updatedDate).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("update") && (
                      <TableCell>
                        <Link to={"/editVehicle"} state={row}>
                        <i
                    className="fa-solid fa-pencil action-icons"
                    style={{ color: "#2DCC70" }}
                  ></i>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {vehicleDetails.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {vehicleDetails.length > 0 && (
            // <TablePagination
            //   rowsPerPageOptions={[10, 15, 20]}
            //   disablePortal
            //   id="TablePagination"
            //   component="div"
            //   count={count}
            //   rowsPerPage={rowsPerPage}
            //   page={page}
            //   onPageChange={handleChangePage}
            //   onRowsPerPageChange={handleChangeRowsPerPage}
            // />
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      {sessionExpire && <SessionExpire />}
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
    </Box>
  );
}

export default Vehicle;
