import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { basePath } from "../../../common/config";
import SessionExpire from "../../../components/SessionExpire";
import {
  Box,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  styled,
  Select,
  MenuItem,
  Menu,
  FormControl,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import MuiAppBar from "@mui/material/AppBar";
import RefreshIcon from "@mui/icons-material/Refresh";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const TranslateButton = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "inherit",
  "& .MuiSelect-root": {
    color: "inherit",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function NewHeadBar({
  open,
  setOpen,
  page,
  headerItems,
  setHeaderItems,
  getSidebarItems,
}) {
  const { t, i18n } = useTranslation();
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [sessionExpire, setSessionExpire] = useState(false);
  const languageMap = {
    1: "en",
    2: "ua",
    5: "te",
  };
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem("language") === "null"
      ? "1"
      : localStorage.getItem("language");
  });
  const [languages, setLanguages] = useState([]);
  const handleLangChange = (event) => {
    const newLang = event.target.value;
    const storedLanguage = languageMap[newLang];
    setLanguage(newLang);
    localStorage.setItem("language", newLang);
    i18n.changeLanguage(storedLanguage);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElProfile(null);
  };

  const Logout = () => {
    setAnchorElProfile(null);
    localStorage.clear();
    window.location.replace("/");
  };
  //get all languages from backend
  const getLanguages = () => {
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePath}languages`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setLanguages(res?.data);
        }
      });
  };
  useEffect(() => {
    // i18n.changeLanguage(language);
    getLanguages();
    getSidebarItems();
  }, [
    language,
    //i18n
  ]);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        className={`height headbarwidth ${open ? "opening-headber" : ""}`}
        id="header"
      >
        <Toolbar sx={{ minHeight: "50px !important" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 1,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {page}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <div className="d-flex gap-3 flex-row-reverse flex-wrap">
            {headerItems.map((item) =>
              item.parent.isActive ? (
                <Link
                  key={item.parent.id}
                  to={item.parent.link}
                  className="text-decoration-underline"
                  style={{ color: "white" }}
                >
                  {item.parent.title}
                </Link>
              ) : null
            )}
          </div>
          <TranslateButton>
            <TranslateIcon className="ms-4" />
            <FormControl variant="outlined" className="dropdownColor">
              <Select
                labelId="language-select-label"
                id="language-select"
                value={language}
                sx={"color:white"}
                size="small"
                onChange={handleLangChange}
                displayEmpty
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.id} value={lang.id}>
                    {lang.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TranslateButton>
          <IconButton color="inherit">
            <RefreshIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleProfileMenuOpen}>
            <PeopleAltIcon />
          </IconButton>

          <Menu
            anchorEl={anchorElProfile}
            open={Boolean(anchorElProfile)}
            onClose={handleMenuClose}
          >
            <MenuItem style={{ fontWeight: "bold" }}>
              {localStorage.getItem("firstName") +
                " " +
                localStorage.getItem("lastName")}
            </MenuItem>
            <MenuItem>{localStorage.getItem("email")}</MenuItem>
            <MenuItem>
              {t("Role")}: {localStorage.getItem("roleName")}
            </MenuItem>
            <Link to="/settings" className="text-decoration-none">
              <MenuItem
                onClick={handleMenuClose}
                style={{ color: "#000000e3" }}
              >
                {t("settings")}
              </MenuItem>
            </Link>
            <MenuItem onClick={Logout}>{t("logout")}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {sessionExpire && <SessionExpire />}
    </>
  );
}

export default NewHeadBar;
