import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import moment from "moment";
import CustomTablePagination from "../../../../src/components/CustomTablePagination";
import * as material from "@mui/material";
import React, { useEffect } from "react";
import { searchOperations } from "../../../common/helpers";
import ColumnsBox from "../../../components/ColumnsBox";
import TableHeadRow from "../../../components/TableHeadRow";
import SessionExpire from "../../../components/SessionExpire";
import PopUp from "../../../components/PopUp";
import FilterTable from "../../../components/FilterTable";
import DisplayFilterInputs from "../../../components/DisplayFilterInputs";
import Loader from "../../../components/Loader";
import { handleApi_call } from "../../../common/CommonAPI";
import { headers } from "../../../common/Headers";

const headCells = [
  {
    id: "chargingStationId",
    numeric: false,
    disablePadding: true,
    label: "Location",
    mandatory: true,
    filterOption: true,
    searchType: "text",
    sort: true,
    minWidth: 100,
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: true,
    label: "File Name ",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "statusTime",
    numeric: false,
    disablePadding: true,
    label: "Received Date",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
    mandatory: true,
    filterOption: false,
    minWidth: 100,
  },
];

function ChargerDiagnostics({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("chargingStationId");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchInputs, setSearchInputs] = React.useState({
    chargingStationId: "",
  });
  const [count, setCount] = React.useState(0);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [option, setOption] = React.useState("");
  const [diagnostics, setDiagnostics] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [archivePopUp, setArchivePopUp] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [updateRow, setUpdateRow] = React.useState({});
  const [noData, setNoData] = React.useState("");
  const [filterState, setFilterState] = React.useState([
    { key: "chargingStationId", value: "" },
  ]);
  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "chargingStationId") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });

    return params;
  };
  React.useEffect(() => {
    if (refresh) {
      getDiagnostics();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const getDiagnostics = async () => {
    const searchParams = buildSearchParams();
    const requestBody = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    let Url = "csDiagnosticsUpdate/search";
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      requestBody,
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    setDiagnostics(res?.data);
    setCount(res?.count);
    setNoData(res?.count === 0 ? "No Data Available" : "");
  };
  const archiveDiagnostics = async (archiveStatus) => {
    let Url = `csDiagnosticsUpdate/Archive/${updateRow.id}?archiveStatus=${
      updateRow.archiveStatus === 1 ? 0 : 1
    }`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    // if (res?.status) {
    setAlertMessage(res?.message);
    setArchivePopUp(false);
    handleAlertPopup();
    // }
  };

  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      chargingStationId: "",
    });
    setFilterState([{ key: "chargingStationId", value: "" }]);
  };
  function handleArchivePopup(row) {
    setUpdateRow({
      ...row,
      isArchive: "true",
    });
    setArchivePopUp((pop) => !pop);
  }

  useEffect(() => {
    getDiagnostics();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        chargingStationId: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };
  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  function handleAlertOk() {
    getDiagnostics();
    handleAlertPopup();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Station Diagnostics</div>
        <div className="d-flex align-items-center gap-3 ">
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer className="table_body_height">
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              disableCheckBox={true}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={diagnostics?.length}
              disableColumns={disableColumns}
            />
            <TableBody>
              {diagnostics?.map((row, index) => {
                // const isItemSelected = isSelected(row.id);
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // checked={isItemSelected}
                        checked={false}
                        disabled={true}
                        // onClick={(event) => handleClick(event, row.id)}
                        // inputProps={{
                        //   "aria-labelledby": labelId,
                        // }}
                      />
                    </TableCell>
                    {!disableColumns.includes("chargingStationId") && (
                      <TableCell
                        // component="th"
                        // id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.chargingStationId}
                      </TableCell>
                    )}
                    {!disableColumns.includes("fileName") && (
                      <TableCell>{row.fileName}</TableCell>
                    )}
                    {!disableColumns.includes("statusTime") && (
                      <TableCell>
                        {row.statusTime &&
                          moment(row.statusTime).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("action") && (
                      <TableCell>
                        <a href={row?.diagnosticURL} download>
                          <i
                            className="fa-solid fa-download action-icons cursor-Pointer"
                            style={{ color: "#2DCC70" }}
                          ></i>
                        </a>

                        <i
                          className="fa-solid fa-box-archive txt-blue cursor-Pointer"
                          onClick={() => handleArchivePopup(row)}
                          style={{ color: "#2DCC70" }}
                          disabled={row.isArchive}
                        ></i>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {diagnostics?.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {diagnostics?.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>

      <PopUp
        open={alertPopup}
        title={"Alert"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={archivePopUp}
        message={"Do you want to Archive this Charge Point?"}
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"No"}
        btn2Text={"Yes"}
        handleClickBtn1={handleArchivePopup}
        title={"Confirm"}
        handleClickBtn2={archiveDiagnostics}
        popUpWidth="width-100"
      />
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}

export default ChargerDiagnostics;
