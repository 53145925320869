import moment from "moment";
import React, { useEffect, useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as material from "@mui/material";
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import { BarChart } from "@mui/x-charts/BarChart";
import TableHeadRow from "../../components/TableHeadRow";
import ErrorCodes from "../ChargingSessions/ErrorCodes";
import SessionEvents from "../ChargingSessions/SessionEvents";
import CustomTablePagination from "../../components/CustomTablePagination";
import { searchOperations } from "../../common/helpers";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import FilterTable from "../../components/FilterTable";
import FullLoader from "../../components/FullLoader";
import PowerGraph from "./../ChargingSessions/PowerGraph";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function StationMeterValues({ refresh, setRefresh }) {
  const detailsRef = useRef(null);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [alignment, setAlignment] = React.useState("");
  const [orderBy, setOrderBy] = React.useState();
  const [order, setOrder] = React.useState("asc");
  const [option, setOption] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [noData, setNoData] = React.useState("");
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchInputs, setSearchInputs] = React.useState({
    value: "",
    fromDate: "",
    toDate: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "value", value: "" },
    { key: "fromDate", value: "" },
    { key: "toDate", value: "" },
  ]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [sessionObject, setSessionObject] = React.useState({});
  const [billingValues, setBillingValues] = React.useState([]);
  const [chargingPowerData, setChargingPowerData] = React.useState([]);
  const [isVisible, setIsVisible] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [powerX, setPowerX] = React.useState([]); //x-axis dates
  const [powerY, setPowerY] = React.useState([]); //y-axis values
  // { data: [60] }

  function formatChargingDuration(duration) {
    if (!duration || typeof duration !== "string") {
      return "00:00:00:00";
    }
    const parts = duration.split(":");
    if (parts.length !== 4) {
      return "00:00:00:00";
    }
    const [days, hours, minutes, seconds] = parts.map((num) =>
      String(Number(num)).padStart(2, "0")
    );
    return `${days}:${hours}:${minutes}:${seconds}`;
  }
  const Visibility = () => {
    setVisible((visible) => !visible);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const navigate = useNavigate();

  const headCells = [
    {
      id: "value",
      numeric: false,
      disablePadding: true,
      label: "Value",
      mandatory: true,
      filterOption: true,
      searchType: "text",
      sort: true,
    },

    {
      id: "unit",
      numeric: false,
      disablePadding: false,
      label: "Unit",
      mandatory: false,
      filterOption: false,
      searchType: "text",
      sort: true,
    },
    {
      id: "timestamp",
      numeric: false,
      disablePadding: false,
      label: "Updated Time",
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "fromDate",
      numeric: false,
      disablePadding: false,
      label: "From Date",
      filterOption: true,
      onlySearch: true,
      searchType: "date",
    },
    {
      id: "toDate",
      numeric: false,
      disablePadding: false,
      label: "To Date",
      filterOption: true,
      onlySearch: true,
      searchType: "date",
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleOptionClick = (value) => {
    setOption(option === value ? "" : value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "value") {
          return {
            key: key, // or "chargingSessionId"
            value: searchInputs[key],
            operation: searchOperations.EQUALITY,
          };
        } else if (key === "fromDate") {
          return {
            key: "timestamp",
            value: searchInputs.fromDate,
            operation: searchOperations.GREATER_THAN,
          };
        } else if (key === "toDate") {
          return {
            key: "timestamp",
            value: searchInputs.toDate,
            operation: searchOperations.LESS_THAN,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });

    // Add the additional chargingSessionId object from location.state
    if (location.state) {
      params.push({
        key: "chargingSessionId",
        value: location.state.id,
        operation: searchOperations.EQUALITY,
      });
    }

    return params;
  };

  const getChargingSessions = async () => {
    const searchParams = buildSearchParams();
    const requestBody = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    let Url = `metervalues/search`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      requestBody,
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    // fetch(`${basePathone}metervalues/search`, {
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify(requestBody),
    // })
    //   .then((resp) => {
    //     setLoader(false);
    //     if (resp.status === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return resp.json();
    //     }
    //   })
    //   .then((res) => {
    setChargingPowerData(res?.data);
    if (res?.data) {
      let x = powerX;
      let y = powerY;
      res?.data?.map((record, index) => {
        if (record?.updatedAt) {
          x.push(moment(record?.updatedAt).format("DD MMM YYYY"));
        }
        y.push(record?.value);
      });
      setPowerX(x);
      setPowerY(y);
    }
    setCount(res?.count);
    setNoData(res?.count === 0 ? "No Data Available" : "");
    // });
  };
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      value: "",
      fromDate: "",
      toDate: "",
    });
    setFilterState([
      { key: "value", value: "" },
      { key: "fromDate", value: "" },
      { key: "toDate", value: "" },
    ]);
  };
  React.useEffect(() => {
    getChargingSessions();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);
  React.useEffect(() => {
    if (refresh) {
      getChargingSessions();
      setSessionObject(location.state);
      getBillingValuesBySessionId();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    setSessionObject(location.state);
    getBillingValuesBySessionId();
  }, []);
  const getBillingValuesBySessionId = async () => {
    let Url = `charging-sessions-billing/session?chargingSessionId=${location.state.id}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    // fetch(
    //   `${basePathone}charging-sessions-billing/session?chargingSessionId=${location.state.id}`,
    //   {
    //     method: "GET",
    //     headers: headers,
    //   }
    // )
    //   .then((resp) => {
    //     setLoader(false);
    //     if (resp.status === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return resp.json();
    //     }
    //   })
    //   .then((res) => {
    if (res?.status) {
      setBillingValues(res?.data);
    }
    // });
  };

  return (
    <div>
      <div className=" mb-4 mx-0 p-0 d-flex align-items-center justify-content-between">
        <div className="pagetitle">Station Meter Values</div>
        <div>
          <Button
            className="submit-btn"
            variant="contained"
            size="small"
            onClick={() => navigate("/viewLocation")}
          >
            <ArrowBackIosIcon fontSize="10px" />
            Back
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-4 ">
          <div className="p-4 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Charge PointID
              </span>
              <span>:</span>
              <span>{sessionObject.chargingStationId}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                ConnectorID
              </span>
              <span>:</span>
              <span>{sessionObject.connectorId}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Charging Duration
              </span>
              <span>:</span>
              <span>
                {formatChargingDuration(sessionObject.chargingDuration)}
              </span>
            </div>
            {/* <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Vehicle Mileage
              </span>
              <span>:</span>
              <span>{sessionObject.mileage}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Vehicle Inventory Number
              </span>
              <span>:</span>
              <span>{sessionObject.vehicleInventoryNumber}</span>
            </div> */}
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Max Charging Power
              </span>
              <span>:</span>
              <span>{sessionObject.maximumChargingPower}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Authorization Type
              </span>
              <span>:</span>
              {/* <span>{sessionObject.chargingStationId}</span> */}
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Charging Station Location
              </span>
              <span>:</span>
              {/* <span>{sessionObject.chargingStationId}</span> */}
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Customer Number
              </span>
              <span>:</span>
              <span>{sessionObject.customerNumber}</span>
            </div>
          </div>
        </div>
        <div className="col-4 ">
          <div className="p-4 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Charging Status
              </span>
              <span>:</span>
              <span>{sessionObject.chargingState}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                ID Tag
              </span>
              <span>:</span>
              <span>{sessionObject.idTag}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Start Time
              </span>
              <span>:</span>
              <span>
                {sessionObject.startTime
                  ? moment(sessionObject.startTime).format("YYYY-MM-DD hh:mm A")
                  : null}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Charging Session Duration
              </span>
              <span>:</span>
              <span>
                {formatChargingDuration(sessionObject.chargingSessionDuration)}
              </span>
            </div>

            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Goal Reached Time
              </span>
              <span>:</span>
              <span>
                {sessionObject.chargingGoalReachedTime
                  ? moment(sessionObject.chargingGoalReachedTime).format(
                      "DD-MMM-YYYY hh:mm A"
                    )
                  : null}
              </span>
            </div>

            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Current Charging Power
              </span>
              <span>:</span>
              <span>
                {/* {sessionObject.currentChargingPower}  */}
                {sessionObject.currentChargingPower !== undefined &&
                sessionObject.currentChargingPower !== null
                  ? `${sessionObject.currentChargingPower} WH`
                  : ""}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Reference Number
              </span>
              <span>:</span>
              <span>{sessionObject.referenceNumber}</span>
            </div>
          </div>
        </div>
        <div className="col-4 ">
          <div className="p-4 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                ID
              </span>
              <span>:</span>
              <span>{sessionObject.id}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Reservation ID
              </span>
              <span>:</span>
              <span>{sessionObject.reservationId}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Stop Time
              </span>
              <span>:</span>
              <span>
                {sessionObject.endTime
                  ? moment(sessionObject.endTime).format("YYYY-MM-DD hh:mm A")
                  : null}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Stop Reason
              </span>
              <span>:</span>
              <span>{sessionObject.stoppedReason}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Energy Level
              </span>
              <span>:</span>
              <span>{sessionObject.energyLevel}</span>
            </div>
            {/* <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Note
              </span>
              <span>:</span>
              <span>{chargingSessionList.referenceNumber}</span>
            </div> */}
          </div>
        </div>
      </div>

      <div
        className="view my-3 "
        onClick={toggleVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>Charging Session Costs</span>
        <span className={`icon ${isVisible ? "minus" : "plus"}`}>
          {isVisible ? "-" : "+"}
        </span>
      </div>
      {isVisible && (
        <div className="row border br-10 mx-0">
          <div className="col-4 p-0">
            <div className="p-3 cardBackground h-100">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Plug In Time
                </span>
                <span>:</span>
                <span>
                  {sessionObject.startTime
                    ? moment(sessionObject.startTime).format(
                        "YYYY-MM-DD hh:mm A"
                      )
                    : null}
                </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Plug Out time
                </span>
                <span>:</span>
                <span>
                  {sessionObject.stotTime
                    ? moment(sessionObject.stotTime).format(
                        "YYYY-MM-DD hh:mm A"
                      )
                    : null}
                </span>
              </div>
            </div>
          </div>
          <div className="col-4 p-0">
            <div className="p-3  cardBackground h-100">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Start Meter Value
                </span>
                <span>:</span>
                <span>{sessionObject.meterStart}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Stop Meter Value
                </span>
                <span>:</span>
                <span>{sessionObject.meterStop}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Charged Energy
                </span>
                <span>:</span>
                <span>{sessionObject.chargedEnergy}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Cost per Charging Session
                </span>
                <span>:</span>
                <span>{billingValues?.costsPerChargingSession}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Running Cost
                </span>
                <span>:</span>
                <span>{billingValues?.runningCost}</span>
              </div>
            </div>
          </div>
          <div className="col-4 p-0">
            <div className="p-3  cardBackground h-100">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  One-time fees
                </span>
                <span>:</span>
                <span>{billingValues?.costsPerChargingSession}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Costs per kWH
                </span>
                <span>:</span>
                <span>{billingValues?.costsPerKwh}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Charging Session Currency
                </span>
                <span>:</span>
                <span>{billingValues?.chargingSessionCurrency}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Total Costs
                </span>
                <span>:</span>
                <span>
                  {billingValues?.totalCost} {billingValues?.currencyCode}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div> */}
      <div className="pagetitleview cursor-Pointers d-flex">
        <span
          className="col-10"
          onClick={() => {
            alignment ? setAlignment("") : setAlignment("table");
          }}
        >
          Charging Power
        </span>
        <div className="col-2 d-flex justify-content-end">
          <div>
            <small className="tc-blue me-2" style={{ fontSize: "16px" }}>
              View
            </small>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              className="table-graph-toggle"
            >
              <ToggleButton
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                className="table-graph-toggle"
              >
                <i class="bi bi-table"></i>
              </ToggleButton>
              <ToggleButton
                color="primary"
                value="graph"
                style={{ width: "40px" }}
              >
                <i class="bi bi-bar-chart-line-fill"></i>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* </div> */}

      {alignment === "table" && (
        // {isVisible && (
        <div className="row border br-10 mx-0 h-auto">
          <Box sx={{ width: "100%", position: "relative" }}>
            <div className="d-flex align-items-center justify-content-between cardFilter">
              <div>
                <DisplayFilterInputs
                  searchInputs={searchInputs}
                  clearFilterValue={clearFilterValue}
                  clearInputValue={clearInputValue}
                  resetStates={resetStates}
                />
              </div>
              <div>
                <material.Box className="d-flex align-items-center gap-3">
                  <div>
                    <div
                      className="filter-filter bgcolor-blue"
                      onClick={() => handleOptionClick("filter")}
                    >
                      <i class="fa-solid fa-sliders"></i>
                      <span className="ms-1">Filter</span>
                    </div>
                    {option === "filter" && (
                      <material.ClickAwayListener
                        onClickAway={() => handleOptionClick("")}
                      >
                        <div className="filterBox">
                          <FilterTable
                            headCells={headCells}
                            filterColumns={filterColumns}
                            handleOptionClick={handleOptionClick}
                            updateFilter={updateFilter}
                            filterState={filterState}
                            syncFilterStateToSearchInputs={
                              syncFilterStateToSearchInputs
                            }
                          />
                        </div>
                      </material.ClickAwayListener>
                    )}
                  </div>
                </material.Box>
              </div>
            </div>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"small"}
                >
                  <TableHeadRow
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={chargingPowerData.length}
                    disableColumns={[]}
                    disableCheckBox={true}
                  />
                  <TableBody>
                    {chargingPowerData.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow tabIndex={-1} key={row.id}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={false} disabled={true} />
                          </TableCell>
                          <TableCell>{row.value}</TableCell>
                          <TableCell>{row.unit}</TableCell>
                          <TableCell>
                            {row?.updatedAt
                              ? moment(row?.updatedAt).format(
                                  "DD MMM YYYY | hh:mm A"
                                )
                              : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {chargingPowerData.length === 0 && (
                      <TableRow style={{ height: 40 }}>
                        <TableCell colSpan={8} className="saas-nodata-row">
                          {noData}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {chargingPowerData.length > 0 && (
                <CustomTablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </Box>
        </div>
      )}
      {alignment === "graph" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px",
            backgroundColor: "white",
            padding: "10px",
          }}
        >
          <PowerGraph powerX={powerX} powerY={powerY} />
        </div>
      )}

      <div>
        <SessionEvents
          chargingSessionId={location.state?.transactionId}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
      <div className="mb-5">
        <ErrorCodes refresh={refresh} setRefresh={setRefresh} />
      </div>
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}
