/** @format */

import * as React from "react";
import * as material from "@mui/material";
import Loader from "../../components/Loader";
import Box from "@mui/material/Box";
import CustomTablePagination from "../../components/CustomTablePagination";
import { Edit } from "@mui/icons-material";
import PopUp from "../../components/PopUp";
import AddForms from "./AddForms";
import EditForms from "./EditForms";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import { basePath } from "../../common/config";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import { useTranslation } from "react-i18next";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import { headers } from "../../common/Headers";
import { handleApi_call } from "../../common/CommonAPI";

export default function Email({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [user, setuser] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [openAddForm, setOpenAddForm] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  // const [message, setMessage] = React.useState("");
  const [searchInputs, setSearchInputs] = React.useState({
    templateName: "",
    subject: "",
  });
  const [option, setOption] = React.useState("");
  const [addform, setAddForm] = React.useState({
    templateName: "",
    subject: "",
    body: "",
  });
  const [addformErrors, setAddformErrors] = React.useState({
    templateName: "",
    subject: "",
    body: "",
  });
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [emails, setEmails] = React.useState([]);
  const [noData, setNoData] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [updateRow, setUpdateRow] = React.useState({});
  const [updatePopup, setUpdatePopup] = React.useState(false);
  const [updateRowErrors, setUpdateRowErrors] = React.useState({
    templateName: "",
    subject: "",
    body: "",
  });
  const [alertMessage, setAlertMessage] = React.useState("");
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [filterState, setFilterState] = React.useState([
    { key: "templateName", value: "" },
    { key: "subject", value: "" },
  ]);
  const resetStates = () => {
    setSearchInputs({
      templateName: "",
      subject: "",
    });
    setFilterState([
      { key: "templateName", value: "" },
      { key: "subject", value: "" },
    ]);
  };
  const headCells = [
    {
      id: "templateName",
      numeric: false,
      disablePadding: false,
      filterOption: true,
      searchType: "text",
      label: "Template Name",
      mandatory: false,
    },
    {
      id: "tenantName",
      numeric: false,
      disablePadding: false,
      label: "Tenant Name",
      filterOption: false,
      mandatory: true,
    },
    {
      id: "subject",
      numeric: false,
      disablePadding: false,
      label: "Subject",
      filterOption: true,
      searchType: "text",
      mandatory: false,
    },

    {
      id: "body",
      numeric: false,
      disablePadding: false,
      label: "Body",
      filterOption: false,
      mandatory: false,
    },
    {
      id: "update",
      numeric: false,
      disablePadding: false,
      label: "Update",
      mandatory: true,
      filterOption: false,
      sort: false,
    },
  ];
  React.useEffect(() => {
    if (refresh) {
      getLogs();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getLogs();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);

  const handleOpen = () => setOpenAddForm(true);
  const handleClose1 = () => {
    setAddForm({
      templateName: "",
      subject: "",
      body: "",
    });

    setAddformErrors({
      templateName: "",
      subject: "",
      body: "",
    });
    setOpenAddForm(false);
  };

  function handleEditClick(row) {
    setUpdateRow(row);
    setUpdatePopup(true);
  }
  function handleDeletePopup() {
    setDeletePopup((pop) => !pop);
    setPage(0);
  }

  function handleCloseUpdatePopup() {
    setUpdateRowErrors({
      templateName: "",
      subject: "",
      body: "",
    });
    setUpdatePopup(false);
    setPage(0);
  }

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  const handleClick1 = (data) => {
    setOpen(true);
    setTimeout(() => {
      document.getElementById("message").innerHTML = data;
    }, 1000);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        templateName: "",
        subject: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }

  function removeInput(input) {
    setFilterColumns((columns) => columns.filter((cell) => cell !== input));
    setSearchInputs({ ...searchInputs, [input.id]: "" });
  }
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };

  function handleAlertOk() {
    getLogs();
    handleAlertPopup();
  }
  function handleUpdate() {
    let error = false;
    if (
      updateRow.templateName?.length === 0 ||
      updateRow.subject?.length === 0 ||
      updateRow.body?.length === 0
    ) {
      error = true;
    }
    if (error) {
      if (updateRow.templateName?.length === 0) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          templateName: t("template_name_validation"),
        }));
      }
      if (updateRow.subject?.length === 0) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          subject: t("subject_validation"),
        }));
      }
      if (updateRow.body?.length === 0) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          body: t("body_validation"),
        }));
      }
    } else {
      updateEmail();
    }
  }
  function handleUpdateForm(key, value) {
    setUpdateRowErrors({ ...updateRowErrors, [key]: "" });
    setUpdateRow({ ...updateRow, [key]: value });
  }
  function handleAddForm(key, value) {
    setAddformErrors({ ...addformErrors, [key]: "" });
    setAddForm({
      ...addform,
      [key]: value,
    });
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  function handleAddUser() {
    let error = false;
    if (
      addform.templateName?.length === 0 ||
      addform.subject?.length === 0 ||
      addform.body?.length === 0
    ) {
      error = true;
    }
    if (error) {
      if (addform.templateName === "") {
        setAddformErrors((errors) => ({
          ...errors,
          templateName: t("template_name_validation"),
        }));
      }
      if (addform.subject === "") {
        setAddformErrors((errors) => ({
          ...errors,
          subject: t("subject_validation"),
        }));
      }
      if (addform.body === "") {
        setAddformErrors((errors) => ({
          ...errors,
          body: t("body_validation"),
        }));
      }
    } else {
      addEmailTemplate();
    }
  }
  const getLogs = async () => {
    const Url = `api/email-templates?pagenumber=${
      page + 1
    }&pagesize=${rowsPerPage}&templateName=${
      searchInputs.templateName
    }&subject=${searchInputs.subject}&sortBy=${orderBy}&sortDir=${order}`;
    const type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    setLoader(false);
    setEmails(res?.data?.content ?? []);
    setCount(res?.data?.totalElements);
    setNoData(res?.data?.totalElements === 0 ? "No data Available" : "");
  };
  const addEmailTemplate = async () => {
    let pload = {
      ...addform,
      tenantId: localStorage.getItem("tenantId"),
    };
    let Url = "api/email-templates";
    let type = "POST";
    let body = pload;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      handleClose1();
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };
  const updateEmail = async () => {
    let payload = {
      ...updateRow,
      tenantId: updateRow.tenantId,
      templateId: updateRow.templateId,
    };
    let Url = "api/email-templates/" + updateRow.id;
    let type = "PUT";
    let body = payload;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      handleCloseUpdatePopup();
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = emails.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    setuser(newSelected[0]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const deleteEmails = () => {
    //setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(basePath + "api/email-templates/deleteemail", {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(selected),
    })
      .then((resp) => resp.json())
      .then((res) => {
        if (res?.status) {
          setStatus(true);
          setSelected([]);
          handleDeletePopup();
          setAlertMessage(res.message);
          handleAlertPopup();
        } else {
          setStatus(false);
          setAlertMessage(res.message);
          handleAlertPopup();
        }
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Email Templates</div>
        <div className="d-flex align-items-center gap-3 ">
          <Button onClick={handleOpen}>
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Button>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                      //  setFilterState={setFilterState}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red " onClick={handleDeletePopup}>
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          sx={{ height: 439 }}
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={emails.length}
              disableColumns={disableColumns}
              disableCheckBox={false}
            />
            <TableBody>
              {emails?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    tabIndex={-1}
                    key={row.id}
                    aria-checked={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    {!disableColumns.includes("templateName") && (
                      <TableCell>{row.templateName}</TableCell>
                    )}
                    {!disableColumns.includes("tenantName") && (
                      <TableCell>{row?.tenant?.tenantName}</TableCell>
                    )}
                    {!disableColumns.includes("subject") && (
                      <TableCell>{row.subject}</TableCell>
                    )}
                    {!disableColumns.includes("body") && (
                      <TableCell>
                        <i className="fa-solid fa-eye" style={{ color: "#2DCC70" }} onClick={() => handleClick1(row.body)}></i>
                      </TableCell>
                    )}
                    {!disableColumns.includes("update") && (
                      <TableCell>
                        <i className="fa-solid fa-pencil action-icons" style={{ color: "#2DCC70" }} onClick={() => handleEditClick(row)}></i>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emails.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {emails.length > 0 && (
          <CustomTablePagination
            rowsPerPageOptions={[10, 15, 20]}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <PopUp
          open={openAddForm}
          title={t("add_email_template")}
          message={
            <AddForms
              addform={addform}
              handleAddForm={handleAddForm}
              roles={emails}
              addformErrors={addformErrors}
            />
          }
          btn1={true}
          btn2={true}
          btn1Color={"cancel-btn"}
          btn2Color={"submit-btn"}
          btn1Text={t("Cancel")}
          btn2Text={t("Submit")}
          handleClickBtn1={handleClose1}
          handleClickBtn2={handleAddUser}
          popUpWidth="width-100"
        />
        <PopUp
          title={t("Edit Form")}
          message={
            <EditForms
              updateRow={updateRow}
              handleUpdateForm={handleUpdateForm}
              roles={emails}
              updateRowErrors={updateRowErrors}
            />
          }
          open={updatePopup}
          popUpWidth={"width-100"}
          btn1={true}
          btn1Color={"cancel-btn"}
          btn1Text={t("Cancel")}
          handleClickBtn1={handleCloseUpdatePopup}
          btn2={true}
          btn2Color={"submit-btn"}
          btn2Text={t("Update")}
          handleClickBtn2={handleUpdate}
        />
        <PopUp
          open={deletePopup}
          title={t("Confirm")}
          message={t("Do you want to delete these items")}
          btn1={true}
          btn1Text={t("Cancel")}
          btn1Color={"submit-btn"}
          handleClickBtn1={handleDeletePopup}
          btn2={true}
          btn2Text={t("Delete")}
          btn2Color={"cancel-btn"}
          handleClickBtn2={deleteEmails}
          popUpWidth={"width-100"}
        />
        <PopUp
          open={alertPopup}
          handleClosePopUp={handleAlertPopup}
          title={t(status === true ? "SUCCESS" : "FAILURE")}
          message={alertMessage}
          btn1={true}
          btn1Text={t("ok")}
          btn1Color={"submit-btn"}
          handleClickBtn1={handleAlertOk}
          popUpWidth={"width-100"}
        />
        <PopUp
          open={open}
          title={t("View Body")}
          message={<div id="message"></div>}
          btn1={true}
          btn1Text={t("Ok")}
          btn1Color={"submit-btn"}
          handleClickBtn1={handleClose}
          popUpWidth={"width-100"}
        />
      </Paper>
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
