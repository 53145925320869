import {
  Button,
  FormControl,
  FormLabel,
  Switch,
  TextField,
} from "@mui/material";
import PopUp from "../../components/PopUp";
import React from "react";
import { handleEmail } from "../../common/helpers";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";

function Overview({ chargeStation, getChargeStation }) {
  const [assignPopup, setAssignPopup] = React.useState(false);
  const [assignEmail, setAssignEmail] = React.useState("");
  const [assignEmailValidation, setAssignEmailValidation] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [sessionExpire, setSessionExpire] = React.useState(false);

  function handleAssignChange(e) {
    let valid = handleEmail(e.target.value);
    setAssignEmail(e.target.value);
    if (valid) {
      setAssignEmailValidation("Enter Valid Email");
    } else {
      setAssignEmailValidation("");
    }
  }
  const checkFirmWareVersion = (firmwareVersion, chargingStationModel) => {
    let headers = {
      "content-type": "application/json",
      // Authorization: localStorage.getItem("Authorization"),
      // "X-TenantID": localStorage.getItem("tenantId"),
    };
    let payload = {
      firmwareVersion: firmwareVersion,
      chargingStationModel: chargingStationModel,
    };
    fetch(basePathone + "csinfo/checkFirmWareUpdate", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        setAlertMessage(res.message);
        handleAlertPopup();
      });
  };
  const updateLogbookStatus = (status, chargingStationId) => {
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(
      basePathone +
        `csinfo/toggleLogBookStatus/${chargingStationId}?status=${status}`,
      {
        method: "PATCH",
        headers: headers,
      }
    )
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        setAlertMessage(res.message);
        handleAlertPopup();
      });
  };
  const assignOrRemoveEmail = (email, chargingStationId, assign) => {
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    let payload = {
      email: email,
      chargingStationId: chargingStationId,
      assign: assign,
    };
    fetch(basePathone + "csinfo/assignOrRemoveEmail", {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        setAlertMessage(res.message);
        handleAlertPopup();
      });
  };
  function handleAssign() {
    if (!assignEmail) {
      setAssignEmailValidation("User Email is Mandatory");
    } else {
      assignOrRemoveEmail(assignEmail, chargeStation.chargingStationId, 1);
      setAssignEmail("");
      handleAssignPopup();
    }
  }
  function handleLogbookStatus(logbookData, chargingStationId) {
    const status = logbookData ? 0 : 1;
    updateLogbookStatus(status, chargingStationId);
  }
  function handleAssignPopup() {
    setAssignPopup((pop) => !pop);
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleAlertOk() {
    getChargeStation();
    handleAlertPopup();
  }
  function handleAssignPopupClose() {
    setAssignEmail("");
    setAssignEmailValidation("");
    handleAssignPopup();
  }
  return (
    <>
      <div className="row">
        <div className="col-4">
          <div className="p-3 border br-10 cardBackground">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "30%" }}>
                Organization
              </span>
              <span>:</span>
              <span>{chargeStation?.chargePointVendor}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "30%" }}>
                Installation Site
              </span>
              <span>:</span>
              <span>{chargeStation?.installationSite}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "30%" }}>
                Forwarded to
              </span>
              <span>:</span>
              <span>{chargeStation?.vendorName}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3">
              <div style={{ width: "30%" }}>
                <span className="saas-boldHeading me-1">Logbook</span>
                <span className="customTooltip">
                  <i className="fa-solid fa-circle-info text-primary"></i>
                  <div className="position-absolute z-3 br-10 cardBackground customTooltipCard">
                    <div
                      id="heading"
                      style={{ backgroundColor: "#f0f0f0" }}
                      className="text-center saas-boldHeading p-1"
                    >
                      Logbook
                    </div>
                    <div id="body" className="p-1 customTooltipBody">
                      If activated, all users who want to start a
                      chargingsession within the Webasto ChargeConnect App need
                      to insert the mileage of the used vehicle and the
                      inventory number of the used vehicle.
                    </div>
                  </div>
                </span>
              </div>
              <span>:</span>
              <Switch
                size="small"
                checked={chargeStation?.logbookData}
                onClick={() =>
                  handleLogbookStatus(
                    chargeStation.logbookData,
                    chargeStation.chargingStationId
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="border br-10 p-3 cardBackground h-100">
            <span className="saas-boldHeading">Description:</span>
            <span className="ms-2">{chargeStation?.description}</span>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <div className="p-3 border br-10 cardBackground h-100">
            <span style={{ fontSize: "15px" }} className="saas-boldHeading">
              Boot Configuration
            </span>
            <div className="mt-3">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  FW Version
                </span>
                <span>:</span>
                <span>{chargeStation?.firmwareVersion}</span>
                {chargeStation?.firmwareVersion && (
                  <div className="checkUpdate">
                    <SystemUpdateAltIcon
                      color="primary"
                      fontSize="small"
                      className="pointerCursor"
                      onClick={() =>
                        checkFirmWareVersion(
                          chargeStation.firmwareVersion,
                          chargeStation.chargingStationModel
                        )
                      }
                    />
                    <div className="updateTooltip">Check for Updates</div>
                  </div>
                )}
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Model
                </span>
                <span>:</span>
                <span>{chargeStation?.chargingStationModel}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  MES Serial Number
                </span>
                <span>:</span>
                <span>{chargeStation?.chargingStationSerialNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  ChargeBox Serial Number
                </span>
                <span>:</span>
                <span>{chargeStation?.chargeBoxSerialNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Meter Serial Number
                </span>
                <span>:</span>
                <span>{chargeStation?.meterSerialNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Part Number
                </span>
                <span>:</span>
                <span>{chargeStation?.partNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center mb-2 gap-3">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Meter Type
                </span>
                <span>:</span>
                <span>{chargeStation?.meterType}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  ICCID
                </span>
                <span>:</span>
                <span>{chargeStation?.iccid}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="p-3 cardBackground border br-10 mb-4">
            <span className="saas-boldHeading" style={{ fontSize: "15px" }}>
              Address
            </span>
            <div className="mt-3">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  House Number
                </span>
                <span>:</span>
                <span>{chargeStation?.csAddress?.houseNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Street
                </span>
                <span>:</span>
                <span>{chargeStation?.csAddress?.street}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  City
                </span>
                <span>:</span>
                <span>{chargeStation?.csAddress?.city}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Zip Code
                </span>
                <span>:</span>
                <span>{chargeStation?.csAddress?.zipCode}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Country
                </span>
                <span>:</span>
                <span>{chargeStation?.csAddress?.country}</span>
              </div>
            </div>
          </div>
          <div className="p-3 cardBackground border br-10">
            <div className="d-flex justify-content-between align-items-center">
              <span className="saas-boldHeading" style={{ fontSize: "15px" }}>
                Assignment
              </span>
              {chargeStation?.assignedEmail ? (
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={() =>
                    assignOrRemoveEmail(
                      chargeStation.assignedEmail,
                      chargeStation.chargingStationId,
                      0
                    )
                  }
                  style={{ borderRadius: "20px" }}
                >
                  Delete <DeleteIcon fontSize="inherit" className="ms-1" />
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  style={{ borderRadius: "20px" }}
                  onClick={handleAssignPopup}
                >
                  Assign
                </Button>
              )}
            </div>
            <div className="mt-3">
              <div className=" w-100 d-flex align-items-center gap-3">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Wallbox is assigned to
                </span>
                <span>:</span>
                <span>{chargeStation?.assignedEmail}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp
        open={assignPopup}
        title={"Assign Wallbox to residential enduser"}
        message={
          <FormControl className="simpleInput">
            <FormLabel>
              <b>User Email ID *</b>
            </FormLabel>
            <TextField
              size="small"
              required
              value={assignEmail}
              onChange={handleAssignChange}
              error={assignEmailValidation}
              helperText={assignEmailValidation}
            />
          </FormControl>
        }
        btn1={true}
        btn1Color={"cancel-btn"}
        btn1Text={"Cancel"}
        handleClickBtn1={handleAssignPopupClose}
        btn2={true}
        btn2Color={"submit-btn"}
        btn2Text={"Assign"}
        handleClickBtn2={handleAssign}
        popUpWidth={"width-100 popUpMaxWidth"}
      />
      <PopUp
        open={alertPopup}
        title={"Alert"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      {sessionExpire && <SessionExpire />}
    </>
  );
}

export default Overview;
