import { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import { basePath } from "../../common/config";
import { Link } from "react-router-dom";
import { handleEmail } from "../../common/helpers";
import { Alert } from "@mui/material";

function ForgetPassword() {
  const [inputFields, setInputFields] = useState({
    tenantid: "",
    email: "",
  });
  let valid = false;
  const [errors, setErrors] = useState({
    tenantid: "",
    email: "",
  });
  const [errorMessage, seterrorMessage] = useState(false);
  function handleChange(e) {
    setErrors({ ...errors, [e.target.name]: "" });
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      valid = handleEmail(e.target.value);
      if (valid) {
        setErrors({
          ...errors,
          [e.target.name]: "Enter Valid Email",
        });
      } else {
        setErrors({ ...errors, [e.target.name]: "" });
      }
    }
  }
  function closewAlertbox() {
    setTimeout(() => {
      seterrorMessage(false); // Hide alert after 3 seconds
    }, 3000);
  }
  function handleSubmit(e) {
    e.preventDefault();
    let error = false;
    if (inputFields.tenantid.length === 0 || inputFields.email.length === 0) {
      error = true;
    }
    if (error) {
      if (inputFields.tenantid.length <= 0) {
        setErrors((errors) => ({
          ...errors,
          tenantid: "Tenant Id is Mandatory",
        }));
      }
      if (inputFields.email.length <= 0) {
        setErrors((errors) => ({ ...errors, email: "Email is Mandatory" }));
      }
    }

    let to = inputFields.email;
    let headers = {
      "content-type": "application/json",
      "X-TenantID": inputFields.tenantid,
    };

    if (inputFields.email) {
      fetch(basePath + `auth/forget-password?to=${to}`, {
        method: "POST",
        headers: headers,
      })
        .then((resp) => resp.json())

        .then((res) => {
          if (res.status === true) {
            seterrorMessage(true);
            closewAlertbox();
          } else {
            if (res.status === false && res.message.includes("Tenant")) {
              setErrors((errors) => ({
                ...errors,
                tenantid: res.message,
              }));
            } else if (res.status === false && res.message.includes("Email")) {
              setErrors((errors) => ({
                ...errors,
                email: res.message,
              }));
            }
          }
        });
    }
  }

  return (
    <div className="row g-0 login-container">
      <div className="col-lg-6 img-hide">
        <img src="bg.jpg" alt="background" className="img-style" />
      </div>
      <div className="col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center form-container">
        <form className="p-3 form-layout mx-auto">
          <div className="text-center mb-5">
            <h3> Forgot Password?</h3>
            {errorMessage && (
              <Alert
                variant="filled"
                severity="success"
                className="w-100 mb-4 "
              >
                Email sent successfully
              </Alert>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="form-label text-muted lables-hide">
              Tenant ID
            </label>
            <div className="input-container">
              <FontAwesomeIcon icon={faUser} className="input-icon" />
              <input
                id="tenantId"
                name="tenantid"
                type="text"
                className="form-control rounded-input"
                value={inputFields.tenantid}
                placeholder="123456"
                onChange={handleChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>
              {errors.tenantid}
            </div>
          </div>
          <div className="form-group mb-3">
            <label className="form-label text-muted lables-hide">Email</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
              <input
                name="email"
                id="email"
                type="email"
                className="form-control rounded-input"
                value={inputFields.email}
                placeholder="example@gmail.com"
                onChange={handleChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>{errors.email}</div>
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-success mt-3 p-2"
          >
            Submit
          </button>
        </form>
        <div className="mt-3">
          <Link to="/" className="forgot-signin-link">
            <KeyboardArrowLeftIcon className="mb-1" /> Back to Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
