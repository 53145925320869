import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import * as material from "@mui/material";
import moment from "moment";
import TableHeadRow from "../../components/TableHeadRow";
import ColumnsBox from "../../components/ColumnsBox";
import PopUp from "../../components/PopUp";
import SessionExpire from "../../components/SessionExpire";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import FilterTable from "../../components/FilterTable";
import CustomTablePagination from "../../components/CustomTablePagination";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    mandatory: false,
    filterOption: true,
    sort: true,
    searchType: "text",
    minWidth: 150,
  },
  {
    id: "parkingType",
    numeric: false,
    disablePadding: false,
    label: "Parking Type",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "energy_mix.energy_product_name",
    numeric: false,
    disablePadding: false,
    label: "Energy Product Name",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "Longitude",
    numeric: false,
    disablePadding: false,
    label: "Longitude",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 150,
  },
  {
    id: "Latitude",
    numeric: false,
    disablePadding: false,
    label: "Latitude",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 150,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Address",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 350,
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "last_updated",
    numeric: false,
    disablePadding: false,
    label: "Updated Date ",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 180,
  },
  {
    id: "publish",
    numeric: false,
    disablePadding: false,
    label: "Publish",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Status",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "update",
    numeric: false,
    disablePadding: false,
    label: "Update",
    mandatory: false,
    filterOption: false,
    sort: false,
  },
  {
    id: "fromDate",
    numeric: false,
    disablePadding: false,
    label: "From Date",
    filterOption: true,
    onlySearch: true,
    searchType: "date",
  },
  {
    id: "toDate",
    numeric: false,
    disablePadding: false,
    label: "To Date",
    filterOption: true,
    onlySearch: true,
    searchType: "date",
  },
];

function Location({ refresh, setRefresh }) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("last_updated");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [noData, setNoData] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [searchInputs, setSearchInputs] = React.useState({
    name: "",
    fromDate: "",
    toDate: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "name", value: "" },
    { key: "fromDate", value: "" },
    { key: "toDate", value: "" },
  ]);
  const [count, setCount] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [disableColumns, setDisableColumns] = React.useState([
    "Longitude",
    "Latitude",
    "address",
    "state",
    "last_updated",
  ]);
  const [option, setOption] = React.useState("");
  const [locationDetails, setLocationDetails] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [activeDeactivePopUp, setActivateDeactivePopUp] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState();
  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(() => {
    if (refresh) {
      getLocation();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  const getLocation = async () => {
    let Url = `cpo/2.2/locations?pageNo=${
      page + 1
    }&pageSize=${rowsPerPage}&name=${searchInputs.name}&fromDate=${
      searchInputs.fromDate
    }&toDate=${searchInputs.toDate}&sortBy=${orderBy}&sortDir=${order}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.statusCode) {
      setLocationDetails(res?.data);
      setCount(res?.count);
      setNoData(res?.count === 0 ? "No Data Available" : "");
    }
  };

  function handleActiveDeactivePopup(Id, isActive) {
    setIsActive(isActive);
    setSelectedRow(Id);
    setActivateDeactivePopUp((pop) => !pop);
  }
  async function activateDeactivateAccount() {
    let Url = `api/locations/${selectedRow}/${
      isActive === true ? false : true
    }`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.statusCode) {
      handleAlertPopup();
      setAlertMessage(res?.data);
    }
    setActivateDeactivePopUp(false);
  }

  useEffect(() => {
    getLocation();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);

  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };

  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };

  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };

  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };

  const resetStates = () => {
    setSearchInputs({
      name: "",
      fromDate: "",
      toDate: "",
    });
    setFilterState([
      { key: "name", value: "" },
      { key: "fromDate", value: "" },
      { key: "toDate", value: "" },
    ]);
  };

  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        name: "",
        fromDate: "",
        toDate: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }

  function handleViewLocation(LocationId) {
    localStorage.setItem("LocationID", LocationId);
    navigate("/viewLocation");
  }

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  function handleAlertOk() {
    getLocation();
    handleAlertPopup();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Locations</div>
        <div className="d-flex align-items-center gap-3">
          <Link to="/AddLocation">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 200 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={locationDetails.length}
              disableColumns={disableColumns}
              disableCheckBox={true}
            />
            <TableBody>
              {locationDetails.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox size="small" checked={false} disabled={true} />
                    </TableCell>
                    {!disableColumns.includes("name") && (
                      <TableCell
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => handleViewLocation(row.id)}
                      >
                        <span>{row.name}</span>
                      </TableCell>
                    )}
                    {!disableColumns.includes("parkingType") && (
                      <TableCell>{row.parkingType}</TableCell>
                    )}
                    {!disableColumns.includes(
                      "energy_mix.energy_product_name"
                    ) && (
                      <TableCell>
                        {row?.energy_mix?.energy_product_name}
                      </TableCell>
                    )}
                    {!disableColumns.includes("Longitude") && (
                      <TableCell>{row?.coordinates?.longitude}</TableCell>
                    )}
                    {!disableColumns.includes("Latitude") && (
                      <TableCell>{row?.coordinates?.latitude}</TableCell>
                    )}
                    {!disableColumns.includes("address") && (
                      <TableCell>{row.address}</TableCell>
                    )}
                    {!disableColumns.includes("city") && (
                      <TableCell>{row.city}</TableCell>
                    )}
                    {!disableColumns.includes("state") && (
                      <TableCell>{row.state}</TableCell>
                    )}
                    {!disableColumns.includes("country") && (
                      <TableCell>{row.country}</TableCell>
                    )}
                    {!disableColumns.includes("last_updated") && (
                      <TableCell>
                        {row.last_updated &&
                          moment(row.last_updated).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("publish") && (
                      <TableCell>
                        {row?.publish ? (
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            style={{
                              color: "green",
                              position: "relative",
                              left: "20px",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{
                              color: "red",
                              position: "relative",
                              left: "20px",
                            }}
                          />
                        )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("active") && (
                      <TableCell>
                        <Switch
                          checked={row.active}
                          onClick={() =>
                            handleActiveDeactivePopup(row.id, row.active)
                          }
                          size="small"
                        />
                      </TableCell>
                    )}
                    {!disableColumns.includes("update") && (
                      <TableCell
                        className="m-0"
                        style={{ textAlign: "center" }}
                      >
                        <Link to={"/editLocation"} state={row}>
                          <i
                            className="fa-solid fa-pencil action-icons"
                            style={{ color: "#2DCC70" }}
                          ></i>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {locationDetails.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {locationDetails.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      {sessionExpire && <SessionExpire />}
      {loader && <Loader />}
      <PopUp
        open={alertPopup}
        title={"ALERT"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={activeDeactivePopUp}
        message={`Do youn want to  ${isActive ? "Deactivate" : "Activate"}`}
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"no"}
        btn2Text={"yes"}
        handleClickBtn1={handleActiveDeactivePopup}
        title={"confirm"}
        handleClickBtn2={activateDeactivateAccount}
        popUpWidth="width-100"
      />
    </Box>
  );
}

export default Location;
