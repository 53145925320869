import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import * as material from "@mui/material";
import React, { useRef } from "react";
import TableHeadRow from "../../components/TableHeadRow";
import CustomTablePagination from "../../components/CustomTablePagination";
import { searchOperations } from "../../common/helpers";
import SessionExpire from "../../components/SessionExpire";
import moment from "moment";
import FilterTable from "../../components/FilterTable";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import { useLocation } from "react-router-dom";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function ErrorCodes({ refresh, setRefresh }) {
  const [orderBy, setOrderBy] = React.useState("vendorErrorCode");
  const [order, setOrder] = React.useState("asc");
  const [noData, setNoData] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [errorCodesData, setErrorCodesData] = React.useState([]);
  const location = useLocation();
  const [isVisible, setIsVisible] = React.useState(false);
  const detailsRef = useRef(null);
  const [searchInputs, setSearchInputs] = React.useState({
    vendorErrorCode: "",
    fromDate: "",
    toDate: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "vendorErrorCode", value: "" },
    { key: "fromDate", value: "" },
    { key: "toDate", value: "" },
  ]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [option, setOption] = React.useState("");
  const headCells = [
    {
      id: "vendorErrorCode",
      numeric: false,
      disablePadding: false,
      label: "Internal Error Code ",
      mandatory: true,
      filterOption: true,
      sort: true,
      searchType: "text",
    },
    {
      id: "info",
      numeric: false,
      disablePadding: false,
      label: "Description ",
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At ",
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: "Updated At ",
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "fromDate",
      numeric: false,
      disablePadding: false,
      label: "From Date",
      filterOption: true,
      onlySearch: true,
      searchType: "date",
    },
    {
      id: "toDate",
      numeric: false,
      disablePadding: false,
      label: "To Date",
      filterOption: true,
      onlySearch: true,
      searchType: "date",
    },
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleOptionClick = (value) => {
    setOption(option === value ? "" : value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      vendorErrorCode: "",
      fromDate: "",
      toDate: "",
    });
    setFilterState([
      { key: "vendorErrorCode", value: "" },
      { key: "fromDate", value: "" },
      { key: "toDate", value: "" },
    ]);
  };
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "vendorErrorCode") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.EQUALITY,
          };
        } else if (key === "fromDate") {
          return {
            key: "createdAt",
            value: searchInputs.fromDate,
            operation: searchOperations.GREATER_THAN,
          };
        } else if (key === "toDate") {
          return {
            key: "createdAt",
            value: searchInputs.toDate,
            operation: searchOperations.LESS_THAN,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });
    if (location.state) {
      params.push({
        key: "transId",
        value: location.state.id,

        operation: searchOperations.EQUALITY,
      });
    }
    return params;
  };
  React.useEffect(() => {
    if (refresh) {
      getErrorCodes();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const getErrorCodes = async () => {
    const searchParams = buildSearchParams();
    const requestBody = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    let Url = "cs-business-errors/search";
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      requestBody,
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    setErrorCodesData(res?.data);
    setCount(res?.count);
    setNoData(res?.count === 0 ? "No data Available" : "");
  };
  React.useEffect(() => {
    getErrorCodes();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);
  return (
    <div>
      {/* <div className="d-flex align-items-center justify-content-start my-3"> */}
      {/* <div className="pagetitle">Error Codes</div> */}
      <div
        className="view my-3 "
        onClick={toggleVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>Error Codes</span>
        <span className={`icon ${isVisible ? "minus" : "plus"}`}>
          {isVisible ? "-" : "+"}
        </span>
      </div>

      {/* <div ref={detailsRef} className="session-details"> */}
      {isVisible && (
        <div className="row border br-10 mx-0 h-auto">
          <Box sx={{ width: "100%", position: "relative" }}>
            <div className="d-flex align-items-center justify-content-between cardFilter">
              <div>
                <DisplayFilterInputs
                  searchInputs={searchInputs}
                  clearFilterValue={clearFilterValue}
                  clearInputValue={clearInputValue}
                  resetStates={resetStates}
                />
              </div>
              <div>
                <material.Box className="d-flex align-items-center gap-3">
                  <div>
                    <div
                      className="filter-filter bgcolor-blue"
                      onClick={() => handleOptionClick("filter")}
                    >
                      <i class="fa-solid fa-sliders"></i>
                      <span className="ms-1">Filter</span>
                    </div>
                    {option === "filter" && (
                      <material.ClickAwayListener
                        onClickAway={() => handleOptionClick("")}
                      >
                        <div className="filterBox">
                          <FilterTable
                            headCells={headCells}
                            filterColumns={filterColumns}
                            handleOptionClick={handleOptionClick}
                            updateFilter={updateFilter}
                            filterState={filterState}
                            syncFilterStateToSearchInputs={
                              syncFilterStateToSearchInputs
                            }
                          />
                        </div>
                      </material.ClickAwayListener>
                    )}
                  </div>
                </material.Box>
              </div>
            </div>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"small"}
                >
                  <TableHeadRow
                    headCells={headCells}
                    order={order}
                    disableCheckBox={true}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={errorCodesData.length}
                    disableColumns={[]}
                  />
                  <TableBody>
                    {errorCodesData.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow tabIndex={-1} key={row.id}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={false} disabled={true} />
                          </TableCell>
                          <TableCell>{row.chargePointErrorCode}</TableCell>
                          <TableCell>{row.info}</TableCell>
                          <TableCell>
                            {moment(row.createdAt).format(
                              "DD MMM YYYY | hh:mm A"
                            )}
                          </TableCell>
                          <TableCell>
                            {moment(row.updatedAt).format(
                              "DD MMM YYYY | hh:mm A"
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {errorCodesData.length === 0 && (
                      <TableRow style={{ height: 40 }}>
                        <TableCell colSpan={8} className="saas-nodata-row">
                          {noData}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {errorCodesData.length > 0 && (
                <CustomTablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </Box>
        </div>
      )}
      {/* </div> */}
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}
