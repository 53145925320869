import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, Link } from "react-router-dom";
import { Button, Card, CardContent } from "@mui/material";
import moment from "moment";
import FullLoader from "../../../components/FullLoader";
import SessionExpire from "../../../components/SessionExpire";
import PopUp from "../../../components/PopUp";
import { handleApi_call } from "../../../common/CommonAPI";
import { headers } from "../../../common/Headers";
function ViewChargingProfiles({ refresh, setRefresh }) {
  const [SchedulesData, setSchedulesData] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const profileId = localStorage.getItem("ProfileId");
  let navigate = useNavigate();
  const [profileobj, setprofileobj] = React.useState({});
  React.useEffect(() => {
    getChargingSchedulesByProfileId();
  }, []);
  const getChargingSchedulesByProfileId = async () => {
    let Url = `chargingProfile/${profileId}`;
    let type = "GET";
    let body = {};
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res.status) {
      setprofileobj(res?.data);
      setSchedulesData(res?.data?.chargingSchedules ?? []);
    }
  };

  const [selected, setSelected] = React.useState([]);
  const deleteProfile = async (id) => {
    selected.push(id);
    let Url = `chargingProfile/ids`;
    let type = "DELETE";
    let body = selected;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setSelected([]);
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.message);
      handleAlertPopup();
    } else {
      setSelected([]);
      setStatusMessage("FAILURE");
      setAlertMessage(res?.message);
      handleAlertPopup();
    }
  };
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  React.useEffect(() => {
    if (refresh) {
      getChargingSchedulesByProfileId();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  return (
    <div>
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
      <div className="w-100 mb-4 mx-0 p-0 d-flex align-items-center justify-content-between">
        <div className=" mx-0 p-0 pagetitle">View Charging Profile</div>
        <div>
          <Button
            className="submit-btn"
            variant="contained"
            size="small"
            onClick={() => navigate("/chargerProfiles")}
          >
            <ArrowBackIosIcon fontSize="10px" />
            Back
          </Button>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6 ">
          <div className="py-4 px-5 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Name
              </span>
              <span>:</span>
              <span>{profileobj.chargingProfileName} </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Stack Level
              </span>

              <span>:</span>

              <span>{profileobj.stackLevel}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Purpose
              </span>
              <span>:</span>
              <span>{profileobj.chargingProfilePurpose}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Kind
              </span>
              <span>:</span>
              <span>{profileobj?.chargingProfileKind}</span>
            </div>
          </div>
        </div>
        <div className="col-6 ">
          <div className="py-4 px-5 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                RecurrencyKind
              </span>
              <span>:</span>
              <span>{profileobj?.recurrencyKind}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                ValidFrom
              </span>

              <span>:</span>
              <span>
                <span>
                  {moment(profileobj.validFrom).format("DD-MMM-YYYY")}{" "}
                </span>
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                ValidTo
              </span>

              <span>:</span>
              <span>
                <span>{moment(profileobj.validTo).format("DD-MMM-YYYY")} </span>
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Schedule Name
              </span>
              <span>:</span>
              <span>
                {profileobj?.chargingSchedules?.map((list, index) => (
                  <span key={index}>
                    {list.chargingScheduleName}

                    {index < profileobj?.chargingSchedules.length - 1 && ", "}
                  </span>
                ))}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="ms-2 p-1 pagetitle">
        <span>Charging Schedule Configuration</span>
      </div>
      <div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <table class="table table-box-shadow mt-3">
              <thead>
                <tr class="table-primary">
                  <th scope="col">Schedule Name</th>
                  <th scope="col">Charging Duration</th>
                  <th scope="col">Start Schedule</th>
                  <th scope="col">Charging Rate Unit</th>
                  <th scope="col">Min Charging Rate</th>
                </tr>
              </thead>
              {SchedulesData?.length > 0 ? (
                <tbody>
                  {SchedulesData?.map((list, index) => (
                    <tr key={index}>
                      <td>{list?.chargingScheduleName}</td>
                      <td>{list?.duration}</td>
                      <td>
                        {moment(list?.startSchedule).format(
                          "DD-MMM-YYYY hh:mm A"
                        )}
                      </td>
                      <td>{list?.chargingRateUnit}</td>
                      <td>{list?.minChargingRate}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colspan="6">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </CardContent>
        </Card>
      </div>
      <div className="d-flex aligm-items-center justify-content-end gap-4 my-4 ">
        <Link to="/editChargeProfile" className="cancle-button">
          <Button variant="contained" className="submit-btn">
            Edit
          </Button>
        </Link>
        <Button
          variant="contained"
          color="error"
          className="cancel-btn"
          onClick={() => deleteProfile(profileobj.id)}
        >
          Delete
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={
          statusMessage === "SUCCESS"
            ? () => navigate("/chargerProfiles")
            : handleAlertPopup
        }
        popUpWidth={"width-100"}
      />
    </div>
  );
}

export default ViewChargingProfiles;
