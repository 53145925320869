/** @format */
import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, Checkbox, ClickAwayListener } from "@mui/material";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import FilterInputs from "../../components/FilterInputs";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import { resize } from "../../common/helpers";
import { basePathone } from "../../common/config";

export default function PingPongLogs() {
  const [orderBy, setOrderBy] = React.useState("id");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [option, setOption] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [noData, setNoData] = React.useState("");
  const [pinglogs, setPingLogs] = React.useState([]);
  const [searchInputs, setSearchInputs] = React.useState({
    chargingStationId: "",
    type: "",
    tenantId: "",
    createdAt: "",
    updatedAt: "",
    time: "",
  });
  const searchOperations = {
    EQUALITY: "EQUALITY",
    NEGATION: "NEGATION",
    GREATER_THAN: "GREATER_THAN",
    LESS_THAN: "LESS_THAN",
    LIKE: "LIKE",
    STARTS_WITH: "STARTS_WITH",
    ENDS_WITH: "ENDS_WITH",
    CONTAINS: "CONTAINS",
  };
  const headCells = [
    {
      id: "chargingStationId",
      numeric: false,
      disablePadding: false,
      label: "Charging Station",
      mandatory: true,
      filterOption: true,
      sort: true,
    },

    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
      mandatory: true,
      filterOption: true,
      sort: true,
    },

    {
      id: "tenantId",
      numeric: false,
      disablePadding: false,
      label: "Tenant Id",
      mandatory: false,
      filterOption: true,
      sort: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
      mandatory: true,
      filterOption: true,
      sort: true,
    },

    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: "Updated At",
      mandatory: false,
      filterOption: true,
      sort: true,
    },
    {
      id: "time",
      numeric: false,
      disablePadding: false,
      label: "PingPong Time",
      mandatory: true,
      filterOption: true,
      sort: true,
    },
  ];
  const handleSearchInputs = (key, value) => {
    setPage(0);
    setSearchInputs({ ...searchInputs, [key]: value });
  };

  const handleOptionClick = (value) => {
    setOption(option === value ? "" : value);
  };

  const handleColumnChange = (id) => {
    setDisableColumns(
      disableColumns.includes(id)
        ? disableColumns.filter((c) => c !== id)
        : [...disableColumns, id]
    );
  };

  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        chargingStationId: "",
        type: "",
        tenantId: "",
        createdAt: "",
        updatedAt: "",
        time: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };

  const removeInput = (input) => {
    setFilterColumns(filterColumns.filter((column) => column !== input));
    setSearchInputs({ ...searchInputs, [input.id]: "" });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "type") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.EQUALITY,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });

    return params;
  };

  const getPingPongLogs = () => {
    const searchParams = buildSearchParams();
    const headers = {
      "content-type": "application/json",
    };

    const body = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };

    fetch(`${basePathone}api/cspingpong/search`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        setPingLogs(res?.data ?? []);
        setCount(res?.count);
        setNoData(res?.count === 0 ? "No Data Available" : "");
      });
  };

  React.useEffect(() => {
    resize();
  }, []);

  React.useEffect(() => {
    getPingPongLogs();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box
          sx={{ width: "100%", p: "10px" }}
          className="d-flex align-items-end justify-content-between"
        >
          <div
            style={{ width: "55%" }}
            className="d-flex flex-wrap align-items-center"
          >
            {filterColumns.map((column) => (
              <FilterInputs
                key={column.id}
                name={column}
                removeInput={removeInput}
                value={searchInputs[column.id]}
                handleSearchInputs={handleSearchInputs}
              />
            ))}
          </div>
          <Box className="d-flex gap-1">
            <div>
              <Button onClick={() => handleOptionClick("filter")}>
                <FilterListIcon />
                <span className="ms-1">{"add filter"}</span>
              </Button>
              {option === "filter" && (
                <ClickAwayListener onClickAway={() => handleOptionClick("")}>
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                    />
                  </div>
                </ClickAwayListener>
              )}
            </div>
            <div>
              <Button onClick={() => handleOptionClick("column")}>
                <ViewWeekIcon />
                <span className="ms-1">{"column"}</span>
              </Button>
              {option === "column" && (
                <ClickAwayListener onClickAway={() => handleOptionClick("")}>
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handleColumnChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </ClickAwayListener>
              )}
            </div>
            <Button>
              <DownloadIcon />
              <span className="ms-1">{"export"}</span>
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <TableHeadRow
              headCells={headCells}
              order={order}
              // deleteCheckBox={false}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={pinglogs.length}
              disableColumns={disableColumns}
              disableCheckBox={true}
            />
            <TableBody>
              {pinglogs.map((row, index) => {
                //  const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow tabIndex={-1} key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    {!disableColumns.includes("chargingStationIdid") && (
                      <TableCell>{row.chargingStationId}</TableCell>
                    )}
                    {!disableColumns.includes("type") && (
                      <TableCell>{row.type}</TableCell>
                    )}
                    {!disableColumns.includes("tenantId") && (
                      <TableCell>{row.tenantId}</TableCell>
                    )}
                    {!disableColumns.includes("createdAt") && (
                      <TableCell>
                        {moment(row.createdAt).format("DD-MMM-YYYY | hh:mm A")}
                      </TableCell>
                    )}
                    {!disableColumns.includes("updatedAt") && (
                      <TableCell>
                        {moment(row.updatedAt).format("DD-MMM-YYYY | hh:mm A")}
                      </TableCell>
                    )}

                    {!disableColumns.includes("time") && (
                      <TableCell>{row.time}</TableCell>
                    )}
                  </TableRow>
                );
              })}
              {pinglogs.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {pinglogs.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            id="TablePagination"
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
