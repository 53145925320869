import * as React from "react";
import * as material from "@mui/material";
import * as icons from "@mui/icons-material";
import moment from "moment";
import FilterInputs from "../../../components/FilterInputs";
import FilterTable from "../../../components/FilterTable";
import ColumnsBox from "../../../components/ColumnsBox";
import TableHeadRow from "../../../components/TableHeadRow";
import { resize, searchOperations } from "../../../common/helpers";
import SessionExpire from "../../../components/SessionExpire";
import { basePathone } from "../../../common/config";
import { handleApi_call } from "../../../common/CommonAPI";
import { headers } from "../../../common/Headers";
const headCells = [
  {
    id: "chargingStationId",
    numeric: false,
    disablePadding: true,
    label: "Charging Station",
    mandatory: true,
    filterOption: true,
    sort: true,
    minWidth: 130,
  },
  {
    id: "evseId",
    numeric: false,
    disablePadding: true,
    label: "EVSE ID",
    mandatory: false,
    filterOption: true,
    sort: true,
    minWidth: 130,
  },
  {
    id: "connectorId",
    numeric: false,
    disablePadding: true,
    label: "Connector ID",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 130,
  },
  {
    id: "connectorStatus",
    numeric: false,
    disablePadding: true,
    label: "Status",
    mandatory: true,
    filterOption: true,
    sort: true,
    minWidth: 130,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: true,
    label: "Created At",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 130,
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: true,
    label: "Updated At",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 130,
  },
  {
    id: "activeProfileId",
    numeric: false,
    disablePadding: true,
    label: "Charging Profile",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 130,
  },
];
function ConnectorStatus() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("chargingStationId");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [option, setOption] = React.useState("");
  const [noData, setNoData] = React.useState("");
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [searchInputs, setSearchInputs] = React.useState({
    chargingStationId: "",
    evseId: "",
    connectorStatus: "",
  });
  const [connectorStatusList, setConnectorStatusList] = React.useState([]);
  const [connectorStatusOpt, setConnectorStatusOpt] = React.useState([]);
  React.useEffect(() => {
    getDropdownConnectorStatus();
  }, []);
  React.useEffect(() => {
    getConnectorStatus();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);

  const getDropdownConnectorStatus = async () => {
    let Url = "csevses/dropdown/connector-status";
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      () => {},
      () => {}
    );
    setConnectorStatusOpt(res);
  };

  const getConnectorStatus = async () => {
    const searchParams = buildSearchParams();
    let Url = `csevses/search`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      payload,
      setSessionExpire,
      () => {},
      () => {},
      () => {}
    );
    const payload = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    setCount(res?.count);
    setConnectorStatusList(res?.data);
    setNoData(res?.count === 0 ? "No Data Available" : "");
  };

  function buildSearchParams() {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "connectorStatus" || key === "evseId") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.EQUALITY,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });
    return params;
  }

  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }
  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({ tenantName: "", email: "", domainName: "", user: "" });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }
  function removeInput(input) {
    setFilterColumns((columns) => columns.filter((cell) => cell !== input));
    setSearchInputs({ ...searchInputs, [input.id]: "" });
  }
  function handleSearchInputs(key, value) {
    setSearchInputs({ ...searchInputs, [key]: value });
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <material.Box sx={{ width: "100%" }}>
      <material.Paper sx={{ width: "100%", mb: 2 }}>
        <material.Box
          sx={{ width: "100%", p: "12px" }}
          className="d-flex align-items-end justify-content-between"
        >
          <div
            style={{ width: "65%" }}
            className="d-flex flex-wrap align-items-center"
          >
            {/* <FormControl
            sx={{ m: 1, width: "25ch" }}
            variant="filled"
            className=""
          >
            <FilledInput
              id="filled-adornment-weight"
              size="small"
              sx={{ height: "40px", pb: "5px", width: "200px" }}
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end" sx={{ mt: "15px" }}>
                  <Search />
                </InputAdornment>
              }
              aria-describedby="filled-weight-helper-text"
              inputProps={{
                "aria-label": "search",
              }}
            />
          </FormControl> */}
            {filterColumns.map((column) => (
              <FilterInputs
                key={column.id}
                name={column}
                removeInput={removeInput}
                value={searchInputs[column.id]}
                handleSearchInputs={handleSearchInputs}
              >
                {column.id === "connectorStatus" &&
                  connectorStatusOpt.map((opt) => (
                    <material.MenuItem key={opt} value={opt}>
                      {opt}
                    </material.MenuItem>
                  ))}
              </FilterInputs>
            ))}
          </div>
          <material.Box className="d-flex gap-1">
            <div>
              <material.Button onClick={() => handleOptionClick("filter")}>
                <icons.FilterList />
                <span className="ms-1">{"add filter"}</span>
              </material.Button>

              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div>
              <material.Button onClick={() => handleOptionClick("column")}>
                <icons.ViewWeek />
                <span className="ms-1">COLUMN</span>
              </material.Button>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <material.Button>
              <icons.Download />
              <span className="ms-1">EXPORT</span>
            </material.Button>
          </material.Box>
        </material.Box>

        <material.TableContainer className="table_body_height">
          <material.Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              // rowCount={chargingSessionList.length}
              disableColumns={disableColumns}
              deleteCheckBox={false}
            />
            <material.TableBody>
              {connectorStatusList?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <material.TableRow tabIndex={-1} key={row.id}>
                    {!disableColumns.includes("chargingStationId") && (
                      <material.TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.chargingStationId}
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("evseId") && (
                      <material.TableCell>{row.evseId}</material.TableCell>
                    )}
                    {!disableColumns.includes("connectorId") && (
                      <material.TableCell>{row.connectorId}</material.TableCell>
                    )}
                    {!disableColumns.includes("connectorStatus") && (
                      <material.TableCell>
                        {row.connectorStatus}
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("created_at") && (
                      <material.TableCell>
                        {moment(row.created_at).format("YYYY-MM-DD hh:mm A")}
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("updated_at") && (
                      <material.TableCell>
                        {moment(row.updated_at).format("YYYY-MM-DD hh:mm A")}
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("activeProfileId") && (
                      <material.TableCell>
                        {row.activeProfileId}
                      </material.TableCell>
                    )}
                  </material.TableRow>
                );
              })}
              {connectorStatusList.length === 0 && (
                <material.TableRow style={{ height: 40 }}>
                  <material.TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </material.TableCell>
                </material.TableRow>
              )}
            </material.TableBody>
          </material.Table>
        </material.TableContainer>
        <div className="position-relative">
          {connectorStatusList.length > 0 && (
            <material.TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              disablePortal
              id="TablePagination"
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </material.Paper>
      {sessionExpire && <SessionExpire />}
    </material.Box>
  );
}

export default ConnectorStatus;
