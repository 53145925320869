import React, { useState } from "react";
import { TextField, Switch, Button, Box } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";

const PaymentMethodCard = ({setPageHeading}) => {
  const [cashPayment, setCashPayment] = useState(true);
  const [razorpayPayment, setRazorpayPayment] = useState(true);
  const [stripePayment, setStripePayment] = useState(true);

  const updatePayment = (paymentMethod) => {
    if (paymentMethod === "cash_payment") {
      setCashPayment(!cashPayment);
    } else if (paymentMethod === "razorpay_payment") {
      setRazorpayPayment(!razorpayPayment);
    } else if (paymentMethod === "stripe_payment") {
      setStripePayment(!stripePayment);
    }
  };
  React.useEffect(() => {
    setPageHeading("Payment");
  }, []);

  const saveRazorpayPayment = () => {
    // Save Razorpay Payment logic here
  };
  const saveStripePayment = () => {
    // Save Stripe Payment logic here
  };

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 4,
        borderColor: "divider",
        boxShadow: 1,
        p: 2,
        mb: 3,
      }}
    >
      <div className="card-header">
        <b className="cardHeading2">
          Select your favourite payment method and start receiving money
          <hr className="straightLine" />
        </b>
      </div>
      <div className="card-body">
        <div className="mb-24">
          <b className="contentStyle mb-24">Offline Payment</b>
          <div className="row mb-3 border-bottom">
            <div className="col-sm">
              <img
                className="rounded"
                alt="COD"
                src="./cash.png"
                height="100"
                width="100"
              />
            </div>
            <div className="col-sm codContent paymentText mt-4">
              <b className="cardSubheading mb-1 ">Cash on Delivery</b>
              <p className="cardText ml-0">
                Take payment in cash upon delivery
              </p>
            </div>
            <div className="col-sm d-flex align-items-center justify-content-end">
              <Switch
                checked={cashPayment}
                onChange={() => updatePayment("cash_payment")}
                color="primary"
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="mb-24">
          <b className="contentStyle mb-24">Online Payment</b>
          <div className="border-bottom mb-24">
            <div className="row">
              <div className="col-sm">
                <img
                  className="rounded"
                  alt="Razorpay"
                  src="./razorpay.png"
                  height="100"
                  width="100"
                />
              </div>
              <div className="col-sm codContent paymentText mt-3">
                <b className="cardSubheading mb-1">Razorpay</b>
                <div className="cardText cardTextContent">
                  <p>
                    Integrate Razorpay with your account and take online
                    payments seamlessly into your bank account.
                  </p>
                </div>
              </div>
              <div className="col-sm d-flex align-items-center justify-content-end">
                <Switch
                  checked={razorpayPayment}
                  onChange={() => updatePayment("razorpay_payment")}
                  color="primary"
                  size="small"
                />
              </div>
            </div>
            {razorpayPayment && (
              <div className="collapse show" id="collapseRazorpay">
                <form className="form-horizontal" id="razorpay_form">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group mb-24">
                        <Box className="inputFieldContainer">
                          <TextField
                            id="outlined-basic"
                            className="formInput w-75"
                            size="small"
                            label="Razor Key"
                            variant="outlined"
                          />
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group mb-24">
                        <Box className="inputFieldContainer">
                          <TextField
                            id="outlined-basic"
                            className="formInput w-75"
                            size="small"
                            label="Razor Secret"
                            variant="outlined"
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-end mb-24">
                      <Button
                        variant="contained"
                        color="primary"
                        className="mb-4"
                        onClick={saveRazorpayPayment}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
        <div className="mb-24">
          <div className="border-bottom mb-24">
            <div className="row">
              <div className="col-sm">
                <img
                  className="rounded mt-4"
                  alt="Razorpay"
                  src="./stripe.png"
                  height="50"
                  width="100"
                />
              </div>
              <div className="col-sm codContent paymentText mt-4">
                <b className="cardSubheading mb-1">Stripepay</b>
                <div className="cardText cardTextContent">
                  <p>
                    Integrate Stripe with your account and take online payments
                    seamlessly into your bank account.
                  </p>
                </div>
              </div>
              <div className="col-sm d-flex align-items-center justify-content-end">
                <Switch
                  checked={stripePayment}
                  onChange={() => updatePayment("stripe_payment")}
                  color="primary"
                  size="small"
                />
              </div>
            </div>
            {stripePayment && (
              <div className="collapse show" id="collapseRazorpay">
                <form className="form-horizontal" id="razorpay_form">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group mb-24">
                        <Box className="inputFieldContainer">
                          <TextField
                            id="outlined-basic"
                            className="formInput w-75 mt-4"
                            size="small"
                            label="Stripe Key"
                            variant="outlined"
                          />
                        </Box>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group mb-24">
                        <Box className="inputFieldContainer">
                          <TextField
                            id="outlined-basic"
                            className="formInput w-75 mt-4"
                            size="small"
                            label="Stripe Secret"
                            variant="outlined"
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-end mb-24">
                      <Button
                        variant="contained"
                        color="primary"
                        className="mb-4"
                        onClick={saveStripePayment}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default PaymentMethodCard;
