import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";

function DeleteCertificate({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    certificateHashData: {
      issuerNameHash: "",
      issuerKeyHash: "",
      serialNumber: "",
      hashAlgorithm: "",
    },
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    issuerNameHash: "",
    issuerKeyHash: "",
    serialNumber: "",
    hashAlgorithm: "",
  });
  function handleOnChange(key, value) {
    setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
    setInputFields((prevState) => ({
      ...prevState,
      certificateHashData: {
        ...prevState.certificateHashData,
        [key]: value, // Set the serial number value
      },
    }));
  }
  function handleSubmit() {
    let error = false;
    if (
      !inputFields.certificateHashData.hashAlgorithm ||
      !inputFields.certificateHashData.serialNumber ||
      !inputFields.certificateHashData.issuerKeyHash ||
      !inputFields.certificateHashData.issuerNameHash
    ) {
      error = true;
    }
    if (error) {
      if (!inputFields.certificateHashData.hashAlgorithm) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          hashAlgorithm: "HashAlgorithm is Mandatory",
        }));
      }
      if (!inputFields.certificateHashData.serialNumber) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          serialNumber: "SerialNumber is Mandatory",
        }));
      }
      if (!inputFields.certificateHashData.issuerKeyHash) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          issuerKeyHash: "IssuerKeyHash is Mandatory",
        }));
      }
      if (!inputFields.certificateHashData.issuerNameHash) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          issuerNameHash: "IssuerNameHash is Mandatory",
        }));
      }
    }
    handleSubmitOperation(error, inputFields);
  }
  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Hash Algorithm Type *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.hashAlgorithm}
                  size="small"
                  error={inputFieldsValidation.hashAlgorithm}
                  helperText={inputFieldsValidation.hashAlgorithm}
                  onChange={(e) => {
                    handleOnChange("hashAlgorithm", e.target.value);
                  }}
                >
                  <MenuItem value="SHA256">SHA256</MenuItem>
                  <MenuItem value="SHA384">SHA384</MenuItem>
                  <MenuItem value="SHA512">SHA512</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Issuer NameHash *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.issuerNameHash}
                  error={inputFieldsValidation.issuerNameHash}
                  helperText={inputFieldsValidation.issuerNameHash}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("issuerNameHash", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Issuer KeyHash * </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.issuerKeyHash}
                  error={inputFieldsValidation.issuerKeyHash}
                  helperText={inputFieldsValidation.issuerKeyHash}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("issuerKeyHash", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Serial Number *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.serialNumber}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.location}
                  size="small"
                  helperText={inputFieldsValidation.serialNumber}
                  onChange={(e) => {
                    handleOnChange("serialNumber", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default DeleteCertificate;
