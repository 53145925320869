import { Button, FormControl, FormLabel, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import L from "leaflet";
import ClearIcon from '@mui/icons-material/Clear';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMap,
} from "react-leaflet";

const MapEvents = ({ onMapClick }) => {
  useMapEvents({
    click: (event) => {
      onMapClick(event);
    },
  });
  return null;
};
const ChangeView = ({ center }) => {
  const map = useMap();
  map.setView(center, map.getZoom());
  return null;
};

export default function MapComponent({ locationFields, setLocationFields }) {
  // console.log(locationFields);
  const [position, setPosition] = useState(null); // Initially set to null
  const [mapCenter, setMapCenter] = useState(null); // Start with no center
  const [address, setAddress] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (!locationFields.latitude && !locationFields.longitude) {
      getLocation();
    }
  }, []);
  useEffect(() => {
    if (locationFields.latitude && locationFields.longitude) {
      const newPosition = [
        parseFloat(locationFields.latitude),
        parseFloat(locationFields.longitude),
      ];
      setPosition(newPosition);
      setMapCenter(newPosition);
    }
  }, [locationFields.latitude, locationFields.longitude]);

  const customIcon = new L.Icon({
    iconUrl: 'marker-icon-red.png', // Path to your custom icon image
    iconSize: [25, 41], // Size of the icon
    iconAnchor: [12, 41], // The point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // The point from which the popup should open relative to the iconAnchor
  });
  const defaultIcon = new L.Icon.Default();
  function getLocation() {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const currentPosition = [pos.coords.latitude, pos.coords.longitude];
        setPosition(currentPosition); // Set marker position
        setMapCenter(currentPosition); // Set map center to current position
      },
      (err) => {
        console.error(err);
        // Default to a known location (e.g., London) if geolocation fails
        setMapCenter([51.505, -0.09]);
      }
    );
  }

  const handleClearSearch = () => {
    setAddress("");
    handleSearch("");
  }


  function handleSearch(add) {
    let searchAddress = add.replaceAll(" ", "+");
    fetch(
      `https://nominatim.openstreetmap.org/search?q=${searchAddress}&format=json`
    )
      .then((response) => response.json())
      .then((res) => {
        // let locData = locationFields;
        // locData.address = res?.display_name;
        // locData.city = res?.address?.city ?? "";
        // locData.country = res?.address?.country;
        // locData.state = res?.address?.state;
        // locData.postalCode = res?.address?.postcode;
        // setLocationFields((prevData) => ({ ...prevData, address: res?.display_name, city: res?.address?.city ?? "", country: res?.address?.country, state: res?.address?.state, postalCode: res?.address?.postcode }))
        console.log(res);
        setSearchResults(res);
      });
  }

  const handleMapClick = (lat, lng) => {
    lat = Number(lat);
    lng = Number(lng);
    const newPosition = [lat, lng];
    setPosition(newPosition);
    setMapCenter(newPosition); // Center map on the clicked location
    setLocationFields({
      ...locationFields,
      latitude: lat.toFixed(6), // Ensure precision for coordinates
      longitude: lng.toFixed(6),
    });
  };

  if (!mapCenter) {
    return <div>Loading map...</div>; // Show a loading message until location is fetched
  }

  return (
    <>
      <div className="d-flex gap-2 mb-2">
        <TextField
          placeholder="Search By Address"
          className="w-100"
          variant="outlined"
          size="small"
          value={address}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClearSearch}
                  edge="end"
                >
                  {address && <ClearIcon />}
                </IconButton>
              </InputAdornment>
          }}

          onChange={(e) => setAddress(e.target.value)}
        />
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => handleSearch(address)}
          className="submit-btn"
          style={{ lineHeight: 0 }}
        >
          Search
        </Button>
      </div>
      <MapContainer
        center={mapCenter} // Center the map on the mapCenter state
        zoom={15}
        style={{ height: "60vh", width: "100%", position: "sticky" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {mapCenter && <ChangeView center={mapCenter} />}
        {/* Ensure map view updates dynamically */}
        {searchResults.length === 0 && position && ( // Only render the marker if position is available
          <Marker position={position}>
            <Popup>
              Current Position: Latitude: {position[0]}, Longitude: {position[1]}
            </Popup>
          </Marker>
        )}
        {searchResults.length !== 0 && ( // Only render the marker if position is available
          searchResults.map((result, index) =>
            <Marker key={index} icon={Number(result.lat).toFixed(6) === locationFields.latitude && Number(result.lon).toFixed(6) === locationFields.longitude ? customIcon : defaultIcon} position={[result.lat, result.lon]} eventHandlers={{ click: () => { handleMapClick(result.lat, result.lon) } }}>
              <Popup>
                Current Position: Latitude: {position[0]}, Longitude: {position[1]}
              </Popup>
            </Marker>)
        )}
        <MapEvents onMapClick={(e) => handleMapClick(e.latlng.lat, e.latlng.lng)} />
      </MapContainer>
    </>
  );
}
