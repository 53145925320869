import * as React from "react";
import { useTranslation } from "react-i18next";
import { basePath } from "../../common/config";
import Button from "@mui/material/Button";
import PopUp from "../../components/PopUp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SessionExpire from "../../components/SessionExpire";
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { handleEmail } from "../../common/helpers";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function EditTenant({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [updateRow, setUpdateRow] = React.useState({});
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [addressDB, setaddressDB] = React.useState([]);
  const [nameschema, setnameschema] = React.useState([]);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [statusFalsePop, setStatusFalsepop] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [dbtypestate, setdbtypestate] = React.useState("");
  const [status, setStatus] = React.useState("");

  const [updateRowErrors, setUpdateRowErrors] = React.useState({
    email: "",
    address: "",
  });
  const location = useLocation();
  React.useEffect(() => {
    setUpdateRow(location.state);
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setUpdateRow(location.state);
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  function handleUpdate() {
    let error = false;
    if (
      !updateRow.email ||
      !updateRow.address ||
      handleEmail(updateRow.email)
    ) {
      error = true;
    }
    if (error) {
      if (!updateRow.email) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          email: "Email is Mandatory",
        }));
      }
      if (updateRow.email) {
        let valid = handleEmail(updateRow.email);
        if (!valid) {
          setUpdateRowErrors((errors) => ({
            ...errors,
            email: "",
          }));
        } else {
          setUpdateRowErrors((errors) => ({
            ...errors,
            email: "Enter valid email",
          }));
        }
      }
      if (!updateRow.address) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          address: "Address is Mandatory",
        }));
      }
    } else {
      updateTenant();
    }
  }
  const handleDbaddresSet = (e) => {
    handleUpdateForm("dbAddress", e.target.value);
  };
  const navigate = useNavigate();
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleStatusFalsePop() {
    setStatusFalsepop((pop) => !pop);
  }
  function handleUpdateForm(key, value) {
    if (key === "mobile") {
      if (!isNaN(value)) {
        setUpdateRowErrors({ ...updateRowErrors, [key]: "" });
        setUpdateRow({
          ...updateRow,
          [key]: value,
        });
      }
    } else {
      setUpdateRowErrors({ ...updateRowErrors, [key]: "" });
      setUpdateRow({ ...updateRow, [key]: value });
    }
  }
  const updateTenant = async () => {
    let Url = "tenants/" + updateRow.tenantId;
    let type = "PUT";
    let body = updateRow;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };
  return (
    <div>
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
      <div className="pagetitle">Edit Tenant</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-4 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Email *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="outlined"
                  required
                  value={updateRow?.email}
                  error={updateRowErrors.email}
                  helperText={updateRowErrors.email}
                  size="small"
                  onChange={(e) => {
                    handleUpdateForm("email", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Address *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="outlined"
                  required
                  value={updateRow?.address}
                  error={updateRowErrors.address}
                  helperText={updateRowErrors.address}
                  size="small"
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={(e) => {
                    handleUpdateForm("address", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>DB Address Name *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="outlined"
                  value={updateRow?.dbAddress}
                  size="small"
                  required
                  disabled
                  onChange={(e) => {
                    handleUpdateForm("dbAddress", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Schema Name *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="outlined"
                  required
                  disabled
                  value={updateRow?.schemaName}
                  size="small"
                  onChange={(e) => {
                    handleUpdateForm("schemaName", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            {dbtypestate === "separate-schema" && (
              <>
                <div className="col-4">
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>DB Address Name *</b>
                    </FormLabel>
                    <FormControl size="small" className="w-100">
                      <InputLabel id="">DB-Address</InputLabel>
                      <Select
                        required
                        disabled
                        value={updateRow?.dbAddress}
                        onChange={handleDbaddresSet}
                      >
                        {addressDB.map((list, index) => (
                          <MenuItem key={index} value={list}>
                            {list}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </div>
                <div className="col-4">
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Schema Name *</b>
                    </FormLabel>
                    <TextField
                      className="w-100"
                      variant="outlined"
                      required
                      value={updateRow?.schemaName}
                      size="small"
                      onChange={(e) => {
                        handleUpdateForm("schemaName", e.target.value);
                      }}
                    />
                  </FormControl>
                </div>
              </>
            )}
            {dbtypestate === "sharded-db" && (
              <>
                <div className="col-4">
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>DB Address *</b>
                    </FormLabel>
                    <FormControl size="small" className="w-100">
                      <InputLabel id="">DB-Address</InputLabel>
                      <Select
                        required
                        disabled
                        value={updateRow?.dbAddress}
                        label={t("address_name")}
                        onChange={handleDbaddresSet}
                      >
                        {addressDB.map((list, index) => (
                          <MenuItem key={index} value={list}>
                            {list}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </div>
                {nameschema.length === 0 ? (
                  <div className="col-4">
                    <FormControl className="simpleInput">
                      <FormLabel>
                        <b>Schema Name *</b>
                      </FormLabel>
                      <TextField
                        className="w-100"
                        variant="outlined"
                        required
                        disabled
                        value={updateRow?.schemaName}
                        size="small"
                        onChange={(e) => {
                          handleUpdateForm("schemaName", e.target.value);
                        }}
                      />
                    </FormControl>
                  </div>
                ) : (
                  <div className="col-4">
                    <FormControl className="simpleInput">
                      <FormLabel>
                        <b>Schema Name*</b>
                      </FormLabel>
                      <FormControl
                        sx={{ minWidth: 120 }}
                        size="small"
                        className="w-100"
                      >
                        <InputLabel id="demo-select-small-label">
                          Schema Name
                        </InputLabel>
                        <Select
                          value={updateRow?.schemaName}
                          label={t("schema_name")}
                          required
                          disabled
                          onChange={(e) => {
                            handleUpdateForm("schemaName", e.target.value);
                          }}
                        >
                          {nameschema.map((list, index) => (
                            <MenuItem key={index} value={list}>
                              {list}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormControl>
                  </div>
                )}
              </>
            )}
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>User Name *</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  variant="outlined"
                  required
                  disabled
                  value={updateRow?.userName}
                  size="small"
                  onChange={(e) => {
                    handleUpdateForm("userName", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Password *</b>
                </FormLabel>
                <TextField
                  placeholder="Password"
                  className="w-100"
                  variant="outlined"
                  value={updateRow?.password}
                  size="small"
                  required
                  disabled
                  onChange={(e) => {
                    handleUpdateForm("password", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Tenant Name *</b>
                </FormLabel>
                <TextField
                  placeholder="Tenant Name"
                  className="w-100"
                  variant="outlined"
                  value={updateRow?.tenantName}
                  size="small"
                  required
                  disabled
                  onChange={(e) => {
                    handleUpdateForm("tenantName", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Tenant ID *</b>
                </FormLabel>
                <TextField
                  placeholder="Tenant ID"
                  className="w-100"
                  variant="outlined"
                  value={updateRow?.tenantId}
                  size="small"
                  required
                  disabled
                  onChange={(e) => {
                    handleUpdateForm("tenantId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-end gap-3 my-4">
        <Button
          size="small"
          color="error"
          variant="contained"
          className="cancel-btn"
        >
          <Link to="/tenantManagement" className="cancle-button">
            Cancel
          </Link>
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleUpdate}
          className="submit-btn"
        >
          Update
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        handleClosePopUp={handleAlertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={() => navigate("/tenantManagement")}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={statusFalsePop}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleStatusFalsePop}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
