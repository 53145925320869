/** @format */

import { Box, TextField } from "@mui/material";

function HeaderOrFooterForm({
  headerForm,
  headerFormValidation,
  handleHeaderForm,
}) {
  return (
    <div>
      <Box>
        <div className="d-flex flex-column gap-4 w-100">
          <TextField
            label="Title"
            error={headerFormValidation.title}
            // className=" me-3 error"
            required
            variant="outlined"
            value={headerForm?.title}
            size="small"
            helperText={headerFormValidation.title}
            onChange={(e) => {
              handleHeaderForm("title", e.target.value);
            }}
          />
          <TextField
            label="Link"
            // className="w-45 me-3 error"
            error={headerFormValidation.link}
            required
            variant="outlined"
            value={headerForm?.link}
            size="small"
            helperText={headerFormValidation.link}
            onChange={(e) => {
              handleHeaderForm("link", e.target.value);
            }}
          />
        </div>
      </Box>
    </div>
  );
}

export default HeaderOrFooterForm;
