/** @format */

import React from "react";
export function Card({ value, name, percentage, toggles }) {
  const isPositive = percentage > 0;
  return (
    <div
      className={`card customCard justify-content-center ps-4 ${
        toggles && "fullCard"
      }`}
    >
      <div className="d-flex gap-2">
        <span
          className={`pe-3 ps-3 rounded-3 fs-5 ${
            isPositive ? "text-success bg-green" : "text-danger bg-red"
          }`}
        >
          {isPositive ? <span>&uarr;</span> : <span>&darr;</span>}
        </span>
        <div className=" w-75">
          <b className="value lh-1 fs-5">{value.toLocaleString()}</b>
          <div className="d-flex justify-content-between">
            <span className="fontsize">{name}</span>
            <b
              className={`fontsize pe-3 ${
                isPositive ? "text-success" : "text-danger"
              }`}
            >
              {isPositive ? `+${percentage}` : percentage}%
            </b>
          </div>
        </div>
      </div>
    </div>
  );
}
