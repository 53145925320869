import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";

function ViewReservation() {
    const navigate = useNavigate();
    const location = useLocation();
    const [reservation, setReservation] = useState({});

    useEffect(() => {
        setReservation(location.state);
    }, [location.state]);

    return (
        <div>
            <div className="w-100 mb-4 mx-0 p-0 d-flex align-items-center justify-content-between">
                <div className="fs-19 saas-boldHeading">View Reservation</div>
                <div>
                    <Button
                        className="submit-btn"
                        variant="contained"
                        size="small"
                        onClick={() => navigate("/reservations")}
                    >
                        <ArrowBackIosIcon fontSize="10px" />
                        Back
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="p-4 border br-10 cardBackground h-100">
                        <div className="w-100 d-flex align-items-center gap-3 mb-2">
                            <span className="saas-boldHeading" style={{ width: "50%" }}>
                                Reservation ID
                            </span>
                            <span>:</span>
                            <span>{reservation?.reservationId}</span>
                        </div>
                        <div className="w-100 d-flex align-items-center gap-3 mb-2">
                            <span className="saas-boldHeading" style={{ width: "50%" }}>
                                EVSE ID
                            </span>
                            <span>:</span>
                            <span>{reservation?.evseId}</span>
                        </div>
                        <div className="w-100 d-flex align-items-center gap-3 mb-2">
                            <span className="saas-boldHeading" style={{ width: "50%" }}>
                                Valid From
                            </span>
                            <span>:</span>
                            <span>{reservation?.validFrom && moment(reservation?.validFrom).format("DD MMM YYYY | hh:mm A")}</span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="p-4 border br-10 cardBackground h-100">
                        <div className="w-100 d-flex align-items-center gap-3 mb-2">
                            <span className="saas-boldHeading" style={{ width: "50%" }}>
                                Location
                            </span>
                            <span>:</span>
                            <span>
                                {reservation?.chargingStationId}
                            </span>
                        </div>
                        <div className="w-100 d-flex align-items-center gap-3 mb-2">
                            <span className="saas-boldHeading" style={{ width: "50%" }}>
                                Connector ID
                            </span>
                            <span>:</span>
                            <span>
                                {reservation?.connectorId}
                            </span>
                        </div>
                        <div className="w-100 d-flex align-items-center gap-3 mb-2">
                            <span className="saas-boldHeading" style={{ width: "50%" }}>
                                Valid To
                            </span>
                            <span>:</span>
                            <span>
                                {reservation?.validTo && moment(reservation?.validTo).format("DD MMM YYYY | hh:mm A")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewReservation;