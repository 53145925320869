import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";

function GetLog({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    requestId: "",
    logType: "",
    retries: "",
    retryInterval: "",
    log: {
      remoteLocation: "",
      oldestTimestamp: null,
      latestTimestamp: null,
    },
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    requestId: "",
    logType: "",
    remoteLocation: "",
  });
  function handleOnChange(key, value) {
    if (key === "requestId" || key === "retries" || key === "retryInterval") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
        setInputFields({ ...inputFields, [key]: parseInt(value) });
      }
    } else {
      setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
      setInputFields({ ...inputFields, [key]: value });
    }
  }
  function handleSubmit() {
    let error = false;
    if (!inputFields.requestId || !inputFields.logType) {
      error = true;
    }
    if (error) {
      if (!inputFields.requestId) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          requestId: "Request Id is Mandatory",
        }));
      }
      if (!inputFields.logType) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          logType: "Log Type is Mandatory",
        }));
      }
      if (!inputFields?.log?.remoteLocation) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          remoteLocation: "Remote Location is Mandatory",
        }));
      }
    }
    let payLoad = {
      requestId: parseInt(inputFields.requestId),
      logType: inputFields.logType,
      log: { remoteLocation: inputFields?.log?.remoteLocation },
    };
    if (inputFields.retries) {
      payLoad.retries = inputFields.retries;
    }
    if (inputFields.retryInterval) {
      payLoad.retryInterval = inputFields.retryInterval;
    }
    if (inputFields?.log?.latestTimestamp) {
      payLoad.log.latestTimestamp = inputFields?.log?.latestTimestamp;
    }
    if (inputFields?.log?.oldestTimestamp) {
      payLoad.log.oldestTimestamp = inputFields?.log?.oldestTimestamp;
    }
    handleSubmitOperation(error, payLoad);
  }
  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Certificate Type *</b>
                </FormLabel>
                <TextField
                  select
                  error={inputFieldsValidation.logType}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.logType}
                  size="small"
                  helperText={inputFieldsValidation.logType}
                  onChange={(e) => {
                    handleOnChange("logType", e.target.value);
                  }}
                >
                  <MenuItem value="DiagnosticsLog">Diagnostics Log</MenuItem>
                  <MenuItem value="SecurityLog">Security Log</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Request Id *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.requestId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.requestId}
                  size="small"
                  helperText={inputFieldsValidation.requestId}
                  onChange={(e) => {
                    handleOnChange("requestId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Retries </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.retries}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("retries", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Retry Interval </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.retryInterval}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("retryInterval", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Remote Location * </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  error={inputFieldsValidation.remoteLocation}
                  helperText={inputFieldsValidation.remoteLocation}
                  variant="outlined"
                  value={inputFields?.log?.remoteLocation}
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    setInputFields((prevFields) => ({
                      ...prevFields,
                      log: {
                        ...prevFields.log,
                        remoteLocation: value,
                      },
                    }));
                    setInputFieldsValidation((prevValidation) => ({
                      ...prevValidation,
                      remoteLocation: "",
                    }));
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Oldest Time Stamp </span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={inputFields?.log?.oldestTimestamp}
                  onChange={(e) =>
                    setInputFields((prevFields) => ({
                      ...prevFields,
                      log: {
                        ...prevFields.log,
                        oldestTimestamp: e.target.value,
                      },
                    }))
                  }
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Latest Time Stamp </span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={inputFields?.log?.latestTimestamp}
                  onChange={(e) =>
                    setInputFields((prevFields) => ({
                      ...prevFields,
                      log: {
                        ...prevFields.log,
                        latestTimestamp: e.target.value,
                      },
                    }))
                  }
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default GetLog;
