import { TextField } from "@mui/material"

function EditConfigurationKey({ keyFields, valueValidation, handleChange }) {
    return (
        <div>
            <div>
                <TextField
                    label="Configuration Key"
                    className="w-100 mb-3"
                    variant="outlined"
                    size="small"
                    disabled
                    value={keyFields.keyName}
                />
            </div>
            <div>
                <TextField
                    label="Configuration Value"
                    className="w-100"
                    required
                    variant="outlined"
                    size="small"
                    value={keyFields.value}
                    onChange={(e) => handleChange("value", e.target.value)}
                    error={valueValidation}
                    helperText={valueValidation}
                />
            </div>
        </div>
    )
}

export default EditConfigurationKey
