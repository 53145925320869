import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import * as material from "@mui/material";
import ColumnsBox from "../../components/ColumnsBox";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import CustomTablePagination from "../../components/CustomTablePagination";
import { basePathone } from "../../common/config";
import moment from "moment";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import FilterTable from "../../components/FilterTable";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
const headCells = [
  {
    id: "connectorId",
    numeric: false,
    disablePadding: true,
    label: "ID",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 200,
  },
  {
    id: "standard",
    numeric: false,
    disablePadding: true,
    label: "Connector Type",
    mandatory: true,
    filterOption: true,
    searchType: "text",
    sort: true,
    minWidth: 150,
  },
  {
    id: "format",
    numeric: false,
    disablePadding: false,
    label: "Connector Format",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "powerType",
    numeric: false,
    disablePadding: false,
    label: "Power Type",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "maxVoltage",
    numeric: false,
    disablePadding: false,
    label: "Max Voltage",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 130,
  },
  {
    id: "maxAmperage",
    numeric: false,
    disablePadding: false,
    label: "Max Ampherage",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 130,
  },
  {
    id: "maxElectricPower",
    numeric: false,
    disablePadding: false,
    label: "Max Electric Power",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "tariffIds",
    numeric: false,
    disablePadding: false,
    label: "Tariff Ids",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "LastUpdated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 150,
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "Status",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 80,
  },
  {
    id: "terms_and_conditions",
    numeric: false,
    disablePadding: false,
    label: "Terms & Conditions",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 160,
  },
  {
    id: "Update",
    numeric: false,
    disablePadding: false,
    label: "Update",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "fromDate",
    numeric: false,
    disablePadding: false,
    label: "From Date",
    filterOption: true,
    onlySearch: true,
    searchType: "date",
  },
  {
    id: "toDate",
    numeric: false,
    disablePadding: false,
    label: "To Date",
    filterOption: true,
    onlySearch: true,
    searchType: "date",
  },
];
export default function ViewLocation({ refresh, setRefresh }) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [noData, setNoData] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchInputs, setSearchInputs] = React.useState({
    standard: "",
    fromDate: "",
    toDate: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "standard", value: "" },
    { key: "toDate", value: "" },
    { key: "fromDate", value: "" },
  ]);
  const [count, setCount] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [option, setOption] = React.useState("");
  const [locationDetails, setLocationDetails] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [connectorTypeList, setConnectorTypeList] = React.useState([]);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [evsedata, setEvseData] = React.useState({});
  const location = useLocation();
  React.useEffect(() => {
    getEvseById();
    getConnectorType();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getConnectors();
      getEvseById();
      getConnectorType();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const EvseId = localStorage.getItem("EVSEID");
  const getEvseById = async () => {
    let Url = `api/locations/getEvse/${EvseId}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    // fetch(basePathone + `api/locations/getEvse/${EvseId}`, {
    //   method: "GET",
    //   headers: headers,
    // })
    //   .then((resp) => {
    //     setLoader(false);
    //     if (resp.statusCode === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return resp.json();
    //     }
    //   })
    //   .then((res) => {
    if (res?.statusCode) {
      setEvseData(res?.data);
    }
    // });
  };

  const getConnectors = async () => {
    let Url = `api/locations/fetchConnectos/${EvseId}?page=${
      page + 1
    }&size=${rowsPerPage}&standard=${searchInputs.standard}&fromDate=${
      searchInputs.fromDate
    }&toDate=${searchInputs.toDate}&sortBy=${orderBy}&sortDir=${order}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    // fetch(
    //   basePathone +
    //     `api/locations/fetchConnectos/${EvseId}?page=${
    //       page + 1
    //     }&size=${rowsPerPage}&standard=${searchInputs.standard}&fromDate=${
    //       searchInputs.fromDate
    //     }&toDate=${searchInputs.toDate}&sortBy=${orderBy}&sortDir=${order}`,
    //   {
    //     method: "GET",
    //     headers: headers,
    //   }
    // )
    //   .then((resp) => {
    //     setLoader(false);
    //     if (resp.statusCode === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return resp.json();
    //     }
    //   })
    //   .then((res) => {
    setLocationDetails(res?.data);
    setCount(res?.count);
    setNoData(res?.count === 0 ? "No Data Available" : "");
    // });
  };
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const handleColumnChange = (id) => {
    setDisableColumns(
      disableColumns.includes(id)
        ? disableColumns.filter((c) => c !== id)
        : [...disableColumns, id]
    );
  };
  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        fromDate: "",
        toDate: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };
  const resetStates = () => {
    setSearchInputs({
      fromDate: "",
      toDate: "",
      standard: "",
    });
    setFilterState([
      { key: "standard", value: "" },
      { key: "toDate", value: "" },
      { key: "fromDate", value: "" },
    ]);
  };

  React.useEffect(() => {
    getConnectors();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);

  const deleteConnectors = async () => {
    let Url = `api/locations/deleteConnectors/${EvseId}`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      selected,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    //  fetch(`${basePathone}api/locations/deleteConnectors/${EvseId}`, {
    //   method: "PATCH",
    //   headers: headers,
    //   body: JSON.stringify(selected),
    // })
    //   .then((res) => {
    //     setLoader(false);
    //     if (res.statusCode === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return res.json();
    //     }
    //   })
    //   .then((res) => {
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      setSelected([]);
      handleAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleAlertPopup();
    }
    // });
  };

  const getConnectorType = async () => {
    let Url = `api/locations/getConnectorTypeDropdown`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    // let headers = {
    //   "content-type": "application/json",
    //   Authorization: localStorage.getItem("Authorization"),
    //   "X-TenantID": localStorage.getItem("tenantId"),
    // };

    // fetch(`${basePathone}api/locations/getConnectorTypeDropdown`, {
    //   method: "GET",
    //   headers: headers,
    // })
    //   .then((res) => {
    //     if (res.statusCode === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return res.json();
    //     }
    //   })
    //   .then((res) => {
    if (res?.statusCode) {
      setConnectorTypeList(res?.data);
    }
    // });
  };

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = locationDetails.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  function handleAlertOk() {
    getConnectors();
    handleAlertPopup();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <div style={{ width: "99%" }}>
      <div className="w-100 mb-4 mx-0 p-0 d-flex align-items-center justify-content-between">
        <div className="pagetitle">View EVSE</div>
        <div>
          <Button
            className="submit-btn"
            variant="contained"
            size="small"
            onClick={() => navigate("/viewLocation")}
          >
            <ArrowBackIosIcon fontSize="10px" />
            Back
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-6 ">
          <div className="py-4 px-5 border br-10 cardBackground h-100">
            {/* <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                UID
              </span>
              <span>:</span>
              <span>{evsedata.uid}</span>
            </div> */}
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Status
              </span>

              <span>:</span>
              <span>
                <span>{evsedata.status}</span>
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Capabilities
              </span>
              <span>:</span>
              <span className="card-text-width-adjust w-275">
                {evsedata.capabilities?.map((list, index) => (
                  <span key={index}>{list},</span>
                ))}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Floor Level
              </span>
              <span>:</span>
              <span>{evsedata.floorLevel}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Latitude
              </span>
              <span>:</span>
              <span>{evsedata?.coordinates?.latitude}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Parking Restrictions
              </span>
              <span>:</span>
              <span className="card-text-width-adjust w-275">
                {evsedata.parkingRestrictions?.map((list, index) => (
                  <span key={index}>{list},</span>
                ))}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Image
              </span>
              <span>:</span>
              {/* <span>{evsedata.uid}</span> */}
            </div>
          </div>
        </div>
        <div className="col-6 ">
          <div className="py-4 px-5 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                EVSE ID
              </span>
              <span>:</span>
              <span>
                <span>{evsedata.evseId}</span>
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Schedule Status
              </span>
              <span>:</span>
              {evsedata.statusSchedule?.map((list, index) => (
                <span key={index}>{list.status}</span>
              ))}
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Schedule PeriodBegin
              </span>
              <span>:</span>
              {evsedata.statusSchedule?.map((list, index) => (
                <span key={index}>
                  {moment(list.period_begin).format("YYYY-MM-DD hh:mm A")}
                </span>
              ))}
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Schedule Period End
              </span>
              <span>:</span>
              {evsedata.statusSchedule?.map((list, index) => (
                <span key={index}>
                  {moment(list.period_begin).format("YYYY-MM-DD hh:mm A")}
                </span>
              ))}
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Physical Reference
              </span>
              <span>:</span>
              <span>{evsedata.physicalReference}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Longitude
              </span>
              <span>:</span>
              <span>{evsedata?.coordinates?.longitude}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Directions
              </span>
              <span>:</span>
              {/* <span>{evsedata.uid}</span>{" "} */}
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "40%" }}>
                Last Update
              </span>
              <span>:</span>
              <span>
                {evsedata.last_updated != null
                  ? moment(evsedata?.last_updated).format("YYYY-MM-DD hh:mm A")
                  : null}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Box sx={{ width: "100%" }}>
        <div className="d-flex align-items-center justify-content-between mt-3">
          <div className="pagetitle">Connectors</div>
          <div className="d-flex gap-3">
            <Link to={"/addConnector"} state={EvseId}>
              <div className="Export-Button bgcolor-blue">
                <i class="fa-solid fa-plus" />
                Add
              </div>
            </Link>
            <div className="Export-Button bgcolor-blue">
              <i className="fa-solid fa-arrow-up-from-bracket" />
              Export
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between cardFilter">
          <div>
            <DisplayFilterInputs
              searchInputs={searchInputs}
              clearFilterValue={clearFilterValue}
              clearInputValue={clearInputValue}
              resetStates={resetStates}
            />
          </div>
          <div>
            <material.Box className="d-flex align-items-center gap-3">
              <div>
                <div
                  className="filter-filter bgcolor-blue"
                  onClick={() => handleOptionClick("filter")}
                >
                  <i class="fa-solid fa-sliders"></i>
                  <span className="ms-1">Filter</span>
                </div>
                {option === "filter" && (
                  <material.ClickAwayListener
                    onClickAway={() => handleOptionClick("")}
                  >
                    <div className="filterBox">
                      <FilterTable
                        headCells={headCells}
                        handleFilterColumns={handleFilterColumns}
                        filterColumns={filterColumns}
                        handleOptionClick={handleOptionClick}
                        updateFilter={updateFilter}
                        filterState={filterState}
                        syncFilterStateToSearchInputs={
                          syncFilterStateToSearchInputs
                        }
                      />
                    </div>
                  </material.ClickAwayListener>
                )}
              </div>
              <div className="position-relative">
                <div
                  className="filter-filter bgcolor-blue"
                  onClick={() => handleOptionClick("column")}
                >
                  <i
                    class="fa-solid fa-bars"
                    style={{
                      transform: "rotate(-90deg)",
                    }}
                  ></i>
                  <span className="ms-1">Column</span>
                </div>
                {option === "column" && (
                  <material.ClickAwayListener
                    onClickAway={() => handleOptionClick("")}
                  >
                    <div className="columnBox">
                      <ColumnsBox
                        headCells={headCells}
                        handelChange={handleColumnChange}
                        disableColumns={disableColumns}
                      />
                    </div>
                  </material.ClickAwayListener>
                )}
              </div>
              {selected.length > 0 && (
                <div className="deletediv bg-red " onClick={deleteConnectors}>
                  <i class="fa-regular fa-trash-can delete-icon"></i>
                </div>
              )}
            </material.Box>
          </div>
        </div>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ height: 250 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              // sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
              className="overflow-auto"
            >
              <TableHeadRow
                headCells={headCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={locationDetails.length}
                disableColumns={disableColumns}
              />
              <TableBody>
                {locationDetails.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      // hover
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      {!disableColumns.includes("connectorId") && (
                        <TableCell>{row.connectorId}</TableCell>
                      )}
                      {!disableColumns.includes("standard") && (
                        <TableCell>{row.standard}</TableCell>
                      )}
                      {!disableColumns.includes("format") && (
                        <TableCell>{row.format}</TableCell>
                      )}
                      {!disableColumns.includes("powerType") && (
                        <TableCell>{row.powerType}</TableCell>
                      )}
                      {!disableColumns.includes("maxVoltage") && (
                        <TableCell>{row.maxVoltage}</TableCell>
                      )}
                      {!disableColumns.includes("maxAmperage") && (
                        <TableCell>{row.maxAmperage}</TableCell>
                      )}
                      {!disableColumns.includes("maxElectricPower") && (
                        <TableCell>{row.maxElectricPower}</TableCell>
                      )}
                      {!disableColumns.includes("tariffIds") && (
                        <TableCell>{row.country}</TableCell>
                      )}
                      {!disableColumns.includes("last_updated") && (
                        <TableCell>
                          {row.last_updated
                            ? moment(row.last_updated).format(
                                "YYYY-MM-DD hh:mm A"
                              )
                            : ""}
                        </TableCell>
                      )}
                      {!disableColumns.includes("isActive") && (
                        <TableCell>
                          <Switch
                            checked={row.isActive}
                            // onClick={() =>
                            //   handleActiveDeactivePopup(row.id, row.active)
                            // }
                            size="small"
                          />
                        </TableCell>
                      )}
                      {!disableColumns.includes("terms_and_conditions") && (
                        <TableCell>{row.terms_and_conditions}</TableCell>
                      )}
                      {!disableColumns.includes("update") && (
                        <TableCell>
                          <Link to={"/editConnector"} state={row.id}>
                            <IconButton>
                              <Edit color="primary" />
                            </IconButton>
                          </Link>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
                {locationDetails.length === 0 && (
                  <TableRow style={{ height: 40 }}>
                    <TableCell colSpan={8} className="saas-nodata-row">
                      {noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="position-relative">
            {locationDetails.length > 0 && (
              <CustomTablePagination
                rowsPerPageOptions={[10, 15, 20]}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </div>
        </Paper>
        {sessionExpire && <SessionExpire />}
        {loader && <FullLoader />}
        <PopUp
          open={alertPopup}
          title={statusMessage}
          message={alertMessage}
          btn1={true}
          btn1Text={"Ok"}
          btn1Color={"submit-btn"}
          handleClickBtn1={handleAlertOk}
          popUpWidth={"width-100"}
        />
      </Box>
    </div>
  );
}
