/** @format */

import { FormControlLabel, List, ListItem, Paper, Switch } from "@mui/material";

function ColumnsBox({ headCells, handelChange, disableColumns }) {
  const filteredHeadCells = headCells.filter(
    (cells) => cells.id !== "fromDate" && cells.id !== "toDate"
  );
  return (
    <Paper
      elevation={3}
      className="p-2 mt-15"
      style={{
        maxHeight: "300px",
        overflowY: "scroll",
        color: "black",
        width: "230px",
      }}
    >
      <List>
        {filteredHeadCells.map((cells) => (
          <ListItem key={cells.id}>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={!disableColumns.includes(cells.id)}
                  disabled={cells.mandatory}
                  onChange={() => handelChange(cells.id)}
                  size="small"
                />
              }
              label={cells.label}
              key={cells.id}
              className="d-flex"
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

export default ColumnsBox;
