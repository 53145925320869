import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  MenuItem,
  Popper,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { allowLettersNum, resize } from "../../common/helpers";
import SessionExpire from "../../components/SessionExpire";
import { basePathone } from "../../common/config";
import PopUp from "../../components/PopUp";

function EditAuthorizationTag({setPageHeading}) {
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [tagFields, setTagFields] = React.useState({});
  const [parentTags, setParentTags] = React.useState([]);
  const [tagFieldsValidation, setTagFieldsValidation] = React.useState({
    idTag: "",
    idTokenType: "",
    expiryDate: "",
    tagType: "",
  });
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  React.useEffect(() => {
    resize();
    setPageHeading("Update AuthorizationTag")
    getAuthorizationTagById();
    getParentIdTags();
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const getAuthorizationTagById = () => {
    const headers = {
      "content-type": "application/json",
      // Authorization: localStorage.getItem("Authorization"),
      // "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(basePathone + "api/idtags/" + location.state, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setTagFields(res.data);
        }
      });
  };
  const EditAuthorizationTag = () => {
    let headers = {
      "content-type": "application/json",
      // Authorization: localStorage.getItem("Authorization"),
      // "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(basePathone + "api/idtags/" + location.state, {
      method: "PUT",
      body: JSON.stringify(tagFields),
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        setAlertMessage(res.message);
        handleAlertPopup();
      });
  };
  const getParentIdTags = () => {
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}api/idtags/parentIdtags/list`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setParentTags(res?.data);
        }
      });
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleSubmit() {
    let error = false;
    if (
      !tagFields.idTag ||
      !tagFields.idTokenType ||
      !tagFields.expiryDate ||
      !tagFields.tagType
    ) {
      error = true;
    }
    if (error) {
      if (!tagFields.idTag) {
        setTagFieldsValidation((validation) => ({
          ...validation,
          idTag: "ID Tag is Mandatory",
        }));
      }
      if (!tagFields.idTokenType) {
        setTagFieldsValidation((validation) => ({
          ...validation,
          idTokenType: "Token Type is Mandatory",
        }));
      }
      if (!tagFields.expiryDate) {
        setTagFieldsValidation((validation) => ({
          ...validation,
          expiryDate: "Expiry Date is Mandatory",
        }));
      }
      if (!tagFields.tagType) {
        setTagFieldsValidation((validation) => ({
          ...validation,
          tagType: "Tag Type is Mandatory",
        }));
      }
    } else {
      EditAuthorizationTag();
    }
  }
  function handleChange(key, value) {
    if (key === "blocked" || key === "inTransaction") {
      setTagFields({ ...tagFields, [key]: Number(value) });
    } else {
      setTagFieldsValidation({ ...tagFieldsValidation, [key]: "" });
      setTagFields({ ...tagFields, [key]: value });
    }
  }
  if (!Object.keys(tagFields).length) return null;

  return (
    <div>
      <div className="row row-gap-3 mt-3">
        <div className="col-3">
          <TextField
            required
            label="ID Tag"
            className="w-100"
            variant="outlined"
            size="small"
            value={tagFields.idTag}
            inputProps={{
              minLength: 1,
              maxLength: 30,
              onKeyDown: allowLettersNum  
            }}
            onChange={(e) => handleChange("idTag", e.target.value)}
            error={tagFieldsValidation.idTag}
            helperText={tagFieldsValidation.idTag}
          />
        </div>
        <div className="col-3">
          <TextField
            select
            label="ParentId Tag"
            className="w-100"
            variant="outlined"
            size="small"
            value={tagFields.parentIdTag}
            onChange={(e) => handleChange("parentIdTag", e.target.value)}
          >
            {parentTags.map((list, index) => (
              <MenuItem key={index} value={list}>
                {list}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="col-3">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm
              viewRenderers={{
                hours: null,
                minutes: null,
                seconds: null,
              }}
              label="Expiry Date"
              value={moment(tagFields.expiryDate)}
              className="w-100"
              slots={{
                popper: (props) => (
                  <div className="position-relative">
                    <Popper
                      {...props}
                      style={{ zIndex: 1000 }}
                      disablePortal
                      className="position-absolute"
                    />
                  </div>
                ),
              }}
              sx={{ width: "96%" }}
              slotProps={{
                textField: {
                  size: "small",
                  required: "true",
                  error: tagFieldsValidation.expiryDate,
                  helperText: tagFieldsValidation.expiryDate,
                },
              }}
              onChange={(newDate) => {
                handleChange("expiryDate", newDate);
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="col-3">
          <TextField
            select
            label="Tag Type"
            required
            className="w-100"
            variant="outlined"
            size="small"
            value={tagFields.tagType}
            onChange={(e) => handleChange("tagType", e.target.value)}
            error={tagFieldsValidation.tagType}
            helperText={tagFieldsValidation.tagType}
          >
            <MenuItem value="Private">Private</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </TextField>
        </div>
        <div className="col-3">
          <TextField
            select
            label="Id Token Type"
            required
            className="w-100"
            variant="outlined"
            size="small"
            onFocus={(e) => e.target.select}
            value={tagFields.idTokenType}
            onChange={(e) => handleChange("idTokenType", e.target.value)}
            error={tagFieldsValidation.idTokenType}
            helperText={tagFieldsValidation.idTokenType}
          >
            <MenuItem value="Central">Central</MenuItem>
            <MenuItem value="eMAID">eMAID</MenuItem>
            <MenuItem value="ISO14443">ISO14443</MenuItem>
            <MenuItem value="ISO15693">ISO15693</MenuItem>
            <MenuItem value="KeyCode">KeyCode</MenuItem>
            <MenuItem value="Local">Local</MenuItem>
            <MenuItem value="MacAddress">MacAddress</MenuItem>
            <MenuItem value="NoAuthorization">NoAuthorization</MenuItem>
          </TextField>
        </div>
        <div className="col-3">
          <TextField
            label="Group Id Token"
            className="w-100"
            variant="outlined"
            size="small"
            value={tagFields.groupIdToken}
            inputProps={{
              minLength: 1,
              maxLength: 30,
              onKeyDown: allowLettersNum  
            }}
            onChange={(e) => handleChange("groupIdToken", e.target.value)}
          />
        </div>
        <div className="col-3">
          <Checkbox
            checked={tagFields.blocked}
            onChange={(e) => handleChange("blocked", e.target.checked)}
          />
          <span>Blocked</span>
        </div>
        <div className="col-3">
          <Checkbox
            checked={tagFields.inTransaction}
            onChange={(e) => handleChange("inTransaction", e.target.checked)}
          />
          <span>In Transaction</span>
        </div>
      </div>
      <div>
        <span className="saas-boldHeading">Additional Note :</span>
        <TextareaAutosize
          label="Additional Note"
          className="w-100 mt-2"
          variant="outlined"
          style={{ height: "100px" }}
          value={tagFields.content}
          onChange={(e) => handleChange("content", e.target.value)}
          size="small"
        />
      </div>
      <div className="d-flex justify-content-end gap-3 mt-4">
        <Link to="/authorizationTag">
          <Button size="small" color="error" variant="contained">
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={"Alert"}
        message={alertMessage}
        btn1={true}
        btn1Color={"bg-primary"}
        btn1Text={"Ok"}
        handleClickBtn1={() => navigate("/authorizationTag")}
        popUpWidth={"width-100"}
      />
      {sessionExpire && <SessionExpire />}
    </div>
  );
}

export default EditAuthorizationTag;
