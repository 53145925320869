import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import React from "react";
import TableHeadRow from "../../components/TableHeadRow";
import { useTranslation } from "react-i18next";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomTablePagination from "../../components/CustomTablePagination";
import FullLoader from "../../components/FullLoader";

export default function Versionendpoints({ refresh, setRefresh }) {
  const [orderBy, setOrderBy] = React.useState("id");
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [versionendpoint, setversionendpoint] = React.useState([]);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const location = useLocation();
  const headCells = [
    {
      id: "identifier",
      numeric: false,
      disablePadding: false,
      label: "Identifier ",
      mandatory: true,
      filterOption: false,
      sort: true,
      // maxWidth: 20,
    },
    {
      id: "role",
      numeric: false,
      disablePadding: false,
      label: "Role ",
      mandatory: true,
      filterOption: true,
      sort: true,
      minWidth: 150,
    },
    {
      id: "url",
      numeric: false,
      disablePadding: false,
      label: "URL ",
      mandatory: true,
      filterOption: true,
      sort: true,
      minWidth: 150,
    },

    {
      id: "Update",
      numeric: false,
      disablePadding: false,
      label: "Update ",
      mandatory: true,
      filterOption: true,
      sort: false,
      // minWidth: 150,
    },
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = versionendpoint.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    // setuser(newSelected[0]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
    getErrorCodes();
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getErrorCodes = () => {
    setLoader(true);
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(
      `${basePathone}cpo/versions/${location.state}?pageNo=${
        page + 1
      }&pageSize=${rowsPerPage}&sortBy=${orderBy}&sortDir=${order}`,
      {
        method: "GET",
        headers: headers,
      }
    )
      .then((resp) => {
        setLoader(false);
        // if (resp.status === 401) {
        //   setSessionExpire(true);
        // } else {
        console.log(resp);
        return resp.json();
        // }
      })
      .then((res) => {
        if (res && res?.statusCode) {
          console.log(res);
          setversionendpoint(res?.data);
          console.log(res?.data);
          setCount(res?.count);
        }
      });
  };
  const deleteVersions = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}cpo/versions/${location.state}`, {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(selected),
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.statusCode) {
          setAlertMessage(res?.data);
          setSelected([]);
          handleAlertPopup();
          getErrorCodes();
        }
      });
  };
  React.useEffect(() => {
    if (refresh) {
      getErrorCodes();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getErrorCodes();
  }, [page, rowsPerPage, order, orderBy, location.state]);
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="pagetitle">Version End Points</div>
          <div className="d-flex align-items-center gap-3">
            <Link to="/addversionendpoint" state={location.state}>
              <div className="Export-Button bgcolor-blue">
                <i class="fa-solid fa-plus" />
                Add
              </div>
            </Link>
            <div className="Export-Button bgcolor-blue">
              <i className="fa-solid fa-arrow-up-from-bracket" />
              Export
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between cardFilter">
          <Button
            variant="contained"
            size="small"
            className="d-flex align-items-center justify-content-start submit-btn"
            onClick={() => navigate("/version")}
          >
            <ArrowBackIosIcon fontSize="10px" />
            Back
          </Button>
          <div>
            <Box className="d-flex align-items-center gap-3">
              {selected.length > 0 && (
                <div className="deletediv bg-red " onClick={deleteVersions}>
                  <i class="fa-regular fa-trash-can delete-icon"></i>
                </div>
              )}
            </Box>
          </div>
        </div>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: "14px" }}>
          {/* <Box>
            {selected.length < 1 ? (
              <Box
                sx={{ width: "100%", p: "15px" }}
                className="d-flex align-items-end justify-content-between"
              >
                <Button
                  variant="contained"
                  size="small"
                  className="d-flex align-items-center justify-content-start"
                  onClick={() => navigate("/version")}
                >
                  <ArrowBackIosIcon fontSize="10px" />
                  Back
                </Button>
                <Box className="d-flex gap-1">
                  <Link to="/addversionendpoint" state={location.state}>
                    <Button>
                      <AddIcon />
                      {t("add")}
                    </Button>
                  </Link>
                </Box>
              </Box>
            ) : (
              <Toolbar
                className="border-top"
                sx={{
                  p: "5px",
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  ...(selected.length > 0 && {
                    bgcolor: (theme) =>
                      alpha(
                        theme.palette.primary.main,
                        theme.palette.action.activatedOpacity
                      ),
                  }),
                }}
              >
                {selected.length > 0 && (
                  <i
                    className="fa-solid fa-xmark me-3"
                    style={{ cursor: "pointer" }}
                    onClick={handleSelectAllClick}
                  ></i>
                )}
                {selected.length > 0 ? (
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                  >
                    {selected.length} selected
                  </Typography>
                ) : (
                  <Typography
                    sx={{ flex: "1 1 100%", textAlign: "center" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  ></Typography>
                )}

                {selected.length > 0 && (
                  <IconButton
                    style={{ color: "red" }}
                    className="d-flex gap-2 rounded"
                    onClick={deleteVersions}
                  >
                    <i
                      className="fa-solid fa-trash"
                      style={{ fontSize: "16px" }}
                    ></i>
                    <span className="fs-6">{t("delete")}</span>
                  </IconButton>
                )}
              </Toolbar>
            )}
          </Box> */}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <TableHeadRow
                headCells={headCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={versionendpoint.length}
                disableColumns={[]}
              />
              <TableBody>
                {versionendpoint.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      // hover
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.userId}
                      // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell>{row.identifier}</TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell>{row.url}</TableCell>

                      <TableCell>
                        <Link
                          to={"/editversionendpoint"}
                          state={{ row, version: location.state }}
                        >
                          <IconButton>
                            <Edit color="primary" />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {versionendpoint.length === 0 && (
                  <TableRow style={{ height: 40 }}>
                    <TableCell colSpan={8} className="saas-nodata-row">
                      {t("not_available")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="position-relative">
            {versionendpoint.length > 0 && (
              <CustomTablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </div>
        </Paper>
        <PopUp
          open={alertPopup}
          title={"Alert"}
          message={alertMessage}
          btn1={true}
          btn1Text={"Ok"}
          btn1Color={"submit-btn"}
          handleClickBtn1={handleAlertPopup}
          popUpWidth={"width-100"}
        />
      </Box>
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}
