/** @format */

import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import { Button, ClickAwayListener } from "@mui/material";
import FilterTable from "../../../components/FilterTable";
import ColumnsBox from "../../../components/ColumnsBox";
import FilterInputs from "../../../components/FilterInputs";
import TableHeadRow from "../../../components/TableHeadRow";
import SessionExpire from "../../../components/SessionExpire";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import PopUp from "../../../components/PopUp";
import { basePathone } from "../../../common/config";
import { AccessTime, CalendarToday } from "@mui/icons-material";
import Loader from "../../../components/Loader";
import { searchOperations } from "../../../common/helpers";
export default function ChargingSchedule({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [noData, setNoData] = React.useState("");
  const [searchInputs, setSearchInputs] = React.useState({
    chargingScheduleName: "",
    chargingRateUnit: "",
    minChargingRate: "",
  });
  const [loader, setLoader] = React.useState(false);
  const [option, setOption] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [chargingScheduleData, setChargScheduleData] = React.useState([]);

  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);

  const headCells = [
    {
      id: "chargingScheduleName",
      numeric: false,
      disablePadding: true,
      label: "Name",
      mandatory: true,
      filterOption: true,
      sort: true,
      minWidth: 90,
    },
    {
      id: "duration",
      numeric: false,
      disablePadding: false,
      label: "Charging Duration",
      mandatory: false,
      filterOption: false,
      sort: true,
      minWidth: 50,
    },
    {
      id: "startSchedule",
      numeric: false,
      disablePadding: false,
      label: "Start Schedule",
      mandatory: true,
      filterOption: false,
      sort: true,
      minWidth: 110,
    },
    {
      id: "chargingRateUnit",
      numeric: false,
      disablePadding: false,
      label: "Charging Rate Unit",
      mandatory: true,
      filterOption: true,
      sort: true,
      minWidth: 90,
    },

    {
      id: "minChargingRate",
      numeric: false,
      disablePadding: false,
      label: "Min Charging Rate",
      mandatory: false,
      filterOption: true,
      sort: true,
      minWidth: 90,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      mandatory: true,
      filterOption: false,
      sort: false,
      minWidth: 90,
    },
  ];
  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        chargingScheduleName: "",
        schedulingUnit: "",
        minChargingRate: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }
  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  function removeInput(input) {
    setFilterColumns((columns) => columns.filter((cell) => cell !== input));
    setSearchInputs({ ...searchInputs, [input.id]: "" });
  }
  function handleSearchInputs(key, value) {
    setSearchInputs({ ...searchInputs, [key]: value });
    setPage(0);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = chargingScheduleData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleAlertOk() {
    getChargingSchedules();
    //getChargingSchedulesCount();
    handleAlertPopup();
  }
  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "duration" || key === "minChargingRate") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.EQUALITY,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });

    return params;
  };
  const getChargingSchedules = () => {
    setLoader(true);
    const searchParams = buildSearchParams();
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    const body = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };

    fetch(`${basePathone}chargingSchedules/search`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        setChargScheduleData(res?.data ?? []);
        setCount(res?.count);
        setNoData(res?.count === 0 ? "No Data Available" : "");
      });
  };
  const deleteschedule = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}chargingSchedules/${selected[0]}`, {
      method: "DELETE",
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setStatus(true);
          setSelected([]);
          setAlertMessage(res.message);
          handleAlertPopup();
        } else {
          setStatus(false);
          setAlertMessage(res.message);
          handleAlertPopup();
        }
      });
  };
  React.useEffect(() => {
    getChargingSchedules();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);
  React.useEffect(() => {
    if (refresh) {
      getChargingSchedules();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box>
          {selected.length < 1 ? (
            <Box
              sx={{ width: "100%", p: "10px" }}
              className="d-flex align-items-end justify-content-between"
            >
              <div
                style={{ width: "55%" }}
                className="d-flex flex-wrap align-items-center"
              >
                {filterColumns.map((column) => (
                  <FilterInputs
                    key={column.id}
                    name={column}
                    removeInput={removeInput}
                    value={searchInputs[column.id]}
                    handleSearchInputs={handleSearchInputs}
                  />
                ))}
              </div>
              <Box className="d-flex gap-1">
                <Link to="/AddchargingSchedule">
                  <Button>
                    <AddIcon />
                    {t("ADD")}
                  </Button>
                </Link>
                <Button onClick={() => handleOptionClick("filter")}>
                  <FilterListIcon />
                  <span className="ms-1">{t("ADD FILTER")}</span>
                </Button>
                {option === "filter" && (
                  <ClickAwayListener onClickAway={() => handleOptionClick("")}>
                    <div className="filterBox">
                      <FilterTable
                        headCells={headCells}
                        handleFilterColumns={handleFilterColumns}
                        filterColumns={filterColumns}
                      />
                    </div>
                  </ClickAwayListener>
                )}
                <Button onClick={() => handleOptionClick("column")}>
                  <ViewWeekIcon />
                  <span className="ms-1">{t("COLUMN")}</span>
                </Button>
                {option === "column" && (
                  <ClickAwayListener onClickAway={() => handleOptionClick("")}>
                    <div className="columnBox">
                      <ColumnsBox
                        headCells={headCells}
                        handelChange={handelChange}
                        disableColumns={disableColumns}
                      />
                    </div>
                  </ClickAwayListener>
                )}
                <Button>
                  <DownloadIcon />
                  <span className="ms-1">{t("EXPORT")}</span>
                </Button>
              </Box>
            </Box>
          ) : (
            <Toolbar
              className="border-top"
              sx={{
                p: "5px",
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selected.length > 0 && {
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.activatedOpacity
                    ),
                }),
              }}
            >
              {selected.length > 0 && (
                <i
                  className="fa-solid fa-xmark me-3 pt-1"
                  style={{ cursor: "pointer" }}
                  onClick={handleSelectAllClick}
                ></i>
              )}
              {selected.length > 0 ? (
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected.length} selected
                </Typography>
              ) : (
                <Typography
                  sx={{ flex: "1 1 100%", textAlign: "center" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                ></Typography>
              )}

              {selected.length > 0 && (
                <IconButton
                  style={{ color: "red" }}
                  className="d-flex gap-2 rounded"
                  onClick={deleteschedule}
                >
                  <i
                    className="fa-solid fa-trash"
                    style={{ fontSize: "16px" }}
                  ></i>
                  <span className="fs-6">Delete</span>
                </IconButton>
              )}
            </Toolbar>
          )}
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={chargingScheduleData.length}
              disableColumns={disableColumns}
            />
            <TableBody>
              {chargingScheduleData.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {!disableColumns.includes("chargingScheduleName") && (
                      <TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.chargingScheduleName}
                      </TableCell>
                    )}
                    {!disableColumns.includes("duration") && (
                      <TableCell style={{ width: "150px" }}>
                        {row.duration}
                      </TableCell>
                    )}
                    {!disableColumns.includes("startSchedule") && (
                      <TableCell style={{ width: "50px" }}>
                        <CalendarToday className="calDate" />
                        {moment(row.startSchedule).format("DD-MM-YYYY")}
                        <AccessTime className="calTime" />
                        {moment(row.startSchedule).format("HH:mm A")}
                      </TableCell>
                    )}
                    {!disableColumns.includes("chargingRateUnit") && (
                      <TableCell>{row.chargingRateUnit}</TableCell>
                    )}
                    {!disableColumns.includes("minChargingRate") && (
                      <TableCell>{row.minChargingRate}</TableCell>
                    )}
                    {!disableColumns.includes("action") && (
                      <TableCell className="m-0 " align="center">
                        <Link to="/editChargingSchedule" state={row}>
                          <i className="fa-solid fa-pencil action-icons"></i>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {chargingScheduleData.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {chargingScheduleData.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              disablePortal
              id="TablePagination"
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("Rows per page")}
            />
          )}
        </div>
      </Paper>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
