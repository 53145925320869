import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faUser,
  faEnvelope,
  faEye,
  faUserLock,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import { basePath } from "../../common/config";
import { validatePassword } from "../../common/helpers";

function ResetPassword() {
  const [show, setShow] = useState(false);
  const [inputFields, setInputFields] = useState({
    tenantid: "",
    email: "",
    otp: "",
    password: "",
    pwd: "",
  });

  const [errors, setErrors] = useState({
    tenantid: "",
    email: "",
    otp: "",
    password: "",
    pwd: "",
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleChange1 = () => {
    setShow(!show);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setInputFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let newErrors = { tenantid: "", email: "", otp: "", password: "", pwd: "" };

    if (!inputFields.tenantid) {
      error = true;
      newErrors = { ...newErrors, tenantid: "Tenant ID is required" };
    }
    if (!inputFields.email) {
      error = true;
      newErrors = { ...newErrors, email: "Email is required" };
    }
    if (inputFields.email && !emailRegex.test(inputFields.email)) {
      error = true;
      newErrors = { ...newErrors, email: "Enter valid email" };
    }
    if (!inputFields.otp) {
      error = true;
      newErrors = { ...newErrors, otp: "OTP is required" };
    }
    if (!inputFields.password) {
      error = true;
      newErrors = { ...newErrors, password: "Password is required" };
    }
    if (!inputFields.pwd) {
      error = true;
      newErrors = { ...newErrors, pwd: "Confirm Password is required" };
    }
    if (
      inputFields.password &&
      inputFields.pwd &&
      inputFields.password !== inputFields.pwd
    ) {
      error = true;
      newErrors = { ...newErrors, pwd: "Passwords do not match" };
    }
    if (inputFields.password && !validatePassword(inputFields.password)) {
      error = true;
      newErrors = {
        ...newErrors,
        password:
          "Password must be 8-20 characters long and include at least 1 lowercase character" +
          "(a-z), 1 uppercase character (A-Z), 1 number (0-9), and 1 special character (!@#$%^&*()+)",
      };
    }

    if (error) {
      setErrors(newErrors);
    } else {
      const payload = {
        email: inputFields.email,
        token: inputFields.otp,
        newPassword: inputFields.password,
      };
      const headers = {
        "content-type": "application/json",
        "X-TenantID": inputFields.tenantid,
      };

      fetch(`${basePath}auth/reset-password`, {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: headers,
      })
        .then((resp) => {
          return resp.json();
        })
        .then((res) => {
          if (res.status === false && res.message.includes("Tenant")) {
            setErrors((errors) => ({
              ...errors,
              tenantid: res.message,
            }));
          } else if (res.status === false && res.message.includes("Email")) {
            setErrors((errors) => ({
              ...errors,
              email: res.message,
            }));
          } else if (res.status === false && res.message.includes("OTP")) {
            console.log(res.message);
            setErrors((errors) => ({
              ...errors,
              otp: res.message,
            }));
          } else {
            window.location.href = "/";
          }
          console.log(res.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <div className="row g-0 login-container">
      <div className="col-lg-6 img-hide">
        <img src="bg.jpg" alt="background" className="img-style" />
      </div>
      <div className="col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center form-container">
        <form className="p-3 form-layout mx-auto">
          <div className="text-center mb-4">
            <h3> Reset Password</h3>
          </div>
          <div className="form-group mb-2">
            <label className="form-label text-muted lables-hide">
              Tenant ID
            </label>
            <div className="input-container">
              <FontAwesomeIcon icon={faUser} className="input-icon" />
              <input
                id="tenantId"
                name="tenantid"
                type="text"
                className="form-control rounded-input"
                value={inputFields.tenantid}
                placeholder="123456"
                onChange={handleInputChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>
              {errors.tenantid}
            </div>
          </div>
          <div className="form-group mb-2">
            <label className="form-label text-muted lables-hide">Email</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
              <input
                name="email"
                id="email"
                type="email"
                className="form-control rounded-input"
                value={inputFields.email}
                placeholder="example@gmail.com"
                onChange={handleInputChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>{errors.email}</div>
          </div>
          <div className="form-group mb-2">
            <label className="form-label text-muted lables-hide">OTP</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faUserLock} className="input-icon" />
              <input
                name="otp"
                id="otp"
                type="text"
                className="form-control rounded-input"
                value={inputFields.otp}
                placeholder="1234"
                onChange={handleInputChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>{errors.otp}</div>
          </div>
          <div className="form-group mb-2">
            <label className="form-label text-muted lables-hide">
              New Password
            </label>
            <div className="input-container">
              <FontAwesomeIcon icon={faLock} className="input-icon" />
              <input
                name="password"
                id="password"
                type="password"
                className="form-control rounded-input"
                value={inputFields.password}
                placeholder="Abcd@123"
                onChange={handleInputChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>
              {errors.password}
            </div>
          </div>
          <div className="form-group mb-2">
            <label className="form-label text-muted lables-hide">
              Confirm Password
            </label>
            <div className="input-container">
              <FontAwesomeIcon icon={faLock} className="input-icon" />
              <FontAwesomeIcon
                icon={show ? faEye : faEyeSlash}
                className="eye-icon"
                onClick={handleChange1}
              />
              <input
                id="pwd"
                name="pwd"
                type={show ? "text" : "password"}
                className="form-control rounded-input"
                value={inputFields.pwd}
                placeholder="Password"
                onChange={handleInputChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>{errors.pwd}</div>
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-success mt-3 p-2"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
