import {
  Button,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Box,
  FormControl,
  FormLabel,
} from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resize } from "../../common/helpers";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function EditVersion({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState();
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const navigate = useNavigate();
  const [versionlist, setversionlist] = React.useState([]);
  const [statusFalsePop, setStatusFalsePop] = React.useState(false);
  const location = useLocation();
  const [addData, setAddData] = React.useState({
    version: "",
    url: "",
  });
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setAddData(location.state);
    getVersions();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setAddData(location.state);
      getVersions();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))" + // domain name or IP address
      "(\\:[0-9]{1,5})?(\\/.*)?$",
    "i"
  );

  const [errors, setErrors] = React.useState({ url: "", version: "" });
  const validateForm = () => {
    const newErrors = {};
    if (!addData.version) newErrors.version = " Version is required";
    if (!addData.url) newErrors.url = " Url is required";
    return newErrors;
  };
  function handleAddVersion(event) {
    event.preventDefault();
    let error2 = false;
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      error2 = true;
    }
    if (!error2 & (errors.url.length === 0)) {
      updateVersion();
    }
  }
  function handleChange(key, value) {
    setAddData({
      ...addData,
      [key]: value,
    });
    if (key === "url") {
      if (urlPattern.test(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "Enter a valid URL",
        }));
      }
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
    }
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleClosePop() {
    navigate("/version");
  }
  const handleStatusFalse = () => {
    setStatusFalsePop((pop) => !pop);
  };
  const getVersions = async () => {
    let Url = `cpo/versions/getVersionsDropdown`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    setversionlist(res.data);
    setversionlist(res.data);
  };
  const updateVersion = async () => {
    let Url = `cpo/versions/${location.state?.id}`;
    let type = "PUT";
    let body = addData;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusMessage === "SUCCESS") {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleStatusFalse();
    }
  };

  return (
    <div className="row mt-4 column-gap-5 row-gap-4 ms-3">
      {sessionExpire && <SessionExpire />}
      <div className="pagetitle">Update Version</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <Box className="row row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Version*</b>
                </FormLabel>
                <TextField
                  select
                  size="small"
                  className="w-100"
                  name="version"
                  variant="outlined"
                  required
                  value={addData.version}
                  error={errors.version}
                  helperText={errors.version}
                  onChange={(e) => handleChange("version", e.target.value)}
                >
                  {versionlist.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>URL *</b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  name="url"
                  placeholder="URL"
                  variant="outlined"
                  required
                  value={addData.url}
                  error={errors.url}
                  helperText={errors.url}
                  onChange={(e) => handleChange("url", e.target.value)}
                />
              </FormControl>
            </div>
          </Box>
        </CardContent>
      </Card>
      <div className="d-flex aligm-items-center justify-content-end gap-4 my-5 me-5 ">
        <Button
          variant="contained"
          onClick={handleAddVersion}
          className="submit-btn"
        >
          Update
        </Button>
        <Button variant="contained" color="error" className="cancel-btn">
          <Link to="/version" className="cancle-button">
            cancel
          </Link>
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClosePop}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={statusFalsePop}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={handleStatusFalse}
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
    </div>
  );
}
