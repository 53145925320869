import { Box, TextField } from "@mui/material";
import React from "react";

export default function ChangeConfigurationPop({
  formObj,
  handleChange,
  error,
}) {
  return (
    <div className="py-3">
      <Box>
        <div className="d-flex flex-wrap gap-2 w-auto">
          <TextField
            label="Configuration Key"
            className="w-45 me-3 error no-arrows"
            disabled
            variant="outlined"
            value={formObj.key}
            size="small"
            onChange={(e) => {
              handleChange("key", e.target.value);
            }}
          />
          <TextField
            label="Value"
            error={error}
            className="w-45 me-3 error no-arrows"
            required
            step="0.1"
            variant="outlined"
            value={formObj.value}
            size="small"
            helperText={error}
            onChange={(e) => {
              handleChange("value", e.target.value);
            }}
          />
        </div>
      </Box>
    </div>
  );
}
