import React from "react";
import { basePathone } from "../../common/config";
import moment from "moment";

import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { formatDateForInput, resize } from "../../common/helpers";
import Button from "@mui/material/Button";
import PopUp from "../../components/PopUp";
import PriceComponentsPopUp from "./PriceComponentsPopUp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SessionExpire from "../../components/SessionExpire";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function UpdateElement() {
  const [priceComponentPop, setPriceComponentPop] = React.useState(false);
  const [pCEditPopUp, setPCEditPopUp] = React.useState(false);
  const [tablepopup, settablepopup] = React.useState(false);
  const [PCTypeDrop, setPCTypeDrop] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [restrictionsObj, setRestrictionsObj] = React.useState({
    start_time: null,
    start_date: "",
    end_time: null,
    end_date: "",
    min_kwh: "",
    max_kwh: "",
    max_current: "",
    min_current: "",
    min_power: "",
    max_power: "",
    min_duration: "",
    max_duration: "",
    day_of_week: "",
    reservation: "",
  });
  const [priceConfigObj, setPriceConfigObj] = React.useState({
    type: "",
    price: "",
    vat: "",
    step_size: "",
  });
  const [elementsObj, setElementsObj] = React.useState({
    price_components: [],
    restrictions: {},
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [PcErrors, setPcErrors] = React.useState({});
  const [priceConfigList, setPriceConfigList] = React.useState([]);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [reservationdrop, setreservationdrop] = React.useState([]);
  let tariffObject = JSON.parse(localStorage.getItem("TariffObject"));
  const tariffID = tariffObject?.id;
  React.useEffect(() => {
    getpriceComponentsType();
    getPCByElementIdAndTAriffId(location.state);
    getElementbyid();
    // setElementsObj(location.state);
    getReservationlist();
  }, [location.state, tariffID]);
  const getReservationlist = async () => {
    let Url = `cpo/${2.1}/tariffs/getreservationTypeDropdown`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setreservationdrop(res?.data);
    }
  };
  const UpdateTAriffElement = async () => {
    let Url = `cpo/${2.1}/tariffs/elements/updateTariffElement/${tariffID}/${
      location.state
    }`;
    let type = "PATCH";
    let body = restrictionsObj;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleAlertPopup();
    }
  };

  const getpriceComponentsType = async () => {
    let Url = `cpo/${2.1}/tariffs/getTariffDimensionType`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setPCTypeDrop(res?.data);
    }
  };

  //Price components All Apis
  const [rocId, setRocId] = React.useState();
  const [elementId, setElementId] = React.useState();
  //Get Energy sources by energy-mix id and Location Id
  const getPCByElementIdAndTAriffId = async (row) => {
    let Url = `cpo/${2.1}/tariffs/elements/priceComponents/${location.state}`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      setPriceConfigList(res?.data);
    }
  };

  //Add EnergySources By mix id And Location Id
  const addPCByElementIdAndTAriffId = async () => {
    let Url = `cpo/${2.1}/tariffs/elements/addpriceComponents/${tariffID}/${
      location.state
    }`;
    let type = "POST";
    let body = priceConfigObj;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setAlertMessage(res?.data);
      handletablepopup();
    }
  };
  //Update energy sources By rocId and enrgymixId and Loc Id
  const updatePCByElementIdAndTAriffId = async (tariffId, row) => {
    let Url = `cpo/${2.1}/tariffs/elements/updatepriceComponents/${tariffID}/${
      location.state
    }/${rocId}`;
    let type = "PATCH";
    let body = priceConfigObj;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handletablepopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handletablepopup();
    }
  };
  //Delete EnergySource By rocId and mixId and Loc
  const DeletePCByElementIdAndTAriffId = async (id) => {
    let Url = `cpo/${2.1}/tariffs/elements/deletePriceComponent/${tariffID}/${
      location.state
    }/${id}`;
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handletablepopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handletablepopup();
    }
  };
  const getElementbyid = async () => {
    let Url = `cpo/${2.1}/tariffs/elements/getElementData/${tariffID}/${
      location.state
    }`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setElementsObj(res.data);
      setPriceConfigList(res.data?.price_components);
      setRestrictionsObj(res.data?.restrictions);
    }
  };

  function handlePCValidations() {
    let error = {};
    if (!priceConfigObj.type) error.type = "please select Type";
    if (!priceConfigObj.price) error.price = "price is required";
    if (!priceConfigObj.vat) error.vat = "vat is required";
    if (!priceConfigObj.step_size) error.step_size = "Step-size is required";
    setPcErrors(error);
    return Object.keys(error).length === 0; // return true or false
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handletablepopup() {
    settablepopup((pop) => !pop);
  }
  function handlePCPop() {
    setPriceConfigObj({
      type: "",
      price: "",
      vat: "",
      step_size: "",
    });
    setPcErrors({
      type: "",
      price: "",
      vat: "",
      step_size: "",
    });
    setPriceComponentPop((pop) => !pop);
  }
  function handlePCEditPop() {
    setPcErrors({
      type: "",
      price: "",
      vat: "",
      step_size: "",
    });
    setPCEditPopUp((pop) => !pop);
  }
  function handlePCEditingPopUp(list) {
    setPriceConfigObj(list);
    setRocId(list.id);
    handlePCEditPop();
  }
  function handlePCchange(key, value) {
    setPcErrors({ ...PcErrors, [key]: "" });
    setPriceConfigObj({
      ...priceConfigObj,
      [key]: value,
    });
  }
  function handleRestrictionChange(key, value) {
    setRestrictionsObj({
      ...restrictionsObj,
      [key]: value,
    });
  }
  const PCvalidateForm = () => {
    if (handlePCValidations()) {
      addPCByElementIdAndTAriffId();
      handlePCPop();
      setPriceConfigObj({
        type: "",
        price: "",
        vat: "",
        step_size: "",
      });
    }
  };
  const PCEditvalidateForm = () => {
    if (handlePCValidations()) {
      updatePCByElementIdAndTAriffId();
      handlePCEditPop();
      setPriceConfigObj({
        type: "",
        price: "",
        vat: "",
        step_size: "",
      });
    }
  };

  return (
    <div className="mb-3">
      <div className="  ps-2 pagetitle ">Update Element</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="  ps-2 pagetitle ">Restrictions</div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Start Date </span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={formatDateForInput(restrictionsObj.start_date)}
                  onChange={(e) => {
                    handleRestrictionChange("start_date", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>End Date </span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={formatDateForInput(restrictionsObj?.end_date)}
                  onChange={(e) => {
                    handleRestrictionChange("end_date", e.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Start Time </span>
                </div>
                <input
                  type="time"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={restrictionsObj.start_time}
                  onChange={(e) => {
                    handleRestrictionChange("start_time", e.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>End Time </span>
                </div>
                <input
                  type="time"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={restrictionsObj.end_time}
                  onChange={(e) => {
                    handleRestrictionChange("end_time", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Min kWh </b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  value={restrictionsObj?.min_kwh}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    handleRestrictionChange("min_kwh", e.target.value);
                  }}
                  variant="outlined"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max kWh </b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  value={restrictionsObj?.max_kwh}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    handleRestrictionChange("max_kwh", e.target.value);
                  }}
                  variant="outlined"
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Min Current </b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  value={restrictionsObj?.min_current}
                  //   error={restrictionsObjErrors.min_power}
                  //   helperText={restrictionsObjErrors.min_power}
                  onChange={(e) => {
                    handleRestrictionChange("min_current", e.target.value);
                  }}
                  variant="outlined"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Current </b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  value={restrictionsObj?.max_current}
                  //   error={restrictionsObjErrors.min_power}
                  //   helperText={restrictionsObjErrors.min_power}
                  onChange={(e) => {
                    handleRestrictionChange("max_current", e.target.value);
                  }}
                  variant="outlined"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Min Power </b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  value={restrictionsObj?.min_power}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    handleRestrictionChange("min_power", e.target.value);
                  }}
                  variant="outlined"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Power </b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  value={restrictionsObj?.max_power}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    handleRestrictionChange("max_power", e.target.value);
                  }}
                  variant="outlined"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Min Duration </b>
                </FormLabel>
                <TextField
                  size="small"
                  type="number"
                  className="w-100"
                  value={restrictionsObj?.min_duration}
                  onChange={(e) => {
                    handleRestrictionChange("min_duration", e.target.value);
                  }}
                  variant="outlined"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Duration </b>
                </FormLabel>
                <TextField
                  size="small"
                  type="number"
                  className="w-100"
                  value={restrictionsObj?.max_duration}
                  //   error={restrictionsObjErrors.max_duration}
                  //   helperText={restrictionsObjErrors.max_duration}
                  onChange={(e) => {
                    handleRestrictionChange("max_duration", e.target.value);
                  }}
                  variant="outlined"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Day Of Week </b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={restrictionsObj?.day_of_week}
                  onChange={(e) => {
                    handleRestrictionChange("day_of_week", e.target.value);
                  }}
                >
                  <MenuItem value="SUNDAY">SUNDAY</MenuItem>
                  <MenuItem value="MONDAY">MONDAY</MenuItem>
                  <MenuItem value="TUESDAY">TUESDAY</MenuItem>
                  <MenuItem value="WEDNESDAY">WEDNESDAY</MenuItem>
                  <MenuItem value="THURSDAY">THURSDAY</MenuItem>
                  <MenuItem value="FRIDAY">FRIDAY</MenuItem>
                  <MenuItem value="SATURDAY">SATURDAY</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Reservation </b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={restrictionsObj?.reservation}
                  onChange={(e) =>
                    handleRestrictionChange("reservation", e.target.value)
                  }
                >
                  {reservationdrop.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div>
        <div>
          <div className="d-flex justify-content-between mt-4">
            <div className=" mx-0 p-0 pagetitle">Price Configurations</div>
            <div
              className="Export-Button bgcolor-blue cursor-Pointer"
              onClick={handlePCPop}
            >
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </div>
          <Card
            sx={{ minWidth: 275 }}
            style={{ borderRadius: "25px", marginTop: "14px" }}
          >
            <CardContent className="cardPadding">
              <table class="table table-box-shadow mt-3">
                <thead>
                  <tr class="table-primary">
                    <th scope="col">Type</th>
                    <th scope="col">Price</th>
                    <th scope="col">Vat</th>
                    <th scope="col">Step-Size</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {priceConfigList?.length > 0 ? (
                    priceConfigList.map((list, index) => (
                      <tr key={index}>
                        <td>{list.type}</td>
                        <td>{list.price}</td>
                        <td>{list.vat}</td>
                        <td>{list.step_size}</td>
                        <td>
                          <span onClick={() => handlePCEditingPopUp(list)}>
                            <i className="fa-solid fa-pencil action-icons"></i>
                          </span>

                          <span
                            onClick={() =>
                              DeletePCByElementIdAndTAriffId(list.id)
                            }
                            style={{ color: "red" }}
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </CardContent>
          </Card>
        </div>

        <div className="d-flex justify-content-end gap-3 mt-4">
          <Link to="/TariffEdit">
            <Button
              size="small"
              color="error"
              variant="contained"
              className="cancel-btn"
            >
              Cancel
            </Button>
          </Link>
          <Button
            className="submit-btn"
            size="small"
            color="primary"
            variant="contained"
            onClick={UpdateTAriffElement}
          >
            Submit
          </Button>
        </div>
        <PopUp
          open={priceComponentPop}
          handleClosePopUp={handlePCPop}
          title={"Add Price Element"}
          message={
            <PriceComponentsPopUp
              PCTypeDrop={PCTypeDrop}
              priceConfigObj={priceConfigObj}
              handlePCchange={handlePCchange}
              PcErrors={PcErrors}
            />
          }
          btn1={true}
          btn2={true}
          btn1Color={"cancel-btn"}
          btn2Color={"submit-btn"}
          btn1Text={"Cancel"}
          btn2Text={"Submit"}
          handleClickBtn1={handlePCPop}
          handleClickBtn2={PCvalidateForm}
          popUpWidth="width-100"
        />
        <PopUp
          open={pCEditPopUp}
          handleClosePopUp={handlePCEditPop}
          title={"Add Price Element"}
          message={
            <PriceComponentsPopUp
              PCTypeDrop={PCTypeDrop}
              priceConfigObj={priceConfigObj}
              handlePCchange={handlePCchange}
              PcErrors={PcErrors}
            />
          }
          btn1={true}
          btn2={true}
          btn1Color={"cancel-btn"}
          btn2Color={"submit-btn"}
          btn1Text={"Cancel"}
          btn2Text={"Submit"}
          handleClickBtn1={handlePCEditPop}
          handleClickBtn2={PCEditvalidateForm}
          popUpWidth="width-100"
        />
        <PopUp
          open={alertPopup}
          title={statusMessage}
          message={alertMessage}
          btn1={true}
          btn1Color={"submit-btn"}
          btn1Text={"Ok"}
          handleClickBtn1={() =>
            navigate("/TariffEdit", {
              state: {
                row: location.state?.row,
                tariffId: location.state?.tariffId,
              },
            })
          }
          popUpWidth={"width-100"}
        />
        <PopUp
          open={tablepopup}
          title={statusMessage}
          message={alertMessage}
          btn1={true}
          btn1Color={"submit-btn"}
          btn1Text={"Ok"}
          handleClickBtn1={() => {
            settablepopup(false);
            getPCByElementIdAndTAriffId(location.state.row);
          }}
          popUpWidth={"width-100"}
        />
        {loader && <FullLoader />}
        {sessionExpire && SessionExpire}
      </div>
    </div>
  );
}
