import { Box, TextField } from "@mui/material";
import React from "react";

export default function DirectionsPOP({
  directionsObj,
  handleDirectionschange,
  dirErrors,
}) {
  return (
    <div className="py-3">
      <Box>
        <div className="d-flex flex-wrap gap-2 w-auto">
          <TextField
            label="Language"
            error={dirErrors.language}
            className="w-45 me-3 error no-arrows"
            required
            disabled
            step="0.1"
            variant="outlined"
            value={directionsObj.language}
            size="small"
            helperText={dirErrors.language}
            onChange={(e) => {
              handleDirectionschange("language", e.target.value);
            }}
          />
          <TextField
            label="Text"
            error={dirErrors.text}
            className="w-45 me-3 error no-arrows"
            required
            step="0.1"
            variant="outlined"
            value={directionsObj.text}
            size="small"
            helperText={dirErrors.text}
            onChange={(e) => {
              handleDirectionschange("text", e.target.value);
            }}
          />
        </div>
      </Box>
    </div>
  );
}
