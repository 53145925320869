/** @format */

import { Button, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import PopUp from "../../components/PopUp";
import { basePath } from "../../common/config";
import HeaderOrFooterForm from "./HeaderOrFooterForm";
import FullLoader from "../../components/FullLoader";

function HeaderOrFooterItem(props) {
  const [updateHeaderItems, setUpdateHeaderItems] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [deletePopup, setDeletePopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [addFormPopup, setAddFormPopup] = useState(false);
  const [updateFormPopup, setupdateFormPopup] = useState(false);
  const [headerForm, setHeaderForm] = useState({ title: "", link: "" });
  const [sessionExpire, setSessionExpire] = useState(false);
  const [headerFormValidation, setHeaderFormValidation] = useState({
    title: "",
    link: "",
  });
  function addHeaderItem() {
    let payload = {
      ...headerForm,
      tenantId: localStorage.getItem("tenantId"),
      isActive: 1,
      roleId: localStorage.getItem("roleId"),
      category: props?.category,
      menuOrder: 0,
    };
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(basePath + "api/menuItems", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: headers,
    })
      .then((resp) => resp.json())
      .then((res) => {
        setAlertMessage(res.message);
        handleAlertPopup();
      });
  }

  function updateHeaderItem(updatePayload) {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(basePath + "api/menuItems", {
      method: "PUT",
      body: JSON.stringify(updatePayload),
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setAlertMessage(res.message);
          handleAlertPopup();
        }
      });
  }
  const deleteMenuItem = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(basePath + "api/menuItems/delete/" + deleteId, {
      method: "DELETE",
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setAlertMessage(res.message);
          handleAlertPopup();
        }
      });
  };
  function handleDeletePopup() {
    setDeletePopup((pop) => !pop);
  }
  function handleAlertOk() {
    props.getSidebarItems();
    handleAlertPopup();
  }
  function handleAddFormPopUp() {
    setAddFormPopup((pop) => !pop);
    setHeaderForm({ title: "", link: "" });
    setHeaderFormValidation({ title: "", link: "" });
  }
  function handleUpdateFormPopup() {
    setupdateFormPopup((pop) => !pop);
  }
  function handleDeleteClick() {
    deleteMenuItem();
    handleDeletePopup();
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleHeaderForm(key, value) {
    setHeaderFormValidation({ ...headerFormValidation, [key]: "" });
    setHeaderForm({ ...headerForm, [key]: value });
  }
  function handleFormSubmit() {
    let error = false;
    if (
      headerForm.title.length === 0 ||
      headerForm.link.length === 0 ||
      !headerForm.link.startsWith("/")
    ) {
      error = true;
    }
    if (error) {
      if (headerForm.title.length === 0) {
        setHeaderFormValidation((error) => ({
          ...error,
          title: "Title is Mandatory",
        }));
      }
      if (headerForm.link.length === 0) {
        setHeaderFormValidation((error) => ({
          ...error,
          link: "Link is Mandatory",
        }));
      }
      if (!headerForm.link.startsWith("/") && headerForm.link.length > 0) {
        setHeaderFormValidation((error) => ({
          ...error,
          link: "Link must Start with /",
        }));
      }
    } else {
      if (addFormPopup) {
        addHeaderItem();
        handleAddFormPopUp();
      } else {
        updateHeaderItem([headerForm]);
        handleUpdateFormPopup();
      }
    }
  }
  function handleEditClick(headerItem) {
    setHeaderForm(headerItem);
    handleUpdateFormPopup();
  }
  function handleCheck(updatedValue) {
    props.setHeaderItems((menuItems) =>
      menuItems.map((item) =>
        item.parent.id === updatedValue.id
          ? {
              ...item,
              parent: {
                ...item.parent,
                isActive: Number(!item.parent.isActive),
              },
            }
          : item
      )
    );
    let isPresent = false;
    for (let i = 0; i < updateHeaderItems.length; i++) {
      if (updateHeaderItems[i].id === updatedValue.id) {
        isPresent = true;
        break;
      }
    }
    if (isPresent) {
      setUpdateHeaderItems((menuItems) =>
        menuItems.filter((item) => item.id !== updatedValue.id)
      );
    } else {
      setUpdateHeaderItems([...updateHeaderItems, updatedValue]);
    }
  }
  function handleDelete(id) {
    setDeleteId(id);
    handleDeletePopup();
  }
  return (
    <div style={{ height: "55vh" }}>
      <div className="d-flex justify-content-between">
        <b className="mb-4 d-block fs-5">{props?.title} Configuration</b>
        <Button
          // style={{ backgroundColor: "gray", color: "white" }}
          size="small"
          className="submit-btn"
          onClick={handleAddFormPopUp}
          sx={{ height: "40px" }}
        >
          <AddIcon />
          ADD
        </Button>
      </div>
      <div className="d-flex flex-column h-100 justify-content-between">
        <div
          className="w-auto row overflow-auto"
          style={{ maxHeight: "20rem" }}
        >
          {props?.headerItems?.map((item) => (
            <div key={item.parent.id} className="col-4 ">
              <FormControlLabel
                label={item.parent.title}
                control={
                  <Checkbox
                    checked={item.parent.isActive}
                    onChange={() =>
                      handleCheck({
                        id: item.parent.id,
                        isActive: Number(!item.parent.isActive),
                      })
                    }
                  />
                }
              />
              <IconButton onClick={() => handleEditClick(item.parent)}>
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
              <IconButton onClick={() => handleDelete(item.parent.id)}>
                <DeleteIcon color="error" fontSize="small" />
              </IconButton>
            </div>
          ))}
        </div>
        <div className="mb-5 d-flex justify-content-end w-100">
          <Button
            className="cancel-btn"
            // style={{ backgroundColor: "#2DCC70", color: "white" }}
            variant="contained"
            onClick={() => updateHeaderItem(updateHeaderItems)}
          >
            Update
          </Button>
        </div>
      </div>
      <PopUp
        open={deletePopup}
        title={"Confirm"}
        message={"Do you want to Delete this Header Item"}
        btn1={true}
        btn1Color={"cancel-btn"}
        btn1Text={"Delete"}
        btn2={true}
        btn2Color={"submit-btn"}
        btn2Text={"Cancel"}
        handleClickBtn2={handleDeletePopup}
        handleClickBtn1={handleDeleteClick}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={addFormPopup}
        title={`Add ${props?.title} Item`}
        message={
          <HeaderOrFooterForm
            headerForm={headerForm}
            headerFormValidation={headerFormValidation}
            handleHeaderForm={handleHeaderForm}
          />
        }
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Submit"}
        handleClickBtn1={handleFormSubmit}
        btn2={true}
        btn2Color={"cancel-btn"}
        btn2Text={"Cancel"}
        handleClickBtn2={handleAddFormPopUp}
        popUpWidth={"addMenu"}
      />
      <PopUp
        open={updateFormPopup}
        title={`Update ${props?.title} Item`}
        message={
          <HeaderOrFooterForm
            headerForm={headerForm}
            headerFormValidation={headerFormValidation}
            handleHeaderForm={handleHeaderForm}
          />
        }
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Update"}
        handleClickBtn1={handleFormSubmit}
        btn2={true}
        btn2Color={"cancel-btn"}
        btn2Text={"Cancel"}
        handleClickBtn2={handleUpdateFormPopup}
        popUpWidth={"addMenu"}
      />
      <PopUp
        open={alertPopup}
        title={"Alert"}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
      {sessionExpire && <sessionExpire />}
    </div>
  );
}

export default HeaderOrFooterItem;
