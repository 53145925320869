import { Button } from "@mui/material";

function GetLocalListVersion({ handleSubmitOperation }) {
  function handleSubmit() {
    let error = false;
    let inputFields = {};
    handleSubmitOperation(error, inputFields);
  }
  return (
    <>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default GetLocalListVersion;
