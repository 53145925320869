import { Button } from "@mui/material"
import ConfigurationTable from "./ConfigurationTable"
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import React from "react";
import PopUp from "../../components/PopUp";

function Configuration() {
    const [sessionExpire, setSessionExpire] = React.useState(false);
    const [configurationKeys, setConfigurationKeys] = React.useState([]);
    const [favouriteKeys, setFavouriteKeys] = React.useState([]);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertPopup, setAlertPopup] = React.useState();

    React.useEffect(() => { getConfigurtionKeys() }, [])
    const getConfigurtionKeys = () => {
        const headers = {
            "content-type": "application/json",
            // Authorization: localStorage.getItem("Authorization"),
            // "X-TenantID": localStorage.getItem("tenantId"),
        };
        // const searchParams = [{ key: "idTag", value: searchInputs, operation: searchOperations.CONTAINS }]
        const payload = {}
        fetch(basePathone + "cskey/search",
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(payload)
            }
        )
            .then((resp) => {
                if (resp.status === 401) {
                    setSessionExpire(true);
                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                if (res?.status) {
                    // set(res.data);
                    setConfigurationKeys(res.data.configurationKeys);
                    setFavouriteKeys(res.data.favourites);
                }
            });
    };

    const updateFavourite = (favourite, id) => {
        const headers = {
            "content-type": "application/json",
            Authorization: localStorage.getItem("Authorization"),
            "X-TenantID": localStorage.getItem("tenantId"),
        };
        fetch(basePathone + `cskey/toggleActiveStatus/${id}?status=${favourite}`,
            {
                method: "PATCH",
                headers: headers,
            }
        )
            .then((resp) => {
                if (resp.status === 401) {
                    setSessionExpire(true);
                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                setAlertMessage(res.message);
                handleAlertPopup();
            });
    };
    function handleAlertOk() {
        getConfigurtionKeys();
        handleAlertPopup();
    }
    function handleAlertPopup() {
        setAlertPopup((pop) => !pop);
    }
    return (
        <div>
            <Button variant="contained" size="small" className="mb-3">Get Configuration</Button>
            <div className="mb-2"> <span className="saas-boldHeading fs-15">Favourites</span></div>
            <ConfigurationTable keys={favouriteKeys} updateFavourite={updateFavourite} getConfigurationKeys={getConfigurtionKeys} />
            <div className="mt-3 mb-2"><span className="saas-boldHeading fs-15">Configuration</span></div>
            <ConfigurationTable keys={configurationKeys} updateFavourite={updateFavourite} getConfigurationKeys={getConfigurtionKeys} />
            {sessionExpire && <SessionExpire />}
            <PopUp
                open={alertPopup}
                title={"Alert"}
                message={alertMessage}
                btn1={true}
                btn1Text={"Ok"}
                btn1Color={"bg-primary"}
                handleClickBtn1={handleAlertOk}
                popUpWidth={"width-100"}
            />
        </div>
    )
}

export default Configuration
