/** @format */

import React from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import MenuItem from "./MenuItem";
import HeaderOrFooterItem from "./HeaderOrFooterItem";

/** @format */
function MenuConfiguration({
  refresh,
  setRefresh,
  headerItems,
  setHeaderItems,
  menuItems,
  setMenuItems,
  footerItems,
  setFooterItems,
  getSidebarItems,
}) {
  const [value, setValue] = React.useState("header");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#2DCC70",
              },
            }}
          >
            <Tab
              label="Header"
              value="header"
              className="settings_Fonts_style"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#2DCC70",
                  color: "white",
                },
              }}
            />
            <Tab
              label="Menu Items"
              className="settings_Fonts_style"
              value="sidenav"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#2DCC70",
                  color: "white",
                },
              }}
            />
            <Tab
              label="Footer"
              className="settings_Fonts_style"
              value="footer"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#2DCC70",
                  color: "white",
                },
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="header">
          <HeaderOrFooterItem
            category={value}
            headerItems={headerItems}
            title={"Header"}
            getSidebarItems={getSidebarItems}
          />
        </TabPanel>
        <TabPanel value="sidenav">
          <MenuItem
            refresh={refresh}
            setRefresh={setRefresh}
            category={value}
            menuItems={menuItems}
            setMenuItems={setMenuItems}
            getSidebarItems={getSidebarItems}
          />
        </TabPanel>
        <TabPanel value="footer">
          <HeaderOrFooterItem
            category={value}
            headerItems={footerItems}
            title={"Footer"}
            getSidebarItems={getSidebarItems}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default MenuConfiguration;
