import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";

function DataTransfer({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    vendorId: "",
    messageId: "",
    data: "",
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    vendorId: "",
  });
  function handleOnChange(key, value) {
    setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
    setInputFields({ ...inputFields, [key]: value });
  }
  function handleSubmit() {
    let error = false;
    if (!inputFields.vendorId) {
      error = true;
    }
    if (error) {
      if (!inputFields.vendorId) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          vendorId: "Vendor Id is Mandatory",
        }));
      }
    }
    let payload = {
      vendorId: inputFields.vendorId,
    };
    if (inputFields.messageId) {
      payload.messageId = inputFields.messageId;
    }
    if (inputFields.data) {
      payload.data = inputFields.data;
    }

    handleSubmitOperation(error, payload);
  }

  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Vendor Id (String) *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.vendorId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.vendorId}
                  size="small"
                  helperText={inputFieldsValidation.vendorId}
                  onChange={(e) => {
                    handleOnChange("vendorId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Message Id (String) </b>
                </FormLabel>
                <TextField
                  variant="outlined"
                  className="w-100"
                  value={inputFields.messageId}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("messageId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Data (Text) </b>
                </FormLabel>
                <TextField
                  variant="outlined"
                  className="w-100"
                  value={inputFields.data}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("data", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          className="submit-btn"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default DataTransfer;
