import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, FormControl, FormLabel, TextField } from "@mui/material";
import { MenuItem, Switch } from "@mui/material";
import { allowLetters, handleKeyDown } from "../../common/helpers";
import SessionExpire from "../../components/SessionExpire";
import { basePathone } from "../../common/config";
import PopUp from "../../components/PopUp";
import Card from "@mui/material/Card";
import * as material from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

function Addtoken({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [whitelist, setwhitelist] = useState([]);
  const [typelist, settypelist] = useState([]);
  const [loader, setLoader] = React.useState(false);
  const [countryList, setCountryList] = React.useState([]);
  const [profiletypelist, setprofiletypelist] = useState([]);
  const [status, setStatus] = React.useState("");
  const [tokenfields, settokenfields] = React.useState({
    idTag: "",
    partyId: "",
    tagType: "",
    idTokenType: null,
    contractId: "",
    countryCode: "",
    issuer: "",
    groupId: "",
    valid: false,
    wlType: null,
    defaultProfileType: null,
    energyContract: { supplierName: "", contractId: "" },
  });
  const [tokenvalidation, settokenvalidation] = React.useState({
    idTag: "",
    partyId: "",
    countryCode: "",
    contractId: "",
    issuer: "",
    groupId: "",
    valid: "",
    wlType: "",
    contractId: "",
    defaultProfileType: "",
    energyContract: { supplierName: "", contractId: "" },
  });
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);

  React.useEffect(() => {
    getWhitelist();
    handleGetCountries();
    getTypelist();
    getProfileType();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getWhitelist();
      handleGetCountries();
      getTypelist();
      getProfileType();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  const navigate = useNavigate();

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  function validateFields() {
    const validationErrors = {};
    const {
      idTag,
      partyId,
      issuer,
      contractId,
      energyContract: { supplierName },
    } = tokenfields;

    if (!idTag) validationErrors.idTag = "IdTag is Mandatory";
    // if (!partyId) validationErrors.partyId = "Party ID is Mandatory";
    // if(!countryCode)validationErrors.countryCode="Country Code is Mandatory";
    if (!issuer) validationErrors.issuer = "Issuer Name is Mandatory";
    // if (!wlType) validationErrors.wlType = "WhiteList Type is Mandatory";
    if (!contractId) validationErrors.contractId = "Contract ID is Mandatory";
    if (!supplierName)
      validationErrors.energyContract = {
        supplierName: "Supplier Name is Mandatory",
      };

    settokenvalidation(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }

  function handleSubmit() {
    if (validateFields()) {
      addtoken();
    }
  }
  function handleChange(key, value) {
    if (key === "energyContract") {
      settokenfields({
        ...tokenfields,
        energyContract: {
          ...tokenfields.energyContract,
          ...value,
        },
      });
    } else {
      settokenfields({ ...tokenfields, [key]: value });
    }
    settokenvalidation((prev) => ({
      ...prev,
      [key]: "",
    }));
  }

  const getProfileType = async () => {
    let Url = `emsp/${2.1}/tokens/getProfileType`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    setprofiletypelist(res.data);
  };
  const addtoken = async () => {
    let Url = `emsp/${2.1}/tokens/createToken`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      tokenfields,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.statusCode === 1000) {
      setStatus(true);
      setAlertMessage(res?.data);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res?.data);
      handleAlertPopup();
    }
  };
  const getWhitelist = async () => {
    let Url = `emsp/${2.1}/tokens/getWhiteListType`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    setwhitelist(res.data);
  };
  const getTypelist = async () => {
    let Url = `emsp/${2.1}/tokens/getTokenType`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    settypelist(res.data);
  };
  const handleGetCountries = async () => {
    let Url = `emsp/countryCodes`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setCountryList(res.data);
    }
  };
  return (
    <div>
      <div className="pagetitle">Add Token</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          {sessionExpire && <SessionExpire />}
          <Box>
            <div className="row row-gap-3 mt-3">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Token Type </b>
                  </FormLabel>
                  <material.Select
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={tokenfields.idTokenType || ""}
                    onChange={(e) =>
                      handleChange("idTokenType", e.target.value)
                    }
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <span
                            style={{ color: "var(--light-ash-color, #A0A0A0)" }}
                          >
                            Select Token Type
                          </span>
                        );
                      }
                      return selected;
                    }}
                    // error={!!tokenvalidation.wlType}  // Dynamically show the error
                    // helperText={tokenvalidation.wlType}  // Display custom error message if validation fails
                  >
                    {typelist.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </material.Select>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Group ID </b>
                  </FormLabel>
                  <TextField
                    placeholder="Group ID"
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={tokenfields.groupId}
                    inputProps={{
                      minLength: 10,
                      maxLength: 30,
                      onKeyDown: handleKeyDown,
                    }}
                    onChange={(e) => handleChange("groupId", e.target.value)}
                  ></TextField>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Issuer Name </b>
                  </FormLabel>
                  <TextField
                    required
                    placeholder="Issuer Name"
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={tokenfields.issuer}
                    inputProps={{
                      minLength: 3,
                      maxLength: 30,
                      onKeyDown: allowLetters,
                    }}
                    onChange={(e) => handleChange("issuer", e.target.value)}
                    error={!!tokenvalidation.issuer}
                    helperText={tokenvalidation.issuer}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>ID Tag *</b>
                  </FormLabel>
                  <TextField
                    required
                    placeholder="ID Tag"
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={tokenfields.idTag}
                    inputProps={{
                      minLength: 3,
                      maxLength: 30,
                      pattern:
                        "^[A-Z]{2}-[A-Za-z0-9]{3}-[A-Za-z0-9]{3,}-[A-Za-z0-9-]{1,30}$",
                      onKeyDown: (e) => {
                        if (
                          !/[A-Za-z0-9-]/.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                            "Tab",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      },
                    }}
                    onChange={(e) => handleChange("idTag", e.target.value)}
                    error={!!tokenvalidation.idTag}
                    helperText={tokenvalidation.idTag}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>WhiteList </b>
                  </FormLabel>
                  <material.Select
                    select
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={tokenfields.wlType || ""}
                    onChange={(e) => handleChange("wlType", e.target.value)}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <span
                            span
                            style={{ color: "var(--light-ash-color, #A0A0A0)" }}
                          >
                            Select Whitelist
                          </span>
                        );
                      }
                      return selected;
                    }}
                    error={!!tokenvalidation.wlType}
                    helperText={tokenvalidation.wlType}
                  >
                    {whitelist.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </material.Select>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Contract ID *</b>
                  </FormLabel>
                  <TextField
                    required
                    placeholder="Contract ID"
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={tokenfields.contractId}
                    inputProps={{
                      minLength: 10,
                      maxLength: 30,
                      onKeyDown: handleKeyDown,
                    }}
                    onChange={(e) => handleChange("contractId", e.target.value)}
                    error={!!tokenvalidation.contractId}
                    helperText={tokenvalidation.contractId}
                  ></TextField>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Supplier Name *</b>
                  </FormLabel>
                  <TextField
                    required
                    placeholder="Supplier Name"
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={tokenfields.energyContract.supplierName}
                    inputProps={{
                      minLength: 10,
                      maxLength: 30,
                      onKeyDown: allowLetters,
                    }}
                    onChange={(e) =>
                      handleChange("energyContract", {
                        supplierName: e.target.value,
                      })
                    }
                    error={!!tokenvalidation.energyContract?.supplierName}
                    helperText={tokenvalidation.energyContract?.supplierName}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Country </b>
                  </FormLabel>
                  <material.Select
                    size="small"
                    className="error"
                    variant="outlined"
                    required
                    value={tokenfields.countryCode || ""}
                    onChange={(e) => {
                      // Store only the country code when a country is selected
                      handleChange("countryCode", e.target.value);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <span
                            style={{ color: "var(--light-ash-color, #A0A0A0)" }}
                          >
                            Select Country
                          </span>
                        );
                      }
                      // Find and display the country name corresponding to the selected country code
                      const selectedCountry = countryList.find(
                        (country) => country.countryCode === selected
                      );
                      return selectedCountry
                        ? selectedCountry.countryName
                        : selected;
                    }}
                  >
                    {countryList.map((country) => (
                      <MenuItem
                        key={country.countryCode}
                        value={country.countryCode}
                      >
                        {country.countryName}
                      </MenuItem>
                    ))}
                  </material.Select>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Party ID </b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="w-100"
                    placeholder="Party ID"
                    variant="outlined"
                    required
                    value={tokenfields.partyId}
                    // inputProps={{
                    //   minLength: 3,
                    //   maxLength: 10,
                    //   onKeyDown: (e) => {
                    //     if (
                    //       !/[0-9]/.test(e.key) &&
                    //       ![
                    //         "Backspace",
                    //         "Delete",
                    //         "ArrowLeft",
                    //         "ArrowRight",
                    //       ].includes(e.key)
                    //     ) {
                    //       e.preventDefault();
                    //     }
                    //   },
                    // }}
                    // error={!!tokenvalidation.partyId}
                    // helperText={tokenvalidation.partyId}
                    onChange={(e) => {
                      handleChange("partyId", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Profile Type </b>
                  </FormLabel>
                  <material.Select
                    select
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={tokenfields.defaultProfileType}
                    onChange={(e) =>
                      handleChange("defaultProfileType", e.target.value)
                    }
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <span
                            span
                            style={{ color: "var(--light-ash-color, #A0A0A0)" }}
                          >
                            Select Profile Type
                          </span>
                        );
                      }
                      return selected;
                    }}
                  >
                    {profiletypelist.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </material.Select>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Valid </b>
                  </FormLabel>
                  <Switch
                    checked={tokenfields.valid}
                    onChange={(e) => handleChange("valid", e.target.checked)}
                    size="small"
                  />
                </FormControl>
              </div>
            </div>
          </Box>
        </CardContent>
      </Card>
      <Box>
        <div className="d-flex aligm-items-center justify-content-end gap-4 my-4">
          <Link to="/token">
            <Button
              size="small"
              color="error"
              variant="contained"
              className="cancel-btn"
            >
              Cancel
            </Button>
          </Link>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className="submit-btn"
          >
            Add
          </Button>
        </div>
      </Box>

      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={
          status === true ? () => navigate("/token") : handleAlertPopup
        }
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}

export default Addtoken;
