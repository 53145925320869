import {
  Button,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Overview from "./Overview";
import ChargingStationSessions from "./ChargingStationSessions";
import ChargingStationsOperation from "./ChargingStationsOperation";
import AuthorizationLocalList from "./AuthorizationLocalList";
import Configuration from "./Configuration";
import { basePathone } from "../../common/config";
import ChargingStationAuthorizationTag from "./ChargingStationAuthorizationTag";
import SessionExpire from "../../components/SessionExpire";
function ViewChargePoint() {
  const [option, setOption] = useState("1");
  const [chargeStation, setChargeStation] = useState({});
  const [sessionExpire, setSessionExpire] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    // getChargeStation();
    setChargeStation(location.state);
  }, []);
  const getChargeStation = () => {
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(basePathone + "csinfo/" + location.state?.id, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        setChargeStation(res?.data);
      });
  };
  function handleSetOption(e) {
    setOption(e.target.value);
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="pagetitle ">Charging Station Operations</div>
        <Button
          className="submit-btn"
          variant="contained"
          size="small"
          onClick={() => navigate("/location")}
        >
          <ArrowBackIosIcon fontSize="10px" />
          Back
        </Button>
      </div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="d-flex align-items-center gap-4">
            <FormControl className="simpleInput">
              <TextField
                select
                sx={{ width: "300px" }}
                size="small"
                value={option}
                onChange={handleSetOption}
              >
                <MenuItem value="1">Overview</MenuItem>
                <MenuItem value="2">Operations</MenuItem>
                <MenuItem value="3">Charging Session</MenuItem>
                <MenuItem value="4">Authorization Local List</MenuItem>
                <MenuItem value="5">Configuration Keys</MenuItem>
                {/* <MenuItem value="6">ID Tag</MenuItem> */}
              </TextField>
            </FormControl>
          </div>
        </CardContent>
      </Card>
      <div className="mt-3">
        {option === "1" && (
          <Overview
            chargeStation={chargeStation}
            getChargeStation={getChargeStation}
          />
        )}
        {option === "2" && (
          <ChargingStationsOperation
            locationName={chargeStation.chargingStationId}
          />
        )}
        {option === "3" && (
          <ChargingStationSessions
            locationName={chargeStation.chargingStationId}
          />
        )}
        {option === "4" && (
          <AuthorizationLocalList
            locationName={chargeStation.chargingStationId}
          />
        )}
        {option === "5" && <Configuration />}
        {/* {option === "6" && <ChargingStationAuthorizationTag />} */}
      </div>
      {sessionExpire && <SessionExpire />}
    </div>
  );
}

export default ViewChargePoint;
