import {
  Button,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Box,
  FormControl,
  FormLabel,
} from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import FullLoader from "../../components/FullLoader";

export default function AddEndpointversion({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [identifierlist, setidentifierlist] = React.useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [addData, setAddData] = React.useState({
    identifier: "",
    role: "",
    url: "",
  });
  React.useEffect(() => {
    getIdentifierlist();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getIdentifierlist();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))" + // domain name or IP address
      "(\\:[0-9]{1,5})?(\\/.*)?$",
    "i"
  );

  const [errors, setErrors] = React.useState({
    identifier: "",
    role: "",
    url: "",
  });
  const validateForm = () => {
    const newErrors = {};
    if (!addData.identifier) newErrors.identifier = " identifier is required";
    if (!addData.role) newErrors.role = "Role is Required";
    if (!addData.url) newErrors.url = " Url is required";
    return newErrors;
  };
  function handleAddVersion(event) {
    event.preventDefault();
    let error2 = false;
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      error2 = true;
    }
    if (!error2 & (errors.url.length === 0)) {
      addVersion();
    }
  }
  function handleChange(key, value) {
    setAddData({
      ...addData,
      [key]: value,
    });
    if (key === "url") {
      if (urlPattern.test(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "Enter a valid URL",
        }));
      }
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
    }
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleClosePop() {
    navigate("/versionendpoint", { state: location.state });
  }
  const getIdentifierlist = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}cpo/versions/getIdentifier`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        console.log("API Response:", res);
        // if (res?.statusCode === 1000)
        // || Array.isArray(res?.data)) settimezoneslist(res.data);
        setidentifierlist(res.data);
      });
  };
  const addVersion = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}cpo/versions/${location.state}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(addData),
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.statusCode) {
          setAlertMessage(res?.data);
          handleAlertPopup();
        }
      });
  };

  return (
    <div className="row mt-4 column-gap-5 row-gap-4 ms-3">
      {sessionExpire && <SessionExpire />}
      <div className="pagetitle">Add Endpoint</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <Box className="row row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Identifier*</b>
                </FormLabel>
                <TextField
                  select
                  size="small"
                  className="w-100"
                  name="identifier"
                  variant="outlined"
                  required
                  value={addData.identifier}
                  error={errors.identifier}
                  helperText={errors.identifier}
                  onChange={(e) => handleChange("identifier", e.target.value)}
                >
                  {identifierlist.map((list, index) => (
                    <MenuItem key={index} value={list.value}>
                      {list.value}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Role*</b>
                </FormLabel>
                <TextField
                  select
                  size="small"
                  className="w-100"
                  name="role"
                  variant="outlined"
                  required
                  value={addData.role}
                  error={errors.role}
                  helperText={errors.role}
                  onChange={(e) => handleChange("role", e.target.value)}
                >
                  <MenuItem value="SENDER">SENDER</MenuItem>
                  <MenuItem value="RECEIVER">RECEIVER</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>URL*</b>
                </FormLabel>
                <TextField
                  size="small"
                  className="w-100"
                  placeholder="URL"
                  name="url"
                  variant="outlined"
                  required
                  value={addData.url}
                  error={errors.url}
                  helperText={errors.url}
                  onChange={(e) => handleChange("url", e.target.value)}
                />
              </FormControl>
            </div>
          </Box>
        </CardContent>
      </Card>
      <div className="d-flex aligm-items-center justify-content-end gap-4 my-5 me-5 ">
        <Button
          size="small"
          color="error"
          variant="contained"
          className="cancel-btn"
        >
          <Link
            to="/versionendpoint"
            state={location.state}
            className="cancle-button"
          >
            Cancel
          </Link>
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleAddVersion}
          className="submit-btn"
        >
          Add
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={"Alert"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClosePop}
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
    </div>
  );
}
