import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faUser,
  faEnvelope,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import { basePath } from "../../common/config";
import { Link } from "react-router-dom";
import { handleEmail } from "../../common/helpers";

function LoginForm() {
  const [show, setShow] = useState(false);
  const [inputFields, setInputFields] = useState({
    tenantid: "",
    email: "",
    pwd: "",
  });
  let valid = false;
  const [errors, setErrors] = useState({
    tenantid: "",
    email: "",
    pwd: "",
  });
  const handleChange1 = () => {
    setShow(!show);
  };
  function handleChange(e) {
    setErrors({ ...errors, [e.target.name]: "" });
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      valid = handleEmail(e.target.value);
      if (valid) {
        setErrors({
          ...errors,
          [e.target.name]: "Enter Valid Email",
        });
      } else {
        setErrors({ ...errors, [e.target.name]: "" });
      }
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    let error = false;
    if (
      inputFields.email.length === 0 ||
      inputFields.tenantid.length === 0 ||
      inputFields.pwd.length === 0
    ) {
      error = true;
    }
    if (error) {
      if (inputFields.tenantid.length <= 0) {
        setErrors((errors) => ({
          ...errors,
          tenantid: "Tenant Id is Mandatory",
        }));
      }
      if (inputFields.email.length <= 0) {
        setErrors((errors) => ({ ...errors, email: "Email is Mandatory" }));
      }
      if (inputFields.pwd.length <= 0) {
        setErrors((errors) => ({ ...errors, pwd: "Password is Mandatory" }));
      }
    }
    let payload = {
      email: inputFields.email,
      password: inputFields.pwd,
      // tenantId: inputFields.tenantid,
    };

    let headers = {
      "content-type": "application/json",
      "X-TenantID": inputFields.tenantid,
    };

    if (inputFields.email && inputFields.pwd && inputFields.tenantid) {
      fetch(basePath + "auth/login", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: headers,
      })
        .then((resp) => {
          return resp.json();
        })
        .then((res) => {
          if (res.status === true) {
            localStorage.setItem("Authorization", res?.data?.token);
            localStorage.setItem("userId", res?.data?.userId);
            localStorage.setItem("tenantId", res?.data?.tenantId);
            localStorage.setItem("mobile", res?.data?.userDetails?.mobile);
            localStorage.setItem("email", res?.data?.userDetails?.email);
            localStorage.setItem("Refresh_Token", res?.data?.refreshToken);
            localStorage.setItem(
              "firstName",
              res?.data?.userDetails?.firstName
            );
            localStorage.setItem("lastName", res?.data?.userDetails?.lastName);
            localStorage.setItem("roleId", res?.data?.userDetails?.roleId);
            localStorage.setItem(
              "roleName",
              res?.data?.userDetails?.role?.roleName
            );
            localStorage.setItem(
              "tenantAddress",
              res?.data?.userDetails?.tenant?.address
            );
            localStorage.setItem(
              "tenantEmail",
              res?.data?.userDetails?.tenant?.email
            );
            localStorage.setItem(
              "tenantName",
              res?.data?.userDetails?.tenant?.tenantName
            );
            localStorage.setItem(
              "profileImage",
              res?.data?.userDetails?.profileImageUrl
            );
            localStorage.setItem(
              "tenantLogopath",
              res?.data?.userDetails?.tenant?.tenantLogopath
            );
            localStorage.setItem(
              "countryCode",
              res?.data?.userDetails?.countryCode
            );
            localStorage.setItem("partyId", res?.data?.userDetails?.partyId);
            getUserRole();
          } else {
            if (res.status === false && res.message.includes("Tenant")) {
              setErrors((errors) => ({
                ...errors,
                tenantid: res.message,
              }));
            } else if (
              res.status === false &&
              (res.message.includes("Email") || res.message.includes("account"))
            ) {
              setErrors((errors) => ({
                ...errors,
                email: res.message,
              }));
            } else if (
              res.status === false &&
              res.message.includes("password")
            ) {
              setErrors((errors) => ({
                ...errors,
                pwd: res.message,
              }));
            }
          }
        });
    }
  }
  const getUserRole = () => {
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(basePath + "user/api/me", {
      method: "GET",
      headers: headers,
    })
      .then((resp) => resp.json())
      .then((res) => {
        if (res.status === true) {
          localStorage.setItem("theme", res?.data?.theme);
          localStorage.setItem("noficationEnable", res?.data?.noficationEnable);
          localStorage.setItem("language", res?.data?.languageId);
        }
        window.location.replace("/location");
      })
      .catch(() => {
        window.location.replace("/location");
      });
  };

  return (
    <div className="row g-0 login-container">
      <div className="col-lg-6 img-hide">
        <img src="bg.jpg" alt="background" className="img-style" />
      </div>
      <div className="col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center form-container">
        <form className="p-3 form-layout mx-auto">
          <div className="text-center mb-4">
            <img src="login.png" alt="login" className="login-logo" />
          </div>
          <div className="form-group mb-3">
            <label className="form-label text-muted lables-hide">
              Tenant ID
            </label>
            <div className="input-container">
              <FontAwesomeIcon icon={faUser} className="input-icon" />
              <input
                id="tenantId"
                name="tenantid"
                type="text"
                className="form-control rounded-input"
                value={inputFields.tenantid}
                placeholder="123456"
                onChange={handleChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>
              {errors.tenantid}
            </div>
          </div>
          <div className="form-group mb-3">
            <label className="form-label text-muted lables-hide">Email</label>
            <div className="input-container">
              <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
              <input
                name="email"
                id="email"
                type="email"
                className="form-control rounded-input"
                value={inputFields.email}
                placeholder="example@gmail.com"
                onChange={handleChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>{errors.email}</div>
          </div>
          <div className="form-group mb-3">
            <label className="form-label text-muted lables-hide">
              Password
            </label>
            <div className="input-container">
              <FontAwesomeIcon icon={faLock} className="input-icon" />
              <FontAwesomeIcon
                icon={show ? faEye : faEyeSlash}
                className="eye-icon"
                onClick={handleChange1}
              />
              <input
                id="pwd"
                name="pwd"
                type={show ? "text" : "password"}
                className="form-control rounded-input"
                value={inputFields.pwd}
                placeholder="Password"
                onChange={handleChange}
              />
            </div>
            <div style={{ color: "red", fontSize: "14px" }}>{errors.pwd}</div>
          </div>

          <div className="d-flex justify-content-end">
            <Link to="/forgetPassword" className="link-success">
              Forgot Password?
            </Link>
          </div>

          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-success mt-3 p-2"
          >
            Login
          </button>
          <div className="d-flex align-items-center m-3 text-muted">
            <div className="w-50 border-bottom"></div>
            <div className="mx-3">Or</div>
            <div className="w-50 border-bottom"></div>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-4">
            <div className="btn-circle">
              <img src="fb.png" alt="fb-logo" role="button" />
            </div>
            <div className="btn-circle">
              <img src="google.png" alt="google-icon" role="button" />
            </div>
            <div className="btn-circle">
              <img src="twitter.png" alt="twitter-icon" role="button" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
