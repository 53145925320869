import * as React from "react";
import Box from "@mui/material/Box";
import PopUp from "../../components/PopUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import CustomTablePagination from "../../components/CustomTablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as material from "@mui/material";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import { basePath } from "../../common/config";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import { useTranslation } from "react-i18next";
import { resize, searchOperations } from "../../common/helpers";
import Loader from "../../components/Loader";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function EventLogs({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("timestamp");
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState(["tenantName"]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [searchInputs, setSearchInputs] = React.useState({
    LogId: "",
    eventType: "",
    tenantName: "",
    fromDate: "",
    toDate: "",
  });
  const [noData, setNoData] = React.useState("");
  const [option, setOption] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [logs, setlogs] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Log ID",
      mandatory: true,
      filterOption: true,
      searchType: "text",
      sort: true,
    },
    {
      id: "eventType",
      numeric: false,
      disablePadding: false,
      label: "Event Type",
      mandatory: true,
      filterOption: true,
      searchType: "text",
      sort: true,
    },

    {
      id: "tenantName",
      numeric: false,
      disablePadding: false,
      label: "Tenant Name",
      mandatory: false,
      filterOption: false,
      searchType: "text",
      sort: false,
    },
    {
      id: "timestamp",
      numeric: false,
      disablePadding: false,
      label: "Timestamp",
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
      mandatory: false,
      searchType: "text",
      filterOption: false,
      sort: true,
    },
    {
      id: "fromDate",
      numeric: false,
      disablePadding: false,
      label: "From Date",
      filterOption: true,
      onlySearch: true,
      searchType: "date",
    },
    {
      id: "toDate",
      numeric: false,
      disablePadding: false,
      label: "To Date",
      filterOption: true,
      onlySearch: true,
      searchType: "date",
    },
  ];
  const [filterState, setFilterState] = React.useState([
    { key: "id", value: "" },
    { key: "eventType", value: "" },
    { key: "tenantName", value: "" },
    { key: "timestamp", value: "" },
    { key: "description", value: "" },
    { key: "fromDate", value: "" },
    { key: "toDate", value: "" },
  ]);

  function handleSearchInputs(key, value) {
    if (key === "id") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setPage(0);
        setSearchInputs({ ...searchInputs, [key]: value });
      }
    } else {
      setPage(0);
      setSearchInputs({ ...searchInputs, [key]: value });
    }
  }

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }
  const handleClick = (data) => {
    setMessage(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        LogId: "",
        eventType: "",
        tenantName: "",
        fromDate: "",
        toDate: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }

  function removeInput(input) {
    setFilterColumns((columns) => columns.filter((cell) => cell !== input));
    setSearchInputs({ ...searchInputs, [input.id]: "" });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "id") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.LIKE,
          };
        } else if (key === "fromDate") {
          return {
            key: "timestamp",
            value: searchInputs[key],
            operation: searchOperations.GREATER_THAN,
          };
        } else if (key === "toDate") {
          return {
            key: "timestamp",
            value: searchInputs[key],
            operation: searchOperations.LESS_THAN,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });

    return params;
  };
  const getLogs = async () => {
    const searchParams = buildSearchParams();
    const Url = `api/eventLogs/entities/search?pageNo=${
      page + 1
    }&size=${rowsPerPage}&sortBy=${orderBy}&sortDir=${order}`;
    const type = "POST";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      [],
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    setlogs(res?.data?.response ?? []);
    setCount(res?.data?.count);
    setNoData(res?.data?.count === 0 ? "No Data Available" : "");
  };
  React.useEffect(() => {
    if (refresh) {
      getLogs();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getLogs();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      id: "",
      eventType: "",
      tenantName: "",
      timestamp: "",
      description: "",
      fromDate: "",
      toDate: "",
    });
    setFilterState([
      { key: "id", value: "" },
      { key: "eventType", value: "" },
      { key: "tenantName", value: "" },
      { key: "timestamp", value: "" },
      { key: "description", value: "" },
      { key: "fromDate", value: "" },
      { key: "toDate", value: "" },
    ]);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Event Logs</div>
        <div className="d-flex align-items-center gap-3 ">
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red ">
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 500 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              order={order}
              // deleteCheckBox={false}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={logs.length}
              disableColumns={disableColumns}
              disableCheckBox={true}
            />
            <TableBody>
              {logs.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow tabIndex={-1} key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    {!disableColumns.includes("id") && (
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {row.id}
                      </TableCell>
                    )}
                    {!disableColumns.includes("eventType") && (
                      <TableCell>{row.eventType}</TableCell>
                    )}
                    {!disableColumns.includes("tenantName") && (
                      <TableCell>
                        {row?.userDetails?.tenant?.tenantName}
                      </TableCell>
                    )}
                    {!disableColumns.includes("timestamp") && (
                      <TableCell>
                        {row.timestamp &&
                          moment(row.timestamp).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}
                    {!disableColumns.includes("description") && (
                      <TableCell>
                        <i className="fa-solid fa-eye" style={{ color: "#2DCC70" }} onClick={() => handleClick(row.description)}></i>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {logs.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {logs.length > 0 && (
          <CustomTablePagination
            rowsPerPageOptions={[10, 15, 20]}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <PopUp
        open={open}
        title={t("view_description")}
        message={message}
        btn1={true}
        btn1Text={t("ok")}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClose}
        popUpWidth={"width-100"}
      />
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
