/** @format */

import { Checkbox, IconButton, Popper, TextField } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useState } from "react";
function FilterInputs({
  fromDateField,
  toDateField,
  name,
  removeInput,
  value,
  handleSearchInputs,
  children,
}) {
  const [openDatePop, setOpenDatePop] = useState(false);
  if (name.searchType === "date")
    return (
      <div className="d-flex">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div style={{ width: "198px", borderRadius: "5px" }}>
            <DateTimePicker
              sx={{ height: "45px" }}
              variant="filled"
              open={openDatePop}
              onOpen={() => setOpenDatePop(true)}
              onClose={() => setOpenDatePop(false)}
              label={name.label}
              maxDateTime={moment()}
              // minDateTime={fromDateField ? moment(fromDateField) : null}
              // maxDateTime={toDateField ? moment(toDateField) : moment()}
              inputFormat="YYYY-MM-DDTHH:mm:ss.SSSSSS"
              maxDate={moment()}
              value={value === "" ? null : moment(value)}
              slots={{
                popper: (props) => (
                  <div className="position-relative">
                    <Popper {...props} style={{ zIndex: 1000 }} disablePortal />
                  </div>
                ),
              }}
              slotProps={{
                textField: {
                  sx: {
                    "& .MuiInputBase-root": {
                      height: "45px",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "0.8rem",
                      lineHeight: "1.0em",
                    },
                  },
                  variant: "filled",
                },
              }}
              onChange={(date) => {
                handleSearchInputs(name.id, moment(date).toISOString());
                setOpenDatePop(false);
              }}
            />
          </div>
        </LocalizationProvider>
        <IconButton onClick={() => removeInput(name)}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      </div>
    );
  if (name.searchType === "checkbox")
    return (
      <>
        <Checkbox
          checked={value}
          onChange={() => handleSearchInputs(name.id, value)}
        />
        <span>{name.label}</span>
        <IconButton onClick={() => removeInput(name)}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      </>
    );
  return (
    <>
      {children ? (
        <TextField
          select
          label={name.label}
          className="w-25"
          variant="filled"
          value={value}
          size="small"
          onChange={(e) => handleSearchInputs(name.id, e.target.value)}
        >
          {children}
        </TextField>
      ) : (
        <TextField
          label={name.label}
          variant="filled"
          size="small"
          id={name.id}
          value={value}
          onChange={(e) => handleSearchInputs(name.id, e.target.value)}
        ></TextField>
      )}
      <IconButton onClick={() => removeInput(name)}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </>
  );
}

export default FilterInputs;
