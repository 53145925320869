/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Link } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as material from "@mui/material";
import CustomTablePagination from "../../../src/components/CustomTablePagination";
import IconButton from "@mui/material/IconButton";
import { Checkbox, Switch } from "@mui/material";
import { Edit } from "@mui/icons-material";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import { basePath } from "../../common/config";
import PopUp from "../../components/PopUp";
import TableHeadRow from "../../components/TableHeadRow";
import moment from "moment";
import SessionExpire from "../../components/SessionExpire";
import { useTranslation } from "react-i18next";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function TenantManagementTable({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("tenantName");
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [tenants, setTenants] = React.useState([]);
  const [activeDeactivePopUp, setActivateDeactivePopUp] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState();
  const [isActive, setIsActive] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [Alertmessage, setAlertmessage] = React.useState("");
  const [option, setOption] = React.useState("");
  const [filterColumns, setFilterColumns] = React.useState([]);

  const [alertPopup, setAlertPopup] = React.useState(false);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [noData, setNoData] = React.useState("");
  const [searchInputs, setSearchInputs] = React.useState({
    tenantName: "",
    tenantEmailId: "",
    tenantDomain: "",
    userName: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "tenantName", value: "" },
    { key: "tenantEmailId", value: "" },
    { key: "tenantDomain", value: "" },
    { key: "userName", value: "" },
  ]);
  const headCells = [
    {
      id: "tenantName",
      numeric: false,
      disablePadding: false,
      label: "Tenant Name",
      mandatory: true,
      searchType: "text",
      filterOption: true,
      sort: true,
    },
    {
      id: "tenantDomain",
      numeric: false,
      disablePadding: false,
      label: "Domain Name",
      mandatory: true,
      searchType: "text",
      filterOption: true,
      sort: true,
    },
    {
      id: "tenantEmailId",
      numeric: false,
      disablePadding: false,
      label: "Email",
      mandatory: false,
      searchType: "text",
      filterOption: true,
      sort: true,
    },
    {
      id: "userName",
      numeric: false,
      disablePadding: false,
      label: "User Name",
      mandatory: false,
      searchType: "text",
      filterOption: true,
      sort: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created Time",
      mandatory: false,
      filterOption: false,
      sort: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      mandatory: false,
      filterOption: false,
      sort: false,
    },
    {
      id: "update",
      numeric: false,
      disablePadding: false,
      label: "Update",
      mandatory: true,
      filterOption: false,
      sort: false,
    },
  ];
  const getTenants = async () => {
    const Url = `api/v1.0/tenants/all?page=${
      page + 1
    }&&sortDir=${order}&&sortBy=${orderBy}&&size=${rowsPerPage}&&tenantName=${
      searchInputs.tenantName
    }&&userName=${searchInputs.userName}&&tenantDomain=${
      searchInputs.tenantDomain
    }&&tenantEmailId=${searchInputs.tenantEmailId}`;
    const type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      handleAlertPopup,
      setLoader
    );
    setTenants(res?.data?.response ?? []);
    setCount(res?.data?.count);
    setNoData(res?.data?.count === 0 ? "No Data Available" : "");
  };
  React.useEffect(() => {
    getTenants();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);
  React.useEffect(() => {
    if (refresh) {
      getTenants();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const deactivateAccount = async () => {
    let Url = `api/v1.0/tenants/${selectedRow}/activate?isActivate=false`;
    let type = "PUT";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      handleAlertPopup,
      setLoader
    );
    handleActiveDeactivePopup();
    setAlertmessage(res?.message);
    handleAlertPopup();
  };
  const activateAccount = async () => {
    let Url = `api/v1.0/tenants/${selectedRow}/activate?isActivate=true`;
    let type = "PUT";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      handleAlertPopup,
      setLoader
    );
    handleActiveDeactivePopup();
    setAlertmessage(res.message);
    handleAlertPopup();
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function handleActiveDeactivePopup(tenantId, isActive) {
    setIsActive(isActive);
    setSelectedRow(tenantId);
    setActivateDeactivePopUp((pop) => !pop);
  }
  function handleOption(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      tenantName: "",
      tenantEmailId: "",
      tenantDomain: "",
      userName: "",
    });
    setFilterState([
      { key: "tenantName", value: "" },
      { key: "tenantEmailId", value: "" },
      { key: "tenantDomain", value: "" },
      { key: "userName", value: "" },
    ]);
  };

  function handleSearchInputs(key, value) {
    setSearchInputs({ ...searchInputs, [key]: value });
  }
  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }
  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        tenantName: "",
        tenantEmailId: "",
        tenantDomain: "",
        userName: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }
  function removeInput(input) {
    setFilterColumns((columns) => columns.filter((cell) => cell !== input));
    setSearchInputs({ ...searchInputs, [input.id]: "" });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      {loader && <Loader />}
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Tenant Management</div>
        <div className="d-flex align-items-center gap-3">
          <Link to="/addTenant">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {/* {selected.length > 0 && (
              <div className="deletediv bg-red " onClick={deleteReservation}>
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )} */}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer className="table_body_height">
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <TableHeadRow
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              disableCheckBox={true}
              onRequestSort={handleRequestSort}
              rowCount={tenants.length}
              disableColumns={disableColumns}
              // deleteCheckBox={false}
            />
            <TableBody>
              {tenants.map((row, index) => {
                // const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    {!disableColumns.includes("tenantName") && (
                      <TableCell className="saas-pl-12px">
                        {row.tenantName}
                      </TableCell>
                    )}
                    {!disableColumns.includes("tenantDomain") && (
                      <TableCell>{row.tenantDomain}</TableCell>
                    )}
                    {!disableColumns.includes("tenantEmailId") && (
                      <TableCell>{row.tenantEmailId}</TableCell>
                    )}
                    {!disableColumns.includes("userName") && (
                      <TableCell>{row.userName}</TableCell>
                    )}

                    {!disableColumns.includes("createdAt") && (
                      <TableCell>
                        {row.createdAt &&
                          moment(row.createdAt).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}

                    {!disableColumns.includes("status") && (
                      <TableCell>
                        <Switch
                          disabled
                          checked={row.isActive}
                          onClick={() =>
                            handleActiveDeactivePopup(
                              row.tenantId,
                              row.isActive
                            )
                          }
                          size="small"
                        />
                      </TableCell>
                    )}
                    {!disableColumns.includes("update") && (
                      <TableCell>
                        <Link to="/editTenant" state={row}>
                          <i
                            className="fa-solid fa-pencil action-icons"
                            style={{ color: "#2DCC70" }}
                          ></i>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {tenants.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {tenants.length > 0 && (
          <CustomTablePagination
            rowsPerPageOptions={[10, 15, 20]}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <PopUp
        open={activeDeactivePopUp}
        handleClosePopUp={handleActiveDeactivePopup}
        message={
          isActive
            ? "Do you want to deactivate the account?"
            : "Do you want to activate the account?"
        }
        btn1={true}
        btn2={true}
        btn1Color={"error"}
        btn2Color={"submit-btn"}
        btn1Text={t("no")}
        btn2Text={t("yes")}
        handleClickBtn1={handleActiveDeactivePopup}
        title={t("confirm")}
        handleClickBtn2={isActive ? deactivateAccount : activateAccount}
        popUpWidth={"width-100"}
      />
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
