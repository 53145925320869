/** @format */

import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { handleKeyDownAlphaUnderScore } from "../../common/helpers";
function RoleUpdateForm({
  updateRow,
  handleUpdateForm,
  tenants,
  updateRowErrors,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Box>
        <div className="d-flex flex-column  align-items-center gap-4 w-auto mt-3">
          <FormControl className="simpleInput">
            <FormLabel>
              <b>Role Name *</b>
            </FormLabel>
            <TextField
              error={updateRowErrors.roleName}
              className=" me-3 w-100 error"
              required
              inputProps={{
                maxLength: 25,
              }}
              onKeyDown={handleKeyDownAlphaUnderScore}
              variant="outlined"
              value={updateRow?.roleName}
              size="small"
              helperText={updateRowErrors.roleName}
              onChange={(e) => {
                handleUpdateForm("roleName", e.target.value);
              }}
            />
          </FormControl>
          <FormControl className="simpleInput">
            <FormLabel>
              <b>Tenant Name *</b>
            </FormLabel>
            <TextField
              select
              className=" me-3 w-100 error"
              // error={addformErrors.roleId}
              value={updateRow?.tenantId}
              required
              size="small"
              disabled
              // helperText={addformErrors.roleId}
              onChange={(e) => {
                handleUpdateForm("tenantId", e.target.value);
              }}
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                  {tenant.tenantName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl className="simpleInput">
            <FormLabel>
              <b>Description *</b>
            </FormLabel>
            <TextField
              required
              className=" me-3 w-100 error"
              error={updateRowErrors.description}
              variant="outlined"
              value={updateRow?.description}
              size="small"
              inputProps={{
                maxLength: 100,
              }}
              helperText={updateRowErrors.description}
              onChange={(e) => {
                handleUpdateForm("description", e.target.value);
              }}
            />
          </FormControl>
        </div>
      </Box>
    </div>
  );
}

export default RoleUpdateForm;
