/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Switch } from "@mui/material";
import FilterTable from "../../components/FilterTable";
import * as material from "@mui/material";
import CustomTablePagination from "../../components/CustomTablePagination";
import ColumnsBox from "../../components/ColumnsBox";
import { basePathone } from "../../common/config";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import PopUp from "../../components/PopUp";
import { searchOperations } from "../../common/helpers";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function Firmware({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState([
    "createdAt",
    "updatedAt",
  ]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [loader, setLoader] = React.useState([]);
  const [searchInputs, setSearchInputs] = React.useState({
    firmwareVersion: "",
    chargingStationModel: "",
    prerequisite: "",
    releaseDate: "",
    modifiedDate: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "firmwareVersion", value: "" },
    { key: "chargingStationModel", value: "" },
    { key: "prerequisite", value: "" },
    { key: "releaseDate", value: "" },
    { key: "modifiedDate", value: "" },
  ]);
  const [option, setOption] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [firmwareList, setFirmwareList] = React.useState([]);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [rowId, setRowId] = React.useState();
  const [noData, setNoData] = React.useState("");
  const [archivePopUp, setArchivePopUp] = React.useState(false);
  const headCells = [
    {
      id: "firmwareVersion",
      numeric: false,
      disablePadding: true,
      label: t("Firmware Version"),
      mandatory: true,
      searchType: "text",
      filterOption: true,
      sort: true,
    },
    {
      id: "chargingStationModel",
      numeric: false,
      disablePadding: false,
      label: t("Model"),
      mandatory: true,
      searchType: "text",
      filterOption: true,
      sort: true,
    },
    {
      id: "prerequisite",
      numeric: false,
      disablePadding: false,
      label: t("Prerequisite"),
      mandatory: true,
      searchType: "text",
      filterOption: true,
      sort: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: t("Created Date"),
      mandatory: false,
      filterOption: false,
      sort: true,
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: t("Updated Date"),
      mandatory: false,
      filterOption: false,
      sort: true,
    },
    {
      id: "isArchive",
      numeric: false,
      disablePadding: false,
      label: t("Archive"),
      mandatory: true,
      filterOption: false,
      sort: true,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: t("Action"),
      mandatory: true,
      filterOption: false,
      sort: false,
    },
  ];
  function handleFilterColumns(id) {
    if (id === t("removeAll")) {
      setFilterColumns([]);
      setSearchInputs({
        firmwareVersion: "",
        chargingStationModel: "",
        prerequisite: "",
        releaseDate: "",
        modifiedDate: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }
  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = firmwareList.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleAlertOk() {
    getFirmwares();
    handleAlertPopup();
  }
  function handleArchivePopup() {
    setArchivePopUp(false);
  }
  const handleIsArchiveChange = (id) => {
    setRowId(id);
    setArchivePopUp(true);
  };
  const updateIsArchive = async () => {
    let Url = `api/firmware/toggleIsArchive/${rowId}?status=1`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      setAlertMessage(res.message);
      setArchivePopUp(false);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };

  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "chargingStationId") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.LIKE,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });
    return params;
  };
  const getFirmwares = async () => {
    const searchParams = buildSearchParams();
    const requestBody = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    const Url = `api/firmware/search`;
    const type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      requestBody,
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    if (res && res?.status) {
      setFirmwareList(res?.data);
      setCount(res?.count);
      setNoData(res?.count === 0 ? "No Data Available" : "");
    }
  };
  const deleteFirmware = async () => {
    let Url = `api/firmware/deleteIds`;
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      selected,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    // setLoader(true);
    // let headers = {
    //   "content-type": "application/json",
    //   Authorization: localStorage.getItem("Authorization"),
    //   "X-TenantID": localStorage.getItem("tenantId"),
    // };

    // fetch(`${basePathone}api/firmware/deleteIds`, {
    //   method: "DELETE",
    //   headers: headers,
    //   body: JSON.stringify(selected),
    // })
    //   .then((resp) => {
    //     setLoader(false);
    //     if (resp.status === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return resp.json();
    //     }
    //   })
    //   .then((res) => {
    if (res?.status) {
      setStatus(true);
      setAlertMessage(res?.message);
      setSelected([]);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res?.message);
      handleAlertPopup();
    }
    // });
  };
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      firmwareVersion: "",
      chargingStationModel: "",
      prerequisite: "",
      releaseDate: "",
      modifiedDate: "",
    });
    setFilterState([
      { key: "firmwareVersion", value: "" },
      { key: "chargingStationModel", value: "" },
      { key: "prerequisite", value: "" },
      { key: "releaseDate", value: "" },
      { key: "modifiedDate", value: "" },
    ]);
  };
  React.useEffect(() => {
    if (refresh) {
      getFirmwares();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getFirmwares();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);
  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Firmware Repository </div>
        <div className="d-flex align-items-center gap-3 ">
          <Link to="/addFirmware">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red " onClick={deleteFirmware}>
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer className="table_body_height">
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={firmwareList.length}
              disableColumns={disableColumns}
            />
            <TableBody>
              {firmwareList.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {!disableColumns.includes("firmwareVersion") && (
                      <TableCell
                        style={{ width: "200px" }}
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.firmwareVersion}
                      </TableCell>
                    )}
                    {!disableColumns.includes("chargingStationModel") && (
                      <TableCell>{row.chargingStationModel}</TableCell>
                    )}
                    {!disableColumns.includes("prerequisite") && (
                      <TableCell>{row.prerequisite}</TableCell>
                    )}
                    {!disableColumns.includes("createdAt") && (
                      <TableCell>
                        {row.createdAt &&
                          moment(row.createdAt).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}
                    {!disableColumns.includes("updatedAt") && (
                      <TableCell>
                        {row.updatedAt &&
                          moment(row.updatedAt).format("DD MMM YYYY | hh:mm A")}
                      </TableCell>
                    )}
                    {!disableColumns.includes("isArchive") && (
                      <TableCell>
                        <Switch
                          checked={row.isArchive === true}
                          disabled={row.isArchive === true}
                          onClick={() => handleIsArchiveChange(row.id)}
                          size="small"
                        />
                      </TableCell>
                    )}
                    {!disableColumns.includes("action") && (
                      <TableCell className="m-0">
                        <a href={row?.buildUrl} download>
                          <i
                            className="fa-solid fa-download action-icons"
                            style={{ color: "#2DCC70" }}
                          ></i>
                        </a>

                        <a href={row?.releasenotesUrl} download>
                          <i
                            className="fa-solid fa-note-sticky action-icons"
                            style={{ color: "#2DCC70" }}
                          />
                        </a>

                        <Link to="/editFirmware" state={{ received: row }}>
                          <i
                            className="fa-solid fa-pencil action-icons"
                            style={{ color: "#2DCC70" }}
                          ></i>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {firmwareList.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {firmwareList.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={archivePopUp}
        message={t("Do you want to Archive this build", {})}
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={t("No")}
        btn2Text={t("Yes")}
        handleClickBtn1={handleArchivePopup}
        title={t("Confirm")}
        handleClickBtn2={updateIsArchive}
        popUpWidth="width-100"
      />
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
