import * as React from "react";
import { TextField, MenuItem, FormControl, FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../components/SessionExpire";
import { useNavigate } from "react-router-dom";
import PopUp from "../components/PopUp";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FullLoader from "../components/FullLoader";
import { handleApi_call } from "../common/CommonAPI";
import { headers } from "../common/Headers";

export default function AddReservation({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [locationList, setLocationList] = React.useState([]);
  const [evseList, setEvseList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [connectorslist, setConnectorsList] = React.useState([]);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [addform, setAddForm] = React.useState({
    chargingStationId: "",
    evseId: "",
    connectorId: "",
    validFrom: "",
    validTo: "",
  });
  const [errors, setErrors] = React.useState({
    chargingStationId: "",
    evseId: "",
    connectorId: "",
    validFrom: "",
    validTo: "",
  });

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const navigate = useNavigate();
  function handleClosePopup() {
    navigate("/reservations");
  }
  function handleSubmit() {
    let error = false;
    if (
      addform.chargingStationId.length === 0 ||
      addform.evseId.length === 0 ||
      addform.connectorId.length === 0 ||
      addform.validFrom.length === 0 ||
      addform.validTo.length === 0
    ) {
      error = true;
    }
    if (error) {
      if (addform.chargingStationId === "") {
        setErrors((errors) => ({
          ...errors,
          chargingStationId: "Charging Station is required",
        }));
      }
      if (addform.evseId === "") {
        setErrors((errors) => ({
          ...errors,
          evseId: "EVSE Id is required",
        }));
      }
      if (addform.connectorId === "") {
        setErrors((errors) => ({
          ...errors,
          connectorId: "Connector Id is required",
        }));
      }
      if (addform.validFrom === "") {
        setErrors((errors) => ({
          ...errors,
          validFrom: "Valid From is required",
        }));
      }
      if (addform.validTo === "") {
        setErrors((errors) => ({
          ...errors,
          validTo: "Valid To is required",
        }));
      }
    } else {
      addReservation();
    }
  }
  React.useEffect(() => {
    if (refresh) {
      getAllLocations();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const getAllLocations = async () => {
    let Url = "api/locations/locationIdsDropdown";
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setLocationList(res?.data);
    }
  };
  const getEvseIdList = async (locId) => {
    let Url = `api/locations/getEvseListForSelectedLocation/${locId}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setEvseList(res?.data);
      if (res?.data?.length === 1) {
        handleEvseChange(res?.data[0]);
      }
      if (res?.count === 0) {
        setErrors((errors) => ({
          ...errors,
          chargingStationId: "This charging station does not have any EVSE.",
        }));
      } else {
        const stationid = errors;
        stationid.chargingStationId = "";
      }
    }
  };
  const getAllConectorsList = async (locId, evseId) => {
    let Url = `api/locations/getConnectorListForSelectedEvse/${locId}/${evseId}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setConnectorsList(res?.data);
      if (res?.data?.length === 1) {
        handleConnectorChange(res?.data[0]);
      }
      if (res?.data?.length === 0) {
        setErrors((errors) => ({
          ...errors,
          evseId: "This EVSE does not have any connectors.",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          evseId: "",
        }));
      }
    }
  };
  const addReservation = async () => {
    let Url = "reservation/create";
    let type = "POST";
    let body = addform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };

  function handleAddForm(key, value) {
    setErrors({ ...errors, [key]: "" });
    setAddForm((addform) => ({
      ...addform,
      [key]: value,
    }));
  }
  React.useEffect(() => {
    getAllLocations();
  }, []);
  const handleLocationChange = (e) => {
    const selectedId = e.target.value;
    handleAddForm("chargingStationId", selectedId);
    handleAddForm("evseId", "");
    handleAddForm("connectorId", "");
    setErrors((errors) => ({
      ...errors,
      chargingStationId: "",
      evseId: "",
    }));
    setConnectorsList([]);
    getEvseIdList(selectedId);
  };

  // const handleEvseChange = (value) => {
  //   handleAddForm("evseId", value);
  //   handleAddForm("connectorId", "");
  //   getAllConectorsList(value);
  // };
  const handleEvseChange = (value) => {
    console.log("Selected EVSE ID:", value); // Check if evseId is correctly set
    setAddForm((prevForm) => ({
      ...prevForm,
      evseId: value,
    }));
  };

  React.useEffect(() => {
    if (addform.evseId && addform.chargingStationId) {
      getAllConectorsList(addform.chargingStationId, addform.evseId);
    }
  }, [addform.evseId, addform.chargingStationId]);

  const handleConnectorChange = (value) => {
    handleAddForm("connectorId", value);
  };
  return (
    <div>
      {sessionExpire && <SessionExpire />}
      <div className="pagetitle">Add Reservation</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Charging Station *</b>
                </FormLabel>
                <TextField
                  required
                  select
                  placeholder="Charging Station"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={addform.chargingStationId}
                  onChange={handleLocationChange}
                  error={errors.chargingStationId}
                  helperText={errors.chargingStationId}
                >
                  {locationList?.map((list, index) => (
                    <MenuItem key={index} value={list.name}>
                      {list.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            {evseList.length === 1 ? (
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>EVSE Id *</b>
                  </FormLabel>
                  <TextField
                    className="w-100"
                    required
                    type="text"
                    variant="outlined"
                    name="evseId"
                    disabled={addform.chargingStationId === "" ? true : false}
                    value={evseList[0]}
                    onChange={(e) => handleEvseChange(e.target.value)}
                    error={errors.evseId}
                    helperText={errors.evseId}
                    size="small"
                  />
                </FormControl>
              </div>
            ) : (
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>EVSE Id *</b>
                  </FormLabel>
                  <TextField
                    required
                    select
                    placeholder="EVSE Id"
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={addform.evseId}
                    onChange={(e) => handleEvseChange(e.target.value)}
                    error={errors.evseId}
                    helperText={errors.evseId}
                    disabled={
                      addform.chargingStationId === "" || evseList.length === 0
                        ? true
                        : false
                    }
                  >
                    {evseList?.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            )}
            {connectorslist.length === 1 ? (
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Connector Id *</b>
                  </FormLabel>
                  <TextField
                    className="w-100"
                    required
                    type="text"
                    variant="outlined"
                    disabled={addform.chargingStationId === "" ? true : false}
                    value={connectorslist[0]}
                    onChange={(e) => handleConnectorChange(e.target.value)}
                    error={errors.connectorId}
                    helperText={errors.connectorId}
                    size="small"
                  />
                </FormControl>
              </div>
            ) : (
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Connector Id *</b>
                  </FormLabel>
                  <TextField
                    required
                    select
                    placeholder="Connector Id"
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={addform.connectorId}
                    onChange={(e) => handleConnectorChange(e.target.value)}
                    error={errors.connectorId}
                    helperText={errors.connectorId}
                    disabled={
                      addform.evseId === "" || connectorslist.length === 0
                        ? true
                        : false
                    }
                  >
                    {connectorslist?.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            )}
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Valid From *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={addform.validFrom}
                  min={new Date().toISOString().slice(0, 16)}
                  max={addform.validTo}
                  placeholder="Select date"
                  onChange={(e) => {
                    const validFromValue = e.target.value;
                    if (
                      addform.validTo &&
                      new Date(validFromValue) > new Date(addform.validTo)
                    ) {
                      handleAddForm(
                        "validFrom",
                        new Date().toISOString().slice(0, 16)
                      );
                    } else {
                      handleAddForm("validFrom", validFromValue);
                    }
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {" "}
                  {errors.validFrom}
                </p>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Valid To *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  onError={errors.validTo}
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={addform.validTo}
                  min={
                    addform.validFrom
                      ? addform.validFrom
                      : new Date().toISOString().slice(0, 16)
                  }
                  placeholder="Select date"
                  onChange={(e) => {
                    const validToValue = e.target.value;
                    if (addform.validFrom) {
                      const validFromDate = new Date(addform.validFrom);
                      const validToDate = new Date(validToValue);

                      if (validToDate <= validFromDate) {
                        validFromDate.setMinutes(
                          validFromDate.getMinutes() + 1
                        );
                        handleAddForm(
                          "validTo",
                          validFromDate.getMinutes() + 1
                        );
                      } else {
                        handleAddForm("validTo", validToValue);
                      }
                    }
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors.validTo}
                </p>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-end gap-3 my-4">
        <Button
          size="small"
          color="error"
          variant="contained"
          className="cancel-btn"
          onClick={handleClosePopup}
        >
          Cancel
        </Button>

        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className="submit-btn"
        >
          Add
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FIALURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={status === true ? handleClosePopup : handleAlertPopup}
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
    </div>
  );
}
