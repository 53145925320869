import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import * as material from "@mui/material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useNavigate } from "react-router-dom";
import ColumnsBox from "../../components/ColumnsBox";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import { basePathone } from "../../common/config";
import CustomTablePagination from "../../components/CustomTablePagination";
import moment from "moment";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import FilterTable from "../../components/FilterTable";
import { Edit } from "@mui/icons-material";
import FullLoader from "../../components/FullLoader";
import Operations from "../Operations/Operations";
import ChargingStationSessions from "../ChargingStations/ChargingStationSessions";
import ChargingStationsOperation from "../ChargingStations/ChargingStationsOperation";
import AuthorizationLocalList from "../ChargingStations/AuthorizationLocalList";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
import ChargingStationConfigurations from "../ChargingStations/ChargingStationConfigurations";
const headCells = [
  {
    id: "uid",
    numeric: false,
    disablePadding: true,
    label: "EVSE ID",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },

  {
    id: "evseId",
    numeric: false,
    disablePadding: true,
    label: "UID",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 250,
  },
  {
    id: "capabilities",
    numeric: false,
    disablePadding: false,
    label: "Capabilities",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 250,
  },
  {
    id: "parking_restrictions",
    numeric: false,
    disablePadding: false,
    label: "Parking Restrictions",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 250,
  },

  {
    id: "latitude",
    numeric: false,
    disablePadding: false,
    label: "Latitude",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 150,
  },
  {
    id: "longitude",
    numeric: false,
    disablePadding: false,
    label: "Longitude",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 150,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 150,
  },
  {
    id: "last_updated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 180,
  },
  {
    id: "Update",
    numeric: false,
    disablePadding: false,
    label: "Update",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "fromDate",
    numeric: false,
    disablePadding: false,
    label: "From Date",
    filterOption: true,
    onlySearch: true,
    searchType: "date",
  },
  {
    id: "toDate",
    numeric: false,
    disablePadding: false,
    label: "To Date",
    filterOption: true,
    onlySearch: true,
    searchType: "date",
  },
];
export default function ViewLocation({ refresh, setRefresh }) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [noData, setNoData] = React.useState("");
  const [isVisible, setIsVisible] = React.useState(false);
  const [configurationVisible, setConfigurationVisible] = React.useState(false);
  const [isALLVisible, setIsALLVisible] = React.useState(false);
  const [overViewVisible, setOverViewVisible] = React.useState(false);
  const [isChargingSessionVisible, setIsChargingSessionVisible] =
    React.useState(false);
  const [isEVSEVisible, setIsEVSEVisible] = React.useState(false);
  const [viewFirmDetails, setViewFirmDetails] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchInputs, setSearchInputs] = React.useState({
    fromDate: "",
    toDate: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "fromDate", value: "" },
    { key: "toDate", value: "" },
  ]);
  const [count, setCount] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [option, setOption] = React.useState("");
  const [evseList, setEvseList] = React.useState([]);
  const [chargeStation, setChargeStation] = React.useState({});
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [locationObj, setLocationObj] = React.useState({});
  React.useEffect(() => {
    getLocationByLocationId();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getLocationByLocationId();
      getEVSES();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getEVSES();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);

  let locationId = localStorage.getItem("LocationID");
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  // Function to toggle visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  // EvsetoggleVisibility
  const EvsetoggleVisibility = () => {
    setIsEVSEVisible(!isEVSEVisible);
  };
  const ConfigurationsVisibility = () => {
    setConfigurationVisible(!configurationVisible);
  };

  const firmwareDetailstoggleVisibility = () => {
    setViewFirmDetails(!viewFirmDetails);
  };
  //
  // overViewtoggleVisibility
  const overViewtoggleVisibility = () => {
    setOverViewVisible(!overViewVisible);
  };

  const AuthorizationLLtToggleVisibility = () => {
    setIsALLVisible(!isALLVisible);
  };
  //
  const ChargingSessionstoggleVisibility = () => {
    setIsChargingSessionVisible(!isChargingSessionVisible);
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const handleColumnChange = (id) => {
    setDisableColumns(
      disableColumns.includes(id)
        ? disableColumns.filter((c) => c !== id)
        : [...disableColumns, id]
    );
  };
  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        fromDate: "",
        toDate: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };
  const resetStates = () => {
    setSearchInputs({
      fromDate: "",
      toDate: "",
    });
    setFilterState([
      { key: "fromDate", value: "" },
      { key: "toDate", value: "" },
    ]);
  };

  const getLocationByLocationId = async () => {
    console.log("boot notifications");
    let Url = `api/locations/${locationId}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.statusCode) {
      setLocationObj(res?.data);
      getChargingStationBootById(res?.data?.name);
    }
  };

  const getChargingStationBootById = async (locName) => {
    let Url = `csinfo/${locName}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.status) {
      setChargeStation(res?.data);
    }
  };
  const checkFirmWareVersion = async (
    firmwareVersion,
    chargingStationModel
  ) => {
    let payload = {
      firmwareVersion: firmwareVersion,
      chargingStationModel: chargingStationModel,
    };
    let Url = `csinfo/checkFirmWareUpdate`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      payload,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    setAlertMessage(res.message);
    handleAlertPopup();
  };

  const getEVSES = async () => {
    let Url = `api/locations/getAllEvse/${locationId}?page=${
      page + 1
    }&size=${rowsPerPage}&fromDate=${searchInputs.fromDate}&toDate=${
      searchInputs.toDate
    }&sortBy=${orderBy}&sortDir=${order}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    setEvseList(res?.data);
    setCount(res?.count);
    setNoData(res?.count === 0 ? "No Data Available" : "");
  };
  const deleteEvseList = async () => {
    let Url = `api/locations/deleteMultipleEvses/${locationId}`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      selected,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setAlertMessage(res?.data);
      setSelected([]);
      handleAlertPopup();
    }
  };

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function handleViewEVSE(evseId) {
    localStorage.setItem("EVSEID", evseId);
    navigate("/viewEvse");
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = evseList.map((n) => n.uid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  function handleAlertPopup() {
    getEVSES();
    setAlertPopup((pop) => !pop);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <div style={{ width: "99%" }} className="mb-3">
      <div className="w-100 mb-4 mx-0 p-0 d-flex align-items-center justify-content-between">
        <div className="pagetitle">View Location</div>
        <div>
          <Button
            className="submit-btn"
            variant="contained"
            size="small"
            onClick={() => navigate("/location")}
          >
            <ArrowBackIosIcon fontSize="10px" />
            Back
          </Button>
        </div>
      </div>
      <div
        className="view my-2 "
        onClick={overViewtoggleVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>Overview</span>
        <span className={`icon ${overViewVisible ? "minus" : "plus"}`}>
          {overViewVisible ? "-" : "+"}
        </span>
      </div>
      {overViewVisible && (
        <div className="row border br-10 mx-0 my-2 ">
          <div className="col-6 p-0 ">
            <div className="ps-5 py-3  cardBackground h-100">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Location Name
                </span>
                <span>:</span>
                <span>{locationObj?.name} </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Address
                </span>

                <span>:</span>
                <span style={{ width: "220px" }}>
                  <span>{locationObj?.address} </span>
                </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  State
                </span>
                <span>:</span>
                <span>{locationObj?.state} </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Latitude
                </span>
                <span>:</span>
                <span>{locationObj?.coordinates?.latitude}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Publish
                </span>
                <span>:</span>
                <span>
                  <Switch
                    disabled
                    checked={locationObj?.publish}
                    size="small"
                  />
                </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Facilities
                </span>
                <span>:</span>
                <span>
                  {locationObj?.facilities?.map((list, index) => (
                    <span key={index}>
                      {list}
                      {index < locationObj?.facilities.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </div>
          <div className="col-6 p-0 ">
            <div className="ps-5 py-3  cardBackground h-100">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Parking Type
                </span>
                <span>:</span>
                <span>{locationObj?.parkingType}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  City
                </span>
                <span>:</span>
                <span>{locationObj?.city}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Country
                </span>
                <span>:</span>
                {locationObj?.country}
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Longitude
                </span>
                <span>:</span>
                <span>{locationObj?.coordinates?.longitude}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Charging When Closed
                </span>
                <span>:</span>
                <span>
                  <Switch
                    disabled
                    checked={locationObj?.charging_when_closed}
                    size="small"
                  />
                </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Time Zone
                </span>
                <span>:</span>
                <span>{locationObj?.time_zone}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="view my-2 "
        onClick={EvsetoggleVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>EVSE</span>
        <span className={`icon ${isEVSEVisible ? "minus" : "plus"}`}>
          {isEVSEVisible ? "-" : "+"}
        </span>
      </div>
      {isEVSEVisible && (
        <Box sx={{ width: "100%" }}>
          <div className="d-flex align-items-center justify-content-between">
            <div></div>
            <div className="d-flex gap-3">
              <Link to={"/addEvse"} state={locationId}>
                <div className="Export-Button bgcolor-blue">
                  <i class="fa-solid fa-plus" />
                  Add
                </div>
              </Link>
              <div className="Export-Button bgcolor-blue">
                <i className="fa-solid fa-arrow-up-from-bracket" />
                Export
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between cardFilter">
            <div>
              <DisplayFilterInputs
                searchInputs={searchInputs}
                clearFilterValue={clearFilterValue}
                clearInputValue={clearInputValue}
                resetStates={resetStates}
              />
            </div>
            <div>
              <material.Box className="d-flex align-items-center gap-3">
                <div>
                  <div
                    className="filter-filter bgcolor-blue"
                    onClick={() => handleOptionClick("filter")}
                  >
                    <i class="fa-solid fa-sliders"></i>
                    <span className="ms-1">Filter</span>
                  </div>
                  {option === "filter" && (
                    <material.ClickAwayListener
                      onClickAway={() => handleOptionClick("")}
                    >
                      <div className="filterBox">
                        <FilterTable
                          headCells={headCells}
                          handleFilterColumns={handleFilterColumns}
                          filterColumns={filterColumns}
                          handleOptionClick={handleOptionClick}
                          updateFilter={updateFilter}
                          filterState={filterState}
                          syncFilterStateToSearchInputs={
                            syncFilterStateToSearchInputs
                          }
                        />
                      </div>
                    </material.ClickAwayListener>
                  )}
                </div>
                <div className="position-relative">
                  <div
                    className="filter-filter bgcolor-blue"
                    onClick={() => handleOptionClick("column")}
                  >
                    <i
                      class="fa-solid fa-bars"
                      style={{
                        transform: "rotate(-90deg)",
                      }}
                    ></i>
                    <span className="ms-1">Column</span>
                  </div>
                  {option === "column" && (
                    <material.ClickAwayListener
                      onClickAway={() => handleOptionClick("")}
                    >
                      <div className="columnBox">
                        <ColumnsBox
                          headCells={headCells}
                          handelChange={handleColumnChange}
                          disableColumns={disableColumns}
                        />
                      </div>
                    </material.ClickAwayListener>
                  )}
                </div>
                {selected.length > 0 && (
                  <div className="deletediv bg-red " onClick={deleteEvseList}>
                    <i class="fa-regular fa-trash-can delete-icon"></i>
                  </div>
                )}
              </material.Box>
            </div>
          </div>
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ minWidth: 1000 }}
                aria-labelledby="tableTitle"
                size={"small"}
                className="overflow-auto"
              >
                <TableHeadRow
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={evseList?.length}
                  disableColumns={disableColumns}
                />
                <TableBody>
                  {evseList?.map((row, index) => {
                    const isItemSelected = isSelected(row.uid);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        // hover
                        // role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        // selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.uid)}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        {!disableColumns.includes("evseId") && (
                          <TableCell
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => handleViewEVSE(row.uid)}
                          >
                            {row.evseId}
                          </TableCell>
                        )}
                        {!disableColumns.includes("uid") && (
                          <TableCell>{row.uid}</TableCell>
                        )}

                        {!disableColumns.includes("capabilities") && (
                          <TableCell>
                            {row.capabilities?.map((list, index) => (
                              <span key={index}>
                                {list}
                                {index < row.capabilities.length - 1 && ", "}
                              </span>
                            ))}
                          </TableCell>
                        )}

                        {!disableColumns.includes("parking_restrictions") && (
                          <TableCell>
                            {row.parkingRestrictions?.map((list, index) => (
                              <span key={index}>
                                {list}
                                {index < row.parkingRestrictions.length - 1 &&
                                  ", "}
                              </span>
                            ))}
                          </TableCell>
                        )}
                        {!disableColumns.includes("latitude") && (
                          <TableCell>{row?.coordinates?.latitude}</TableCell>
                        )}
                        {!disableColumns.includes("longitude") && (
                          <TableCell>{row?.coordinates?.longitude}</TableCell>
                        )}
                        {!disableColumns.includes("status") && (
                          <TableCell>{row.status}</TableCell>
                        )}
                        {!disableColumns.includes("last_updated") && (
                          <TableCell>
                            {row.last_updated &&
                              moment(row.last_updated).format(
                                "DD MMM YYYY | hh:mm A"
                              )}
                          </TableCell>
                        )}
                        {!disableColumns.includes("update") && (
                          <TableCell>
                            <Link to={"/editEvse"} state={row}>
                              <IconButton>
                                <Edit color="primary" />
                              </IconButton>
                            </Link>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                  {evseList?.length === 0 && (
                    <TableRow style={{ height: 40 }}>
                      <TableCell colSpan={10} className="saas-nodata-row">
                        {noData}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="position-relative">
              {evseList?.length > 0 && (
                <CustomTablePagination
                  rowsPerPageOptions={[10, 15, 20]}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </div>
          </Paper>
          {loader && <FullLoader />}
          {sessionExpire && <SessionExpire />}
          <PopUp
            open={alertPopup}
            title={"Alert"}
            message={alertMessage}
            btn1={true}
            btn1Color={"submit-btn"}
            btn1Text={"Ok"}
            handleClickBtn1={handleAlertPopup}
            popUpWidth={"width-100"}
          />
        </Box>
      )}
      <div
        className="view my-2 "
        onClick={toggleVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>Station Operations</span>
        <span className={`icon ${isVisible ? "minus" : "plus"}`}>
          {isVisible ? "-" : "+"}
        </span>
      </div>
      {isVisible && (
        <ChargingStationsOperation locationName={locationObj?.name} />
      )}
      <div
        className="view my-2 "
        onClick={ChargingSessionstoggleVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>Charging Sessions</span>
        <span className={`icon ${isChargingSessionVisible ? "minus" : "plus"}`}>
          {isChargingSessionVisible ? "-" : "+"}
        </span>
      </div>
      {isChargingSessionVisible && (
        <ChargingStationSessions locationName={locationObj?.name} />
      )}

      <div
        className="view my-2 "
        onClick={AuthorizationLLtToggleVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>Authorization Local List</span>
        <span className={`icon ${isALLVisible ? "minus" : "plus"}`}>
          {isALLVisible ? "-" : "+"}
        </span>
      </div>
      {isALLVisible && (
        <AuthorizationLocalList locationName={locationObj?.name} />
      )}
      <div
        className="view my-2 "
        onClick={firmwareDetailstoggleVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>Firmware Details</span>
        <span className={`icon ${viewFirmDetails ? "minus" : "plus"}`}>
          {viewFirmDetails ? "-" : "+"}
        </span>
      </div>
      {viewFirmDetails && (
        <div className="row border br-10 mx-0 my-2">
          <div className="col-6 p-0">
            <div className="ps-5 py-3  cardBackground h-100">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  FW Version
                </span>
                <span>:</span>
                <span>{chargeStation?.firmwareVersion}</span>
                {chargeStation?.firmwareVersion && (
                  <div className="checkUpdate">
                    <SystemUpdateAltIcon
                      color="primary"
                      fontSize="small"
                      className="pointerCursor"
                      onClick={() =>
                        checkFirmWareVersion(
                          chargeStation?.firmwareVersion,
                          chargeStation?.chargingStationModel
                        )
                      }
                    />
                    <div className="updateTooltip">Check for Updates</div>
                  </div>
                )}
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Model
                </span>
                <span>:</span>
                <span>{chargeStation?.chargingStationModel}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  MES Serial Number
                </span>
                <span>:</span>
                <span>{chargeStation?.chargingStationSerialNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  ChargeBox Serial Number
                </span>
                <span>:</span>
                <span>{chargeStation?.chargeBoxSerialNumber}</span>
              </div>
            </div>
          </div>
          <div className="col-6 p-0">
            <div className="ps-5 py-3  cardBackground h-100">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Meter Serial Number
                </span>
                <span>:</span>
                <span>{chargeStation?.meterSerialNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Part Number
                </span>
                <span>:</span>
                <span>{chargeStation?.partNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center mb-2 gap-3">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  Meter Type
                </span>
                <span>:</span>
                <span>{chargeStation?.meterType}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3">
                <span className="saas-boldHeading" style={{ width: "40%" }}>
                  ICCID
                </span>
                <span>:</span>
                <span>{chargeStation?.iccid}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="view my-3 "
        onClick={ConfigurationsVisibility}
        style={{ cursor: "pointer" }}
      >
        <span>Configurations</span>
        <span className={`icon ${configurationVisible ? "minus" : "plus"}`}>
          {configurationVisible ? "-" : "+"}
        </span>
      </div>
      {configurationVisible && (
        <ChargingStationConfigurations
          locationName={locationObj?.name}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </div>
  );
}
