/** @format */

import React from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Legend,
  Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

Chart.register(CategoryScale, LinearScale, ArcElement, Title, Legend, Tooltip);

function ProductEarnings({ toggles }) {
  const { t } = useTranslation();

  const data = {
    labels: [t("business_pro"), t("basic_product")],
    datasets: [
      {
        label: t("rohit"),
        data: [30, 38, 32],
        backgroundColor: ["lightBlue", "lightpink", "lightgray"],
        borderRadius: 20,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: 1.5,
    borderWidth: 0,
    cutout: "80%",
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
          usePointStyle: true,
          pointStyle: "rectRounded",
          padding: 30,
          font: { size: 8 },
        },
      },
    },
  };

  const fillPercentage = {
    id: "fillPercentage",
    beforeDatasetsDraw(charts, args, pluginOptions) {
      const { ctx, data } = charts;
      const sum = data.datasets[0].data.reduce((s, i) => i + s, 0);
      const index = data.datasets[0].data.length - 1;
      const percentage = ((sum - data.datasets[0].data[index]) * 100) / sum;
      ctx.save();
      ctx.textAlign = "center";
      ctx.font = "bolder 25px sans-serif";
      ctx.fillStyle = "black";
      ctx.fillText(
        `${percentage}%`,
        charts.getDatasetMeta(0).data[0].x,
        charts.getDatasetMeta(0).data[0].y
      );
      ctx.font = "bolder 10px sans-serif";
      ctx.fillStyle = "green";
      ctx.fillText(
        "+12.96%",
        charts.getDatasetMeta(0).data[0].x + 0,
        charts.getDatasetMeta(0).data[0].y + 20
      );
    },
  };

  const value = {
    id: "value",
    beforeDatasetsDraw(charts, args, pluginOptions) {
      const { ctx } = charts;
      // const sum = data.datasets[0].data.reduce((s, i) => i + s, 0);
      // const index = data.datasets[0].data.length - 1;
      // const percentage = ((sum - data.datasets[0].data[index]) * 100) / sum;
      ctx.save();
      ctx.textAlign = "center";
      ctx.font = " 10px sans-serif";
      ctx.fillStyle = "gray";
      ctx.fillText(
        t("total_earnings"),
        charts.getDatasetMeta(0).data[0].x + 0,
        charts.getDatasetMeta(0).data[0].y +
          charts.getDatasetMeta(0).data[0].outerRadius +
          20
      );
    },
  };

  return (
    <div className={`card ${toggles ? "earningsWidth" : "customEarnings"}`}>
      <div className="d-flex justify-content-between p-2">
        <b className="ps-3 pt-2 fs-5 mb-2 fw-bolder">{t("product_earnings")}</b>
        <i className="fa-solid fa-ellipsis me-2 mt-2 fs-4 text-secondary"></i>
      </div>
      <div
        className={`text-center d-flex justify-content-center align-items-center`}
        style={{ height: 183 }}
      >
        <Doughnut
          data={data}
          height="150px"
          options={options}
          plugins={[fillPercentage, value]}
        />
      </div>
    </div>
  );
}

export default ProductEarnings;
