/** @format */
import { Edit } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import moment from "moment";
import TableHeadRow from "../../components/TableHeadRow";
import ColumnsBox from "../../components/ColumnsBox";
import React from "react";
import PopUp from "../../components/PopUp";
import { Link, useNavigate } from "react-router-dom";
import SessionExpire from "../../components/SessionExpire";
import { searchOperations } from "../../common/helpers";
import { basePathone } from "../../common/config";
import CustomTablePagination from "../../components/CustomTablePagination";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

const headCells = [
  {
    id: "energyProductName",
    numeric: false,
    disablePadding: false,
    label: "Energy Product Name ",
    mandatory: true,
    filterOption: false,
    minWidth: 200,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    mandatory: true,
    filterOption: true,
    minWidth: 150,
  },
  {
    id: "greenEnergy",
    numeric: false,
    disablePadding: false,
    label: "Green Energy",
    mandatory: false,
    filterOption: true,
    minWidth: 150,
  },
  {
    id: "minPrice",
    numeric: false,
    disablePadding: false,
    label: "Min Price (Vat) ",
    mandatory: true,
    filterOption: true,
    minWidth: 150,
  },
  {
    id: "maxPrice",
    numeric: false,
    disablePadding: false,
    label: "Max Price  (Vat)  ",
    mandatory: true,
    filterOption: true,
    minWidth: 150,
  },
  {
    id: "currency",
    numeric: false,
    disablePadding: false,
    label: "Currency",
    mandatory: false,
    filterOption: true,
  },
  {
    id: "supplierName",
    numeric: false,
    disablePadding: false,
    label: "Supplier Name",
    mandatory: false,
    filterOption: true,
    minWidth: 150,
  },
  {
    id: "startTime",
    numeric: false,
    disablePadding: false,
    label: "Start Time",
    mandatory: false,
    filterOption: true,
    minWidth: 180,
  },
  {
    id: "endTime",
    numeric: false,
    disablePadding: false,
    label: "End Time",
    mandatory: false,
    filterOption: true,
    minWidth: 180,
  },

  {
    id: "tariffAltUrl",
    numeric: false,
    disablePadding: false,
    label: "Tariff Alt Url",
    mandatory: false,
    filterOption: true,
    minWidth: 200,
  },
  {
    id: "update",
    numeric: false,
    disablePadding: false,
    label: "Update",
    mandatory: true,
    filterOption: false,
  },
];
function Tariffs({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchInputs, setSearchInputs] = React.useState({
    energyProductName: "",
    startDateTime: "",
    endDateTime: "",
  });
  const navigate = useNavigate();
  const [noData, setNoData] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [disableColumns, setDisableColumns] = React.useState([
    "greenEnergy",
    "tariffAltUrl",
    "endTime",
    "startTime",
    "supplierName",
    "currency",
  ]);
  const [option, setOption] = React.useState("");
  const [tariffsList, setTariffsList] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);

  const buildSearchParams = () => {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "energyProductName") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.LIKE,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });
    return params;
  };

  const getTariffsData = async () => {
    const searchParams = buildSearchParams();
    const Url = `cpo/${2.1}/tariffs?offset=${page}&limit=${rowsPerPage}&date_from=${
      searchInputs.startDateTime
    }&date_to=${searchInputs.endDateTime}`;
    const type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.statusCode) {
      setTariffsList(res?.data);
      setCount(res?.count);
      setNoData(res?.count === 0 ? "No Data Available" : "");
    }
  };

  React.useEffect(() => {
    getTariffsData();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);
  React.useEffect(() => {
    if (refresh) {
      getTariffsData();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const deleteTariff = async (id) => {
    const tariff = tariffsList.find((tariff) => tariff.id === id);
    const countryCode = tariff?.countryCode;
    const partyId = tariff?.partyId;
    let Url = `cpo/${2.1}/tariffs/${countryCode}/${partyId}/${id}`;
    let type = "DELETE";
    // let body = selected;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      setSelected([]);
      handleAlertPopup();
    }
    if (res?.statusMessage === "FAILURE") {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleAlertPopup();
    }
  };
  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  function handleSaveTariffId(tariff, countryCode, partyId) {
    localStorage.setItem("TariffObject", JSON.stringify(tariff));
    navigate("/TariffEdit");
  }

  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tariffsList.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  function handleAlertOk() {
    getTariffsData();
    handleAlertPopup();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Tariffs</div>
        <div className="d-flex align-items-center gap-3">
          <Link to="/tariffsAdd">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end cardFilter">
        <div>
          <Box className="d-flex align-items-center gap-3">
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <ClickAwayListener onClickAway={() => handleOptionClick("")}>
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div
                className="deletediv bg-red "
                onClick={() => deleteTariff(selected[0])}
              >
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer className="table_body_height">
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 200 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              rowCount={tariffsList.length}
              disableColumns={disableColumns}
              // disableCheckBox={true}
            />
            <TableBody>
              {tariffsList.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // hover
                    // role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {!disableColumns.includes("energyProductName") && (
                      <TableCell
                        // component="th"
                        style={{ width: "300px" }}
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Link to={"/viewTariff"} state={row}>
                          {row.energy_mix?.energy_product_name}
                        </Link>
                      </TableCell>
                    )}
                    {!disableColumns.includes("type") && (
                      <TableCell>{row.type}</TableCell>
                    )}
                    {!disableColumns.includes("greenEnergy") && (
                      <TableCell>
                        <Switch
                          disabled
                          checked={row.energy_mix?.is_green_energy}
                          size="small"
                        />
                      </TableCell>
                    )}
                    {!disableColumns.includes("minPrice") && (
                      <TableCell>
                        {row.minPrice?.exclVat}
                        {"  (excl)"} <br />
                        {row.minPrice?.inclVat}
                        {"  (incl)"}
                      </TableCell>
                    )}
                    {!disableColumns.includes("maxPrice") && (
                      <TableCell>
                        {row.maxPrice?.exclVat}
                        {" (excl)"} <br />
                        {row.maxPrice?.inclVat}
                        {" (incl)"}
                      </TableCell>
                    )}
                    {!disableColumns.includes("currency") && (
                      <TableCell>{row.currency}</TableCell>
                    )}
                    {!disableColumns.includes("supplierName") && (
                      <TableCell>{row.energy_mix?.supplier_name}</TableCell>
                    )}

                    {!disableColumns.includes("startTime") && (
                      <TableCell>
                        {row.startDateTime &&
                          moment(row.startDateTime).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}
                    {!disableColumns.includes("endTime") && (
                      <TableCell>
                        {row.endDateTime &&
                          moment(row.endDateTime).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </TableCell>
                    )}

                    {!disableColumns.includes("tariffAltUrl") && (
                      <TableCell>{row.tariff_alt_url}</TableCell>
                    )}
                    {!disableColumns.includes("update") && (
                      <TableCell onClick={() => handleSaveTariffId(row)}>
                        {/* <Link to="/TariffEdit" state={row}> */}
                        <i
                          className="fa-solid fa-pencil action-icons"
                          style={{ color: "#2DCC70" }}
                        ></i>
                        {/* </Link> */}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {tariffsList.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {tariffsList.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      {sessionExpire && <SessionExpire />}
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      {loader && <Loader />}
    </Box>
  );
}

export default Tariffs;
