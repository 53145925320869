import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

function Profile() {
  const [image, setImage] = useState("");
  function handleImage(e) {
    console.log(e.target.files);
  }

  function handleUploadClick() {
    console.log("Upload button clicked");
  }
  return (
    <div className="col-md-3">
      <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        <IconButton color="dark" component="label">
          <CameraAltIcon fontSize="large" />
          <input hidden accept="image/*" type="file" />
        </IconButton>

        <Typography variant="body1" mt={2}>
          Upload your photo here
        </Typography>

        <Button
          variant="contained"
          component="label"
          className="submit-btn"
          sx={{ mt: 2 }}
          onClick={handleUploadClick}
        >
          Upload
          <input hidden accept="image/*" type="file" onChange={handleImage} />
        </Button>
      </Box>
      {/* <Box display="flex" flexDirection="row" justifyContent="end">
        <Button displ variant="contained" color="secondary" sx={{ mt: 5 }}>
          Apply
        </Button>
      </Box> */}
    </div>
  );
}

export default Profile;
