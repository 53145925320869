import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import moment from "moment";
import * as material from "@mui/material";
import React, { useEffect } from "react";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
import { Link } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { basePathEV } from "../../common/config";
import ChangeConfigurationPop from "./ChangeConfigurationPop";

function ChargingStationConfigurations({ refresh, setRefresh, locationName }) {
  const [ConData, setConData] = React.useState("");
  const [FavData, setFavData] = React.useState("");
  const [loader, setLoader] = React.useState([]);
  const [FavouritesList, setFavouritesList] = React.useState([]);
  const [ConfigurationList, setConfigurationList] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [favPop, setFavPop] = React.useState(false);
  const [editPop, setEditPop] = React.useState(false);
  const [error, setError] = React.useState("");
  const [formObj, setFormObj] = React.useState({
    key: "",
    value: "",
  });
  const [statusMessage, setStatusMessage] = React.useState("");
  const [id, setId] = React.useState();
  const [fav, setFav] = React.useState();

  const headCells = [
    {
      id: "configurationKey",
      numeric: false,
      disablePadding: true,
      label: "Configuration Key",
      mandatory: true,
      filterOption: false,
      sort: false,
      // minWidth: 100,
    },
    {
      id: "configurationValue",
      numeric: false,
      disablePadding: true,
      label: "Configuration Value",
      mandatory: true,
      filterOption: false,
      sort: false,
      searchType: "text",
      // minWidth: 150,
    },
    {
      id: "lastUpdate",
      numeric: false,
      disablePadding: true,
      label: "Last Update",
      mandatory: false,
      filterOption: false,
      sort: false,
      // minWidth: 170,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      mandatory: true,
      filterOption: false,
      sort: false,
      // minWidth: 100,
    },
  ];
  const ConHeadCells = [
    {
      id: "configurationKey",
      numeric: false,
      disablePadding: true,
      label: "Configuration Key",
      mandatory: true,
      filterOption: false,
      sort: false,
      //minWidth: 100,
    },
    {
      id: "configurationValue",
      numeric: false,
      disablePadding: true,
      label: "Configuration Value",
      mandatory: true,
      filterOption: false,
      sort: false,
      searchType: "text",
      // Width: 150,
    },
    {
      id: "lastUpdate",
      numeric: false,
      disablePadding: true,
      label: "Last Update",
      mandatory: false,
      filterOption: false,
      sort: false,
      // minWidth: 170,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      mandatory: true,
      filterOption: false,
      sort: false,
      //minWidth: 100,
    },
  ];
  React.useEffect(() => {
    getConfiguration();
  }, []);
  const handleAlertPopup = () => {
    setAlertPopup((pop) => !pop);
  };
  const handleFavPop = () => {
    setFavPop((pop) => !pop);
  };
  const handleEditPop = () => {
    setEditPop((pop) => !pop);
  };
  const handleAddFavourite = (id, state) => {
    setId(id);
    setFav(state);
    handleFavPop();
  };
  function handleChange(key, value) {
    setError("");
    setFormObj({
      ...formObj,
      [key]: value,
    });
  }
  const handleUpdateValue = (data) => {
    setFormObj({
      ...formObj,
      key: data.keyName,
      value: data.value,
    });
    handleEditPop();
  };
  function handleSubmitOperation() {
    if (!formObj.value) {
      error = true;
    }
    if (error) {
      if (!formObj.value) {
        setError("Value is required");
      }
    } else {
      handleEditPop();
      setLoader(true);
      let headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("Authorization"),
        "X-TenantID": localStorage.getItem("tenantId"),
      };

      fetch(
        `${basePathEV}api/cs/cmps-call/${localStorage.getItem(
          "tenantId"
        )}/${locationName}/${"ChangeConfiguration"}?protocol=${"OCPP16"}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(formObj),
        }
      ).then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else if (resp.status === 200) {
          setStatusMessage("SUCCESS");
          setAlertMessage(
            "ChangeConfiguration Operation Initiated Successfully."
          );
          handleAlertPopup();
        } else {
          setStatusMessage("FAILURE");
          setAlertMessage("ChangeConfiguration Operation failed to Initiate.");
          handleAlertPopup();
        }
      });
    }
  }
  const getConfiguration = async () => {
    let Url = `csconfiguration/getConfigurationData/${locationName}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    setConfigurationList(res?.data?.configurationKey);
    setFavouritesList(res?.data?.favconfigurationKey);
    setConData(res?.data?.configurationKey === null ? true : false);
    setFavData(res?.data?.favconfigurationKey === null ? true : false);
  };
  const updateFavourite = async (id, state) => {
    let Url = `csconfiguration/Setfavourite/${id}`;
    let type = "PUT";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      state,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res.status) {
      setStatus(true);
      setAlertMessage(res?.message);
      handleAlertPopup();
      getConfiguration();
      handleFavPop();
    } else {
      setStatus(false);
      setAlertMessage(res?.message);
      handleAlertPopup();
      handleFavPop();
    }
  };

  React.useEffect(() => {
    if (refresh) {
      getConfiguration();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  return (
    <Box sx={{ width: "100%" }}>
      {loader && <FullLoader />}
      <div className="pagetitle mb-2">Favourites:</div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              //   numSelected={selected.length}
              //   order={order}
              //   orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              //   onRequestSort={handleRequestSort}
              //   rowCount={chargingSessionList?.length}
              disableColumns={[]}
              disableCheckBox={true}
            />
            <TableBody>
              {FavouritesList?.map((row, index) => {
                // const isItemSelected = isSelected(row.id);
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell>
                      <material.Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: "240px" }}>
                      {row.keyName}
                    </TableCell>
                    <TableCell style={{ minWidth: "200px" }}>
                      {row.value}
                    </TableCell>
                    <TableCell>
                      {row.updatedDate &&
                        moment(row.updatedDate).format("DD MMM YYYY | hh:mm A")}
                    </TableCell>
                    <TableCell>
                      <i
                        class="fa-solid fa-star cursor-Pointer"
                        style={{ color: "#2DCC70" }}
                        onClick={() => handleAddFavourite(row.id, false)}
                      ></i>

                      {!row.readonly && (
                        <i
                          className="fa-solid fa-pencil action-icons ms-1 cursor-Pointer"
                          style={{ color: "#2DCC70" }}
                          onClick={() => handleUpdateValue(row)}
                        ></i>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {FavData && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {"No Data Available"}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className="pagetitle mt-3 mb-2">Configurations</div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={ConHeadCells}
              //   numSelected={selected.length}
              //   order={order}
              //   orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              //   onRequestSort={handleRequestSort}
              //   rowCount={chargingSessionList?.length}
              disableColumns={[]}
              disableCheckBox={true}
            />
            <TableBody>
              {ConfigurationList?.map((row, index) => {
                // const isItemSelected = isSelected(row.id);
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell>
                      <material.Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: "200px" }}>
                      {row.keyName}
                    </TableCell>
                    <TableCell
                      style={{ maxWidth: "200px", overflowWrap: "break-word" }}
                    >
                      {row.value}
                    </TableCell>
                    <TableCell>
                      {row.updatedDate &&
                        moment(row.updatedDate).format("DD MMM YYYY | hh:mm A")}
                    </TableCell>
                    <TableCell>
                      <i
                        class="fa-regular fa-star cursor-Pointer"
                        style={{ color: "#2DCC70" }}
                        onClick={() => handleAddFavourite(row.id, true)}
                      ></i>

                      {!row.readonly && (
                        <i
                          className="fa-solid fa-pencil action-icons ms-2 cursor-Pointer"
                          style={{ color: "#2DCC70" }}
                          onClick={() => handleUpdateValue(row)}
                        ></i>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {ConData && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {"No Data Available"}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {sessionExpire && <SessionExpire />}
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FIALURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertPopup}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={favPop}
        title={"Alert"}
        message={
          fav === true
            ? `Do you want to add this to your favorites?`
            : `Do you want to remove this from your favorites?`
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={fav === true ? "Add" : "Remove"}
        handleClickBtn1={handleFavPop}
        handleClickBtn2={() => updateFavourite(id, fav)}
        popUpWidth="width-100"
      />
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertPopup}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={editPop}
        title={"Change Configuration"}
        message={
          <ChangeConfigurationPop
            formObj={formObj}
            handleChange={handleChange}
            error={error}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleEditPop}
        handleClickBtn2={handleSubmitOperation}
        popUpWidth="popUpMaxWidth"
      />
    </Box>
  );
}

export default ChargingStationConfigurations;
