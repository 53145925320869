import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";

function RemoteStartTransaction({
  handleSubmitOperation,
  refresh,
  setRefresh,
}) {
  const [idTagList, setIdTagList] = useState([]);
  const [sessionExpire, setSessionExpire] = useState(false);
  const [cpName, setCpName] = useState([]);
  const [inputFields, setInputFields] = useState({
    connectorId: "",
    idTag: "",
    chargingProfile: "",
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    idTag: "",
  });
  function handleOnChange(key, value) {
    if (key === "connectorId") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
        setInputFields({ ...inputFields, [key]: value });
      }
    } else {
      setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
      setInputFields({ ...inputFields, [key]: value });
    }
  }
  useEffect(() => {
    getIdTags();
    getChargingProfileNames();
  }, []);
  useEffect(() => {
    if (refresh) {
      getIdTags();
      getChargingProfileNames();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const getIdTags = () => {
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}api/idtags/all`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        if (
          resp.status === false &&
          resp.message === "Session expired! Please login again!"
        ) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setIdTagList(res?.data);
        }
      });
  };
  const getChargingProfileNames = () => {
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}chargingProfile/all`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        // if (res?.status) {
        setCpName(res);
        // }
      });
  };
  function handleSubmit() {
    let error = false;
    if (!inputFields.idTag) {
      error = true;
    }
    if (error) {
      if (!inputFields.idTag) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          idTag: "Please Select the RFID Tag",
        }));
      }
    }

    let payload = {
      idTag: inputFields.idTag,
    };

    if (inputFields.connectorId) {
      payload.connectorId = parseInt(inputFields.connectorId);
    }

    if (inputFields.chargingProfile) {
      payload.chargingProfile = inputFields.chargingProfile;
    }

    handleSubmitOperation(error, payload);
  }
  return (
    <>
      {sessionExpire && <SessionExpire />}
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Id </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.connectorId}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("connectorId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select ID Tag *</b>
                </FormLabel>
                <TextField
                  select
                  error={inputFieldsValidation.idTag}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.idTag}
                  size="small"
                  helperText={inputFieldsValidation.idTag}
                  onChange={(e) => {
                    handleOnChange("idTag", e.target.value);
                  }}
                >
                  {idTagList?.map((list, index) => (
                    <MenuItem key={index} value={list?.idTag}>
                      {list?.idTag}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Charging Profile</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  variant="outlined"
                  value={inputFields.chargingProfile}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("chargingProfile", e.target.value);
                  }}
                >
                  {cpName?.map((list, index) => (
                    <MenuItem key={index} value={list?.chargingProfileName}>
                      {list?.chargingProfileName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default RemoteStartTransaction;
