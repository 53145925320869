import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SessionExpire from "../../components/SessionExpire";
import { Link, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { handleVersion } from "../../common/helpers";
import { basePathone } from "../../common/config";
import FullLoader from "../../components/FullLoader";

export default function AddFirmware({ refresh, setRefresh }) {
  const [firmwareVersion, setFirmwareVersion] = React.useState("");
  const [chargingStationModel, setChargingStationModel] = React.useState("");
  const [prerequisite, setPrerequisite] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [releaseNoteFile, setReleaseNoteFile] = React.useState(null);
  const [fileName, setFileName] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [releaseNote, setReleaseNote] = React.useState();
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [modelList, setModelList] = React.useState([]);
  const [prerequisiteList, setPrerequisiteList] = React.useState([]);

  const [errors, setErrors] = React.useState({});

  const handleFirmwareVersion = (event) => {
    let valid = handleVersion(event.target.value);
    if (valid) {
      setErrors((errors) => ({
        ...errors,
        firmwareVersion: "Enter Valid firmwareVersion",
      }));
    } else {
      setErrors({ ...errors, firmwareVersion: "" });
    }
    setFirmwareVersion(event.target.value);
  };

  const handlePrerequisite = (event) => {
    setErrors({ ...errors, prerequisite: "" });
    setPrerequisite(event.target.value);
  };

  const handleChargingStationModel = (event) => {
    setErrors({ ...errors, chargingStationModel: "" });
    setChargingStationModel(event.target.value);
    getPrerequisites(event.target.value);
  };
  const validateForm = () => {
    const newErrors = {};
    if (!firmwareVersion)
      newErrors.firmwareVersion = "Firmware Version is required";
    if (!chargingStationModel)
      newErrors.chargingStationModel = " Please select Model";
    if (!prerequisite) newErrors.prerequisite = "Please select prerequisite";
    if (!fileName) newErrors.fileName = "FileName is required";
    if (!releaseNote) newErrors.releaseNote = "ReleaseNote is required";
    if (firmwareVersion < prerequisite)
      newErrors.prerequisite = "Prerequisite should be less than Version";
    if (firmwareVersion) {
      let valid = handleVersion(firmwareVersion);
      if (valid) {
        newErrors.firmwareVersion = "Enter Valid firmwareVersion";
      }
    }
    return newErrors;
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const navigate = useNavigate();
  function handleClosePopup() {
    navigate("/firmware");
  }

  function handleAddFirmware(event) {
    event.preventDefault();
    let error2 = false;
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      error2 = true;
    }
    if (!error2) {
      addFirmware();
    }
  }
  const addFirmware = () => {
    setLoader(true);
    let headers = {
      // "content-type": "multipart/form-data ",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("releaseNoteFile", releaseNoteFile);

    fetch(
      `${basePathone}api/firmware/upload?chargingStationModel=${chargingStationModel}&firmwareVersion=${firmwareVersion}&prerequisite=${prerequisite}`,
      {
        method: "POST",
        headers: headers,
        body: formData,
      }
    )
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setStatus(true);
          setAlertMessage(res.message);
          handleAlertPopup();
        } else {
          setStatus(false);
          setAlertMessage(res?.message);
          handleAlertPopup();
        }
      });
  };
  const getModels = () => {
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}api/firmware/getAllModels`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setModelList(res?.data);
        }
      });
  };

  const getPrerequisites = (value) => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}api/firmware/getPrerequisites/${value}`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setPrerequisiteList(res?.data);
        }
      });
  };
  React.useEffect(() => {
    if (refresh) {
      getModels();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getModels();
  }, []);
  const handleFileChange = (event) => {
    setErrors({ ...errors, releaseNote: "" });
    const selectedFile = event.target.files[0];
    setReleaseNoteFile(selectedFile);
    setReleaseNote(selectedFile.name);
  };
  const handleFileChange1 = (event) => {
    setErrors({ ...errors, fileName: "" });
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };
  return (
    <div>
      {sessionExpire && <SessionExpire />}
      {loader && <FullLoader />}
      <div className="pagetitle">Add Firmware</div>
      <Box>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <div className="row row-gap-3 mt-3">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Model *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    select
                    required
                    value={chargingStationModel}
                    error={errors.chargingStationModel}
                    name="chargingStationModel"
                    variant="outlined"
                    onChange={handleChargingStationModel}
                  >
                    {modelList.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormHelperText error>
                    {errors.chargingStationModel}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Version *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    name="firmwareVersion"
                    variant="outlined"
                    required
                    value={firmwareVersion}
                    error={errors.firmwareVersion}
                    helperText={errors.firmwareVersion}
                    onChange={handleFirmwareVersion}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Prerequisite *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    select
                    required
                    value={prerequisite}
                    error={errors.prerequisite}
                    name="prerequisite"
                    onChange={handlePrerequisite}
                  >
                    {prerequisiteList.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormHelperText error>
                    <b className="fs-12">{errors.prerequisite}</b>
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-4 ">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>FileName *</b>
                  </FormLabel>
                  <Box
                    noValidate
                    required
                    autoComplete="off"
                    component="form"
                    className={`${
                      errors.fileName ? "file-border-error" : "file-border"
                    }`}
                    style={{
                      display: "flex",
                      gap: 5,
                      padding: "2px",
                      alignItems: "center",
                      height: "38px",
                    }}
                  >
                    <Button
                      component="label"
                      variant="contained"
                      style={{
                        width: "150px",
                        textTransform: "none",
                        fontSize: "13px",
                        lineHeight: "13px",
                        opacity: "0.9",
                        padding: "10px",
                        height: "30px",
                        color: "white",
                      }}
                    >
                      Choose Firmware file
                      <CloudUploadIcon />
                      <input type="file" hidden onChange={handleFileChange1} />
                    </Button>
                    {fileName ? (
                      <Typography
                        className="file-text "
                        style={{ padding: "10px" }}
                      >
                        {fileName}
                      </Typography>
                    ) : (
                      "No Choosen file"
                    )}
                  </Box>
                  <FormHelperText
                    error
                    style={{ fontWeight: "700", fontSize: "13px" }}
                  >
                    {file ? null : errors.fileName}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>ReleaseNote *</b>
                  </FormLabel>
                  <Box
                    noValidate
                    required
                    className={`${
                      errors.releaseNote ? "file-border-error" : "file-border"
                    }`}
                    component="form"
                    autoComplete="off"
                    style={{
                      display: "flex",
                      gap: 5,
                      padding: "2px",
                      alignItems: "center",
                      height: "38px",
                    }}
                  >
                    <Button
                      component="label"
                      variant="contained"
                      style={{
                        width: "150px",
                        height: "30px",
                        textTransform: "none",
                        fontSize: "13px",
                        lineHeight: "13px",
                        opacity: "0.9",
                        color: "white",
                        padding: "13px",
                      }}
                    >
                      Choose Release Notes
                      <CloudUploadIcon />
                      <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                    {releaseNote ? (
                      <Typography
                        className="file-text"
                        style={{ padding: "10px" }}
                      >
                        {releaseNote}
                      </Typography>
                    ) : (
                      "No Choosen file"
                    )}
                  </Box>
                  <FormHelperText
                    error
                    style={{ fontWeight: "700", fontSize: "13px" }}
                  >
                    {releaseNoteFile ? null : errors.releaseNote}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </CardContent>
        </Card>
        <div className="d-flex aligm-items-center justify-content-end gap-4 my-5 me-5 ">
          <Button
            variant="contained"
            onClick={handleAddFirmware}
            className="submit-btn"
          >
            Add
          </Button>
          <Button variant="contained" color="error" className="cancel-btn">
            <Link to="/firmware" className="cancle-button">
              Cancel
            </Link>
          </Button>
        </div>
      </Box>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClosePopup}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
