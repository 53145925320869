import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SessionExpire from "../../components/SessionExpire";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

function SetChargingProfile({ handleSubmitOperation, setLoader }) {
  const [sessionExpire, setSessionExpire] = useState(false);
  const [cpName, setCpName] = useState([]);
  const [inputFields, setInputFields] = useState({
    csChargingProfiles: "",
    connectorId: "",
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    csChargingProfiles: "",
    connectorId: "",
  });
  function handleOnChange(key, value) {
    if (key === "connectorId") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
        setInputFields({ ...inputFields, [key]: parseInt(value) });
      }
    } else {
      setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
      setInputFields({ ...inputFields, [key]: value });
    }
  }
  useEffect(() => {
    getChargingProfileNames();
  }, []);
  const getChargingProfileNames = async () => {
    let Url = `chargingProfile/all`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      () => {},
      () => {},
      setLoader
    );
    setCpName(res);
  };
  function handleSubmit() {
    let error = false;
    if (!inputFields.connectorId || !inputFields.csChargingProfiles) {
      error = true;
    }
    if (error) {
      if (!inputFields.connectorId) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          connectorId: "Connector Id is Mandatory",
        }));
      }
      if (!inputFields.csChargingProfiles) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          csChargingProfiles: "Please Select the Charging Profile Name",
        }));
      }
    }
    handleSubmitOperation(error, inputFields);
  }
  return (
    <>
      {sessionExpire && <SessionExpire />}
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select Charging Profile *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  error={inputFieldsValidation.csChargingProfiles}
                  helperText={inputFieldsValidation.csChargingProfiles}
                  variant="outlined"
                  value={inputFields.csChargingProfiles}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("csChargingProfiles", e.target.value);
                  }}
                >
                  {cpName?.map((list, index) => (
                    <MenuItem key={index} value={list?.id}>
                      {list?.chargingProfileName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Id (Integer) *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.connectorId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.connectorId}
                  size="small"
                  helperText={inputFieldsValidation.connectorId}
                  onChange={(e) => {
                    handleOnChange("connectorId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          className="submit-btn"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default SetChargingProfile;
