import * as React from "react";
import Box from "@mui/material/Box";
import { TextField, FormControl, FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { resize } from "../../common/helpers";
import { basePathone } from "../../common/config";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FullLoader from "../../components/FullLoader";

export default function EditCpo({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const location = useLocation();
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [addform, setAddForm] = React.useState({
    name: "",
    contactPerson: "",
    contactphone: "",
    address: "",
    city: "",
    country: "",
    sourcePartyId: "",
    email: "",
  });
  const [addformErrors, setAddFormErrors] = React.useState({
    name: "",
    contactPerson: "",
    contactphone: "",
    address: "",
    city: "",
    country: "",
    sourcePartyId: "",
    email: "",
  });

  const navigate = useNavigate();
  function handleClosePopup() {
    navigate("/cpoPage");
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleAddForm(key, value) {
    setAddFormErrors({ ...addformErrors, [key]: "" });
    setAddForm({ ...addform, [key]: value });
    if (key === "email") {
      const emailRegex =
        /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) {
        setAddFormErrors((errors) => ({
          ...errors,
          email: "Invalid email format",
        }));
      }
      setAddForm({ ...addform, [key]: value });
    }
  }
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10,16}$/;
    return phoneRegex.test(phoneNumber);
  };
  function handleErrorEditCpo() {
    let error = false;
    if (
      !addform.name ||
      !addform.contactPerson ||
      !addform.contactphone ||
      !addform.address ||
      !addform.city ||
      !addform.country ||
      !addform.sourcePartyId ||
      !validatePhoneNumber(addform.contactphone) ||
      !addform.email
    ) {
      error = true;
    }
    if (error) {
      if (!addform.name) {
        setAddFormErrors((errors) => ({
          ...errors,
          name: "Name is Mandatory",
        }));
      }
      if (!addform.contactPerson) {
        setAddFormErrors((errors) => ({
          ...errors,
          contactPerson: "ContactPerson is Mandatory",
        }));
      }
      if (!addform.contactphone || !validatePhoneNumber(addform.contactphone)) {
        setAddFormErrors((errors) => ({
          ...errors,
          contactphone: !addform.contactphone
            ? "ContactPhone is Mandatory"
            : "Invalid phone number. It must be exactly 10 digits.",
        }));
      }
      if (!addform.address) {
        setAddFormErrors((errors) => ({
          ...errors,
          address: "Address is Mandatory",
        }));
      }
      if (!addform.city) {
        setAddFormErrors((errors) => ({
          ...errors,
          city: "City is Mandatory",
        }));
      }
      if (!addform.country) {
        setAddFormErrors((errors) => ({
          ...errors,
          country: " Country is Mandatory",
        }));
      }
      if (!addform.sourcePartyId) {
        setAddFormErrors((errors) => ({
          ...errors,
          sourcePartyId: "sourcePartyId is Mandatory",
        }));
      }
      if (!addform.email) {
        setAddFormErrors((errors) => ({
          ...errors,
          email: "Email is Mandatory",
        }));
      }
    }
    if (!error) {
      updateCpo();
    }
  }
  const updateCpo = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}cpo/2.2/tokens/${addform.uid}`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(addform),
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.statusCode) {
          setAlertMessage(res?.data);
          handleAlertPopup();
        }
      });
  };

  React.useEffect(() => {
    setAddForm(location.state);
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setAddForm(location.state);
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <div>
      <div className="pagetitle">Update CPO</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          {sessionExpire && <SessionExpire />}
          <Box>
            <div className="row row-gap-3 mt-3">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>UID *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="UID"
                    Name="uid"
                    variant="outlined"
                    disabled
                    value={addform.uid}
                    inputProps={{
                      minLength: 10,
                      maxLength: 10,
                      onKeyDown: (e) => {
                        if (
                          !/[A-Z0-9]/.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.uid}
                    helperText={addformErrors.uid}
                    onChange={(e) => {
                      handleAddForm("uid", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Name *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Name"
                    name="name"
                    variant="outlined"
                    required
                    value={addform.name}
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z\s]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.name}
                    helperText={addformErrors.name}
                    onChange={(e) => {
                      handleAddForm("name", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Contact Person *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Contact Person"
                    Name="contactPerson"
                    variant="outlined"
                    required
                    value={addform.contactPerson}
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.contactPerson}
                    helperText={addformErrors.contactPerson}
                    onChange={(e) => {
                      handleAddForm("contactPerson", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Contact Phone *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Contact Phone"
                    Name="contactphone"
                    variant="outlined"
                    required
                    value={addform.contactphone}
                    inputProps={{
                      minLength: 10,
                      maxLength: 16,
                      onKeyDown: (e) => {
                        if (
                          !/[0-9]/.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.contactphone}
                    helperText={addformErrors.contactphone}
                    onChange={(e) => {
                      handleAddForm("contactphone", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Address *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Address"
                    Name="address"
                    variant="outlined"
                    required
                    value={addform.address}
                    inputProps={{
                      minLength: 10,
                      maxLength: 16,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z0-9\s,.\\-]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.address}
                    helperText={addformErrors.address}
                    onChange={(e) => {
                      handleAddForm("address", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>City *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="City"
                    Name="city"
                    variant="outlined"
                    required
                    value={addform.city}
                    inputProps={{
                      minLength: 3,
                      maxLength: 16,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.city}
                    helperText={addformErrors.city}
                    onChange={(e) => {
                      handleAddForm("city", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Country *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Country"
                    Name="country"
                    variant="outlined"
                    disabled
                    value={addform.country}
                    inputProps={{
                      minLength: 10,
                      maxLength: 16,
                      onKeyDown: (e) => {
                        if (/[A-Za-z]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.country}
                    helperText={addformErrors.country}
                    onChange={(e) => {
                      handleAddForm("country", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Source Party ID *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Source Party ID"
                    Name="sourcePartyId"
                    variant="outlined"
                    disabled
                    value={addform.sourcePartyId}
                    inputProps={{
                      minLength: 3,
                      maxLength: 3,
                      onKeyDown: (e) => {
                        if (
                          !/[A-Z]/.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.sourcePartyId}
                    helperText={addformErrors.sourcePartyId}
                    onChange={(e) => {
                      handleAddForm("sourcePartyId", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Email *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Email"
                    Name="email"
                    variant="outlined"
                    required
                    value={addform.email}
                    inputProps={{
                      minLength: 10,
                      maxLength: 30,
                      onKeyDown: (e) => {
                        if (
                          !/^[a-zA-Z0-9._@-]$/i.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.email}
                    helperText={addformErrors.email}
                    onChange={(e) => {
                      handleAddForm("email", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="d-flex aligm-items-center justify-content-end gap-4 my-5">
              <Link to="/cpoPage">
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  className="cancel-btn"
                >
                  Cancel
                </Button>
              </Link>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleErrorEditCpo}
                className="submit-btn"
              >
                Update
              </Button>
            </div>
          </Box>
        </CardContent>
      </Card>
      <PopUp
        open={alertPopup}
        title={"Alert"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClosePopup}
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
    </div>
  );
}
