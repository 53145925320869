import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Popper,
  Switch,
  TextField,
} from "@mui/material";
import { MenuItem, FormControl, FormLabel } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  allowLetters,
  allowLettersNumbersAndSpace,
  handleKeyDown,
} from "../../common/helpers";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import MapComponent from "../../MapFunction";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

function LocationAdd({ refresh, setRefresh }) {
  const [mapOpen, setMapOpen] = React.useState(false);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [timeZoneList, setTimeZoneList] = React.useState([]);
  const [parkingTypeList, setParkingTypeLIst] = React.useState([]);
  const [facilitiesList, setFacilitieslist] = React.useState([]);
  const [statusMessage, setStatusMessage] = React.useState("");
  const [directionsObj, setDirectionsObj] = React.useState({
    language: "EN",
    text: "",
  });

  const [locationFields, setLocationFields] = React.useState({
    name: "",
    address: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    publish: false,
    charging_when_closed: false,
    time_zone: "",
    parkingType: "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
    facilities: [],
    directions: [],
    active: "true",
  });
  const [errors, setErrors] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const minLength = 4;
  const maxLength = 10;

  React.useEffect(() => {
    getTimezones();
    getparkingType();
    getFacilities();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getTimezones();
      getparkingType();
      getFacilities();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const navigate = useNavigate();

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const getFacilities = async () => {
    let Url = `api/locations/getFacilities`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setFacilitieslist(res?.data);
    }
  };
  const getparkingType = async () => {
    let Url = `api/locations/getParkingType`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setParkingTypeLIst(res?.data);
    }
  };
  const getTimezones = async () => {
    let Url = "api/locations/getTimeZone";
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setTimeZoneList(res?.data);
    }
  };

  React.useEffect(() => {
    if (
      locationFields.coordinates.latitude &&
      locationFields.coordinates.longitude
    ) {
      getDetailsByLatLongitude();
      getTimeZonesWithApi();
    }
  }, [locationFields.coordinates]);

  const getTimeZonesWithApi = () => {
    fetch(
      `https://api.wheretheiss.at/v1/coordinates/${locationFields.coordinates.latitude},${locationFields.coordinates.longitude}`
    )
      .then((response) => response.json())
      .then((res) => {
        // let locData = locationFields;
        // locData.time_zone = res?.timezone_id;
        // console.log(res?.timezone_id);
        const zoneName = timeZoneList.filter(
          (list) => list.zoneId === res?.timezone_id
        );
        setLocationFields((prevData) => ({
          ...prevData,
          time_zone: zoneName[0]?.zoneName,
        }));
        // locData.time_zone = zoneName[0]?.zoneName;
      });
  };

  const getDetailsByLatLongitude = () => {
    fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${locationFields.coordinates.latitude}&lon=${locationFields.coordinates.longitude}`
    )
      .then((response) => response.json())
      .then((res) => {
        // let locData = locationFields;
        // locData.address = res?.display_name;
        // locData.city = res?.address?.city ?? "";
        // locData.country = res?.address?.country;
        // locData.state = res?.address?.state;
        // locData.postalCode = res?.address?.postcode;
        setLocationFields((prevData) => ({
          ...prevData,
          address: res?.display_name,
          city: res?.address?.city ?? "",
          country: res?.address?.country,
          state: res?.address?.state,
          postalCode: res?.address?.postcode,
        }));
      });
  };
  const longitudeRegex = /^-?[0-9]{1,3}\.[0-9]{5,20}$/;
  const validateForm = () => {
    const newErrors = {};
    if (!locationFields.name) newErrors.name = "Name is required";
    if (!locationFields.address) newErrors.address = "Address is required";
    if (!locationFields.country) newErrors.country = "Country is required";
    if (!locationFields.state) newErrors.state = "State is required";
    if (!locationFields.city) newErrors.city = "City is required";
    if (!locationFields.postalCode)
      newErrors.postalCode = "Postal Code is required";
    if (!locationFields.time_zone)
      newErrors.time_zone = "Time Zone is required";
    if (!locationFields.parkingType)
      newErrors.parkingType = "Parking Type is required";
    if (!locationFields.coordinates.latitude)
      newErrors.latitude = "Latitude is required";
    if (!longitudeRegex.test(locationFields.coordinates.latitude))
      newErrors.latitude = "Enter valid latitude";
    if (!longitudeRegex.test(locationFields.coordinates.longitude))
      newErrors.longitude = "Enter valid longitude";
    if (!locationFields.coordinates.longitude)
      newErrors.longitude = "Longitude is required";
    //
    if (/^\d*$/.test(locationFields.postalCode) && locationFields.postalCode) {
      if (locationFields.postalCode.length > maxLength) {
        newErrors.postalCode = "please enter valid PostalCode";
      } else if (locationFields.postalCode.length < minLength) {
        newErrors.postalCode = "please enter valid PostalCode";
      }
    }
    //
    if (
      !Array.isArray(locationFields.facilities) ||
      locationFields.facilities.length === 0 ||
      locationFields.facilities.some((f) => !f.trim())
    ) {
      newErrors.facilities = "At least one facility must be provided";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleChange = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setLocationFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOpenmap = () => {
    setMapOpen((open) => !open);
  };
  const handleCoordinatesChange = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setLocationFields((prevState) => ({
      ...prevState,
      coordinates: {
        ...prevState.coordinates,
        [name]: value,
      },
    }));
    let valid = longitudeRegex.test(value);
    if (valid) {
      setErrors({ ...errors, [name]: "" });
    } else {
      if (name === "latitude")
        setErrors({ ...errors, [name]: "Enter valid latitude" });
      else setErrors({ ...errors, [name]: "Enter valid longitude" });
    }
  };

  const handleFacilitiesChange = (name, newValue) => {
    setErrors({ ...errors, [name]: "" });
    setLocationFields((prevState) => ({
      ...prevState,
      [name]: Array.isArray(newValue) ? newValue : [], // Ensure `newValue` is an array
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let locFields = locationFields;
      locFields.directions = [directionsObj];
      let Url = "api/locations";
      let type = "POST";
      let res = await handleApi_call(
        Url,
        headers,
        type,
        locFields,
        setSessionExpire,
        setAlertMessage,
        handleAlertPopup,
        setLoader
      );
      if (res?.statusMessage === "SUCCESS") {
        setStatusMessage("SUCCESS");
        setAlertMessage(res?.data);
        handleAlertPopup();
      } else {
        setStatusMessage("FAILURE");
        setAlertMessage(res?.data);
        handleAlertPopup();
      }
    }
  };

  return (
    <div>
      <div className="pagetitle">Add Location</div>
      <div
        sx={{ minWidth: 275 }}
        style={{
          borderRadius: "25px",
          marginTop: "14px",
          backgroundColor: "white",
        }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Location Name *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 35,
                  }}
                  InputLabelProps={{
                    shrink: true, // Ensures label stays above the input box
                  }}
                  value={locationFields.name}
                  placeholder="Location Name"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleChange("name", e.target.value)}
                  error={errors.name}
                  helperText={errors.name}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>City *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="City"
                  className="w-100"
                  onKeyDown={allowLetters}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 20,
                  }}
                  value={locationFields.city}
                  onChange={(e) => handleChange("city", e.target.value)}
                  error={errors.city}
                  helperText={errors.city}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>State *</b>
                </FormLabel>
                <TextField
                  required
                  onKeyDown={allowLetters}
                  placeholder="State"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 25,
                  }}
                  value={locationFields.state}
                  onChange={(e) => handleChange("state", e.target.value)}
                  error={errors.state}
                  helperText={errors.state}
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Address *</b>
                </FormLabel>
                <TextField
                  placeholder="Address"
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 100,
                  }}
                  name="address"
                  value={locationFields.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  error={errors.address}
                  helperText={errors.address}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Postal Code *</b>
                </FormLabel>
                <TextField
                  placeholder="Postal Code"
                  required
                  className="w-100"
                  variant="outlined"
                  type="number"
                  size="small"
                  inputProps={{ maxLength: 10 }}
                  // onKeyDown={handleKeyDownNumbers}
                  name="postalCode"
                  value={locationFields.postalCode}
                  onChange={(e) => handleChange("postalCode", e.target.value)}
                  error={errors.postalCode}
                  helperText={errors.postalCode}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Country *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Country"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    minLength: 5,
                    maxLength: 25,
                  }}
                  onKeyDown={allowLetters}
                  value={locationFields.country}
                  onChange={(e) => handleChange("country", e.target.value)}
                  error={errors.country}
                  helperText={errors.country}
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Parking Type *</b>
                </FormLabel>
                <TextField
                  required
                  select
                  placeholder="Parking Type"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={locationFields.parkingType}
                  onChange={(e) => handleChange("parkingType", e.target.value)}
                  error={errors.parkingType}
                  helperText={errors?.parkingType}
                >
                  {parkingTypeList.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Time Zone *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Time Zone"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={locationFields.time_zone}
                  onChange={(e) => handleChange("time_zone", e.target.value)}
                  error={errors.time_zone}
                  helperText={errors.time_zone}
                ></TextField>
              </FormControl>
            </div>
            <div className="col-4 position-relative">
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={facilitiesList}
                disablePortal
                getOptionLabel={(option) => option}
                onChange={(event, newValue) =>
                  handleFacilitiesChange("facilities", newValue)
                }
                renderInput={(params) => (
                  <FormControl className="simpleInput">
                    <FormLabel>
                      <b>Select Facilities *</b>
                    </FormLabel>
                    <TextField
                      {...params}
                      placeholder="Select Facilities"
                      error={Boolean(errors.facilities)}
                      helperText={errors.facilities}
                    />
                  </FormControl>
                )}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      width: "calc(100% - 16px)",
                      marginLeft: "6px",
                      marginTop: "6px",
                    }}
                    placement="bottom-start"
                  />
                )}
              />
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Latitude *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Latitude"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={locationFields.coordinates.latitude}
                  onChange={(e) =>
                    handleCoordinatesChange("latitude", e.target.value)
                  }
                  error={errors.latitude}
                  helperText={errors.latitude}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Longitude *</b>
                </FormLabel>
                <TextField
                  required
                  placeholder="Longitude"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={locationFields.coordinates.longitude}
                  onChange={(e) =>
                    handleCoordinatesChange("longitude", e.target.value)
                  }
                  error={errors.longitude}
                  helperText={errors.longitude}
                />
              </FormControl>
            </div>
            <div className="col-4 d-flex align-items-end">
              <button
                className=" d-flex align-items-center justify-content-center selectMap gap-2"
                onClick={handleOpenmap}
              >
                <img
                  src="map-icon-new.png"
                  alt="map-icon"
                  className="h-24-w-24"
                />
                <span>Select on Map</span>
              </button>
            </div>
            {/* <div className="col-3">
          <TextField
            required
            select
            label="Token Type"
            className="w-100"
            variant="outlined"
            size="small"
            value={locationFields.parkingType}
            onChange={(e) => handleChange("parkingType", e.target.value)}
            error={errors.parkingType}
            helperText={errors?.parkingType}
          >
            {parkingTypeList.map((list, index) => (
              <MenuItem key={index} value={list}>
                {list}
              </MenuItem>
            ))}
          </TextField>
        </div> */}
            <div className="col-6">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Directions Text</b>
                </FormLabel>
                <TextField
                  placeholder="Directions Text"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  multiline
                  rows={3}
                  inputProps={{
                    minLength: 5,
                  }}
                  onKeyDown={allowLettersNumbersAndSpace}
                  value={directionsObj.text}
                  onChange={(e) =>
                    setDirectionsObj((obj) => ({
                      ...obj,
                      text: e.target.value,
                    }))
                  }
                />
              </FormControl>
            </div>
            <div className="col-2 d-flex align-items-center">
              Publish :
              <Switch
                checked={locationFields.publish}
                onChange={(e) => handleChange("publish", e.target.checked)}
                size="small"
              />
            </div>

            <div className="col-3 d-flex align-items-center">
              Charging When Closed :
              <Switch
                checked={locationFields.charging_when_closed}
                onChange={(e) =>
                  handleChange("charging_when_closed", e.target.checked)
                }
                size="small"
              />
            </div>
          </div>
        </CardContent>
      </div>

      <div className="d-flex justify-content-end gap-3 my-4">
        <Link to="/location">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className="submit-btn"
        >
          Submit
        </Button>
      </div>

      <PopUp
        open={mapOpen}
        title={""}
        message={
          <MapComponent
            locationFields={locationFields.coordinates}
            setLocationFields={(updatedCoords) =>
              setLocationFields((prevState) => ({
                ...prevState,
                coordinates: updatedCoords,
              }))
            }
          />
        }
        btn1={true}
        btn1Color={"cancel-btn"}
        btn1Text={"Cancel"}
        handleClickBtn1={() => setMapOpen(false)}
        btn2={true}
        btn2Color={"submit-btn"}
        btn2Text={"Submit"}
        handleClickBtn2={() => setMapOpen(false)}
        popUpWidth={"mw-920"}
      />
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={
          statusMessage === "SUCCESS"
            ? () => navigate("/location")
            : handleAlertPopup
        }
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}

export default LocationAdd;
