import { Button } from "@mui/material";

function ClearCache({ handleSubmitOperation }) {
  function handleSubmit() {
    let error = false;
    let inputFields = {};
    handleSubmitOperation(error, inputFields);
  }
  return (
    <div>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          className="submit-btn"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default ClearCache;
