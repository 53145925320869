import * as React from "react";
import TableCell from "@mui/material/TableCell";
import * as material from "@mui/material";
import * as helpers from "../../common/helpers";
import { basePathone } from "../../common/config";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import { Link } from "react-router-dom";
import moment from "moment";
import { Switch } from "@mui/material";
import CustomTablePagination from "../../components/CustomTablePagination";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import Loader from "../../components/Loader";

const headCells = [
  {
    id: "chargingStationId",
    numeric: false,
    disablePadding: true,
    label: "Charging Station",
    mandatory: true,
    filterOption: true,
    sort: true,
    minWidth: 150,
    searchType: "text",
  },
  {
    id: "chargingStationModel",
    numeric: false,
    disablePadding: true,
    label: "Charger Model",
    mandatory: true,
    filterOption: true,
    sort: true,
    minWidth: 130,
    searchType: "text",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: true,
    label: "City",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 80,
  },
  {
    id: "country",
    numeric: false,
    disablePadding: true,
    label: "Country",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "zipCode",
    numeric: false,
    disablePadding: true,
    label: "Zip Code",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 100,
  },
  {
    id: "lastHeartbeat",
    numeric: false,
    disablePadding: true,
    label: "Last Active Time",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 130,
  },
  {
    id: "backendConnection",
    numeric: false,
    disablePadding: true,
    label: "Connection",
    mandatory: false,
    filterOption: true,
    sort: false,
    searchType: "active",
    minWidth: 100,
  },
  {
    id: "active",
    numeric: false,
    disablePadding: true,
    label: "Status",
    mandatory: true,
    filterOption: true,
    sort: false,
    minWidth: 100,
    searchType: "active",
  },
];

function ChargingStations({ refresh, setRefresh }) {
  const [orderBy, setOrderBy] = React.useState("lastHeartbeat");
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(0);
  const [noData, setNoData] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disableColumns, setDisableColumns] = React.useState([
    "city",
    "country",
    "zipCode",
  ]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [searchInputs, setSearchInputs] = React.useState({
    chargingStationId: "",
    chargingStationModel: "",
    backendConnection: "",
    active: "",
    firmwareVersion: "",
    date: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "chargingStationId", value: "" },
    { key: "chargingStationModel", value: "" },
    { key: "backendConnection", value: "" },
    { key: "active", value: "" },
    { key: "firmwareVersion", value: "" },
    { key: "date", value: "" },
  ]);
  const [option, setOption] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [chargingStations, setChargingStations] = React.useState([]);

  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  React.useEffect(() => {
    getChargeStations();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);
  React.useEffect(() => {
    if (refresh) {
      getChargeStations();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const getChargeStations = () => {
    setLoader(true);
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    const searchParams = buildSearchParams();
    const payload = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: orderBy,
      sortDir: order,
    };
    fetch(basePathone + "csinfo/search", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res && res?.status) {
          setChargingStations(res.data);
          setCount(res.count);
          setNoData(res?.count === 0 ? "No Data Available" : "");
        }
      });
  };

  const updateStatus = (status, chargingStationId) => {
    setLoader(true);
    const headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };
    fetch(
      basePathone +
        `csinfo/toggleActiveStatus/${chargingStationId}?status=${status}`,
      {
        method: "PATCH",
        headers: headers,
      }
    )
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setStatus(true);
          setAlertMessage(res.message);
          handleAlertPopup();
        } else {
          setStatus(false);
          setAlertMessage(res.message);
          handleAlertPopup();
        }
      });
  };

  function buildSearchParams() {
    const params = Object.keys(searchInputs)
      .filter(
        (key) => searchInputs[key] || typeof searchInputs[key] === "boolean"
      )
      .map((key) => {
        if (key === "backendConnection" || key === "active") {
          return {
            key: key,
            value: searchInputs[key],
            operation: helpers.searchOperations.EQUALITY,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: helpers.searchOperations.CONTAINS,
          };
        }
      });
    return params;
  }

  function handleStatusClick(active, chargingStationId) {
    let status = active ? 0 : 1;
    updateStatus(status, chargingStationId);
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleAlertOk() {
    getChargeStations();
    handleAlertPopup();
  }

  //new functions
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      chargingStationId: "",
      chargingStationModel: "",
      backendConnection: "",
      active: "",
      firmwareVersion: "",
      date: "",
    });
    setFilterState([
      { key: "chargingStationId", value: "" },
      { key: "chargingStationModel", value: "" },
      { key: "backendConnection", value: "" },
      { key: "active", value: "" },
      { key: "firmwareVersion", value: "" },
      { key: "date", value: "" },
    ]);
  };
  // end

  const handleOptionClick = (value) => {
    setOption(option === value ? "" : value);
  };

  const handleColumnChange = (id) => {
    setDisableColumns(
      disableColumns.includes(id)
        ? disableColumns.filter((c) => c !== id)
        : [...disableColumns, id]
    );
  };
  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        chargingStationId: "",
        chargingStationModel: "",
        backendConnection: "",
        active: "",
        firmwareVersion: "",
        date: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <material.Box sx={{ width: "100%" }}>
      {loader && <Loader />}
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Charging Stations</div>
        <div className="Export-Button bgcolor-blue">
          <i className="fa-solid fa-arrow-up-from-bracket" />
          Export
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handleColumnChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
          </material.Box>
        </div>
      </div>
      <material.Paper style={{ width: "100%", borderRadius: "14px" }}>
        <material.TableContainer className="table_body_height">
          <material.Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={chargingStations.length}
              disableColumns={disableColumns}
              disableCheckBox={true}
            />

            <material.TableBody>
              {chargingStations.map((row, index) => {
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <material.TableRow tabIndex={-1} key={row.id}>
                    <material.TableCell padding="checkbox">
                      <material.Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </material.TableCell>
                    {!disableColumns.includes("chargingStationId") && (
                      <material.TableCell
                        // component="th"
                        // id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        <Link
                          to={"/viewChargePoint"}
                          state={row}
                          className="linkUnderLineHover"
                        >
                          {row.chargingStationId}
                        </Link>
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("chargingStationModel") && (
                      <material.TableCell>
                        {row.chargingStationModel}
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("city") && (
                      <material.TableCell>{row.city}</material.TableCell>
                    )}
                    {!disableColumns.includes("country") && (
                      <material.TableCell>{row.country}</material.TableCell>
                    )}
                    {!disableColumns.includes("zipCode") && (
                      <material.TableCell>{row.zipCode}</material.TableCell>
                    )}
                    {!disableColumns.includes("lastHeartbeat") && (
                      <material.TableCell>
                        {row.lastHeartbeat &&
                          moment(row.lastHeartbeat).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </material.TableCell>
                    )}

                    {!disableColumns.includes("backendConnection") && (
                      <material.TableCell>
                        <material.Switch
                          checked={row.backendConnection}
                          disabled
                          size="small"
                        />
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("active") && (
                      <TableCell>
                        <div className="checkUpdate">
                          <Switch
                            checked={row.active}
                            onClick={() =>
                              handleStatusClick(
                                row.active,
                                row.chargingStationId
                              )
                            }
                            size="small"
                          />
                          <div className="updateTooltip">
                            Activate/Deactivate
                          </div>
                        </div>
                      </TableCell>
                    )}
                  </material.TableRow>
                );
              })}
              {chargingStations.length === 0 && (
                <material.TableRow style={{ height: 40 }}>
                  <material.TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </material.TableCell>
                </material.TableRow>
              )}
            </material.TableBody>
          </material.Table>
        </material.TableContainer>
        {chargingStations.length > 0 && (
          <CustomTablePagination
            rowsPerPageOptions={[10, 15, 20]}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </material.Paper>

      <PopUp
        open={alertPopup}
        title={status === true ? "Success" : "Failure"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />

      {sessionExpire && <SessionExpire />}
    </material.Box>
  );
}

export default ChargingStations;
