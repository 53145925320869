import React from "react";

function Loader() {
  return (
    <div className="page_loader center loaderWithzIndex">
      <img src="loader.gif" width="80" height="80" alt="" />
    </div>
  );
}

export default Loader;
