import * as React from "react";

import Box from "@mui/material/Box";
import { MenuItem, TextField, FormControl, FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import { Link, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { resize } from "../../common/helpers";
import { basePathone } from "../../common/config";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function RegisterMsp({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const navigate = useNavigate();
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");

  const [countryList, setCountryList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [addform, setAddForm] = React.useState({
    uid: "",
    name: "",
    contactPerson: "",
    contactphone: "",
    address: "",
    city: "",
    country: "",
    sourceCountryCode: "",
    sourcePartyId: "",
    email: "",
  });
  const [addformErrors, setAddFormErrors] = React.useState({
    uid: "",
    name: "",
    contactPerson: "",
    contactphone: "",
    address: "",
    city: "",
    country: "",
    sourcePartyId: "",
    email: "",
  });
  const emailRegex = /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  function handleClosePop() {
    navigate("/mspPage");
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10,16}$/;
    return phoneRegex.test(phoneNumber);
  };
  const validUid = (uid) => {
    const uidRegex = /^[A-Z]+[A-Z0-9-]{16}$/;
    return uidRegex.test(uid);
  };
  function handleAddForm(key, value) {
    setAddFormErrors({ ...addformErrors, [key]: "" });
    if (key === "country") {
      const result = countryList.find((obj) => obj.countryName === value);
      setAddForm({
        ...addform,
        [key]: value,
        sourceCountryCode: result.countryCode,
      });
    } else if (key === "email") {
      if (!emailRegex.test(value)) {
        setAddFormErrors((errors) => ({
          ...errors,
          email: "Invalid email format",
        }));
      }
      setAddForm({ ...addform, [key]: value });
    } else {
      setAddForm({ ...addform, [key]: value });
    }
  }
  function handleErrorRegisterMsp() {
    let error = false;
    if (
      !addform.uid ||
      !addform.name ||
      !addform.contactPerson ||
      !addform.contactphone ||
      !addform.address ||
      !addform.city ||
      !addform.country ||
      !addform.sourcePartyId ||
      !addform.email ||
      !validatePhoneNumber(addform.contactphone) ||
      !validUid(addform.uid) ||
      !emailRegex.test(addform.email)
    ) {
      error = true;
    }
    if (error) {
      if (!addform.uid || !validUid(addform.uid)) {
        setAddFormErrors((errors) => ({
          ...errors,
          uid: !addform.uid
            ? "UID is Mandatory"
            : "UID must start with letters, be exactly 17 characters long, and contain only uppercase letters, digits, and hyphens",
        }));
      }
      if (!addform.name) {
        setAddFormErrors((errors) => ({
          ...errors,
          name: "Name is Mandatory",
        }));
      }
      if (!addform.contactPerson) {
        setAddFormErrors((errors) => ({
          ...errors,
          contactPerson: "ContactPerson is Mandatory",
        }));
      }
      if (!addform.contactphone || !validatePhoneNumber(addform.contactphone)) {
        setAddFormErrors((errors) => ({
          ...errors,
          contactphone: !addform.contactphone
            ? "ContactPhone is Mandatory"
            : "Invalid phone number. It must be exactly 10 digits.",
        }));
      }

      if (!addform.address) {
        setAddFormErrors((errors) => ({
          ...errors,
          address: "Address is Mandatory",
        }));
      }
      if (!addform.city) {
        setAddFormErrors((errors) => ({
          ...errors,
          city: "City is Mandatory",
        }));
      }
      if (!addform.country) {
        setAddFormErrors((errors) => ({
          ...errors,
          country: " Country is Mandatory",
        }));
      }
      if (!addform.sourcePartyId) {
        setAddFormErrors((errors) => ({
          ...errors,
          sourcePartyId: "SourcePartyId is Mandatory",
        }));
      }
      if (!addform.email) {
        setAddFormErrors((errors) => ({
          ...errors,

          email: "Email is Mandatory",
        }));
      }
    }
    if (!error) {
      handleRegisterMsp();
    }
  }
  const handleRegisterMsp = async () => {
    let Url = "emsp";
    let type = "POST";
    let body = addform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handleAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleAlertPopup();
    }
  };
  const handleGetCountries = async () => {
    let Url = "emsp/countryCodes";
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setCountryList(res.data);
    }
  };

  React.useEffect(() => {
    handleGetCountries();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      handleGetCountries();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <div>
      {loader && <FullLoader />}
      <div className="pagetitle">Register MSP</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          {sessionExpire && <SessionExpire />}
          <Box>
            <div className="row row-gap-3 mt-3">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>UID *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="UID"
                    name="uid"
                    variant="outlined"
                    required
                    value={addform.uid}
                    inputProps={{
                      minLength: 10,
                      maxLength: 18,
                      pattern:
                        "^[A-Z]{2}-[A-Za-z0-9]{3}-[A-Za-z0-9]{3,}-[A-Za-z0-9_-]{1,30}$",
                      onKeyDown: (e) => {
                        const { value: inputValue } = e.target;
                        const allowedKeys = [
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                          "Tab",
                        ];
                        if (allowedKeys.includes(e.key)) return;
                        const isFirstChar = inputValue.length === 0;
                        const hyphenCount = (inputValue.match(/-/g) || [])
                          .length;
                        const lastChar = inputValue.slice(-1);
                        if (isFirstChar && !/[A-Z]/.test(e.key)) {
                          e.preventDefault();
                          return;
                        }
                        if (
                          e.key === "-" &&
                          (hyphenCount >= 2 || lastChar === "-")
                        ) {
                          e.preventDefault();
                          return;
                        }
                        if (!/[A-Z0-9-]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.uid}
                    helperText={addformErrors.uid}
                    onChange={(e) => {
                      handleAddForm("uid", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Name *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Name"
                    name="name"
                    variant="outlined"
                    required
                    value={addform.name}
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z\s]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.name}
                    helperText={addformErrors.name}
                    onChange={(e) => {
                      handleAddForm("name", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Contact Person *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Contact Person"
                    Name="contactPerson"
                    variant="outlined"
                    required
                    value={addform.contactPerson}
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z\s]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.contactPerson}
                    helperText={addformErrors.contactPerson}
                    onChange={(e) => {
                      handleAddForm("contactPerson", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Contact Phone *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Contact Phone"
                    Name="contactphone"
                    variant="outlined"
                    required
                    value={addform.contactphone}
                    inputProps={{
                      minLength: 10,
                      maxLength: 16,
                      onKeyDown: (e) => {
                        if (
                          !/[0-9]/.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.contactphone}
                    helperText={addformErrors.contactphone}
                    onChange={(e) => {
                      handleAddForm("contactphone", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Address *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Address"
                    Name="address"
                    variant="outlined"
                    required
                    value={addform.address}
                    inputProps={{
                      minLength: 10,
                      maxLength: 30,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z0-9\s,.\\-]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.address}
                    helperText={addformErrors.address}
                    onChange={(e) => {
                      handleAddForm("address", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>City *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="City"
                    Name="city"
                    variant="outlined"
                    required
                    value={addform.city}
                    inputProps={{
                      minLength: 3,
                      maxLength: 16,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z\s]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.city}
                    helperText={addformErrors.city}
                    onChange={(e) => {
                      handleAddForm("city", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Country *</b>
                  </FormLabel>
                  <TextField
                    select
                    size="small"
                    className="error"
                    placeholder="Country"
                    Name="country"
                    variant="outlined"
                    required
                    value={addform.country}
                    inputProps={{
                      minLength: 10,
                      maxLength: 16,
                      onKeyDown: (e) => {
                        if (/[A-Za-z]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.country}
                    helperText={addformErrors.country}
                    onChange={(e) => {
                      handleAddForm("country", e.target.value);
                    }}
                  >
                    {countryList.map((list) => (
                      <MenuItem
                        key={list.countryCode}
                        value={list.countryName}
                        id="drop"
                      >
                        {list.countryName}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Source Party ID *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Source Party ID"
                    Name="sourcePartyId"
                    variant="outlined"
                    required
                    value={addform.sourcePartyId}
                    inputProps={{
                      minLength: 3,
                      maxLength: 3,
                      onKeyDown: (e) => {
                        if (
                          !/[A-Z]/.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.sourcePartyId}
                    helperText={addformErrors.sourcePartyId}
                    onChange={(e) => {
                      handleAddForm("sourcePartyId", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Email *</b>
                  </FormLabel>
                  <TextField
                    type="email"
                    size="small"
                    className="error"
                    placeholder="Email"
                    Name="email"
                    variant="outlined"
                    required
                    value={addform.email}
                    inputProps={{
                      minLength: 10,
                      maxLength: 30,
                      onKeyDown: (e) => {
                        if (
                          !/^[a-zA-Z0-9._@-]$/i.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={addformErrors.email}
                    helperText={addformErrors.email}
                    onChange={(e) => {
                      handleAddForm("email", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </Box>
        </CardContent>
      </Card>
      <div className="d-flex aligm-items-center justify-content-end gap-4 my-5">
        <Link to="/mspPage">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleErrorRegisterMsp}
          className="submit-btn"
        >
          Add
        </Button>
      </div>

      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={
          statusMessage === "SUCCESS" ? handleClosePop : handleAlertPopup
        }
        popUpWidth={"width-100"}
      />
    </div>
  );
}
