import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../components/SessionExpire";
import { useLocation, useNavigate } from "react-router-dom";
import PopUp from "../components/PopUp";
import FullLoader from "../components/FullLoader";
import { handleApi_call } from "../common/CommonAPI";
import { headers } from "../common/Headers";

export default function EditReservation({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [locationList, setLocationList] = React.useState([]);
  const [evseList, setEvseList] = React.useState([]);
  const [connectorslist, setConnectorsList] = React.useState([]);
  const location = useLocation();
  const [loader, setLoader] = React.useState(false);
  const [updateform, setUpdateForm] = React.useState({
    chargingStationId: "",
    evseId: "",
    connectorId: "",
    validFrom: "",
    validTo: "",
  });

  const [errors, setErrors] = React.useState({
    chargingStationId: "",
    evseId: "",
    connectorId: "",
    validFrom: "",
    validTo: "",
  });

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const navigate = useNavigate();

  function handleClosePopup() {
    navigate("/reservations");
  }
  function handleSubmit() {
    let error = false;
    if (
      updateform.chargingStationId.length === 0 ||
      updateform.evseId.length === 0 ||
      updateform.connectorId.length === 0 ||
      updateform.validFrom.length === 0 ||
      updateform.validTo.length === 0
    ) {
      error = true;
    }
    if (error) {
      if (updateform.chargingStationId === "") {
        setErrors((errors) => ({
          ...errors,
          chargingStationId: "Charging Station is required",
        }));
      }
      if (updateform.evseId === "") {
        setErrors((errors) => ({
          ...errors,
          evseId: "EVSE Id is required",
        }));
      }
      if (updateform.connectorId === "") {
        setErrors((errors) => ({
          ...errors,
          connectorId: "Connector Id is required",
        }));
      }
      if (updateform.validFrom === "") {
        setErrors((errors) => ({
          ...errors,
          validFrom: "Valid From is required",
        }));
      }
      if (updateform.validTo === "") {
        setErrors((errors) => ({
          ...errors,
          validTo: "valid To is required",
        }));
      }
    } else {
      updateReservation();
    }
  }
  React.useEffect(() => {
    setUpdateForm(location.state);
    getAllLocations();
    getEvseIdList(location.state?.chargingStationId);
    // getAllConectorsList(location.state?.evseId);
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setUpdateForm(location.state);
      getAllLocations();
      getEvseIdList(location.state?.chargingStationId);
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  const getAllLocations = async () => {
    let Url = "api/locations/locationIdsDropdown";
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setLocationList(res?.data);
    }
  };

  const getEvseIdList = async (locId) => {
    let Url = `api/locations/getEvseListForSelectedLocation/${locId}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setEvseList(res?.data);
      if (res?.data?.length === 1) {
        handleEvseChange(res?.data[0]);
      }
      if (res?.data?.length === 0) {
        setErrors((errors) => ({
          ...errors,
          chargingStationId: "This charging station does not have any EVSE.",
        }));
      } else {
        const stationid = errors;
        stationid.chargingStationId = "";
      }
    }
  };
  const getAllConectorsList = async (locId, evseId) => {
    let Url = `api/locations/getConnectorListForSelectedEvse/${locId}/${evseId}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setConnectorsList(res?.data);
      if (res?.data?.length === 1) {
        handleConnectorChange(res?.data[0]);
      }
      if (res?.data?.length === 0) {
        setErrors((errors) => ({
          ...errors,
          evseId: "This EVSE does not have any connectors.",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          evseId: "",
        }));
      }
    }
  };
  const updateReservation = async () => {
    let Url = `reservation/${updateform.reservationId}`;
    let type = "PUT";
    let body = updateform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };
  function handleAddForm(key, value) {
    setErrors({ ...errors, [key]: "" });
    setUpdateForm((updateform) => ({
      ...updateform,
      [key]: value,
    }));
  }
  const handleLocationChange = (e) => {
    handleAddForm("chargingStationId", e.target.value);
    handleAddForm("evseId", "");
    handleAddForm("connectorId", "");
    setErrors((errors) => ({
      ...errors,
      chargingStationId: "",
      evseId: "",
    }));
    setConnectorsList([]);
    getEvseIdList(e.target.value);
  };

  const handleEvseChange = (value) => {
    console.log("Selected EVSE ID:", value); // Check if evseId is correctly set
    setUpdateForm((prevForm) => ({
      ...prevForm,
      evseId: value,
    }));
  };
  React.useEffect(() => {
    if (updateform.evseId && updateform.chargingStationId) {
      getAllConectorsList(updateform.chargingStationId, updateform.evseId);
    }
  }, [updateform.evseId, updateform.chargingStationId]);
  const handleConnectorChange = (value) => {
    handleAddForm("connectorId", value);
  };
  return (
    <div>
      {sessionExpire && <SessionExpire />}
      <div className="pagetitle">Update Reservation</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <Box className="row row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Charging Station Id*</b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  name="chargingStationId"
                  value={updateform.chargingStationId}
                  select
                  variant="outlined"
                  size="small"
                  onChange={handleLocationChange}
                  error={errors.chargingStationId}
                  helperText={errors.chargingStationId}
                >
                  {locationList?.map((list, index) => (
                    <MenuItem key={index} value={list.name}>
                      {list.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>

            {evseList.length === 1 ? (
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>EVSE Id *</b>
                  </FormLabel>
                  <TextField
                    className="w-100"
                    required
                    type="text"
                    name="evseId"
                    disabled={
                      updateform.chargingStationId === "" ? true : false
                    }
                    value={evseList[0]}
                    onChange={(e) => handleEvseChange(e.target.value)}
                    error={errors.evseId}
                    helperText={errors.evseId}
                    size="small"
                  />
                </FormControl>
              </div>
            ) : (
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>EVSE Id *</b>
                  </FormLabel>
                  <TextField
                    className="w-100"
                    required
                    select
                    type="text"
                    name="evseId"
                    disabled={
                      updateform.chargingStationId === "" ||
                      evseList.length === 0
                        ? true
                        : false
                    }
                    value={updateform.evseId}
                    onChange={(e) => handleEvseChange(e.target.value)}
                    error={errors.evseId}
                    helperText={errors.evseId}
                    size="small"
                  >
                    {evseList?.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            )}

            {connectorslist.length === 1 ? (
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Connector Id *</b>
                  </FormLabel>
                  <TextField
                    className="w-100"
                    required
                    name="evseId"
                    value={connectorslist[0]}
                    onChange={(e) => handleConnectorChange(e.target.value)}
                    error={errors.connectorId}
                    helperText={errors.connectorId}
                    size="small"
                  />
                </FormControl>
              </div>
            ) : (
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Connector Id *</b>
                  </FormLabel>
                  <TextField
                    select
                    className="w-100"
                    required
                    name="evseId"
                    disabled={
                      updateform.evseId === "" || connectorslist.length === 0
                        ? true
                        : false
                    }
                    value={updateform.connectorId}
                    onChange={(e) => handleConnectorChange(e.target.value)}
                    error={errors.connectorId}
                    helperText={errors.connectorId}
                    size="small"
                  >
                    {connectorslist?.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            )}
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Valid From *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={updateform.validFrom}
                  min={new Date().toISOString().slice(0, 16)}
                  max={updateform.validTo}
                  placeholder="Select date"
                  onChange={(e) => {
                    const validFromValue = e.target.value;
                    if (
                      updateform.validTo &&
                      new Date(validFromValue) > new Date(updateform.validTo)
                    ) {
                      handleAddForm(
                        "validFrom",
                        new Date().toISOString().slice(0, 16)
                      );
                    } else {
                      handleAddForm("validFrom", validFromValue);
                    }
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {" "}
                  {errors.validFrom}
                </p>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className="my-1 fs-15 fw-600">
                  <span>Valid To *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  onError={errors.validTo}
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={updateform.validTo}
                  min={
                    updateform.validFrom
                      ? updateform.validFrom
                      : new Date().toISOString().slice(0, 16)
                  }
                  placeholder="Select date"
                  onChange={(e) => {
                    const validToValue = e.target.value;
                    if (updateform.validFrom) {
                      const validFromDate = new Date(updateform.validFrom);
                      const validToDate = new Date(validToValue);

                      if (validToDate <= validFromDate) {
                        validFromDate.setMinutes(
                          validFromDate.getMinutes() + 1
                        );
                        handleAddForm(
                          "validTo",
                          validFromDate.getMinutes() + 1
                        );
                      } else {
                        handleAddForm("validTo", validToValue);
                      }
                    }
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors.validTo}
                </p>
              </FormControl>
            </div>
          </Box>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-end gap-3 my-4">
        <Button
          size="small"
          color="error"
          variant="contained"
          className="cancel-btn"
          onClick={handleClosePopup}
        >
          Cancel
        </Button>

        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className="submit-btn"
        >
          Update
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={status === true ? handleClosePopup : handleAlertPopup}
        popUpWidth={"width-100"}
      />
      {loader && <FullLoader />}
    </div>
  );
}
