import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { Button, Switch } from "@mui/material";
function ViewStations({ refresh, setRefresh }) {
  React.useEffect(() => {
    setstationobj(location.state);
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setstationobj(location.state);
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  let navigate = useNavigate();
  const location = useLocation();
  const [stationobj, setstationobj] = React.useState({});
  return (
    <div>
      <div>
        <div className="w-100 mb-4 mx-0 p-0 d-flex align-items-center justify-content-between">
          <div className="pagetitle">View Stations</div>
          <div>
            <Button
              className="submit-btn"
              variant="contained"
              size="small"
              onClick={() => navigate("/Stations")}
            >
              <ArrowBackIosIcon fontSize="10px" />
              Back
            </Button>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6 ">
            <div className="py-4 px-5 border br-10 cardBackground h-100">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Evsn
                </span>
                <span>:</span>
                <span>{stationobj.evsn} </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  MF Date
                </span>

                <span>:</span>
                <span>
                  <span>
                    {moment(stationobj.manufacturingDate).format("DD-MMM-YYYY")}{" "}
                  </span>
                </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Model
                </span>
                <span>:</span>
                <span>{stationobj.modelName}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Part No
                </span>
                <span>:</span>
                <span>{stationobj?.partNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  MES Serial No.
                </span>
                <span>:</span>
                <span>{stationobj?.mesSerialNumber}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Vendor Code
                </span>
                <span>:</span>
                <span>{stationobj?.vendorCode}</span>
              </div>
            </div>
          </div>
          <div className="col-6 ">
            <div className="py-4 px-5 border br-10 cardBackground h-100">
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  FW Version
                </span>
                <span>:</span>
                <span>{stationobj?.firmwareVersion}</span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Created Date
                </span>

                <span>:</span>
                <span>
                  <span>
                    {moment(stationobj.createdAt).format("DD-MMM-YYYY")}{" "}
                  </span>
                </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Modified Date
                </span>

                <span>:</span>
                <span>
                  <span>
                    {moment(stationobj.updatedAt).format("DD-MMM-YYYY")}{" "}
                  </span>
                </span>
              </div>

              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  TimeOut
                </span>
                <span>:</span>
                <span>
                  <Switch
                    disabled
                    checked={stationobj.inTimeout}
                    size="small"
                  />
                </span>
              </div>
              <div className=" w-100 d-flex align-items-center gap-3 mb-2">
                <span className="saas-boldHeading" style={{ width: "50%" }}>
                  Access Code
                </span>
                <span>:</span>
                <span>{stationobj?.accessCode}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewStations;
