/** @format */

import { Edit } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import * as material from "@mui/material";
import CustomTablePagination from "../../components/CustomTablePagination";
import TableHeadRow from "../../components/TableHeadRow";
import ColumnsBox from "../../components/ColumnsBox";
import React from "react";
import PopUp from "../../components/PopUp";
import { Link } from "react-router-dom";
import SessionExpire from "../../components/SessionExpire";
import { resize } from "../../common/helpers";
import { basePathone } from "../../common/config";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import FilterTable from "../../components/FilterTable";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    mandatory: true,
    filterOption: true,
    searchType: "text",
    sort: false,
    minWidth: 100,
    searchType: "text",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Address",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "contactPerson",
    numeric: false,
    disablePadding: true,
    label: "Contact Person",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "contactphone",
    numeric: false,
    disablePadding: true,
    label: "Contact Phone",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Status",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },

  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "Update",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
];

function MspPage({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [loader, setLoader] = React.useState([]);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [option, setOption] = React.useState("");
  const [msp, setMsp] = React.useState([]);
  const [noData, setNoData] = React.useState("");
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState();
  const [status, setStatus] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [searchInputs, setSearchInputs] = React.useState({
    name: "",
  });
  const [isActive, setIsActive] = React.useState(1);
  const [activeDeactivePopUp, setActivateDeactivePopUp] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState();
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [filterState, setFilterState] = React.useState([
    { key: "name", value: "" },
  ]);

  const getMspPage = async () => {
    const Url = `emsp?page=${page + 1}&size=${rowsPerPage}&name=${
      searchInputs.name
    }`;
    const type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res && res?.statusCode === 400) {
      setAlertMessage(res?.data);
      setStatus(false);

      handleAlertPopup(); // Display popup
    } else {
      setMsp(res?.data);
      setCount(res?.count);
      setNoData(res?.count === 0 ? "No data Available" : "");
    }
  };

  const activateDeactivateAccount = async () => {
    let Url = `emsp/${selectedRow}?isActive=${isActive === true ? 0 : 1}`;
    let type = "PATCH";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setStatus(true);
      setActivateDeactivePopUp(false);
      handleAlertPopup();
      setAlertMessage(res.data);
      getMspPage();
    } else {
      setStatus(false);
      setAlertMessage(res.data);
      handleAlertPopup();
    }
  };

  React.useEffect(() => {
    getMspPage();
  }, [page, searchInputs, rowsPerPage]);
  React.useEffect(() => {
    if (refresh) {
      getMspPage();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function handleSearchInputs(value) {
    setSearchInputs(value);
    setPage(0);
  }
  function handleActiveDeactivePopup(uid, active) {
    setIsActive(active);
    setSelectedRow(uid);
    setActivateDeactivePopUp((pop) => !pop);
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = msp.map((n) => n.uid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
    setAlertMessage("");
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        name: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };
  const resetStates = () => {
    setSearchInputs({
      name: "",
    });
    setFilterState([{ key: "name", value: "" }]);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {loader && <Loader />}
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">MSP</div>
        <div className="d-flex align-items-center gap-3 ">
          <Link to="/registerMsp">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i class="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      filterState={filterState}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  class="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={handelChange}
                      disableColumns={disableColumns}
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red ">
                <i class="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              // deleteCheckBox={false}
              onRequestSort={handleRequestSort}
              rowCount={msp.length}
              disableCheckBox={true}
              disableColumns={disableColumns}
            />
            <TableBody>
              {msp?.map((row, index) => {
                const isItemSelected = isSelected(row.uid);
                const labelId = `enhanced - table - checkbox - ${index}`;
                return (
                  <TableRow
                    // hover
                    // role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.uid}
                    // selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={false}
                        disabled={true}
                      />
                    </TableCell>
                    {!disableColumns.includes("name") && (
                      <TableCell
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row?.name}
                      </TableCell>
                    )}

                    {!disableColumns.includes("address") && (
                      <TableCell>{row?.address}</TableCell>
                    )}
                    {!disableColumns.includes("city") && (
                      <TableCell>{row?.city}</TableCell>
                    )}
                    {!disableColumns.includes("country") && (
                      <TableCell>{row?.country}</TableCell>
                    )}
                    {!disableColumns.includes("contactPerson") && (
                      <TableCell>{row?.contactPerson}</TableCell>
                    )}
                    {!disableColumns.includes("contactphone") && (
                      <TableCell>{row?.contactphone}</TableCell>
                    )}
                    {!disableColumns.includes("active") && (
                      <TableCell>
                        <Switch
                          checked={row.active}
                          onClick={() =>
                            handleActiveDeactivePopup(row.uid, row.active)
                          }
                          size="small"
                        />
                      </TableCell>
                    )}
                    {!disableColumns.includes("update") && (
                      <TableCell>
                        <Link to={"/editMsp"} state={row}>
                        <i
                    className="fa-solid fa-pencil action-icons"
                    style={{ color: "#2DCC70" }}
                  ></i>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {msp?.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {msp.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      <PopUp
        open={activeDeactivePopUp}
        message={`Are you sure you want to ${
          isActive ? "deactivate" : "activate"
        } this account?`}
        btn1={true}
        btn2={true}
        btn1Color={"bg-secondary"}
        btn2Color={"submit-btn"}
        btn1Text={"no"}
        btn2Text={"yes"}
        handleClickBtn1={handleActiveDeactivePopup}
        title={"confirm"}
        handleClickBtn2={activateDeactivateAccount}
        popUpWidth="width-100"
      />
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertPopup}
        popUpWidth={"width-100"}
      />
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
export default MspPage;
