/** @format */

import * as material from "@mui/material";
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
import ColumnsBox from "../../components/ColumnsBox";
import CustomTablePagination from "../../components/CustomTablePagination";
import Loader from "../../components/Loader";
import PopUp from "../../components/PopUp";
import SessionExpire from "../../components/SessionExpire";
import TableHeadRow from "../../components/TableHeadRow";

const headCells = [
  {
    id: "token",
    numeric: false,
    disablePadding: true,
    label: "Token",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "url",
    numeric: false,
    disablePadding: true,
    label: "Url",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },

  {
    id: "businessname",
    numeric: false,
    disablePadding: true,
    label: "BusinessName",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "website",
    numeric: false,
    disablePadding: true,
    label: "Businesswebsite",
    mandatory: false,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
  {
    id: "update",
    numeric: false,
    disablePadding: false,
    label: "Update",
    mandatory: true,
    filterOption: false,
    sort: false,
    minWidth: 100,
  },
];

function Credentials({ refresh, setRefresh }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [noData, setNoData] = React.useState("");
  const [disableColumns, setDisableColumns] = React.useState([
    "businessname",
    "website",
  ]);
  const [option, setOption] = React.useState("");
  const [credentials, setCredentials] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const getCredentials = async (version) => {
    const Url = `cpo/${2.1}/credentials?page=${
      page + 1
    }&size=${rowsPerPage}&sortBy=${orderBy}&sortDir=${order}`;
    const type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res.statusCode) {
      setCredentials(res?.data);
      setCount(res?.count);
      setNoData(res?.count === 0 ? "No data Available" : "");
    }
  };

  const deleteCredentials = async () => {
    let Url = `cpo/${2.1}/credentials/ids`;
    let type = "DELETE";
    let body = selected;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      setSelected([]);
      handleAlertPopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handleAlertPopup();
    }
  };

  React.useEffect(() => {
    getCredentials();
  }, [page, rowsPerPage]);
  React.useEffect(() => {
    if (refresh) {
      getCredentials();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }

  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = credentials.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }

  function handleAlertOk() {
    getCredentials();
    handleAlertPopup();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Credentials</div>
        <div className="d-flex align-items-center gap-3">
          <Link to="/addCredentials">
            <div className="Export-Button bgcolor-blue">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Link>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end cardFilter">
        <material.Box className="d-flex align-items-center gap-3">
          <div className="position-relative">
            <div
              className="filter-filter bgcolor-blue"
              onClick={() => handleOptionClick("column")}
            >
              <i
                class="fa-solid fa-bars"
                style={{
                  transform: "rotate(-90deg)",
                }}
              ></i>
              <span className="ms-1">Column</span>
            </div>
            {option === "column" && (
              <material.ClickAwayListener
                onClickAway={() => handleOptionClick("")}
              >
                <div className="columnBox">
                  <ColumnsBox
                    headCells={headCells}
                    handelChange={handelChange}
                    disableColumns={disableColumns}
                  />
                </div>
              </material.ClickAwayListener>
            )}
          </div>
          {selected.length > 0 && (
            <div className="deletediv bg-red " onClick={deleteCredentials}>
              <i class="fa-regular fa-trash-can delete-icon"></i>
            </div>
          )}
        </material.Box>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer className="table_body_height">
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={credentials.length}
              disableColumns={disableColumns}
            />
            <TableBody>
              {credentials?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    // hover
                    // role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    {!disableColumns.includes("token") && (
                      <TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Link
                          to={"/credentialViewform"}
                          state={row}
                          className="linkUnderLineHover"
                        >
                          {row?.token}
                        </Link>
                      </TableCell>
                    )}

                    {!disableColumns.includes("url") && (
                      <TableCell>{row?.url}</TableCell>
                    )}

                    {!disableColumns.includes("businessname") && (
                      <TableCell>
                        {/* {row.roles[0]?.businessDetails?.businessName} */}
                      </TableCell>
                    )}

                    {!disableColumns.includes("website") && (
                      <TableCell>
                        {/* {row.roles[0]?.businessDetails?.website} */}
                      </TableCell>
                    )}

                    {!disableColumns.includes("update") && (
                      <TableCell>
                        <Link to={"/editCredentials"} state={row}>
                          <i
                            className="fa-solid fa-pencil action-icons"
                            style={{ color: "#2DCC70" }}
                          ></i>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {credentials?.length === 0 && (
                <TableRow style={{ height: 40 }}>
                  <TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {credentials?.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 20, 30]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      {loader && <Loader />}
      {sessionExpire && <SessionExpire />}

      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
    </Box>
  );
}

export default Credentials;
