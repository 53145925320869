import * as React from "react";
import * as material from "@mui/material";
import * as icons from "@mui/icons-material";
import { alpha } from "@mui/material/styles";
import * as helpers from "../../common/helpers";
import { basePath } from "../../common/config";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import FilterInputs from "../../components/FilterInputs";
import TableHeadRow from "../../components/TableHeadRow";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import RoleAddForm from "../Role/RoleAddForm";
import RoleUpdateForm from "../Role/RoleUpdateForm";
import { useTranslation } from "react-i18next";
import { Box, Button, Paper, Table, TableContainer } from "@mui/material";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import CustomTablePagination from "../../components/CustomTablePagination";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
export default function Roles({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = React.useState("roleName");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [isActive, setIsActive] = React.useState(1);
  const [loader, setLoader] = React.useState([]);
  const [activeDeactivePopUp, setActivateDeactivePopUp] = React.useState(false);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [updateRow, setUpdateRow] = React.useState({});
  const [updatePopup, setUpdatePopup] = React.useState(false);
  const [openAddForm, setOpenAddForm] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState();
  const [searchInputs, setSearchInputs] = React.useState({
    roleName: "",
    description: "",
    updatedBy: "",
  });
  const [updateRowErrors, setUpdateRowErrors] = React.useState({
    roleName: "",
  });
  const [addform, setAddForm] = React.useState({
    roleName: "",
    tenantId: "",
    description: "",
  });

  const [addformErrors, setAddformErrors] = React.useState({
    roleName: "",
    tenantId: "",
  });
  const [option, setOption] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [noData, setNoData] = React.useState("");
  const [roles, setRoles] = React.useState([]);
  const [tenants, setTenants] = React.useState([]);

  const [deletePopup, setDeletePopup] = React.useState(false);
  const headCells = [
    {
      id: "roleName",
      numeric: false,
      disablePadding: false,
      filterOption: true,
      label: "Role Name",
      searchType: "text",
      mandatory: true,
      sort: true,
    },

    {
      id: "updatedBy",
      numeric: false,
      disablePadding: false,
      filterOption: true,
      label: "Updated By",
      searchType: "text",
      mandatory: false,
      sort: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      mandatory: true,
      filterOption: false,
      sort: false,
    },
    {
      id: "update",
      numeric: false,
      disablePadding: false,
      label: "Update",
      mandatory: true,
      filterOption: false,
      sort: false,
    },
  ];

  const handleSearchInputs = (key, value) => {
    setPage(0);
    setSearchInputs({ ...searchInputs, [key]: value });
  };

  const handleOptionClick = (value) => {
    setOption(option === value ? "" : value);
  };

  const handleColumnChange = (id) => {
    setDisableColumns(
      disableColumns.includes(id)
        ? disableColumns.filter((c) => c !== id)
        : [...disableColumns, id]
    );
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleFilterColumns = (id) => {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        roleName: "",
        description: "",
        updatedBy: "",
      });
    } else {
      setFilterColumns([...filterColumns, id]);
    }
    setOption("");
  };

  const removeInput = (input) => {
    setFilterColumns(filterColumns.filter((column) => column !== input));
    setSearchInputs({ ...searchInputs, [input.id]: "" });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleKeyDownValue = (event, key) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    const allowedChars = /^[a-zA-Z0-9\s]$/;

    if (!allowedKeys.includes(event.key) && !allowedChars.test(event.key)) {
      event.preventDefault();
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function handleDeletePopup() {
    setDeletePopup((pop) => !pop);
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleEditClick(row) {
    setUpdateRow(row);
    setUpdatePopup(true);
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = roles.map((n) => n.roleId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  function handleUpdateForm(key, value) {
    setUpdateRowErrors({ ...updateRowErrors, [key]: "" });
    setUpdateRow({ ...updateRow, [key]: value });
  }
  function handleAddForm(key, value) {
    setAddformErrors({ ...addformErrors, [key]: "" });
    setAddForm({
      ...addform,
      [key]: value,
    });
  }
  const handleOpen = () => setOpenAddForm(true);
  const handleClose = () => {
    setAddForm({
      roleName: "",
      tenantId: "",
      description: "",
    });
    setAddformErrors({
      roleName: "",
      tenantId: "",
    });
    setOpenAddForm(false);
  };
  function handleCloseUpdatePopup() {
    setUpdatePopup(false);
  }
  function handleUpdate() {
    let error = false;
    if (updateRow.roleName.length === 0) {
      error = true;
    }
    if (error) {
      if (updateRow.roleName.length === 0) {
        setUpdateRowErrors((errors) => ({
          ...errors,
          roleName: t("role_validation"),
        }));
      }
    } else {
      updateRole();
    }
  }
  const updateRole = async () => {
    let Url = "roles/updateRole/" + updateRow.roleId;
    let type = "PATCH";
    let body = {
      ...updateRow,
      updatedBy: `${localStorage.getItem("firstName")} ${localStorage.getItem(
        "lastName"
      )}`,
    };
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      handleCloseUpdatePopup();
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };

  function handleActiveDeactivePopup(roleId, isActive) {
    setIsActive(isActive);
    setSelectedRow(roleId);
    setActivateDeactivePopUp((pop) => !pop);
  }
  function handleAlertOk() {
    getRoles();
    handleAlertPopup();
  }

  function handleAddRole() {
    let error = false;
    if (addform.roleName.length === 0 || addform.tenantId.length === 0) {
      error = true;
    }
    if (error) {
      if (addform.roleName === "") {
        setAddformErrors((errors) => ({
          ...errors,
          roleName: t("role_validation"),
        }));
      }
      if (addform.tenantId === "") {
        setAddformErrors((errors) => ({
          ...errors,
          tenantId: t("tenant_validation"),
        }));
      }
    } else {
      addRole();
    }
  }
  const addRole = async () => {
    let Url = "roles/createRole";
    let type = "POST";
    let body = addform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      handleClose();
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };

  const getRoles = async () => {
    let Url = `roles/getAllRolesWithPagination?pagenumber=${
      page + 1
    }&pageSize=${rowsPerPage}&roleName=${searchInputs.roleName}&description=${
      searchInputs.description
    }&updatedBy=${
      searchInputs.updatedBy
    }&sortBy=${orderBy}&sortDirection=${order}`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res && res?.data) {
      setRoles(res?.data?.response);
      setCount(res?.data?.count);
      setNoData(res?.data?.count === 0 ? "No Data Available" : "");
    }
  };
  const getTenants = async () => {
    let Url = "api/v1.0/tenants/getAllTenantIdsWithTenantsNamesAsList";
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.status) {
      setTenants(res?.data);
    }
  };
  const activateDeactivateAccount = async () => {
    let Url = `roles/activateOrDeactivateRole/${selectedRow}?isActive=${
      isActive ? 0 : 1
    }`;
    let type = "PUT";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      handleAlertPopup();
      setAlertMessage(res.data);
    } else {
      setStatus(false);
      setAlertMessage(res.data);
      handleAlertPopup();
    }
    setActivateDeactivePopUp(false);
  };
  const deleterole = async () => {
    let Url = "roles/deleteRole/" + selected[0];
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      setSelected([]);
      //handleDeletePopup();
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
  };
  React.useEffect(() => {
    if (refresh) {
      getTenants();
      getRoles();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getTenants();
  }, []);

  React.useEffect(() => {
    getRoles();
  }, [searchInputs, page, rowsPerPage, order, orderBy]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;
  const [filterState, setFilterState] = React.useState([
    { key: "roleName", value: "" },
    { key: "description", value: "" },
    { key: "updatedBy", value: "" },
  ]);
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "",
    }));
  };

  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      roleName: "",
      description: "",
      updatedBy: "",
    });
    setFilterState([
      { key: "roleName", value: "" },
      { key: "description", value: "" },
      { key: "updatedBy", value: "" },
    ]);
  };
  return (
    <Box sx={{ width: "100%" }}>
      {loader && <FullLoader />}
      <div className="d-flex align-items-center justify-content-between">
        <div className="pagetitle">Roles</div>
        <div className="d-flex align-items-center gap-3 ">
            <div className="Export-Button bgcolor-blue" onClick={handleOpen}>
              <i class="fa-solid fa-plus" />
              Add
            </div>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between cardFilter">
        <div>
          <DisplayFilterInputs
            searchInputs={searchInputs}
            clearFilterValue={clearFilterValue}
            clearInputValue={clearInputValue}
            resetStates={resetStates}
          />
        </div>
        <div>
          <material.Box className="d-flex align-items-center gap-3">
            <div>
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("filter")}
              >
                <i className="fa-solid fa-sliders"></i>
                <span className="ms-1">Filter</span>
              </div>
              {option === "filter" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="filterBox">
                    <FilterTable
                      headCells={headCells}
                      handleFilterColumns={handleFilterColumns}
                      filterColumns={filterColumns}
                      handleOptionClick={handleOptionClick}
                      updateFilter={updateFilter}
                      syncFilterStateToSearchInputs={
                        syncFilterStateToSearchInputs
                      }
                      filterState={filterState} // Pass filter state to FilterTable
                      setFilterState={setFilterState} // Pass the state setter
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            <div className="position-relative">
              <div
                className="filter-filter bgcolor-blue"
                onClick={() => handleOptionClick("column")}
              >
                <i
                  className="fa-solid fa-bars"
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                ></i>
                <span className="ms-1">Column</span>
              </div>
              {option === "column" && (
                <material.ClickAwayListener
                  onClickAway={() => handleOptionClick("")}
                >
                  <div className="columnBox">
                    <ColumnsBox
                      headCells={headCells}
                      handelChange={() => {}} // Implement your column change logic
                      disableColumns={[]} // Define your disabled columns if necessary
                    />
                  </div>
                </material.ClickAwayListener>
              )}
            </div>
            {selected.length > 0 && (
              <div className="deletediv bg-red " onClick={deleterole}>
                <i className="fa-regular fa-trash-can delete-icon"></i>
              </div>
            )}
          </material.Box>
        </div>
      </div>
      <Paper sx={{ width: "100%", borderRadius: "14px" }}>
        <TableContainer
          className="table_body_height"
          style={{
            borderRadius: "14px",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className="overflow-auto"
          >
            <TableHeadRow
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={roles.length}
              disableColumns={disableColumns}
            />
            <material.TableBody>
              {roles.map((row, index) => {
                const isItemSelected = isSelected(row.roleId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <material.TableRow tabIndex={-1} key={row.roleId}>
                    <material.TableCell padding="checkbox">
                      <material.Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.roleId)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </material.TableCell>
                    {!disableColumns.includes("roleName") && (
                      <material.TableCell
                        id={labelId}
                        style={{ width: "300px" }}
                        scope="row"
                        padding="normal"
                      >
                        {row.roleName}
                      </material.TableCell>
                    )}

                    {!disableColumns.includes("updatedBy") && (
                      <material.TableCell style={{ width: "350px" }}>
                        {row.updatedBy}
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("status") && (
                      <material.TableCell style={{ width: "300px" }}>
                        <material.Switch
                          checked={row.isActive}
                          onClick={() =>
                            handleActiveDeactivePopup(row.roleId, row.isActive)
                          }
                          size="small"
                        />
                      </material.TableCell>
                    )}
                    {!disableColumns.includes("update") && (
                      <material.TableCell>
                        <i
                          className="fa-solid fa-pencil action-icons"
                          style={{ color: "#2DCC70" }}
                          onClick={() => handleEditClick(row)}
                        ></i>
                      </material.TableCell>
                    )}
                  </material.TableRow>
                );
              })}
              {roles.length === 0 && (
                <material.TableRow style={{ height: 40 }}>
                  <material.TableCell colSpan={8} className="saas-nodata-row">
                    {noData}
                  </material.TableCell>
                </material.TableRow>
              )}
            </material.TableBody>
          </Table>
        </TableContainer>
        <div className="position-relative">
          {roles.length > 0 && (
            <CustomTablePagination
              rowsPerPageOptions={[10, 15, 20]}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </Paper>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={t("ok")}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={openAddForm}
        title={"Add Role"}
        message={
          <RoleAddForm
            addform={addform}
            handleAddForm={handleAddForm}
            tenants={tenants}
            addformErrors={addformErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Add"}
        handleClickBtn1={handleClose}
        handleClickBtn2={handleAddRole}
        popUpWidth="width-100"
      />
      <PopUp
        title={"Update Role"}
        message={
          <RoleUpdateForm
            updateRow={updateRow}
            handleUpdateForm={handleUpdateForm}
            tenants={tenants}
            updateRowErrors={updateRowErrors}
          />
        }
        open={updatePopup}
        popUpWidth="popUpMaxWidth width-100"
        btn1={true}
        btn1Color={"cancel-btn"}
        btn1Text={"Cancel"}
        handleClickBtn1={handleCloseUpdatePopup}
        btn2={true}
        btn2Color={"submit-btn"}
        btn2Text={t("update")}
        handleClickBtn2={handleUpdate}
      />
      <PopUp
        open={activeDeactivePopUp}
        message={isActive ? "Do you want to Deactivate the account" : "Do you want to Activate the account"}
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={t("no")}
        btn2Text={t("yes")}
        handleClickBtn1={handleActiveDeactivePopup}
        title={"Confirm"}
        handleClickBtn2={activateDeactivateAccount}
        popUpWidth="width-100"
      />
      <PopUp
        open={deletePopup}
        title={"Confirm"}
        message={"Do you want to delete these items"}
        btn1={true}
        btn1Text={"cancel"}
        btn1Color={"cancel-btn"}
        handleClickBtn1={handleDeletePopup}
        btn2={true}
        btn2Text={"Delete"}
        btn2Color={"bg-danger"}
        handleClickBtn2={deleterole}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={alertPopup}
        handleClosePopUp={handleAlertPopup}
        title={t(status === true ? "SUCCESS" : "FAILURE")}
        message={alertMessage}
        btn1={true}
        btn1Text={t("ok")}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleAlertOk}
        popUpWidth={"width-100"}
      />
      {sessionExpire && <SessionExpire />}
    </Box>
  );
}
