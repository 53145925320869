import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { handleKeyDownNumbers } from "../../common/helpers";

function UnlockConnector20({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    connectorId: "",
    evseId: "",
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    connectorId: "",
    evseId: "",
  });
  function handleOnChange(key, value) {
    setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
    setInputFields({ ...inputFields, [key]: value });
  }
  function handleSubmit() {
    let error = false;
    if (!inputFields.connectorId || !inputFields.evseId) {
      error = true;
    }
    if (error) {
      if (!inputFields.connectorId) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          connectorId: "Connector Id is Mandatory",
        }));
      }
      if (!inputFields.evseId) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          evseId: "EVSE Id is Mandatory",
        }));
      }
    }
    let payLoad = {
      connectorId: parseInt(inputFields.connectorId),
      evseId: parseInt(inputFields.evseId),
    };
    handleSubmitOperation(error, payLoad);
  }
  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Id (Integer) *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.connectorId}
                  className="w-100"
                  required
                  variant="outlined"
                  onKeyDown={handleKeyDownNumbers}
                  value={inputFields.connectorId}
                  size="small"
                  helperText={inputFieldsValidation.connectorId}
                  onChange={(e) => {
                    handleOnChange("connectorId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>EVSE Id (Integer) *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.evseId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.evseId}
                  onKeyDown={handleKeyDownNumbers}
                  size="small"
                  helperText={inputFieldsValidation.evseId}
                  onChange={(e) => {
                    handleOnChange("evseId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          className="submit-btn"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default UnlockConnector20;
