import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import React from "react";
import TableHeadRow from "../../components/TableHeadRow";
import { useTranslation } from "react-i18next";
import { resize } from "../../common/helpers";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import PopUp from "../../components/PopUp";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function Versions({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState([]);
  const [versionData, setVersionData] = React.useState([]);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [noData, setNoData] = React.useState("");

  const headCells = [
    {
      id: "version",
      numeric: false,
      disablePadding: false,
      label: "Version ",
      mandatory: true,
      filterOption: false,
      sort: false,
      // maxWidth: 20,
    },
    {
      id: "url",
      numeric: false,
      disablePadding: false,
      label: "URL ",
      mandatory: true,
      filterOption: true,
      sort: false,
      minWidth: 150,
    },
    {
      id: "Update",
      numeric: false,
      disablePadding: false,
      label: "Update ",
      mandatory: true,
      filterOption: true,
      sort: false,
      // minWidth: 150,
    },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = versionData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    // setuser(newSelected[0]);
  };
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const getErrorCodes = async () => {
    let Url = "cpo/versions";
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setVersionData(res?.data);
      //setCount(res?.count);
      setNoData(res?.count === 0 ? "No Data Available" : "");
    }
  };
  React.useEffect(() => {
    if (refresh) {
      getErrorCodes();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  React.useEffect(() => {
    getErrorCodes();
  }, []);
  const deleteVersions = () => {
    setLoader(true);
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}cpo/versions`, {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(selected),
    })
      .then((resp) => {
        setLoader(false);
        if (resp.status === 401) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        setLoader(false);
        if (res?.statusCode === 1000) {
          setStatusMessage("SUCCESS");
          setAlertMessage(res?.data);
          setSelected([]);
          handleAlertPopup();
          getErrorCodes();
        } else {
          setStatusMessage("FAILURE");
          setAlertMessage(res?.data);
          // setSelected([]);
          handleAlertPopup();
          //getErrorCodes();
        }
      });
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        {loader && <Loader />}
        <div className="d-flex align-items-center justify-content-between">
          <div className="pagetitle">Versions</div>
          <div className="d-flex align-items-center gap-3">
            <Link to="/addVersion">
              <div className="Export-Button bgcolor-blue">
                <i class="fa-solid fa-plus" />
                Add
              </div>
            </Link>
            <div className="Export-Button bgcolor-blue">
              <i className="fa-solid fa-arrow-up-from-bracket" />
              Export
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end cardFilter">
          <div>
            <Box className="d-flex align-items-center gap-3">
              {selected.length > 0 && (
                <div className="deletediv bg-red " onClick={deleteVersions}>
                  <i class="fa-regular fa-trash-can delete-icon"></i>
                </div>
              )}
            </Box>
          </div>
        </div>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: "14px" }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <TableHeadRow
                headCells={headCells}
                numSelected={selected.length}
                // order={order}
                // orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                // onRequestSort={handleRequestSort}
                rowCount={versionData.length}
                disableColumns={[]}
              />
              <TableBody>
                {versionData.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      // hover
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.userId}
                      // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        // component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Link
                          to={"/versionendpoint"}
                          className="linkUnderLineHover"
                          state={row.version}
                        >
                          {row.version}
                        </Link>
                      </TableCell>
                      <TableCell>{row.url}</TableCell>

                      <TableCell>
                        <Link to={"/editVersion"} state={row}>
                        <i className="fa-solid fa-pencil action-icons" style={{ color: "#2DCC70" }}></i>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {versionData.length === 0 && (
                  <TableRow style={{ height: 40 }}>
                    <TableCell colSpan={8} className="saas-nodata-row">
                      {noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <PopUp
          open={alertPopup}
          title={statusMessage}
          message={alertMessage}
          btn1={true}
          btn1Text={"Ok"}
          btn1Color={"submit-btn"}
          handleClickBtn1={handleAlertPopup}
          popUpWidth={"width-100"}
        />
        {sessionExpire && <SessionExpire />}
      </Box>
    </div>
  );
}
