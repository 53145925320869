import * as React from "react";
import * as material from "@mui/material";
import * as icons from "@mui/icons-material";
import { alpha } from "@mui/material/styles";
import moment from "moment";
import FilterInputs from "../../components/FilterInputs";
import FilterTable from "../../components/FilterTable";
import ColumnsBox from "../../components/ColumnsBox";
import TableHeadRow from "../../components/TableHeadRow";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";
import { searchOperations } from "../../common/helpers";
import CustomTablePagination from "../../components/CustomTablePagination";
import PopUp from "../../components/PopUp";
import DisplayFilterInputs from "../../components/DisplayFilterInputs";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
const headCells = [
  {
    id: "authorizationId",
    numeric: false,
    disablePadding: true,
    label: "Authorization ID",
    mandatory: true,
    filterOption: true,
    sort: true,
    minWidth: 130,
  },
  {
    id: "evseId",
    numeric: false,
    disablePadding: true,
    label: "EVSE ID",
    mandatory: false,
    filterOption: true,
    sort: true,
    minWidth: 100,
  },
  {
    id: "protocolVersion",
    numeric: false,
    disablePadding: true,
    label: "Protocol Version",
    mandatory: true,
    filterOption: true,
    sort: true,
    minWidth: 135,
  },
  {
    id: "listVersion",
    numeric: false,
    disablePadding: true,
    label: "List Version",
    mandatory: false,
    filterOption: true,
    sort: true,
    minWidth: 130,
  },
  {
    id: "tenantName",
    numeric: false,
    disablePadding: true,
    label: "Tenant Name",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 130,
  },
  {
    id: "updateType",
    numeric: false,
    disablePadding: true,
    label: "Update Type",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 110,
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created At",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 160,
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Updated At",
    mandatory: false,
    filterOption: false,
    sort: true,
    minWidth: 160,
  },
  {
    id: "expiryDate",
    numeric: false,
    disablePadding: true,
    label: "Expiry Date",
    mandatory: false,
    filterOption: true,
    searchType: "date",
    sort: true,
    minWidth: 160,
  },
  {
    id: "updateStatus",
    numeric: false,
    disablePadding: true,
    label: "Update Status",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 120,
  },
  {
    id: "update",
    numeric: false,
    disablePadding: true,
    label: "Update",
    mandatory: true,
    filterOption: false,
    sort: true,
    minWidth: 120,
  },
];
function AuthorizationLocalList({ locationName }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("authorizationIds");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selected, setSelected] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [disableColumns, setDisableColumns] = React.useState([]);
  const [filterColumns, setFilterColumns] = React.useState([]);
  const [option, setOption] = React.useState("");
  const [searchInputs, setSearchInputs] = React.useState({
    evseId: "",
    expiryDate: "",
    authorizationId: "",
    protocolVersion: "",
    listVersion: "",
  });
  const [filterState, setFilterState] = React.useState([
    { key: "evseId", value: "" },
    { key: "expiryDate", value: "" },
    { key: "authorizationId", value: "" },
    { key: "protocolVersion", value: "" },
    { key: "listVersion", value: "" },
  ]);
  const [localAuthList, setLocalAuthList] = React.useState([]);
  const [sessionExpire, setSessionExpire] = React.useState(false);
  const [localAuthField, setLocalAuthField] = React.useState({
    protocolVersion: "",
    evseId: "",
    authorizationId: "",
    expiryDate: "",
    listVersion: "",
    updateType: "",
    updateStatus: "",
  });
  const [localAuthFieldValidation, setLocalAuthFieldValidation] =
    React.useState({
      protocolVersion: "",
      evseId: "",
      listVersion: "",
      authorizationId: "",
    });
  const [addForm, setAddForm] = React.useState(false);
  const [noData, setNoData] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [editForm, setEditForm] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertPopup, setAlertPopup] = React.useState(false);
  React.useEffect(() => {
    getAuthorizationLocalList();
  }, [page, order, orderBy, rowsPerPage, searchInputs]);

  const getAuthorizationLocalList = async () => {
    const searchParams = buildSearchParams();
    const payload = {
      params: searchParams,
      pageNo: page + 1,
      size: rowsPerPage,
      sortBy: "authorizationIds",
      sortDir: order,
    };
    let Url = `localauthlist/search`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      payload,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    // fetch(basePathone + "localauthlist/search", {
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify(payload),
    // })
    //   .then((resp) => {
    //     if (resp.status === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return resp.json();
    //     }
    //   })
    //   .then((res) => {
    setLocalAuthList(res.data);
    setCount(res.count);
    setNoData(res?.count === 0 ? "No Data Available" : "");
    // });
  };
  const updateFilter = (key, newValue) => {
    setFilterState((prevState) =>
      prevState.map((filter) =>
        filter.key === key ? { ...filter, value: newValue } : filter
      )
    );
  };
  const syncFilterStateToSearchInputs = () => {
    const newSearchInputs = filterState.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    setSearchInputs(newSearchInputs);
  };
  // Function to clear a specific input value by key
  const clearInputValue = (key) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [key]: "", // Set the specified key's value to an empty string
    }));
  };
  // Function to clear a specific filter value
  const clearFilterValue = (key) => {
    setFilterState((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key ? { ...filter, value: "" } : filter
      )
    );
  };
  const resetStates = () => {
    setSearchInputs({
      evseId: "",
      expiryDate: "",
      authorizationId: "",
      protocolVersion: "",
      listVersion: "",
    });
    setFilterState([
      { key: "evseId", value: "" },
      { key: "expiryDate", value: "" },
      { key: "authorizationId", value: "" },
      { key: "protocolVersion", value: "" },
      { key: "listVersion", value: "" },
    ]);
  };
  const addAuthorizationLocalList = async () => {
    let payload = {
      ...localAuthField,
      tenantId: localStorage.getItem("tenantId"),
      chargingStationId: locationName,
    };
    let Url = `localauthlists`;
    let type = "POST";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      payload,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    // fetch(basePathone + "localauthlist", {
    //   method: "POST",
    //   body: JSON.stringify(payload),
    //   headers: headers,
    // })
    //   .then((resp) => {
    //     if (resp.status === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return resp.json();
    //     }
    //   })
    //   .then((res) => {
    setAlertMessage(res.message);
    handleAlertPopup();
    // });
  };
  const updateAuthorizationLocalList = async () => {
    let payload = {
      ...localAuthField,
      tenantId: localStorage.getItem("tenantId"),
      chargingStationId: locationName,
    };
    let Url = "localauthlist/" + localAuthField.id;
    let type = "PUT";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      payload,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    // fetch(basePathone + "localauthlist/" + localAuthField.id, {
    //   method: "PUT",
    //   body: JSON.stringify(payload),
    //   headers: headers,
    // })
    //   .then((resp) => {
    //     if (resp.status === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return resp.json();
    //     }
    //   })
    //   .then((res) => {
    setAlertMessage(res.message);
    handleAlertPopup();
    // });
  };
  const deleteAuthorizationLocalList = async () => {
    let Url = `localauthlist/deleteLocalAuthLists`;
    let type = "DELETE";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      selected,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    // fetch(basePathone + "localauthlist/deleteLocalAuthList", {
    //   method: "DELETE",
    //   headers: headers,
    //   body: JSON.stringify(selected),
    // })
    //   .then((resp) => {
    //     if (resp.status === 401) {
    //       setSessionExpire(true);
    //     } else {
    //       return resp.json();
    //     }
    //   })
    //   .then((res) => {
    if (res?.status) {
      setSelected([]);
      setAlertMessage(res.message);
      handleAlertPopup();
    }
    // });
  };
  function buildSearchParams() {
    const params = Object.keys(searchInputs)
      .filter((key) => searchInputs[key])
      .map((key) => {
        if (key === "protocolVersion" || key === "evseId") {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.EQUALITY,
          };
        } else {
          return {
            key: key,
            value: searchInputs[key],
            operation: searchOperations.CONTAINS,
          };
        }
      });
    // Adding chargingSessionId to the params
    params.push({
      key: "chargingStationId",
      value: locationName,
      operation: searchOperations.EQUALITY,
    });
    return params;
  }
  function handleSubmit() {
    let error = false;
    if (
      !localAuthField.authorizationId ||
      !localAuthField.evseId ||
      !localAuthField.listVersion ||
      !localAuthField.protocolVersion
    ) {
      error = true;
    }
    if (error) {
      if (!localAuthField.authorizationId) {
        setLocalAuthFieldValidation((validation) => ({
          ...validation,
          authorizationId: "Authorization Id is Mandatory",
        }));
      }
      if (!localAuthField.evseId) {
        setLocalAuthFieldValidation((validation) => ({
          ...validation,
          evseId: "EVSE Id is Mandatory",
        }));
      }
      if (!localAuthField.listVersion) {
        setLocalAuthFieldValidation((validation) => ({
          ...validation,
          listVersion: "List Version is Mandatory",
        }));
      }
      if (!localAuthField.protocolVersion) {
        setLocalAuthFieldValidation((validation) => ({
          ...validation,
          protocolVersion: "Protocol Version is Mandatory",
        }));
      }
    }
    if (!error) {
      if (addForm) addAuthorizationLocalList();
      if (editForm) updateAuthorizationLocalList();
    }
  }
  function handleCancelButton() {
    setLocalAuthField({
      protocolVersion: "",
      evseId: "",
      authorizationId: "",
      expiryDate: "",
      listVersion: "",
      updateType: "",
      updateStatus: "",
    });
    setLocalAuthFieldValidation({
      protocolVersion: "",
      evseId: "",
      listVersion: "",
      authorizationId: "",
    });
    setAddForm(false);
    setEditForm(false);
  }
  function handelChange(id) {
    if (disableColumns.includes(id)) {
      setDisableColumns((columns) => columns.filter((c) => id !== c));
    } else setDisableColumns([...disableColumns, id]);
  }
  function handleLocalFieldChange(key, value) {
    setLocalAuthFieldValidation({ ...localAuthFieldValidation, [key]: "" });
    setLocalAuthField({ ...localAuthField, [key]: value });
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleAlertOk() {
    getAuthorizationLocalList();
    setAddForm(false);
    setEditForm(false);
    setLocalAuthField({
      protocolVersion: "",
      evseId: "",
      authorizationId: "",
      expiryDate: "",
      listVersion: "",
      updateType: "",
      updateStatus: "",
    });
    handleAlertPopup();
  }
  function handleEditClick(row) {
    setLocalAuthField(row);
    setEditForm(true);
  }
  function handleOptionClick(value) {
    if (option === "") setOption(value);
    else setOption("");
  }
  function handleFilterColumns(id) {
    if (id === "removeAll") {
      setFilterColumns([]);
      setSearchInputs({
        evseId: "",
        expiryDate: "",
        authorizationId: "",
        protocolVersion: "",
        listVersion: "",
      });
    } else setFilterColumns([...filterColumns, id]);
    setOption("");
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = localAuthList.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  if (!addForm && !editForm) {
    return (
      <material.Box sx={{ width: "100%" }}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="pagetitle"></div>
          <div className="Export-Button bgcolor-blue">
            <i className="fa-solid fa-arrow-up-from-bracket" />
            Export
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between cardFilter">
          <div>
            <DisplayFilterInputs
              searchInputs={searchInputs}
              clearFilterValue={clearFilterValue}
              clearInputValue={clearInputValue}
              resetStates={resetStates}
            />
          </div>
          <div>
            <material.Box className="d-flex align-items-center gap-3">
              <div>
                <div
                  className="filter-filter bgcolor-blue"
                  onClick={() => handleOptionClick("filter")}
                >
                  <i class="fa-solid fa-sliders"></i>
                  <span className="ms-1">Filter</span>
                </div>
                {option === "filter" && (
                  <material.ClickAwayListener
                    onClickAway={() => handleOptionClick("")}
                  >
                    <div className="filterBox">
                      <FilterTable
                        headCells={headCells}
                        handleFilterColumns={handleFilterColumns}
                        filterColumns={filterColumns}
                        handleOptionClick={handleOptionClick}
                        updateFilter={updateFilter}
                        filterState={filterState}
                        syncFilterStateToSearchInputs={
                          syncFilterStateToSearchInputs
                        }
                      />
                    </div>
                  </material.ClickAwayListener>
                )}
              </div>
              <div className="position-relative">
                <div
                  className="filter-filter bgcolor-blue"
                  onClick={() => handleOptionClick("column")}
                >
                  <i
                    class="fa-solid fa-bars"
                    style={{
                      transform: "rotate(-90deg)",
                    }}
                  ></i>
                  <span className="ms-1">Column</span>
                </div>
                {option === "column" && (
                  <material.ClickAwayListener
                    onClickAway={() => handleOptionClick("")}
                  >
                    <div className="columnBox">
                      <ColumnsBox
                        headCells={headCells}
                        handelChange={handelChange}
                        disableColumns={disableColumns}
                      />
                    </div>
                  </material.ClickAwayListener>
                )}
              </div>
              {selected.length > 0 && (
                <div
                  className="deletediv bg-red "
                  onClick={deleteAuthorizationLocalList}
                >
                  <i class="fa-regular fa-trash-can delete-icon"></i>
                </div>
              )}
            </material.Box>
          </div>
        </div>
        <material.Paper sx={{ width: "100%", mb: 2 }}>
          <material.TableContainer>
            <material.Table
              stickyHeader
              aria-label="sticky table"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
              className="overflow-auto"
            >
              <TableHeadRow
                headCells={headCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                rowCount={localAuthList?.length}
                disableColumns={disableColumns}
              />
              <material.TableBody>
                {localAuthList?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <material.TableRow tabIndex={-1} key={row.id}>
                      <material.TableCell padding="checkbox">
                        <material.Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </material.TableCell>
                      {!disableColumns.includes("authorizationId") && (
                        <material.TableCell
                          // component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row?.authorizationId}
                        </material.TableCell>
                      )}
                      {!disableColumns.includes("evseId") && (
                        <material.TableCell>{row?.evseId}</material.TableCell>
                      )}
                      {!disableColumns.includes("protocolVersion") && (
                        <material.TableCell>
                          {row?.protocolVersion}
                        </material.TableCell>
                      )}
                      {!disableColumns.includes("listVersion") && (
                        <material.TableCell>
                          {row?.listVersion}
                        </material.TableCell>
                      )}
                      {!disableColumns.includes("tenantName") && (
                        <material.TableCell>
                          {row?.tenantName}
                        </material.TableCell>
                      )}
                      {!disableColumns.includes("updateType") && (
                        <material.TableCell>
                          {row?.updateType}
                        </material.TableCell>
                      )}
                      {!disableColumns.includes("createdAt") && (
                        <material.TableCell>
                          {moment(row?.createdAt).format("YYYY-MM-DD hh:mm A")}
                        </material.TableCell>
                      )}
                      {!disableColumns.includes("updatedAt") && (
                        <material.TableCell>
                          {moment(row?.updatedAt).format("YYYY-MM-DD hh:mm A")}
                        </material.TableCell>
                      )}
                      {!disableColumns.includes("expiryDate") && (
                        <material.TableCell>
                          {" "}
                          {moment(row?.expiryDate).format("YYYY-MM-DD hh:mm A")}
                        </material.TableCell>
                      )}
                      {!disableColumns.includes("updateStatus") && (
                        <material.TableCell>
                          {row?.updateStatus}
                        </material.TableCell>
                      )}
                      <material.TableCell>
                        <material.IconButton>
                          <icons.Edit
                            color="primary"
                            onClick={() => handleEditClick(row)}
                          />
                        </material.IconButton>
                      </material.TableCell>
                    </material.TableRow>
                  );
                })}
                {localAuthList?.length === 0 && (
                  <material.TableRow style={{ height: 40 }}>
                    <material.TableCell
                      colSpan={11}
                      className="saas-nodata-row"
                    >
                      {noData}
                    </material.TableCell>
                  </material.TableRow>
                )}
              </material.TableBody>
            </material.Table>
          </material.TableContainer>
          <div className="position-relative">
            {localAuthList?.length > 0 && (
              <CustomTablePagination
                rowsPerPageOptions={[10, 15, 20]}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </div>
        </material.Paper>
        <PopUp
          open={alertPopup}
          title={"Alert"}
          message={alertMessage}
          btn1={true}
          btn1Color={"bg-primary"}
          btn1Text={"Ok"}
          handleClickBtn1={handleAlertOk}
          popUpWidth={"width-100"}
        />
        {sessionExpire && <SessionExpire />}
      </material.Box>
    );
  }
  if (addForm || editForm) {
    return (
      <div className="mt-5">
        <div className="mb-4">
          <span className="saas-boldHeading fs-15">
            {addForm ? "Add " : "Edit "}Authorization Local List
          </span>
        </div>
        <material.Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <material.CardContent className="cardPadding">
            <div className="row row-gap-3">
              <div className="col-4">
                <material.FormControl className="simpleInput">
                  <material.FormLabel>
                    <b>Authorization Id *</b>
                  </material.FormLabel>
                  <material.TextField
                    required
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={localAuthField?.authorizationId}
                    onChange={(e) =>
                      handleLocalFieldChange("authorizationId", e.target.value)
                    }
                    error={localAuthFieldValidation.authorizationId}
                    helperText={localAuthFieldValidation.authorizationId}
                  />
                </material.FormControl>
              </div>
              <div className="col-4">
                <material.FormControl className="simpleInput">
                  <material.FormLabel>
                    <b>EVSE Id *</b>
                  </material.FormLabel>
                  <material.TextField
                    required
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={localAuthField?.evseId}
                    onChange={(e) =>
                      handleLocalFieldChange("evseId", e.target.value)
                    }
                    error={localAuthFieldValidation.evseId}
                    helperText={localAuthFieldValidation.evseId}
                  />
                </material.FormControl>
              </div>
              <div className="col-4">
                <material.FormControl className="simpleInput">
                  <material.FormLabel>
                    <b>List Version *</b>
                  </material.FormLabel>
                  <material.TextField
                    required
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={localAuthField?.listVersion}
                    onChange={(e) =>
                      handleLocalFieldChange("listVersion", e.target.value)
                    }
                    error={localAuthFieldValidation.listVersion}
                    helperText={localAuthFieldValidation.listVersion}
                  />
                </material.FormControl>
              </div>
              <div className="col-4">
                <material.FormControl className="simpleInput">
                  <material.FormLabel>
                    <b>Protocal Version *</b>
                  </material.FormLabel>
                  <material.TextField
                    select
                    required
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={localAuthField?.protocolVersion}
                    onChange={(e) =>
                      handleLocalFieldChange("protocolVersion", e.target.value)
                    }
                    error={localAuthFieldValidation.protocolVersion}
                    helperText={localAuthFieldValidation.protocolVersion}
                  >
                    <material.MenuItem value="OCPP16">OCPP16</material.MenuItem>
                    <material.MenuItem value="OCPP201">
                      OCPP201
                    </material.MenuItem>
                  </material.TextField>
                </material.FormControl>
              </div>
              <div className="col-4">
                <material.FormControl className="simpleInput">
                  <material.FormLabel>
                    <b>Update Type *</b>
                  </material.FormLabel>
                  <material.TextField
                    select
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={localAuthField?.updateType}
                    onChange={(e) =>
                      handleLocalFieldChange("updateType", e.target.value)
                    }
                  >
                    <material.MenuItem value="Differential">
                      Differential
                    </material.MenuItem>
                    <material.MenuItem value="Full">Full</material.MenuItem>
                  </material.TextField>
                </material.FormControl>
              </div>
              <div className="col-4">
                <material.FormControl className="simpleInput">
                  <material.FormLabel>
                    <b>Update Status *</b>
                  </material.FormLabel>
                  <material.TextField
                    select
                    className="w-100"
                    variant="outlined"
                    size="small"
                    value={localAuthField?.updateStatus}
                    onChange={(e) =>
                      handleLocalFieldChange("updateStatus", e.target.value)
                    }
                  >
                    <material.MenuItem value="Accepted">
                      Accepted
                    </material.MenuItem>
                    <material.MenuItem value="Failed">Failed</material.MenuItem>
                    <material.MenuItem value="NotSupported">
                      NotSupported
                    </material.MenuItem>
                    <material.MenuItem value="VersionMismatch">
                      VersionMismatch
                    </material.MenuItem>
                  </material.TextField>
                </material.FormControl>
              </div>
              <div className="col-4">
                <material.FormControl className="simpleInput">
                  <div className="my-1 fs-15 fw-600">
                    <span>Valid To *</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="px-3 fs-17"
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      border: "1px solid gainsboro",
                    }}
                    value={localAuthField?.expiryDate}
                    onChange={(newDate) => {
                      handleLocalFieldChange("expiryDate", newDate);
                    }}
                  />
                </material.FormControl>
              </div>
            </div>
          </material.CardContent>
        </material.Card>
        <div className="d-flex justify-content-end gap-3 mt-4">
          <material.Button
            size="small"
            color="error"
            variant="contained"
            onClick={handleCancelButton}
            className="cancel-btn"
          >
            Cancel
          </material.Button>
          <material.Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className="submit-btn"
          >
            Submit
          </material.Button>
        </div>
        <PopUp
          open={alertPopup}
          title={"Alert"}
          message={alertMessage}
          btn1={true}
          btn1Color={"bg-primary"}
          btn1Text={"Ok"}
          handleClickBtn1={handleAlertOk}
          popUpWidth={"width-100"}
        />
        {sessionExpire && <SessionExpire />}
      </div>
    );
  }
}

export default AuthorizationLocalList;
