import * as React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";
import Box from "@mui/material/Box";
import { MenuItem, TextField, FormControl, FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import { Link, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { validatePassword } from "../../common/helpers";
import { basePathone } from "../../common/config";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function AddVehicle({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [manufactureList, setManufactureList] = React.useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(false);
  const [addPop, setAddPop] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const togglePasswordVisibility = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  const [addform, setAddForm] = React.useState({
    vehicleName: "",
    oem: "BMW",
    userName: "",
    password: "",
    type: "BATTERY",
    vin: "",
    vehicleId: "",
  });
  const [addformErrors, setAddFormErrors] = React.useState({
    vehicleName: "",
    oem: "",
    userName: "",
    password: "",
    type: "",
    vin: "",
    vehicleId: "",
  });
  function handleClosePop() {
    navigate("/vehicle");
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleAddForm(key, value) {
    setAddFormErrors({ ...addformErrors, [key]: "" });
    setAddForm({ ...addform, [key]: value });
  }
  function handleAddVehicle() {
    let error = false;
    if (
      !addform.password ||
      !addform.type ||
      !addform.vehicleName ||
      !addform.oem ||
      !addform.userName ||
      !addform.vehicleId ||
      !addform.vin ||
      !validatePassword(addform.password)
    ) {
      error = true;
    }
    if (error) {
      if (!addform.password) {
        setAddFormErrors((errors) => ({
          ...errors,
          password: "Password is Mandatory",
        }));
      } else if (!validatePassword(addform.password)) {
        setAddFormErrors((errors) => ({
          ...errors,
          password:
            "Password must be 8-16 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        }));
      }
      if (!addform.userName) {
        setAddFormErrors((errors) => ({
          ...errors,
          userName: "Username is Mandatory",
        }));
      }
      if (!addform.type) {
        setAddFormErrors((errors) => ({
          ...errors,
          type: "EnergyType is Mandatory",
        }));
      }
      if (!addform.oem) {
        setAddFormErrors((errors) => ({
          ...errors,
          oem: "Manufacturer vehicleName is Mandatory",
        }));
      }
      if (!addform.vehicleId) {
        setAddFormErrors((errors) => ({
          ...errors,
          vehicleId: "Vehicle Id is Mandatory",
        }));
      }
      if (!addform.vin) {
        setAddFormErrors((errors) => ({
          ...errors,
          vin: "VIN is Mandatory",
        }));
      }
      if (!addform.vehicleName) {
        setAddFormErrors((errors) => ({
          ...errors,
          vehicleName: "Vehicle Name is Mandatory",
        }));
      }
    }
    if (!error) {
      addVehicle();
    }
  }
  const addVehicle = async () => {
    let Url = "vehicles/create";
    let type = "POST";
    let body = addform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setStatus(true);
      setAlertMessage(res.message);

      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res?.message);
      handleAlertPopup();
    }
  };
  const getManufactures = async () => {
    let Url = "vehicles/dropdown/oem";
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.status) {
      setManufactureList(res?.data);
    }
  };

  React.useEffect(() => {
    getManufactures();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getManufactures();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <div>
      {loader && <FullLoader />}
      <div className="pagetitle">Add Vehicle</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          {sessionExpire && <SessionExpire />}
          <Box>
            <div className="row row-gap-3 mt-3">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Vehicle Name *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    placeholder="Vehicle Name"
                    name="vehicleName"
                    variant="outlined"
                    required
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z0-9\s-]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    value={addform.vehicleName}
                    error={addformErrors.vehicleName}
                    helperText={addformErrors.vehicleName}
                    onChange={(e) => {
                      handleAddForm("vehicleName", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Energy Type *</b>
                  </FormLabel>
                  <TextField
                    select
                    placeholder="Energy Type"
                    className="error"
                    error={addformErrors.type}
                    value={addform?.type}
                    required
                    size="small"
                    helperText={addformErrors.type}
                    onChange={(e) => {
                      handleAddForm("type", e.target.value);
                    }}
                  >
                    <MenuItem value="BATTERY">BATTERY</MenuItem>
                    <MenuItem value="HYBRID">HYBRID</MenuItem>
                  </TextField>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>VIN *</b>
                  </FormLabel>
                  <TextField
                    placeholder="VIN"
                    required
                    className="error"
                    error={addformErrors.vin}
                    variant="outlined"
                    value={addform?.vin}
                    size="small"
                    inputProps={{
                      minLength: 17,
                      maxLength: 17,
                      onKeyDown: (e) => {
                        if (
                          !/^[A-HJ-NPR-Z0-9]$/.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                            "Tab",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      },
                    }}
                    helperText={addformErrors.vin}
                    onChange={(e) => {
                      handleAddForm("vin", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Vehicle ID *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Vehicle ID"
                    vehicleName="vehicleId"
                    variant="outlined"
                    required
                    inputProps={{
                      minLength: 8,
                      maxLength: 8,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    value={addform.vehicleId}
                    error={addformErrors.vehicleId}
                    helperText={addformErrors.vehicleId}
                    onChange={(e) => {
                      handleAddForm("vehicleId", e.target.value);
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Manufacturer *</b>
                  </FormLabel>
                  <TextField
                    placeholder="Manufacturer"
                    select
                    className=" error"
                    error={addformErrors.oem}
                    required
                    variant="outlined"
                    value={addform?.oem}
                    size="small"
                    helperText={addformErrors.oem}
                    onChange={(e) => {
                      handleAddForm("oem", e.target.value);
                    }}
                  >
                    {manufactureList.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
              <p className="mb-1" style={{ fontWeight: 700, marginTop: 10 }}>
                OEM Credentials :
              </p>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>UserName *</b>
                  </FormLabel>
                  <TextField
                    placeholder="UserName"
                    className=" error"
                    error={addformErrors.userName}
                    required
                    type="email"
                    variant="outlined"
                    value={addform?.userName}
                    size="small"
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    helperText={addformErrors.userName}
                    onChange={(e) => {
                      handleAddForm("userName", e.target.value);
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Password *</b>
                  </FormLabel>
                  <TextField
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    className=" style"
                    error={addformErrors.password}
                    required
                    variant="outlined"
                    value={addform?.password}
                    inputProps={{
                      minLength: 8,
                      maxLength: 16,
                    }}
                    size="small"
                    helperText={addformErrors.password}
                    onChange={(e) => {
                      handleAddForm("password", e.target.value);
                      if (addformErrors.password) {
                        setAddFormErrors((errors) => ({
                          ...errors,
                          password: "",
                        }));
                      }
                    }}
                    InputProps={{
                      minLength: 8,
                      maxLength: 16,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="d-flex aligm-items-center justify-content-end gap-4 my-5 me-5 ">
              <Link to="/vehicle">
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  className="cancel-btn"
                >
                  Cancel
                </Button>
              </Link>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleAddVehicle}
                className="submit-btn"
              >
                Add
              </Button>
            </div>
          </Box>
        </CardContent>
      </Card>
      <PopUp
        open={alertPopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={status === true ? handleClosePop : handleAlertPopup}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
