/** @format */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  createTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { blueGrey, grey } from "@mui/material/colors";
import { ThemeProvider } from "@emotion/react";

function PopUp({
  open,
  title,
  message,
  btn1,
  btn2,
  btn3,
  btn1Color,
  btn1Text,
  btn2Color,
  btn2Text,
  btn3Color,
  btn3Text,
  handleClickBtn3,
  handleClickBtn1,
  handleClickBtn2,
  popUpWidth,
}) {
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
  };
  const secondaryColor = createTheme({ palette: { secondary: blueGrey } });
  return (
    <Dialog
      open={open}
      disablePortal={true}
      onClose={handleClose}
      className={popUpWidth}
      sx={{ width: "auto !important" }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <ThemeProvider theme={secondaryColor}>
        <DialogActions className="d-flex">
          {/* <div className="d-flex gap-3 border"> */}
          <div>
            {btn3 && (
              <Button
                variant="contained"
                onClick={handleClickBtn3}
                className={btn3Color}
                // style={{  }}
                // color={btn3Color}
              >
                {btn3Text}
              </Button>
            )}
          </div>
          <div className="d-flex gap-3 justify-content-end">
            {btn1 && (
              <Button
                variant="contained"
                onClick={handleClickBtn1}
                className={btn1Color}
              >
                {btn1Text}
              </Button>
            )}
            {btn2 && (
              <Button
                variant="contained"
                onClick={handleClickBtn2}
                className={btn2Color}
              >
                {btn2Text}
              </Button>
            )}
          </div>
          {/* </div> */}
        </DialogActions>
      </ThemeProvider>
    </Dialog>
  );
}

export default PopUp;
