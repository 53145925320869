import { List, Paper } from "@mui/material";
import * as material from "@mui/material";
import { formatToTimestamp } from "../common/helpers";

function FilterTable({
  headCells,
  handleFilterColumns,
  filterColumns,
  handleOptionClick,
  updateFilter,
  filterState,
  syncFilterStateToSearchInputs,
}) {
  const handleApply = () => {
    syncFilterStateToSearchInputs();
    handleOptionClick("filter");
  };
  return (
    <Paper
      elevation={3}
      className="p-2 w-263 mt-15 pb-4"
      style={{ borderRadius: "14px" }}
    >
      <div className="d-flex align-items-center justify-content-between fs-5 px-2">
        <b>Filters</b>
        <b onClick={() => handleOptionClick("filter")}>
          <i class="fa-solid fa-xmark cursor-Pointer"></i>
        </b>
      </div>
      <List className="ps-1 " style={{ height: "360px", overflowY: "scroll" }}>
        {headCells.map(
          (cells) =>
            !filterColumns.some((col) => cells.id === col.id) &&
            cells.filterOption && (
              <div
                style={{ listStyleType: "none" }}
                key={cells.id}
                className="p-1"
                // onClick={() => handleFilterColumns(cells)}
              >
                <div className="mb-1">
                  {cells?.searchType === "text" && (
                    <div>
                      <div className="my-1 fs-14 fw-600">
                        <span>{cells.label}</span>
                      </div>
                      {filterState.map((filter) => {
                        if (filter.key === cells.id) {
                          return (
                            <div
                              className="filterText d-flex align-items-center"
                              key={filter.key}
                            >
                              <input
                                className="filter-text-field ps-3 fs-13"
                                style={{ paddingRight: "35px" }}
                                type="text"
                                placeholder={"Search " + cells.label}
                                value={filter.value}
                                onChange={(e) =>
                                  updateFilter(cells.id, e.target.value)
                                }
                              />
                              <i className="fa-solid fa-magnifying-glass filter-search-icon"></i>
                            </div>
                          );
                        }
                        // If key does not match, return null to continue iteration
                        return null;
                      })}
                    </div>
                  )}
                  {cells?.searchType === "active" && (
                    <div className="d-flex align-items-center justify-content-between w-237">
                      <div className="my-1 fs-14 fw-600">
                        <span>{cells.label}</span>
                      </div>
                      {filterState.map((filter) => {
                        if (filter.key === cells.id) {
                          return (
                            <material.Switch
                              key={filter.key}
                              checked={filter.value}
                              onChange={(e) =>
                                updateFilter(cells.id, e.target.checked)
                              }
                              size="small"
                            />
                          );
                        }
                        return null; // Skip rendering if keys don't match
                      })}
                    </div>
                  )}
                  {cells?.searchType === "date" && (
                    <div>
                      <div className="my-1 fs-14 fw-600">
                        <span>{cells.label}</span>
                      </div>
                      {filterState.map((filter) => {
                        if (filter.key === cells.id) {
                          return (
                            <input
                              key={filter.key}
                              type="datetime-local"
                              className="filter-text-field px-3"
                              value={filter.value.slice(0, 16)}
                              onChange={(e) => {
                                const inputValue = e.target.value; // 'yyyy-MM-ddTHH:mm'

                                if (!inputValue) {
                                  updateFilter(cells.id, ""); // Handle empty values
                                } else {
                                  const formattedValue =
                                    formatToTimestamp(inputValue);
                                  updateFilter(cells.id, formattedValue); // Update state with formatted timestamp
                                }
                              }}
                            />
                          );
                        }
                        return null; // Skip rendering if keys don't match
                      })}
                    </div>
                  )}
                  {cells?.searchType === "dropdown" && (
                    <div>
                      <div className="my-1 fs-14 fw-600">
                        <span>{cells.label}</span>
                      </div>
                      {filterState.map((filter) => {
                        if (filter.key === cells.id) {
                          return (
                            <div
                              className="filterText d-flex align-items-center"
                              key={filter.key}
                            >
                              <select
                                style={{ width: "227px" }}
                                className="filter-text-field ps-3 fs-14 filter_DropDown"
                                value={filter.value}
                                onChange={(e) =>
                                  updateFilter(cells.id, e.target.value)
                                }
                              >
                                {cells.dropdownOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <i class="fa-solid fa-caret-down filter-search-icon"></i>
                            </div>
                          );
                        }
                        // If key does not match, return null to continue iteration
                        return null;
                      })}
                    </div>
                  )}
                </div>
              </div>
            )
        )}
      </List>
      <div
        className=" bgcolor-blue Apply-button cursor-Pointer"
        onClick={handleApply}
      >
        Apply
      </div>
    </Paper>
  );
}

export default FilterTable;
