import moment from "moment";

function ViewInventoryForm({ viewRow }) {
  return (
    <div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">Charging Station</b>
        <span>:</span>
        <span>{viewRow.evsn}</span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">MF Date</b>
        <span>:</span>
        <span>
          {moment(viewRow.manufacturingDate).format("DD-MMM-YYYY hh:mm A")}
        </span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">Model</b>
        <span>:</span>
        <span>{viewRow.modelName}</span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">Part No.</b>
        <span>:</span>
        <span>{viewRow.partNumber}</span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">MES Serial No.</b>
        <span>:</span>
        <span>{viewRow.mesSerialNumber}</span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">FW Version</b>
        <span>:</span>
        <span>{viewRow.firmwareVersion}</span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">OCPP Password</b>
        <span>:</span>
        <span>{viewRow.ocppPassword}</span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">Vendor Code</b>
        <span>:</span>
        <span>{viewRow.vendorCode}</span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">Access Code</b>
        <span>:</span>
        <span>{viewRow.accessCode}</span>
      </div>

      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">Master Password</b>
        <span>:</span>
        <span>{viewRow.masterPassword}</span>
      </div>
    </div>
  );
}

export default ViewInventoryForm;
