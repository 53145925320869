import Button from "@mui/material/Button";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Card, CardContent, Switch } from "@mui/material";

export default function TariffViewPage({ refresh, setRefresh }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [energySourceList, setEnergySourceList] = React.useState([]);
  const [environImpactList, setEnvironImpactList] = React.useState([]);
  const [priceConfigList, setPriceConfigList] = React.useState([]);
  const [restrictionsObj, setRestrictionsObj] = React.useState({});
  const [energyMixObj, setEnergyMixObj] = React.useState({});

  React.useEffect(() => {
    setData(location.state);
    setEnergyMixObj(location.state?.energy_mix);
    setEnergySourceList(location.state?.energy_mix.energy_sources);
    setEnvironImpactList(location.state?.energy_mix.environ_impact);
    setPriceConfigList(
      location.state.elements.flatMap((element) => element?.price_components)
    );
    setRestrictionsObj(location.state.elements[0]?.restrictions);
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setData(location.state);
      setEnergyMixObj(location.state?.energy_mix);
      setEnergySourceList(location.state?.energy_mix.energy_sources);
      setEnvironImpactList(location.state?.energy_mix.environ_impact);
      setPriceConfigList(
        location.state.elements.flatMap((element) => element?.price_components)
      );
      setRestrictionsObj(location.state.elements[0]?.restrictions);
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);

  return (
    <div>
      <div className="w-100 mb-4 mx-0 p-0 d-flex align-items-center justify-content-between">
        <div className="pagetitle">Tariff View </div>
        <div>
          <Button
            className="submit-btn"
            variant="contained"
            size="small"
            onClick={() => navigate("/tariffs")}
          >
            <ArrowBackIosIcon fontSize="10px" />
            Back
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-4 ">
          <div className="p-4 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "45%" }}>
                Energy Product Name
              </span>
              <span>:</span>
              <span>{energyMixObj?.energy_product_name}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "45%" }}>
                Supplier Name
              </span>
              <span>:</span>
              <span>{energyMixObj?.supplier_name}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "45%" }}>
                Is Green Energy
              </span>
              <span>:</span>
              <span>
                <Switch
                  disabled
                  checked={energyMixObj?.is_green_energy}
                  size="small"
                />
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "45%" }}>
                Tariff Alt Url
              </span>
              <span>:</span>
              <span>{data?.tariff_alt_url}</span>
            </div>
          </div>
        </div>
        <div className="col-4 ">
          <div className="p-4 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Start Date
              </span>
              <span>:</span>
              <span>
                {moment(data?.startDateTime).format("YYYY-MM-DD hh:mm A")}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                End Date
              </span>
              <span>:</span>
              <span>
                {moment(data?.endDateTime).format("YYYY-MM-DD hh:mm A")}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Type
              </span>
              <span>:</span>
              <span>{data?.type}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Currency
              </span>
              <span>:</span>
              <span>{data?.currency}</span>
            </div>
          </div>
        </div>
        <div className="col-4 ">
          <div className="p-4 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Max Price (inclVat)
              </span>
              <span>:</span>
              <span>{data?.maxPrice?.inclVat}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Max Price (exclVat)
              </span>
              <span>:</span>
              <span>{data?.maxPrice?.exclVat}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Min Price (inclVat)
              </span>
              <span>:</span>
              <span>{data?.minPrice?.inclVat}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Min Price (exclVat)
              </span>
              <span>:</span>
              <span>{data?.minPrice?.exclVat}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3 mx-0 p-0 pagetitle ">Restrictions</div>
      <div className="row">
        <div className="col-6 ">
          <div className="py-4 px-5 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Start Date
              </span>
              <span>:</span>
              <span>
                {moment(restrictionsObj?.start_date).format("YYYY-MM-DD")}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Start Time
              </span>

              <span>:</span>
              <span>
                {moment(restrictionsObj?.start_time).format(" hh:mm A")}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Min kWh
              </span>
              <span>:</span>
              <span>{restrictionsObj?.min_kwh}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Min Power
              </span>
              <span>:</span>
              <span>{restrictionsObj?.min_power}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Min Duration
              </span>
              <span>:</span>
              <span>{restrictionsObj?.min_duration}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Days Of week
              </span>
              <span>:</span>
              <span>{restrictionsObj?.day_of_week}</span>
            </div>
          </div>
        </div>
        <div className="col-6 ">
          <div className="py-4 px-5 border br-10 cardBackground h-100">
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                End Date
              </span>
              <span>:</span>
              <span>
                {moment(restrictionsObj?.end_date).format("YYYY-MM-DD")}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                End Time
              </span>
              <span>:</span>
              <span>
                {moment(restrictionsObj?.end_time).format(" hh:mm A")}
              </span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Max kWh
              </span>
              <span>:</span>
              <span>{restrictionsObj?.max_kwh}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Max Power
              </span>
              <span>:</span>
              <span>{restrictionsObj?.max_power}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Max Duration
              </span>
              <span>:</span>
              <span>{restrictionsObj?.max_duration}</span>
            </div>
            <div className=" w-100 d-flex align-items-center gap-3 mb-2">
              <span className="saas-boldHeading" style={{ width: "50%" }}>
                Max Power
              </span>
              <span>:</span>
              <span>{restrictionsObj?.max_power}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-start">
          <div className=" my-3 p-0 pagetitle ">Price Configurations</div>
        </div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <table class="table  table-box-shadow mt-3">
              <thead>
                <tr class="table-primary ">
                  <th scope="col">Type</th>
                  <th scope="col">Price</th>
                  <th scope="col">Vat</th>
                  <th scope="col">Step-Size</th>
                </tr>
              </thead>
              {priceConfigList.length > 0 ? (
                <tbody>
                  {priceConfigList.map((list, index) => (
                    <tr key={index}>
                      <td>{list.type}</td>
                      <td>{list.price}</td>
                      <td>{list.vat}</td>
                      <td>{list.step_size}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colspan="5">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </CardContent>
        </Card>
      </div>
      <div>
        <div className="d-flex justify-content-start">
          <div className=" my-3 p-0 pagetitle ">Energy Sources</div>
        </div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <table class="table table-box-shadow mt-3 ">
              <thead>
                <tr class="table-primary">
                  <th scope="col">Source</th>
                  <th scope="col">Percentage</th>
                </tr>
              </thead>
              {energySourceList.length > 0 ? (
                <tbody>
                  {energySourceList.map((list, index) => (
                    <tr key={index}>
                      <td>{list.energSource}</td>
                      <td>{list.percentage}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colspan="5">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </CardContent>
        </Card>
      </div>
      <div>
        <div className="d-flex justify-content-start">
          <div className=" my-3 p-0 pagetitle ">Environment Impact</div>
        </div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <table class="table  table-box-shadow mt-3 ">
              <thead>
                <tr class="table-primary">
                  <th scope="col">Category</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              {environImpactList.length > 0 ? (
                <tbody>
                  {environImpactList.map((list, index) => (
                    <tr key={index}>
                      <td>{list.source}</td>
                      <td>{list.amount}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colspan="5">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </CardContent>
        </Card>
      </div>

      {/* {sessionExpire && <SessionExpire />}  */}
    </div>
  );
}
