import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import { handlePercentageKeyDown } from "../../common/helpers";

export default function EnergySourcesPopUp({
  energySourceObj,
  handleESchange,
  EsErrors,
  energySourceDrop,
}) {
  return (
    <div className="py-3">
      <Box>
        <div className="row gap-3 ">
          <div className="col-4 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Source *</b>
              </FormLabel>
              <TextField
                select
                required
                className=" me-3 error"
                variant="outlined"
                size="small"
                value={energySourceObj.energSource}
                helperText={EsErrors.energSource}
                error={EsErrors.energSource}
                onChange={(e) => handleESchange("energSource", e.target.value)}
              >
                {energySourceDrop.map((list, index) => (
                  <MenuItem key={index} value={list}>
                    {list}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
          <div className="col-4 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Percentage *</b>
              </FormLabel>
              <TextField
                error={EsErrors.percentage}
                className=" me-3 error no-arrows"
                required
                step="0.1"
                variant="outlined"
                value={energySourceObj.percentage}
                inputProps={{ maxLength: 5 }}
                onKeyDown={handlePercentageKeyDown}
                size="small"
                helperText={EsErrors.percentage}
                onChange={(e) => {
                  handleESchange("percentage", e.target.value);
                }}
              />
            </FormControl>
          </div>
        </div>
      </Box>
    </div>
  );
}
