import * as React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";

import Box from "@mui/material/Box";
import { MenuItem, TextField, FormControl, FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { resize } from "../../common/helpers";
import { basePathone } from "../../common/config";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";

export default function EditVehicle({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState("");
  const location = useLocation();
  const [updatePop, setUpdatePop] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [manufactureList, setManufactureList] = React.useState([]);
  const togglePasswordVisibility = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [editform, setEditForm] = React.useState({
    vehicleName: "",
    oem: "",
    userName: "",
    password: "",
    type: "",
    vin: "",
    vehicleId: "",
  });
  const [editformErrors, setEditFormErrors] = React.useState({
    vehicleName: "",
    oem: "",
    userName: "",
    Password: "",
    type: "",
    vin: "",
    vehicleId: "",
  });
  const navigate = useNavigate();
  function handleClosePopup() {
    navigate("/vehicle");
  }
  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleeditform(key, value) {
    setEditFormErrors({ ...editformErrors, [key]: "" });
    setEditForm({ ...editform, [key]: value });
  }
  function handleEditVehicle() {
    let error = false;
    if (
      !editform.password ||
      !editform.type ||
      !editform.vehicleName ||
      !editform.oem ||
      !editform.userName ||
      !editform.vehicleId ||
      !editform.vin
    ) {
      error = true;
    }
    if (error) {
      if (!editform.password) {
        setEditFormErrors((errors) => ({
          ...errors,
          password: "Password is Mandatory",
        }));
      } else if (!/^\S{8,16}$/.test(editform.password)) {
        setEditFormErrors((errors) => ({
          ...errors,
          password: "Password must be 8-16 characters long with no spaces",
        }));
      }
      if (!editform.userName) {
        setEditFormErrors((errors) => ({
          ...errors,
          userName: "Username is Mandatory",
        }));
      }
      if (!editform.type) {
        setEditFormErrors((errors) => ({
          ...errors,
          type: "EnergyType is Mandatory",
        }));
      }
      if (!editform.vehicleId) {
        setEditFormErrors((errors) => ({
          ...errors,
          vehicleId: "Vehicle Id is Mandatory",
        }));
      }
      if (!editform.vin) {
        setEditFormErrors((errors) => ({
          ...errors,
          vin: "VIN is Mandatory",
        }));
      }
      if (!editform.vehicleName) {
        setEditFormErrors((errors) => ({
          ...errors,
          vehicleName: "Vehicle Name is Mandatory",
        }));
      }
    }
    if (!error) {
      updateVehicle();
    }
  }
  const updateVehicle = async () => {
    let Url = `vehicles/update/${editform.id}`;
    let type = "PATCH";
    let body = editform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res && res?.status) {
      setStatus(true);
      setAlertMessage(res.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res?.data);
      handleAlertPopup();
    }
  };
  const getManufactures = async () => {
    let Url = `vehicles/dropdown/oem`;
    let type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.status) {
      setManufactureList(res?.data);
    }
  };

  React.useEffect(() => {
    setEditForm(location.state);
    getManufactures();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      setEditForm(location.state);
      getManufactures();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <div>
      {loader && <FullLoader />}
      <div className="pagetitle">Update Vehicle</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          {sessionExpire && <SessionExpire />}
          <Box>
            <div className="row row-gap-3 mt-3">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Vehicle Name *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Vehicle Name"
                    vehicleName="vehicleName"
                    variant="outlined"
                    required
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z\s]/.test(e.key)) {
                          e.preventDefault(); // Prevent non-letter input
                        }
                      },
                    }}
                    value={editform.vehicleName}
                    error={editformErrors.vehicleName}
                    helperText={editformErrors.vehicleName}
                    onChange={(e) => {
                      handleeditform("vehicleName", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Energy Type *</b>
                  </FormLabel>
                  <TextField
                    select
                    placeholder="Energy Type"
                    className=" error"
                    error={editformErrors.type}
                    value={editform?.type}
                    required
                    size="small"
                    helperText={editformErrors.type}
                    onChange={(e) => {
                      handleeditform("type", e.target.value);
                    }}
                  >
                    {/* {roles.map((role) => ( */}
                    <MenuItem value="BATTERY">BATTERY</MenuItem>
                    <MenuItem value="HYBRID">HYBRID</MenuItem>
                  </TextField>
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>VIN *</b>
                  </FormLabel>
                  <TextField
                    placeholder="VIN"
                    required
                    className=" error"
                    error={editformErrors.vin}
                    variant="outlined"
                    value={editform?.vin}
                    size="small"
                    inputProps={{
                      minLength: 17,
                      maxLength: 17,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    helperText={editformErrors.vin}
                    onChange={(e) => {
                      handleeditform("vin", e.target.value);
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Vehicle ID *</b>
                  </FormLabel>
                  <TextField
                    size="small"
                    className="error"
                    placeholder="Vehicle ID"
                    vehicleName="vehicleId"
                    variant="outlined"
                    required
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    value={editform.vehicleId}
                    error={editformErrors.vehicleId}
                    helperText={editformErrors.vehicleId}
                    onChange={(e) => {
                      handleeditform("vehicleId", e.target.value);
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Manufacturer *</b>
                  </FormLabel>
                  <TextField
                    placeholder="Manufacturer"
                    select
                    className=" error"
                    error={editformErrors.oem}
                    required
                    variant="outlined"
                    value={editform?.oem}
                    size="small"
                    helperText={editformErrors.oem}
                    onChange={(e) => {
                      handleeditform("oem", e.target.value);
                    }}
                  >
                    {manufactureList.map((list, index) => (
                      <MenuItem key={index} value={list}>
                        {list}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
              <p className="mb-1" style={{ fontWeight: 700, marginTop: 10 }}>
                OEM Credentials :
              </p>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Manufacturer *</b>
                  </FormLabel>
                  <TextField
                    placeholder="Username"
                    className=" error"
                    error={editformErrors.userName}
                    required
                    type="email"
                    variant="outlined"
                    value={editform?.userName}
                    size="small"
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      onKeyDown: (e) => {
                        if (/[^A-Za-z]/.test(e.key)) {
                          e.preventDefault();
                        }
                      },
                    }}
                    helperText={editformErrors.userName}
                    onChange={(e) => {
                      handleeditform("userName", e.target.value);
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Manufacturer *</b>
                  </FormLabel>
                  <TextField
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    className=" style"
                    error={editformErrors.password}
                    required
                    variant="outlined"
                    value={editform?.password}
                    inputProps={{
                      minLength: 8,
                      maxLength: 16,
                    }}
                    size="small"
                    helperText={editformErrors.password}
                    onChange={(e) => {
                      handleeditform("password", e.target.value);
                      if (editformErrors.password) {
                        setEditFormErrors((errors) => ({
                          ...errors,
                          password: "",
                        }));
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="d-flex aligm-items-center justify-content-end gap-4 my-5 me-5 ">
              <Link to="/vehicle">
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  className="cancel-btn"
                >
                  Cancel
                </Button>
              </Link>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleEditVehicle}
                className="submit-btn"
              >
                Update
              </Button>
            </div>
          </Box>
        </CardContent>
      </Card>
      <PopUp
        open={alertPopup}
        title={status === true ? "Success" : "Failure"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={status === true ? handleClosePopup : handleAlertPopup}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
