import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import SessionExpire from "../../components/SessionExpire";
import { Link, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import { useTranslation } from "react-i18next";
import { basePath } from "../../common/config";
import * as helpers from "../../common/helpers";
import FullLoader from "../../components/FullLoader";
import { headers } from "../../common/Headers";
import { handleApi_call } from "../../common/CommonAPI";
export default function AddUser({ refresh, setRefresh }) {
  const { t } = useTranslation();
  const [sessionExpire, setSessionExpire] = React.useState("");
  const [statusFalsePop, setStatusFalsepop] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [roles, setRoles] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [tenants, setTenants] = React.useState([]);
  const [addform, setAddForm] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    roleId: "",
    tenantId: "",
    active: 1,
    userId: "",
  });
  const [addformErrors, setAddformErrors] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    roleId: "",
    tenantId: "",
  });

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handleStatusFalsePop() {
    setStatusFalsepop((pop) => !pop);
  }

  const navigate = useNavigate();
  function handleClosePopup() {
    navigate("/userManagement");
  }

  function handleAddForm(key, value) {
    if (key === "mobile") {
      if (!isNaN(value)) {
        setAddformErrors({ ...addformErrors, [key]: "" });
        setAddForm({
          ...addform,
          [key]: value,
        });
      }
    } else if (key === "firstName" || key === "lastName") {
      if (value === "" || helpers.validateName(value)) {
        setAddformErrors({ ...addformErrors, [key]: "" });
        setAddForm({
          ...addform,
          [key]: value,
        });
      } else {
        setAddformErrors({
          ...addformErrors,
          [key]: "Special characters are not allowed",
        });
      }
    } else {
      setAddformErrors({ ...addformErrors, [key]: "" });
      setAddForm({
        ...addform,
        [key]: value,
      });
    }

    if (key === "email") {
      const valid = helpers.handleEmail(value);
      if (valid) {
        setAddformErrors({
          ...addformErrors,
          [key]: t("valid_email"),
        });
      } else {
        setAddformErrors({ ...addformErrors, [key]: "" });
      }
    }
  }

  function handleAddUser() {
    let error = false;
    if (
      addform.firstName.length === 0 ||
      addform.lastName.length === 0 ||
      addform.email.length === 0 ||
      addform.password.length === 0 ||
      addform.mobile.length === 0 ||
      addform.roleId.length === 0 ||
      addform.tenantId.length === 0 ||
      !helpers.validatePassword(addform.password) ||
      addform.mobile.length !== 10
    ) {
      error = true;
    }
    if (error) {
      if (addform.firstName === "") {
        setAddformErrors((errors) => ({
          ...errors,
          firstName: t("name_validation"),
        }));
      }
      if (addform.lastName === "") {
        setAddformErrors((errors) => ({
          ...errors,
          lastName: t("name_validation"),
        }));
      }
      if (addform.email === "") {
        setAddformErrors((errors) => ({
          ...errors,
          email: t("email_required"),
        }));
      }
      if (addform.roleId === "") {
        setAddformErrors((errors) => ({
          ...errors,
          roleId: t("role_required"),
        }));
      }
      if (addform.password === "") {
        setAddformErrors((errors) => ({
          ...errors,
          password: t("password_required"),
        }));
      }
      if (addform.tenantId === "") {
        setAddformErrors((errors) => ({
          ...errors,
          tenantId: t("tenant_validation"),
        }));
      }
      if (addform.mobile === "") {
        setAddformErrors((errors) => ({
          ...errors,
          mobile: t("number_required"),
        }));
      }
      if (
        !helpers.validatePassword(addform.password) &&
        addform.password.length > 0
      ) {
        setAddformErrors((errors) => ({
          ...errors,
          password: t("password_validation"),
        }));
        //setPasswordError(t("password_validation"));
      }
      if (addform.mobile.length !== 10 && addform.mobile.length > 0) {
        setAddformErrors((errors) => ({
          ...errors,
          mobile: t("phonenumber_validation"),
        }));
      }
    } else {
      addUser();
    }
  }

  const addUser = async () => {
    let Url = "user/signup";
    let type = "POST";
    let body = addform;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.status) {
      setStatus(true);
      setAlertMessage(res?.message);
      handleAlertPopup();
    } else {
      setStatus(false);
      setAlertMessage(res?.message);
      handleAlertPopup();
    }
  };

  const getRoles = async () => {
    const Url = "roles/getAllRoles";
    const type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res && res?.status) {
      setRoles(res.data);
    }
  };

  const getTenants = async () => {
    const Url = "tenants";
    const type = "GET";
    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    setTenants(res);
  };

  React.useEffect(() => {
    getRoles();
    getTenants();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getRoles();
      getTenants();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  return (
    <div>
      {loader && <FullLoader />}
      <div className="pagetitle">Add User</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          {sessionExpire && <SessionExpire />}
          <Box>
            <div className="row row-gap-3 mt-3">
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>First Name *</b>
                  </FormLabel>
                  <TextField
                    type="text"
                    error={addformErrors.firstName}
                    className="w-100"
                    placeholder="First Name"
                    required
                    inputProps={{
                      minLength: 5,
                      maxLength: 20,
                    }}
                    variant="outlined"
                    value={addform?.firstName}
                    size="small"
                    helperText={addformErrors.firstName}
                    onChange={(e) => {
                      handleAddForm("firstName", e.target.value);
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Last Name *</b>
                  </FormLabel>
                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Last Name"
                    error={addformErrors.lastName}
                    required
                    variant="outlined"
                    inputProps={{
                      minLength: 5,
                      maxLength: 20,
                    }}
                    value={addform?.lastName}
                    size="small"
                    helperText={addformErrors.lastName}
                    onChange={(e) => {
                      handleAddForm("lastName", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Email *</b>
                  </FormLabel>
                  <TextField
                    className="w-100"
                    placeholder="Email"
                    error={addformErrors.email}
                    required
                    type="email"
                    variant="outlined"
                    value={addform?.email}
                    size="small"
                    helperText={addformErrors.email}
                    onChange={(e) => {
                      handleAddForm("email", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Role Name *</b>
                  </FormLabel>
                  <TextField
                    select
                    className="w-100"
                    error={addformErrors.roleId}
                    value={addform?.roleId}
                    placeholder="Role Name"
                    required
                    size="small"
                    helperText={addformErrors.roleId}
                    onChange={(e) => {
                      handleAddForm("roleId", e.target.value);
                    }}
                  >
                    {roles?.map((role) => (
                      <MenuItem key={role.roleId} value={role.roleId}>
                        {role.roleName}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Password *</b>
                  </FormLabel>
                  <TextField
                    type="password"
                    className="w-100"
                    placeholder="Password"
                    error={addformErrors.password}
                    required
                    variant="outlined"
                    value={addform?.password}
                    size="small"
                    inputProps={{
                      minLength: 8,
                      maxLength: 20,
                    }}
                    helperText={addformErrors.password}
                    onChange={(e) => {
                      handleAddForm("password", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              {/* <div className="text-danger fw-bold mt-4">{t(passwordError)}</div> */}
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Mobile *</b>
                  </FormLabel>
                  <TextField
                    type="text"
                    className="w-100"
                    placeholder="Mobile"
                    required
                    error={addformErrors.mobile}
                    variant="outlined"
                    value={addform?.mobile}
                    size="small"
                    inputProps={{
                      maxLength: 10,
                    }}
                    helperText={addformErrors.mobile}
                    onChange={(e) => {
                      handleAddForm("mobile", e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl className="simpleInput">
                  <FormLabel>
                    <b>Tenant Name *</b>
                  </FormLabel>
                  <TextField
                    select
                    className="w-100"
                    placeholder="Tenant Name"
                    error={addformErrors.tenantId}
                    value={addform?.tenantId}
                    required
                    size="small"
                    helperText={addformErrors.tenantId}
                    onChange={(e) => {
                      handleAddForm("tenantId", e.target.value);
                    }}
                  >
                    {tenants.map((tenant) => (
                      <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                        {tenant.tenantName}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            </div>
          </Box>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-end gap-3 my-4">
        <Link to="/userManagement" className="cancle-button">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          size="small"
          color="primary"
          variant="contained"
          className="submit-btn"
          onClick={handleAddUser}
        >
          Add
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        handleClosePopUp={handleClosePopup}
        title={status === true ? "SUCCESS" : "FAILURE"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={status === true ? handleClosePopup : handleAlertPopup}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
