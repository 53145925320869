import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const PowerGraph = ({ powerX, powerY }) => {
  const data = {
    labels: powerX, // Make sure powerX is an array
    datasets: [
      {
        label: "Power (Wh)",
        backgroundColor: window.theme?.primary || "#007bff", // Fallback in case window.theme is undefined
        borderColor: window.theme?.primary || "#007bff",
        hoverBackgroundColor: window.theme?.primary || "#007bff",
        hoverBorderColor: window.theme?.primary || "#007bff",
        data: powerY, // Make sure powerY is an array
        barPercentage: 0.75,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: false,
      },
      x: {
        stacked: false,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <div style={{ width: "650px", height: "323px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default PowerGraph;
