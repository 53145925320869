import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import { handleCurrencyKeydown } from "../../common/helpers";

export default function EnvironImpactPopUp({
  environImpactObj,
  handleEIchange,
  EiErrors,
  categoryDrop,
}) {
  return (
    <div className="py-3">
      <Box>
        <div className="row  gap-2 ">
          <div className="col-4 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Category *</b>
              </FormLabel>
              <TextField
                required
                select
                className=" me-3 error"
                variant="outlined"
                size="small"
                error={EiErrors.source}
                helperText={EiErrors.source}
                value={environImpactObj.source}
                onChange={(e) => handleEIchange("source", e.target.value)}
              >
                {categoryDrop.map((list, index) => (
                  <MenuItem key={index} value={list}>
                    {list}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
          <div className="col-4 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Amount *</b>
              </FormLabel>
              <TextField
                error={EiErrors.amount}
                className=" me-3 error"
                required
                variant="outlined"
                value={environImpactObj.amount}
                onKeyDown={handleCurrencyKeydown}
                size="small"
                helperText={EiErrors.amount}
                onChange={(e) => {
                  handleEIchange("amount", e.target.value);
                }}
              />
            </FormControl>
          </div>
        </div>
      </Box>
    </div>
  );
}
