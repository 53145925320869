import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import moment from "moment";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp";
import SessionExpire from "../../components/SessionExpire";
import EnergySourecsPopUp from "./EnergySourcesPopUp";
import EnvironImpactPopUp from "./EnvironImpactPopUp";
import { basePathone } from "../../common/config";
import FullLoader from "../../components/FullLoader";
import { handleApi_call } from "../../common/CommonAPI";
import { headers } from "../../common/Headers";
import { formatDate } from "../../common/helpers";

export default function EditTariffs({ refresh, setRefresh }) {
  const [sessionExpire, setSessionExpire] = React.useState();
  const [energySourceAddPop, setEnergySourceAddPop] = React.useState(false);
  const [environmentImpactPop, setEnvironmentImpactPop] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [tablepopup, settablepopup] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const [elementsList, setElementsList] = React.useState({});
  const [energySourceDrop, setEnergySourceDrop] = React.useState([]);
  const [categoryDrop, setCategoryDrop] = React.useState([]);
  const [tarrifTypeDrop, setTarrifTypeDrop] = React.useState([]);
  const [currencyDrop, setCurrencyDrop] = React.useState([]);
  const [eSEditPopUp, setESEditPopUp] = React.useState(false);
  const [eIEditPopUp, setEIEditPopUp] = React.useState(false);
  const [energySourceList, setEnergySourceList] = React.useState([]);
  const [statusMessage, setStatusMessage] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [environImpactList, setEnvironImpactList] = React.useState([]);
  let tariffObject = JSON.parse(localStorage.getItem("TariffObject"));
  const [elements, setElements] = React.useState([]);
  // const tariffID = location.state?.id;
  const countryCode = tariffObject?.countryCode;
  const partyId = tariffObject?.partyId;
  const tariffID = tariffObject?.id;
  console.log(location, "dfdsf");
  // console.log(location.state.par);

  const [energySourceObj, setEnergySourceObj] = React.useState({
    energSource: "",
    percentage: "",
  });
  const [EsErrors, setEsErrors] = React.useState({});
  const [environImpactObj, setEnvironImpactObj] = React.useState({
    source: "",
    amount: "",
  });
  const [EiErrors, setEiErrors] = React.useState({});

  const [minPriceObj, setMinPriceObj] = React.useState({
    exclVat: "",
    inclVat: "",
  });
  const [maxPriceObj, setMaxPriceObj] = React.useState({
    exclVat: "",
    inclVat: "",
  });
  const [tariffAltText, setTariffAltText] = React.useState({
    language: "eng",
    text: "",
  });
  const [loader, setLoader] = React.useState(false);
  const [energyMixObj, setEnergyMixObj] = React.useState({
    supplier_name: "",
    energy_product_name: "",
    is_green_energy: "",
  });
  const [formData, setFormData] = React.useState({
    currency: "",
    type: "",
    tariff_alt_url: "",
    reservation: "",
    tariff_alt_text: [],
    minPrice: {},
    maxPrice: {},
    startDateTime: "",
    endDateTime: "",
    energy_mix: {},
  });

  React.useEffect(() => {
    getCategory();
    getEnergySources();
    getelements();
    getTarrifType();
    getCurrencyList();
    getTarrifById();
    getEIByMixIdAndLocId();
    getESByMixIdAndLocId();
  }, []);
  React.useEffect(() => {
    if (refresh) {
      getCategory();
      getEnergySources();
      getelements();
      getTarrifType();
      getCurrencyList();
      getTarrifById();
      getEIByMixIdAndLocId();
      getESByMixIdAndLocId();
      setRefresh(false); // Reset refresh state
    }
  }, [refresh]);
  // drop down Apis

  const getCurrencyList = async () => {
    let Url = `cpo/${2.1}/tariffs/countryAndCurrencies`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      setCurrencyDrop(res?.data);
    }
  };
  const getEnergySources = async () => {
    let Url = `cpo/${2.1}/tariffs/getEnergySource`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setEnergySourceDrop(res?.data);
    }
  };

  const getCategory = async () => {
    let Url = `cpo/${2.1}/tariffs/getEnvironmentalImpact`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setCategoryDrop(res?.data);
    }
  };
  const getTarrifType = async () => {
    let Url = `cpo/${2.1}/tariffs/getTarrifType`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      setTarrifTypeDrop(res?.data);
    }
  };

  //get Tariff By tariff Id
  const getTarrifById = async () => {
    //console.log(location.state);
    let Url = `cpo/${2.1}/tariffs/${countryCode}/${partyId}/${tariffID}`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setFormData(res.data);
      setMinPriceObj(res.data?.minPrice);
      setMaxPriceObj(res.data?.maxPrice);
      setEnergyMixObj(res.data?.energy_mix);
      setTariffAltText(res.data?.tariff_alt_text[0]);
      // setElementsList(res.data?.elements);
    }
  };
  const getelements = async () => {
    let Url = `cpo/${2.1}/tariffs/elements/getElements/${tariffID}`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setElements(res?.data);
    }
  };
  //Energy-Mix EnergySource All Apis
  //ES-EnergySources
  const [rocId, setRocId] = React.useState();
  //Get Energy sources by energy-mix id and Location Id
  const getESByMixIdAndLocId = async () => {
    let Url = `cpo/${2.1}/tariffs/energyMix/getEnergySources/${tariffID}`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode) {
      setEnergySourceList(res?.data);
    }
  };
  //Add EnergySources By mix id And Location Id
  const addESByMixIdAndLocId = async () => {
    let Url = `cpo/${2.1}/tariffs/energyMix/addEnergySource/${tariffID}/${formData?.energy_mix?.id
      }`;
    let type = "POST";
    let body = energySourceObj;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      console.log(location.state);
      setAlertMessage(res?.data);
      handletablepopup();
    } else {
      setStatusMessage("FAILURE");
      // console.log(location.state);
      setAlertMessage(res?.data);
      handletablepopup();
    }
  };
  //Update energy sources By rocId and enrgymixId and Loc Id

  const updateESByMixIdRocIdAndLocId = async () => {
    let Url = `cpo/${2.1}/tariffs/energyMix/updateEnergySource/${tariffID}/${rocId}`;
    let type = "PATCH";
    let body = energySourceObj;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handletablepopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handletablepopup();
    }
  };
  //Delete EnergySource By rocId and mixId and Loc
  const DeleteESByRocIdmixIdAndLocId = async (id) => {
    let Url = `cpo/${2.1}/tariffs/energyMix/deleteEnergySource/${tariffID}/${id}`;
    let type = "DELETE";
    let body = energySourceObj;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );
    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setAlertMessage(res?.data);
      handletablepopup();
    } else {
      setStatusMessage("FAILURE");
      setAlertMessage(res?.data);
      handletablepopup();
    }
  };
  //
  //EI-EnvironImpact Apis
  //   const [energyMixId, setEnergyMixId] = React.useState();
  //Get Energy sources by energy-mix id and Location Id
  const getEIByMixIdAndLocId = async () => {
    let Url = `cpo/${2.1}/tariffs/energyMix/getEnvImpact/${tariffID}`;
    let type = "GET";

    let res = await handleApi_call(
      Url,
      headers,
      type,
      {},
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode === 1000) {
      setStatusMessage("SUCCESS");
      setEnvironImpactList(res?.data);
    } else {
      setStatusMessage("FAILURE");
      setEnvironImpactList(res?.data);
    }
  };
  //Add EnergySources By mix id And Location Id
  const addEIByMixIdAndLocId = async () => {
    let Url = `cpo/${2.1}/tariffs/energyMix/EnvImpact/${tariffID}/${formData?.energy_mix?.id
      }`;
    let type = "POST";
    let body = environImpactObj;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      setAlertMessage(res?.data);
      handletablepopup();
    }
  };
  //Update energy sources By rocId and enrgymixId and Loc Id
  const updateEIByMixIdRocIdAndLocId = async () => {
    let Url = `cpo/${2.1}/tariffs/energyMix/updateEnvImpact/${tariffID}/${rocId}`;
    let type = "PATCH";
    let body = environImpactObj;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      setAlertMessage(res?.data);
      handletablepopup();
    }
  };
  //Delete EnergySource By rocId and mixId and Loc
  const DeleteEIByRocIdmixIdAndLocId = async (id) => {
    let Url = `cpo/${2.1}/tariffs/energyMix/deleteEnvImpact/${tariffID}/${id}`;
    let type = "DELETE";
    let body = environImpactObj;
    let res = await handleApi_call(
      Url,
      headers,
      type,
      body,
      setSessionExpire,
      setAlertMessage,
      handleAlertPopup,
      setLoader
    );

    if (res?.statusCode) {
      setAlertMessage(res?.data);
      handletablepopup();
    }
  };
  //
  const handleEsSubmit = () => {
    const errors = {};
    if (!energySourceObj.energSource)
      errors.energSource = "please select Source";
    if (!energySourceObj.percentage)
      errors.percentage = " percentage is required";
    setEsErrors(errors);
    return Object.keys(errors).length === 0; // return true or false
  };

  const handleEIFormValidations = () => {
    const errors = {};
    if (!environImpactObj.source) errors.source = "please select Source";
    if (!environImpactObj.amount) errors.amount = " amount is required";
    setEiErrors(errors);
    return Object.keys(errors).length === 0; // return true or false
  };
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol, must be http:// or https://
    "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))" + // domain name or IP address
    "(\\:[0-9]{1,5})?(\\/.*)?$", // optional port and path
    "i"
  );
  const handleValidations = () => {
    let errors = {};
    if (!energyMixObj.energy_product_name)
      errors.energy_product_name = "Product Name is required";
    if (!energyMixObj.supplier_name)
      errors.supplier_name = "Supplier Name is required";
    if (!formData.currency) errors.currency = "Please select currency type";
    if (!formData.type) errors.type = "Please select type";
    if (!formData.endDateTime) errors.endDateTime = "End Date is required";
    if (!formData.startDateTime)
      errors.startDateTime = "Start Date is required";
    if (!formData.tariff_alt_url)
      errors.tariff_alt_url = "tariff Alt Url is required";
    if (!maxPriceObj.exclVat) errors.MinexclVat = "ExclVat is required";
    if (!maxPriceObj.inclVat) errors.MininclVat = "inclVat is required";
    if (!minPriceObj.exclVat) errors.MaxexclVat = "ExclVat is required";
    if (!minPriceObj.inclVat) errors.MaxinclVat = "inclVat is required";
    //
    if (formData.tariff_alt_url) {
      let valid = urlPattern.test(formData.tariff_alt_url);
      if (!valid) {
        errors.tariff_alt_url = "Enter valid url";
      }
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // return true or false
  };
  function handleESPop() {
    setEsErrors({
      energSource: "",
      percentage: "",
    });
    setEnergySourceObj({
      energSource: "",
      percentage: "",
    });
    setEnergySourceAddPop((pop) => !pop);
  }
  function handleEIPop() {
    setEiErrors({
      source: "",
      amount: "",
    });
    setEnvironImpactObj({
      source: "",
      amount: "",
    });
    setEnvironmentImpactPop((pop) => !pop);
  }
  function handleEsEditPop() {
    setESEditPopUp((pop) => !pop);
    setEsErrors({
      energSource: "",
      percentage: "",
    });
  }
  function handleEIEditPop() {
    setEIEditPopUp((pop) => !pop);
    setEiErrors({
      source: "",
      amount: "",
    });
  }
  function handleEsEditingPopUp(list) {
    setEnergySourceObj(list);
    setRocId(list.id);
    handleEsEditPop();
  }

  function handleEIEditingPopUp(list) {
    setEnvironImpactObj(list);
    setRocId(list.id);
    handleEIEditPop();
  }
  const ESvalidateForm = () => {
    if (handleEsSubmit()) {
      addESByMixIdAndLocId();
      handleESPop();
      setEnergySourceObj({
        energSource: "",
        percentage: "",
      });
    }
  };
  const ESEditvalidateForm = () => {
    if (handleEsSubmit()) {
      updateESByMixIdRocIdAndLocId();
      handleEsEditPop();
      setEnergySourceObj({
        energSource: "",
        percentage: "",
      });
    }
  };
  const EIvalidateForm = () => {
    if (handleEIFormValidations()) {
      addEIByMixIdAndLocId();
      handleEIPop();
      setEnvironImpactObj({
        source: "",
        amount: "",
      });
    }
  };
  const EIEditvalidateForm = () => {
    if (handleEIFormValidations()) {
      updateEIByMixIdRocIdAndLocId();
      handleEIEditPop();
      setEnvironImpactObj({
        source: "",
        amount: "",
      });
    }
  };
  function handleESchange(key, value) {
    setEsErrors({ ...EsErrors, [key]: "" });
    setEnergySourceObj({
      ...energySourceObj,
      [key]: value,
    });
  }
  function handleEIchange(key, value) {
    setEiErrors({ ...EiErrors, [key]: "" });
    setEnvironImpactObj({
      ...environImpactObj,
      [key]: value,
    });
  }
  //handle change functions
  function handleChange(key, value) {
    setErrors({ ...errors, [key]: "" });
    setFormData({
      ...formData,
      [key]: value,
    });
    if (key === "tariff_alt_url") {
      let valid = urlPattern.test(value);
      if (valid) {
        setErrors({ ...errors, [key]: "" });
      } else {
        setErrors({ ...errors, [key]: "Enter valid url" });
      }
    }
  }
  function HandleMinChange(key, value) {
    if (key === "exclVat") {
      setErrors({ ...errors, MinexclVat: "" });
    } else {
      setErrors({ ...errors, MininclVat: "" });
    }
    setMinPriceObj({
      ...minPriceObj,
      [key]: value,
    });
  }
  function HandleMaxChange(key, value) {
    if (key === "exclVat") {
      setErrors({ ...errors, MaxexclVat: "" });
    } else {
      setErrors({ ...errors, MaxinclVat: "" });
    }
    setMaxPriceObj({
      ...maxPriceObj,
      [key]: value,
    });
  }
  function handleEnergyMinChange(key, value) {
    setErrors({ ...errors, [key]: "" });
    if (key === "text") {
      setTariffAltText({ ...tariffAltText, [key]: value });
    } else {
      setEnergyMixObj({
        ...energyMixObj,
        [key]: value,
      });
    }
  }

  //

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  function handletablepopup() {
    settablepopup((pop) => !pop);
  }

  //Edit api
  const EditTariffData = async () => {
    if (handleValidations()) {
      setLoader(true);
      let form = formData;
      form.energy_mix = energyMixObj;
      form.minPrice = minPriceObj;
      form.maxPrice = maxPriceObj;
      // form.tariff_alt_text = [tariffAltText];
      let Url = `cpo/${2.1}/tariffs/updateTariff/${tariffID}`;
      let type = "PUT";
      let body = form;
      let res = await handleApi_call(
        Url,
        headers,
        type,
        body,
        setSessionExpire,
        setAlertMessage,
        handleAlertPopup,
        setLoader
      );
      if (res?.statusCode) {
        setStatusMessage("SUCCESS");
        setAlertMessage(res?.data);
        handleAlertPopup();
      } else {
        setStatusMessage("FAILURE");
        setAlertMessage(res?.data);
        handleAlertPopup();
      }
    }
  };

  return (
    <div className="mb-3">
      <div className="pagetitle">Update Tariff</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <div className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Product Name *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={energyMixObj?.energy_product_name}
                  inputProps={{
                    minLength: 3,
                    maxLength: 30,
                    onKeyDown: (e) => {
                      if (/[^A-Za-z\s]/.test(e.key)) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[0-9]/g, "");
                    handleEnergyMinChange(
                      "energy_product_name",
                      e.target.value
                    );
                  }}
                  error={errors.energy_product_name}
                  helperText={errors.energy_product_name}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Supplier Name *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={energyMixObj?.supplier_name}
                  inputProps={{
                    minLength: 3,
                    maxLength: 30,
                    onKeyDown: (e) => {
                      if (/[^A-Za-z\s]/.test(e.key)) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[0-9]/g, "");
                    handleEnergyMinChange("supplier_name", e.target.value);
                  }}
                  error={errors.supplier_name}
                  helperText={errors.supplier_name}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Type *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={formData.type}
                  error={errors.type}
                  helperText={errors.type}
                  onChange={(e) => handleChange("type", e.target.value)}
                >
                  {tarrifTypeDrop.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <b> Green Energy :</b>
              <Switch
                checked={energyMixObj.is_green_energy}
                onChange={(e) =>
                  handleEnergyMinChange("is_green_energy", e.target.checked)
                }
                size="small"
              />
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Price (exclVat) *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={maxPriceObj.exclVat}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    HandleMaxChange("exclVat", e.target.value);
                  }}
                  error={errors.MaxexclVat}
                  helperText={errors.MaxexclVat}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Max Price (inclVat) *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={maxPriceObj.inclVat}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    HandleMaxChange("inclVat", e.target.value);
                  }}
                  error={errors.MaxinclVat}
                  helperText={errors.MaxinclVat}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Min Price (exclVat) *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={minPriceObj.exclVat}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");
                    HandleMinChange("exclVat", e.target.value);
                  }}
                  error={errors.MinexclVat}
                  helperText={errors.MinexclVat}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Min Price (inclVat) *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={minPriceObj.inclVat}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (
                        !/[0-9.]/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value
                      .replace(/^(\.)/, "0.")
                      .replace(/(\..*?)\..*/g, "$1");

                    HandleMinChange("inclVat", value);
                  }}
                  error={errors.MininclVat}
                  helperText={errors.MininclVat}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Currency *</b>
                </FormLabel>
                <TextField
                  select
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={formData.currency}
                  onChange={(e) => handleChange("currency", e.target.value)}
                  error={errors.currency}
                  helperText={errors.currency}
                >
                  {currencyDrop.map((list, index) => (
                    <MenuItem key={index} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Tarrif Alt Url *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={formData.tariff_alt_url}
                  onChange={(e) =>
                    handleChange("tariff_alt_url", e.target.value)
                  }
                  error={errors.tariff_alt_url}
                  helperText={errors.tariff_alt_url}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Tariff Alt Text *</b>
                </FormLabel>
                <TextField
                  required
                  className="w-100"
                  variant="outlined"
                  size="small"
                  value={tariffAltText?.text}
                  onChange={(e) =>
                    handleEnergyMinChange("text", e.target.value)
                  }
                  error={errors.text}
                  helperText={errors.text}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className=" fs-15 fw-600">
                  <span>Start Date *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={formData.startDateTime}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value); // Convert to Date object
                    handleChange("startDateTime", formatDate(dateValue)); // Pass formatted date
                  }}
                // onChange={(e) => {
                //   handleChange("startDateTime", e.target.value);
                // }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors.startDateTime}
                </p>
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <div className=" fs-15 fw-600">
                  <span>End Date *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={formData.endDateTime}
                  // onChange={(e) => {
                  //   handleChange("endDateTime", e.target.value);
                  // }}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value); // Convert to Date object
                    handleChange("endDateTime", formatDate(dateValue)); // Pass formatted date
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors.endDateTime}
                </p>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      {/* <div className="col-4">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            ampm
            AdapterMoment
            viewRenderers={{
              hours: null,
              minutes: null,
              seconds: null,
            }}
            label="End Date Time"
            inputFormat="DD/MM/YYYY HH:mm"
            value={formData.endDateTime ? moment(formData.endDateTime) : null}
            minDateTime={
              formData.startDateTime
                ? moment(formData.startDateTime).add(1, "minute")
                : null
            }
            className="w-100"
            slots={{
              popper: (props) => (
                <div className="position-relative">
                  <Popper
                    {...props}
                    style={{ zIndex: 1000 }}
                    disablePortal
                    className="position-absolute"
                  />
                </div>
              ),
            }}
            sx={{ width: "96%" }}
            slotProps={{
              textField: {
                size: "small",
                required: "true",
                error: errors.endDateTime,
                helperText: errors.endDateTime,
              },
            }}
            shouldDisableDate={shouldDisableEndDate}
            onChange={(value) => {
              handleChange("endDateTime", value);
              if (
                formData.startDateTime &&
                moment(value).isSameOrBefore(moment(formData.startDateTime))
              ) {
                handleChange("startDateTime", null);
              }
            }}
          />
        </LocalizationProvider>
      </div> */}
      <div>
        <div className="d-flex justify-content-between my-4">
          <div className=" mx-0 p-0 pagetitle">Tariff Elements</div>

          <Link to={"/addElements"} state={tariffID}>
            <div className="Export-Button bgcolor-blue cursor-Pointer">
              <i class="fa-solid fa-plus" />
              Add
            </div>
          </Link>
        </div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <table className="table table-box-shadow mt-3">
              <thead>
                <tr className="table-primary">
                  <th scope="col">ID</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">kWh</th>
                  <th scope="col">Current</th>
                  <th scope="col">Power</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Reservation</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {elements?.length > 0 ? (
                <tbody>
                  {elements.map((list, index) => (
                    <tr key={index}>
                      <td>{list?.id}</td>

                      <td>
                        {list.restrictions?.start_date &&
                          moment(list.restrictions?.start_date).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </td>
                      <td>
                        {list.restrictions?.end_date &&
                          moment(list.restrictions?.end_date).format(
                            "DD MMM YYYY | hh:mm A"
                          )}
                      </td>
                      <td>
                        {list.restrictions?.min_kwh} (min)
                        <br />
                        {list.restrictions?.max_kwh} (max)
                      </td>
                      <td>
                        {list.restrictions?.min_current} (min)
                        <br />
                        {list.restrictions?.max_current} (max)
                      </td>
                      <td>
                        {list.restrictions?.min_power} (min)
                        <br />
                        {list.restrictions?.max_power} (max)
                      </td>
                      <td>
                        {list.restrictions?.min_duration} (min)
                        <br />
                        {list.restrictions?.max_duration} (max)
                      </td>
                      <td>{list.restrictions.reservation || "--"}</td>
                      <td>
                        <Link to="/UpdateElements" state={list?.id}>
                          <i className="fa-solid fa-pencil action-icons"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colSpan="8">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </CardContent>
        </Card>
      </div>

      <div>
        <div className="d-flex justify-content-between my-4">
          <div className=" mx-0 p-0 pagetitle">Energy Sources</div>
          <div
            className="Export-Button bgcolor-blue cursor-Pointer"
            onClick={handleESPop}
          >
            <i class="fa-solid fa-plus" />
            Add
          </div>
        </div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <table class="table table-box-shadow mt-3">
              <thead>
                <tr class="table-primary">
                  <th scope="col">Source</th>
                  <th scope="col">Percentage</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              {energySourceList?.length > 0 ? (
                <tbody>
                  {energySourceList?.map((list, index) => (
                    <tr key={index}>
                      <td>{list.energSource}</td>
                      <td>{list.percentage}</td>
                      <td>
                        <span onClick={() => handleEsEditingPopUp(list)}>
                          <i className="fa-solid fa-pencil action-icons"></i>
                        </span>

                        <span
                          onClick={() => DeleteESByRocIdmixIdAndLocId(list.id)}
                          style={{ color: "red" }}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colspan="5">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </CardContent>
        </Card>
      </div>
      <div>
        <div className="d-flex justify-content-between my-4">
          <div className=" mx-0 p-0 pagetitle ">Environment Impact</div>
          <div
            className="Export-Button bgcolor-blue cursor-Pointer"
            onClick={handleEIPop}
          >
            <i class="fa-solid fa-plus" />
            Add
          </div>
        </div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <table class="table table-box-shadow mt-3">
              <thead>
                <tr class="table-primary">
                  <th scope="col">Category</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              {environImpactList?.length > 0 ? (
                <tbody>
                  {environImpactList?.map((list, index) => (
                    <tr key={index}>
                      <td>{list.source}</td>
                      <td>{list.amount}</td>
                      <td>
                        <span onClick={() => handleEIEditingPopUp(list)}>
                          <i className="fa-solid fa-pencil action-icons"></i>
                        </span>

                        <span
                          onClick={() => DeleteEIByRocIdmixIdAndLocId(list.id)}
                          style={{ color: "red" }}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colspan="5">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </CardContent>
        </Card>
      </div>

      <div className="d-flex justify-content-end gap-3 mt-4">
        <Link to="/tariffs">
          <Button
            size="small"
            color="error"
            variant="contained"
            className="cancel-btn"
          >
            Cancel
          </Button>
        </Link>
        <Button
          className="submit-btn"
          size="small"
          color="primary"
          variant="contained"
          onClick={EditTariffData}
        >
          Update
        </Button>
      </div>
      <PopUp
        open={alertPopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={() => navigate("/tariffs")}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={tablepopup}
        title={statusMessage}
        message={alertMessage}
        btn1={true}
        btn1Color={"submit-btn"}
        btn1Text={"Ok"}
        handleClickBtn1={() => {
          settablepopup(false);
          getEIByMixIdAndLocId();
          getESByMixIdAndLocId();
        }}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={energySourceAddPop}
        handleClosePopUp={handleESPop}
        title={"Add Energy Source"}
        message={
          <EnergySourecsPopUp
            energySourceDrop={energySourceDrop}
            energySourceObj={energySourceObj}
            handleESchange={handleESchange}
            EsErrors={EsErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleESPop}
        handleClickBtn2={ESvalidateForm}
        popUpWidth="width-100"
      />
      <PopUp
        open={eSEditPopUp}
        handleClosePopUp={handleEsEditPop}
        title={"Update Energy Source"}
        message={
          <EnergySourecsPopUp
            energySourceDrop={energySourceDrop}
            energySourceObj={energySourceObj}
            handleESchange={handleESchange}
            EsErrors={EsErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleEsEditPop}
        handleClickBtn2={ESEditvalidateForm}
        popUpWidth="width-100"
      />
      <PopUp
        open={environmentImpactPop}
        handleClosePopUp={handleEIPop}
        title={"Add Environment Impact"}
        message={
          <EnvironImpactPopUp
            categoryDrop={categoryDrop}
            environImpactObj={environImpactObj}
            handleEIchange={handleEIchange}
            EiErrors={EiErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleEIPop}
        handleClickBtn2={EIvalidateForm}
        popUpWidth="width-100"
      />
      <PopUp
        open={eIEditPopUp}
        handleClosePopUp={handleEIEditPop}
        title={"Updatew Environment Impact"}
        message={
          <EnvironImpactPopUp
            categoryDrop={categoryDrop}
            environImpactObj={environImpactObj}
            handleEIchange={handleEIchange}
            EiErrors={EiErrors}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleEIEditPop}
        handleClickBtn2={EIEditvalidateForm}
        popUpWidth="width-100"
      />
      {loader && <FullLoader />}
      {sessionExpire && <SessionExpire />}
    </div>
  );
}
