import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { DatePicker } from "rsuite";
import { FaCalendar } from "react-icons/fa";
import { basePathone } from "../../common/config";
import SessionExpire from "../../components/SessionExpire";

function ReserveNow20({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    connectorType: "",
    id: "",
    idToken: "",
    evseId: "",
    expiryDateTime: "",
  });
  const [sessionExpire, setSessionExpire] = useState(false);
  const [idTagList, setIdTagList] = useState([]);
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    id: "",
    idToken: "",
    expiryDateTime: "",
  });
  React.useEffect(() => {
    getIdTags();
  }, []);
  const getIdTags = () => {
    let headers = {
      "content-type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      "X-TenantID": localStorage.getItem("tenantId"),
    };

    fetch(`${basePathone}api/idtags/all`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        if (
          resp.status === false &&
          resp.message === "Session expired! Please login again!"
        ) {
          setSessionExpire(true);
        } else {
          return resp.json();
        }
      })
      .then((res) => {
        if (res?.status) {
          setIdTagList(res?.data);
        }
      });
  };
  const DateFormatChange = (date) => {
    const localDate = new Date(date); // Create a Date object from the UTC string

    // Extract local time components
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");
    const seconds = String(localDate.getSeconds()).padStart(2, "0");

    // Format it as 'YYYY-MM-DDTHH:mm:ss'
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    // Return the formatted date
    return formattedDate;
  };

  function handleOnChange(key, value) {
    setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
    if (key === "connectorType" || key === "id" || key === "evseId") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFields({ ...inputFields, [key]: value });
      }
    } else {
      setInputFields({ ...inputFields, [key]: value });
    }
  }

  function handleSubmit() {
    let error = false;
    if (
      !inputFields.id ||
      !inputFields.idToken ||
      !inputFields.expiryDateTime
    ) {
      error = true;
    }
    if (error) {
      if (!inputFields.id) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          id: "Reservation Id is Mandatory",
        }));
      }
      if (!inputFields.idToken) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          idToken: "Id Token is Mandatory",
        }));
      }
      if (!inputFields.expiryDateTime) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          expiryDateTime: "Expiry Date is Mandatory",
        }));
      }
    }
    let payLoad = {
      id: parseInt(inputFields.id),
      idToken: inputFields.idToken,
      expiryDateTime: DateFormatChange(inputFields.expiryDateTime),
    };
    if (inputFields.evseId) {
      payLoad.evseId = parseInt(inputFields.evseId);
    }
    if (inputFields.connectorType) {
      payLoad.connectorType = inputFields.connectorType;
    }
    handleSubmitOperation(error, payLoad);
  }
  return (
    <>
      {sessionExpire && <SessionExpire />}
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Type </b>
                </FormLabel>
                <TextField
                  select
                  error={inputFieldsValidation.connectorType}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.connectorType}
                  size="small"
                  helperText={inputFieldsValidation.connectorType}
                  onChange={(e) => {
                    handleOnChange("connectorType", e.target.value);
                  }}
                >
                  <MenuItem value="cCCS1">cCCS1</MenuItem>
                  <MenuItem value="cCCS2">cCCS2</MenuItem>
                  <MenuItem value="cG105">cG105</MenuItem>
                  <MenuItem value="cTesla">cTesla</MenuItem>
                  <MenuItem value="cType1">cType1</MenuItem>
                  <MenuItem value="cType2">cType2</MenuItem>
                  <MenuItem value="s309-1P-16A">s309-1P-16A</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b> Id *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.id}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.id}
                  size="small"
                  helperText={inputFieldsValidation.id}
                  onChange={(e) => {
                    handleOnChange("id", e.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Select IDToken *</b>
                </FormLabel>
                <TextField
                  select
                  error={inputFieldsValidation.idToken}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.idToken}
                  size="small"
                  helperText={inputFieldsValidation.idToken}
                  onChange={(e) => {
                    handleOnChange("idToken", e.target.value);
                  }}
                >
                  {idTagList?.map((list, index) => (
                    <MenuItem key={index} value={list?.idTag}>
                      {list?.idTag}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>EVSE Id </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.evseId}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("evseId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className=" fs-14 fw-600">
                  <span>Expiry Date *</span>
                </div>

                <DatePicker
                  format="dd MMM yyyy hh:mm:ss aa"
                  showMeridiem
                  caretAs={FaCalendar}
                  value={inputFields?.expiryDateTime}
                  onChange={(date) => handleOnChange("expiryDateTime", date)}
                />

                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {inputFieldsValidation.expiryDateTime}
                </p>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default ReserveNow20;
