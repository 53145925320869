import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import PopUp from "../../../components/PopUp";
import { allowLetters } from "../../../common/helpers";
import AddChargingSchedulePopUp from "./AddChargingSchedulePopUp";

export default function AddChargingSchedule({
  row,
  setChargingScheduleList,
  chargingSchedulelist,
}) {
  const [alertPopup, setAlertPopup] = React.useState(false);
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [deleteCharging, setDeleteCharging] = React.useState("");
  const [openAddPopUp, setOpenAddPopUp] = React.useState(false);
  const [indexValue, setIndexValue] = React.useState();
  const [openEditPopUp, setOpenEditPopUp] = React.useState(false);
  const [chargingSchedulePeriod, setChargingSchedulePeriod] = React.useState(
    []
  );
  const [alertMessage, setAlertMessage] = React.useState("");
  const [openstartSchedule, setOpenstartSchedule] = React.useState(false);
  const [chargingPeriod, setChargingPeriod] = React.useState({
    startPeriod: "",
    chargingLimit: "",
    numberPhases: "",
  });
  const [errors2, setErrors2] = React.useState({
    startPeriod: "",
    chargingLimit: "",
    numberPhases: "",
  });
  const [addform, setAddForm] = React.useState({
    chargingScheduleName: "",
    duration: "",
    minChargingRate: "",
    chargingRateUnit: "",
    startSchedule: null,
    chargingSchedulePeriods: "",
  });
  const [errors, setErrors] = React.useState({});
  const handleClose = () => {
    setAddForm({
      chargingScheduleName: "",
      duration: "",
      minChargingRate: "",
      chargingRateUnit: "",
      startSchedule: "",
    });
    setErrors({
      chargingScheduleName: "",
      duration: "",
      minChargingRate: "",
      chargingRateUnit: "",
      startSchedule: "",
    });
  };
  function handleClose2() {
    setChargingPeriod({
      startPeriod: "",
      chargingLimit: "",
      numberPhases: "",
    });
    setErrors2({
      startPeriod: "",
      chargingLimit: "",
      numberPhases: "",
    });
  }
  const handleDeletePopup = () => {
    setDeletePopup((pop) => !pop);
  };
  function handleCloseAddPop() {
    setChargingPeriod({
      startPeriod: "",
      chargingLimit: "",
      numberPhases: "",
    });
    setErrors2({
      startPeriod: "",
      chargingLimit: "",
      numberPhases: "",
    });
    setOpenAddPopUp((pop) => !pop);
  }
  function handleEditPop() {
    setErrors2({
      startPeriod: "",
      chargingLimit: "",
      numberPhases: "",
    });
    setOpenEditPopUp((pop) => !pop);
  }
  function handleOpenAddPop() {
    setOpenAddPopUp((pop) => !pop);
  }

  const deleteSchedule = (list) => {
    setDeleteCharging(list);
    handleDeletePopup();
  };
  const deleteChargingSchedule = () => {
    setChargingSchedulePeriod((prevItems) =>
      prevItems.filter((item) => item !== deleteCharging)
    );
    handleDeletePopup();
  };

  function handleAlertPopup() {
    setAlertPopup((pop) => !pop);
  }
  const navigate = useNavigate();
  function handleClosePopup() {
    handleClose();
    navigate("/addChargerProfile");
  }
  function handlePopErrors() {
    let error = {};
    if (!chargingPeriod.startPeriod)
      error.startPeriod = "Start Period is Mandatory";
    if (!chargingPeriod.chargingLimit)
      error.chargingLimit = "Charging Limit is Mandatory";
    if (!chargingPeriod.numberPhases)
      error.numberPhases = "Number Of Phases is Mandatory";
    setErrors2(error);
    return Object.keys(error).length === 0;
  }
  function handleSubmit2() {
    if (handlePopErrors()) {
      setChargingSchedulePeriod((prevItems) => [...prevItems, chargingPeriod]);
      handleCloseAddPop();
      handleClose2();
    }
  }
  function handleEditSubmit() {
    if (handlePopErrors()) {
      updateChargingSchedule(indexValue, chargingPeriod);
      handleEditPop();
    }
  }
  function handleEditingPopUp(list, index) {
    setChargingPeriod(list);
    setIndexValue(index);
    setOpenEditPopUp((pop) => !pop);
  }
  const updateChargingSchedule = (id, chargingPeriod) => {
    setChargingSchedulePeriod((prevItems) =>
      prevItems.map((item, index) =>
        index === id ? { ...item, ...chargingPeriod } : item
      )
    );
  };

  function handleErrors() {
    let error = {};
    if (!addform.chargingScheduleName)
      error.chargingScheduleName = "ChargingScheduleName is Mandatory";
    if (!addform.duration) error.duration = "Duration is Mandatory";
    if (!addform.chargingRateUnit)
      error.chargingRateUnit = "please select Scheduling Unit";
    if (!addform.startSchedule)
      error.startSchedule = "Select start schedule date";
    if (!addform.minChargingRate)
      error.minChargingRate = "Min Charging Rate is Mandatory";
    setErrors(error);
    return Object.keys(error).length === 0; // Return true if no errors
  }
  function handleSubmit() {
    if (handleErrors()) {
      handleOpenAddPop();
    }
  }
  const handleFinalSubmit = () => {
    if (handleErrors()) {
      let formData = addform;
      formData.chargingSchedulePeriods = chargingSchedulePeriod;
      setChargingScheduleList([...chargingSchedulelist, addform]);
      navigate("/addChargerProfile");
    }
  };

  function handleAddForm(key, value) {
    if (key === "duration" || key === "minChargingRate") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setErrors({ ...errors, [key]: "" });
        setAddForm({
          ...addform,
          [key]: value,
        });
      }
    } else {
      setErrors({ ...errors, [key]: "" });
      setAddForm({
        ...addform,
        [key]: value,
      });
    }
  }
  function handleAddForm2(key, value) {
    if (
      key === "chargingLimit" ||
      key === "numberPhases" ||
      key === "startPeriod"
    ) {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setErrors2({ ...errors2, [key]: "" });
        setChargingPeriod({
          ...chargingPeriod,
          [key]: value,
        });
      }
    } else {
      setErrors2({ ...errors2, [key]: "" });
      setChargingPeriod({
        ...chargingPeriod,
        [key]: value,
      });
    }
  }

  return (
    <div className="mb-2">
      <div className=" mx-0 p-0 pagetitle">Add Charging Schedule</div>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px", marginTop: "14px" }}
      >
        <CardContent className="cardPadding">
          <Box className="row row-gap-3 mt-3">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Charging Schedule Name *</b>
                </FormLabel>
                <TextField
                  required
                  name="chargingScheduleName"
                  value={addform.chargingScheduleName}
                  inputProps={{
                    minLength: 5,
                    maxLength: 30,
                  }}
                  onKeyDown={allowLetters}
                  onChange={(e) => {
                    handleAddForm("chargingScheduleName", e.target.value);
                  }}
                  error={errors.chargingScheduleName}
                  helperText={errors.chargingScheduleName}
                  size="small"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Duration (ms) *</b>
                </FormLabel>
                <TextField
                  required
                  name="duration"
                  value={addform.duration}
                  onChange={(e) => {
                    handleAddForm("duration", e.target.value);
                  }}
                  error={errors.duration}
                  helperText={errors.duration}
                  size="small"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Min Charging Rate *</b>
                </FormLabel>
                <TextField
                  className=" no-arrows"
                  required
                  type="number"
                  step="00.1"
                  name="minChargingRate"
                  value={addform.minChargingRate}
                  onChange={(e) => {
                    handleAddForm("minChargingRate", e.target.value);
                  }}
                  error={errors.minChargingRate}
                  helperText={errors.minChargingRate}
                  size="small"
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Charging Rate Unit *</b>
                </FormLabel>
                <TextField
                  select
                  className=" error"
                  error={errors.chargingRateUnit}
                  value={addform.chargingRateUnit}
                  required
                  size="small"
                  helperText={errors.chargingRateUnit}
                  onChange={(e) => {
                    handleAddForm("chargingRateUnit", e.target.value);
                  }}
                >
                  <MenuItem value="W">W</MenuItem>
                  <MenuItem value="A">A</MenuItem>
                </TextField>
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className=" fs-15 fw-600">
                  <span>Start Schedule *</span>
                </div>
                <input
                  type="datetime-local"
                  className="px-3 fs-17"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "1px solid gainsboro",
                  }}
                  value={addform.startSchedule}
                  onChange={(e) => {
                    handleAddForm("startSchedule", e.target.value);
                  }}
                />
                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors.startSchedule}
                </p>
              </FormControl>
            </div>
          </Box>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-between mt-3 p-0 ">
        <div className=" pagetitle">
          <span>Charging Schedule Period :</span>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className="submit-btn"
          >
            Add
          </Button>
        </div>
      </div>
      <div>
        <Card
          sx={{ minWidth: 275 }}
          style={{ borderRadius: "25px", marginTop: "14px" }}
        >
          <CardContent className="cardPadding">
            <table class="table table-box-shadow mt-3 ">
              <thead>
                <tr class="table-primary">
                  <th scope="col">Start Period (ms)</th>
                  <th scope="col">Charging Limit</th>
                  <th scope="col">Number Phases</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              {chargingSchedulePeriod?.length > 0 ? (
                <tbody>
                  {chargingSchedulePeriod?.map((list, index) => (
                    <tr key={index}>
                      <td>{list.startPeriod}</td>
                      <td>{list.chargingLimit}</td>
                      <td>{list.numberPhases}</td>
                      <td>
                        <span onClick={() => handleEditingPopUp(list, index)}>
                          <i className="fa-solid fa-pencil action-icons"></i>
                        </span>

                        <span
                          onClick={() => deleteSchedule(list)}
                          style={{ color: "red" }}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  <tr>
                    <th colspan="5">No Data</th>
                  </tr>
                </tbody>
              )}
            </table>
          </CardContent>
        </Card>
      </div>
      <div className="d-flex aligm-items-center justify-content-end gap-4 mt-4 ">
        <Button
          variant="contained"
          onClick={handleFinalSubmit}
          className="submit-btn"
        >
          Submit
        </Button>
        <Button variant="contained" color="error" className="cancel-btn">
          <Link to="/addChargerProfile" className="cancle-button">
            Cancel
          </Link>
        </Button>
      </div>

      <PopUp
        open={alertPopup}
        handleClosePopUp={handleClosePopup}
        title={"Alert"}
        message={alertMessage}
        btn1={true}
        btn1Text={"Ok"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleClosePopup}
        popUpWidth={"width-100"}
      />
      <PopUp
        open={openAddPopUp}
        handleClosePopUp={handleCloseAddPop}
        title={"Add Charging Schedule Period"}
        message={
          <AddChargingSchedulePopUp
            chargingPeriod={chargingPeriod}
            handleAddForm2={handleAddForm2}
            errors2={errors2}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={"submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleCloseAddPop}
        handleClickBtn2={handleSubmit2}
        popUpWidth="width-100"
      />
      <PopUp
        open={openEditPopUp}
        handleClosePopUp={handleEditPop}
        title={"Update Charging Schedule Period"}
        message={
          <AddChargingSchedulePopUp
            chargingPeriod={chargingPeriod}
            handleAddForm2={handleAddForm2}
            errors2={errors2}
          />
        }
        btn1={true}
        btn2={true}
        btn1Color={"cancel-btn"}
        btn2Color={" submit-btn"}
        btn1Text={"Cancel"}
        btn2Text={"Submit"}
        handleClickBtn1={handleEditPop}
        handleClickBtn2={handleEditSubmit}
        popUpWidth="width-100"
      />
      <PopUp
        open={deletePopup}
        title={"Confirm"}
        message={"Do you want to delete this ChargingSchedule"}
        btn1={true}
        btn1Text={"Cancel"}
        btn1Color={"submit-btn"}
        handleClickBtn1={handleDeletePopup}
        btn2={true}
        btn2Text={"Delete"}
        btn2Color={"cancel-btn"}
        handleClickBtn2={deleteChargingSchedule}
        handleClosePopUp={handleDeletePopup}
        popUpWidth={"width-100"}
      />
    </div>
  );
}
