import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { DatePicker } from "rsuite";
import { FaCalendar } from "react-icons/fa";

function ReserveNow({ handleSubmitOperation }) {
  const [inputFields, setInputFields] = useState({
    connectorId: "",
    idTag: "",
    parentIdTag: "",
    expiryDate: "",
  });
  const [inputFieldsValidation, setInputFieldsValidation] = useState({
    connectorId: "",
    idTag: "",
    expiryDate: "",
  });
  const DateFormatChange = (date) => {
    const localDate = new Date(date); // Create a Date object from the UTC string

    // Extract local time components
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");
    const seconds = String(localDate.getSeconds()).padStart(2, "0");

    // Format it as 'YYYY-MM-DDTHH:mm:ss'
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    // Return the formatted date
    return formattedDate;
  };

  function handleOnChange(key, value) {
    setInputFieldsValidation({ ...inputFieldsValidation, [key]: "" });
    if (key === "connectorId") {
      if (
        (value.charCodeAt(value.length - 1) >= 48 &&
          value.charCodeAt(value.length - 1) <= 57) ||
        value === ""
      ) {
        setInputFields({ ...inputFields, [key]: parseInt(value) });
      }
    } else {
      setInputFields({ ...inputFields, [key]: value });
    }
  }

  function handleSubmit() {
    let error = false;
    if (
      !inputFields.connectorId ||
      !inputFields.idTag ||
      !inputFields.expiryDate
    ) {
      error = true;
    }
    if (error) {
      if (!inputFields.connectorId) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          connectorId: "Connector Id is Mandatory",
        }));
      }
      if (!inputFields.idTag) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          idTag: "Id Tag is Mandatory",
        }));
      }
      if (!inputFields.expiryDate) {
        setInputFieldsValidation((inputFieldsValidation) => ({
          ...inputFieldsValidation,
          expiryDate: "Expiry Date is Mandatory",
        }));
      }
    }
    let payLoad = {
      connectorId: parseInt(inputFields.connectorId),
      idTag: inputFields.idTag,
      expiryDate: DateFormatChange(inputFields.expiryDate),
    };
    if (inputFields.parentIdTag) {
      payLoad.parentIdTag = inputFields.parentIdTag;
    }
    handleSubmitOperation(error, payLoad);
  }
  return (
    <>
      <Card
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "25px" }}
        className="mt-3"
      >
        <CardContent className="cardPadding ">
          <div className="row row-gap-3 mt-1">
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Connector Id *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.connectorId}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.connectorId}
                  size="small"
                  helperText={inputFieldsValidation.connectorId}
                  onChange={(e) => {
                    handleOnChange("connectorId", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>Id Tag *</b>
                </FormLabel>
                <TextField
                  error={inputFieldsValidation.idTag}
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.idTag}
                  size="small"
                  helperText={inputFieldsValidation.idTag}
                  onChange={(e) => {
                    handleOnChange("idTag", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <FormLabel>
                  <b>ParentId Tag </b>
                </FormLabel>
                <TextField
                  className="w-100"
                  required
                  variant="outlined"
                  value={inputFields.parentIdTag}
                  size="small"
                  onChange={(e) => {
                    handleOnChange("parentIdTag", e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div className="col-4">
              <FormControl className="simpleInput">
                <div className=" fs-14 fw-600">
                  <span>Expiry Date *</span>
                </div>

                <DatePicker
                  format="dd MMM yyyy hh:mm:ss aa"
                  showMeridiem
                  caretAs={FaCalendar}
                  value={inputFields?.expiryDate}
                  onChange={(date) => handleOnChange("expiryDate", date)}
                />

                <p
                  class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {inputFieldsValidation.expiryDate}
                </p>
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="mt-4 d-flex justify-content-end w-100">
        <Button
          color="primary"
          className="submit-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

export default ReserveNow;
