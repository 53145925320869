function ViewCsForm({ viewRow }) {
  return (
    <div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">MesssageID</b>
        <span>:</span>
        <span>{viewRow.messageId}</span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">Request</b>
        <span>:</span>
        <span>{viewRow.payload}</span>
      </div>
      <div className=" w-100 d-flex align-items-center gap-3 mb-2">
        <b className="fs-6 w-50">Response</b>
        <span>:</span>
        <span>{viewRow.response}</span>
      </div>
    </div>
  );
}

export default ViewCsForm;
