import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";

export default function PriceComponentsPopUp({
  priceConfigObj,
  handlePCchange,
  PcErrors,
  PCTypeDrop,
}) {
  return (
    <div className="py-3">
      <Box>
        <div className="d-flex flex-wrap gap-3 w-auto">
          <div className="col-4 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Type *</b>
              </FormLabel>
              <TextField
                select
                required
                className=" me-3 error"
                variant="outlined"
                size="small"
                error={PcErrors.type}
                value={priceConfigObj.type}
                onChange={(e) => handlePCchange("type", e.target.value)}
                helperText={PcErrors.type}
              >
                {PCTypeDrop.map((list, index) => (
                  <MenuItem key={index} value={list}>
                    {list}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
          <div className="col-4 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Price *</b>
              </FormLabel>
              <TextField
                error={PcErrors.price}
                className=" me-3 error"
                required
                variant="outlined"
                value={priceConfigObj.price}
                inputProps={{
                  onKeyDown: (e) => {
                    if (
                      !/[0-9]/.test(e.key) &&
                      ![
                        "Backspace",
                        "Delete",
                        "ArrowLeft",
                        "ArrowRight",
                      ].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  },
                }}
                size="small"
                helperText={PcErrors.price}
                onChange={(e) => {
                  handlePCchange("price", e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="col-4 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Vat *</b>
              </FormLabel>
              <TextField
                error={PcErrors.vat}
                className=" me-3 error no-arrows"
                required
                type="number"
                // step="0.1"
                variant="outlined"
                value={priceConfigObj.vat}
                size="small"
                helperText={PcErrors.vat}
                onChange={(e) => {
                  handlePCchange("vat", e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="col-4 w-45">
            <FormControl className="simpleInput">
              <FormLabel>
                <b>Step Size *</b>
              </FormLabel>
              <TextField
                error={PcErrors.step_size}
                className=" me-3 error no-arrows"
                required
                type="number"
                variant="outlined"
                value={priceConfigObj.step_size}
                size="small"
                helperText={PcErrors.step_size}
                onChange={(e) => {
                  handlePCchange("step_size", e.target.value);
                }}
              />
            </FormControl>
          </div>
        </div>
      </Box>
    </div>
  );
}
