import React from "react";

function FullLoader() {
  return (
    <div className="page_loader_full center loaderWithzIndex">
      <img src="loader.gif" width="80" height="80" alt="" />
    </div>
  );
}

export default FullLoader;
